const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const descendingDateComparator = (a, b, orderBy) => {
  const firstDate = new Date(a[orderBy]);
  const secondDate = new Date(b[orderBy]);

  if (secondDate < firstDate) {
    return -1;
  }
  if (secondDate > firstDate) {
    return 1;
  }
  return 0;
};

const filterByNumber = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const filterByDate = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingDateComparator(a, b, orderBy)
  : (a, b) => -descendingDateComparator(a, b, orderBy));

const getComparator = (order, orderBy) => {
  if (orderBy === 'date') return filterByDate(order, orderBy);

  return filterByNumber(order, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export { descendingComparator, getComparator, stableSort };
