import React from 'react'
import { Formik, Field } from 'formik'
import { connect } from 'react-redux'

import { PatientFlashMessage } from '../shared/PatientFlashMessage'

import { onContactInfoSubmit } from '../../actions/patients'

import { createPatientFlashMessage } from '../../lib/patient_utils'


class PatientContactInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			responseMessage: null
		}
	}

	onResendInvite = () => {
		let url = window.origin + '/' + this.props.patient.clinic_id + '/clients/' + this.props.patient.id + '/resend_invite';
		let verb = 'POST';
		fetch(url, {
			method: verb,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': this.props.csrfToken
			},
			body: JSON.stringify({})
		}).then((response) => response.json())
		.then(data => {
			if (data.success) {
				this.setState({
					responseMessage: createPatientFlashMessage(data.success, false)
				})
			}
			else if (data.error) {
				this.setState({
					responseMessage: createPatientFlashMessage(data.error, true)
				})
			}
		})
	}

	clearMessage = () => {
		this.setState({
			responseMessage: null
		});
	}

	render() {
		let patient = this.props.patient
		let last_login = new Date(patient.last_sign_in_at);
		let initialValues = {
			name: patient.name || '',
			email: patient.email || '',
			address: patient.address || '',
			city: patient.city || '',
			state: patient.state || '',
			zipcode: patient.zipcode || '',
			phone_number: patient.phone_number || ''
		};

		let stateAbbreviations = [
		 'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
		 'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
		 'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
		 'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
		 'VT','VI','VA','WA','WV','WI','WY'
		];
		let stateMarkup = [];
		for (var i = 0; i < stateAbbreviations.length; i++) {
			stateMarkup.push(<option key={i} value={stateAbbreviations[i]}>{stateAbbreviations[i]}</option>)
		}
		return (
      <div>
        <PatientFlashMessage
          message={this.state.responseMessage}
          clearMessage={this.clearMessage}
        />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            this.setState({
              errors: [],
              successMessages: []
            });

            //validate phone_number
            if (
              !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                values.phone_number
              )
            ) {
              let errors = this.state.errors;
              errors.push('Invalid phone number');
              this.setState({ errors: errors });
            }

            if (this.state.errors.length == 0) {
              setTimeout(() => {
                this.props.onSubmit(
                  values.name,
                  values.email,
                  values.address,
                  values.city,
                  values.state,
                  values.zipcode,
                  values.phone_number
                );
                this.setState({
                  successMessages: ['Contact information saved.']
                });
                setSubmitting(false);
              }, 400);
            }
          }}
        >
          {formik => (
            <div className='card mt-3'>
              <div className='card-body'>
                <form onSubmit={formik.handleSubmit}>
                  <div className='form-row'>
                    <div className='col-12'>
                      <p className='float-right'>
                        Last Login:{' '}
                        <span className='highlighted-text'>
                          {patient.last_sign_in_at == null
                            ? 'Never'
                            : `${
                                last_login.getMonth() + 1
                              }/${last_login.getDate()}/${last_login.getFullYear()}`}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <label htmlFor='name'>Name</label>
                        <Field
                          disabled={!this.props.isOwner}
                          id='name'
                          type='text'
                          className='form-control'
                          name='name'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='form-row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <label htmlFor='email'>Email</label>
                        <Field
                          id='email'
                          type='email'
                          required
                          className='form-control'
                          name='email'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='form-row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='address'>Address</label>
                        <Field
                          id='address'
                          required
                          type='text'
                          className='form-control'
                          name='address'
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='city'>City</label>
                        <Field
                          id='city'
                          type='text'
                          className='form-control'
                          name='city'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='form-row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='state'>State</label>
                        <Field
                          as='select'
                          id='state'
                          required
                          className='form-control'
                          name='state'
                        >
                          {stateMarkup}
                        </Field>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='zipcode'>Zipcode</label>
                        <Field
                          id='zipcode'
                          type='text'
                          required
                          className='form-control'
                          name='zipcode'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='form-row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <label htmlFor='phone_number'>Phone Number</label>
                        <Field
                          id='phone_number'
                          type='text'
                          className='form-control'
                          name='phone_number'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row mt-3 mb-2'>
                    <div className='col'>
                      <button
                        type='submit'
                        className='btn btn-outline-success ml-2 mt-2'
                      >
                        Save
                      </button>
                      {this.props.isOwner && patient['invitation_pending?'] && (
                        <button
                          type='button'
                          className='btn btn-outline-warning ml-2 mt-2'
                          onClick={this.onResendInvite}
                        >
                          Resend Invitation
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
	}
}

const mapStateToProps = state => {
	return {
		isOwner: state.patientIndex.isOwner,
		csrfToken: state.patientIndex.csrfToken
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSubmit: (name, email, address, city, state, zipcode, phone_number) => {
			dispatch(onContactInfoSubmit(name,
									email,
									address,
									city,
									state,
									zipcode,
									phone_number));
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PatientContactInfo)
