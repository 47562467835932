import fetch from 'cross-fetch';

export const onNewMessageSubmit = (text, clinicId, patientId, sentByClinic) => {
  return function (dispatch, getState) {
    dispatch(requestNewMessage());

    const state = getState().patientIndex;

    sendMessage(text, clinicId, patientId, sentByClinic, state.csrfToken);
  };
};

export const markMessagesRead = (conversationId, isFromClinic, clinicId) => {
  return function (dispatch, getState) {
    dispatch(requestMarkMessageRead());

    const state = getState().patientIndex;

    const conversationBody = isFromClinic
      ? { clinic_last_viewed: new Date() }
      : { patient_last_viewed: new Date() };

    let url =
      window.origin + '/' + clinicId + '/conversations/' + conversationId;

    return fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': state.csrfToken
      },
      body: JSON.stringify({
        conversation: conversationBody
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        dispatch(receiveNewConversationMetadata(responseJson.conversation));
      });
  };
};

export const requestMarkMessageRead = () => {
  return {
    type: 'REQUEST_MARK_MESSAGE_READ'
  };
};

export const receiveNewConversationMetadata = conversation => {
  return {
    type: 'RECEIVE_NEW_CONVERSATION_METADATA',
    conversation
  };
};

export const requestNewMessage = () => {
  return {
    type: 'REQUEST_NEW_MESSAGE'
  };
};

export const receiveNewMessageSuccess = message => {
  return {
    type: 'RECEIVE_NEW_MESSAGE_SUCCESS',
    message
  };
};

export const sendMessage = (
  text,
  clinicId,
  patientId,
  sentByClinic,
  csrfToken
) => {
  const url =
    window.origin + '/' + clinicId + '/clients/' + patientId + '/new_message';

  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify({
      message: {
        text,
        sent_by_clinic: sentByClinic
      }
    })
  });
  toastr.success('Message Delivered!');
};
