import {
  FILTER_CLIENT
} from '../actions/actionsType';

export default (state = '', action) => {
  switch (action.type) {
    case FILTER_CLIENT: {
      return {...state, clientFilter: action.client}
    }
    default:
      return state;
  }
};
