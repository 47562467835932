import React from 'react'
import { Droppable } from "react-beautiful-dnd";

const DropZone = ({children}) => {
  return (
    <Droppable droppableId='droppable'>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className='drop-zone'
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DropZone;
