import React from 'react'
import styled from 'styled-components'

const Message = props => {
	return (

		<div>
			{props.sent_by_me 
				? <ToMessage>{props.message.text}</ToMessage>
				: <FromMessage>{props.message.text}</FromMessage>
			}
			<small>{new Date(props.message.created_at).toLocaleString('en-US')}</small>
		</div>
	)
}

const ToMessage = styled.div`
	background-color: #007bff;
	color: #fff;
	border-radius: 5px;
	padding: 5px;
`;

const FromMessage = styled.div`
	background-color: #ebebeb;
	border-radius: 5px;
	padding: 10px;
`;

export default Message