import {
  Backdrop, CircularProgress, Dialog, makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessments } from '../../actions/assesstments';
import IndexTable from './IndexTable';
import NavBar from './NavBar';
import NewAssessment from './NewAssessment';

const useStylesOwner = makeStyles(() => ({
  dialog: {
    dialog: 999,
  },
}));

const useStyles = makeStyles(() => ({
  dialog: {
    zIndex: 999,
    paddingLeft: 0,
  },
}));

const Assessments = () => {
  const clinicId = useSelector((state) => state.patientIndex.clinicId);
  const patientId = useSelector((state) => state.patientIndex.activePatient);
  const fetching = useSelector((state) => state.patientIndex.fetching);
  const isOwner = useSelector((state) => state.patientIndex.isOwner);

  const [openAssessment, setOpenAssessment] = useState(false);
  const [assessment, setAssesment] = useState(null);
  const [openNewAssessment, setOpenNewAssessment] = useState(false);

  const dispatch = useDispatch();

  const classes = isOwner ? useStylesOwner() : useStyles();

  const handleOpenNewAssessment = () => {
    setOpenNewAssessment(!openNewAssessment);
  };

  const handleOpenAssessment = (obj) => {
    setOpenAssessment(true);
    setAssesment(obj);
  };

  useEffect(() => {
    dispatch(getAssessments(clinicId, patientId));
  }, [patientId]);

  return (
    <div>
      <Dialog
        open={openNewAssessment}
        className={classes.dialog}
        onClose={handleOpenNewAssessment}
      >
        <NewAssessment closeForm={handleOpenNewAssessment} />
      </Dialog>
      <Dialog
        open={openAssessment}
        className={`${classes.dialog} assessment-modal`}
        onClose={() => setOpenAssessment(false)}
      >
        <NewAssessment assessment={assessment} closeForm={() => setOpenAssessment(false)} />
      </Dialog>
      <Backdrop open={!!fetching} className={classes.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavBar openNewAssessment={handleOpenNewAssessment} />
      <IndexTable openAssessment={handleOpenAssessment} />
    </div>
  );
};

export default Assessments;
