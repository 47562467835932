import { Link } from '@material-ui/core';
import React, { useState } from 'react';
import { weightLossTableColumns } from '../../../../helpers/weight_loss';
import DataGridMUI from '../../../common/DataGridMUI';

const WLPList = props => {
  const {
    newWeightLossProgramUrl,
    csrfToken,
    clinicId,
    weightLossProgramsList
  } = props;
  const [weightLossList, setWeightLossList] = useState(weightLossProgramsList);
  return (
    <div className='flex-grow-1 container-p-y'>
      <div className='card'>
        <div className='card-header'>
          <h4 className='font-weight-bold ml-3 d-inline'>
            Weight Loss Program
          </h4>
          <Link
            className='btn btn-primary float-right text-white btn-responsive'
            href={newWeightLossProgramUrl}
          >
            Create
          </Link>
        </div>
        <div className='card-body'>
          <DataGridMUI
            list={weightLossList}
            columns={weightLossTableColumns(
              setWeightLossList,
              csrfToken,
              clinicId
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default WLPList;
