export const generateFoodList = (userWlProgram, date) => {
  let start = new Date(userWlProgram.start_date);
  let today = new Date(date);
  let diff = Math.round((today - start) / (1000 * 60 * 60 * 24)) + 1;

  const { phases } = userWlProgram.weight_loss_program;
  let totalDays = 0
  let current_phase = 0;

  for(let i= 0; i < phases?.length; i++) {
    totalDays += phases[i].days;
    current_phase = i

    if(diff <= totalDays) break
  }

  const allowedFoods =
    userWlProgram?.weight_loss_program.phases[current_phase]?.allowed_food;
  const arr = [];
  allowedFoods?.map(allowedFood => {
    const obj = JSON.parse(allowedFood.replaceAll('=>', ':'));
    arr.push({
      label: obj.category,
      options: createDropDownOptions(obj.name.split(','))
    });
  });
  return arr;
};


const createDropDownOptions = allowedFoodList => {
  const dropDownArray = [];
  allowedFoodList.map(allowedFood =>
    dropDownArray.push({ value: allowedFood, label: allowedFood })
  );
  return dropDownArray;
};
