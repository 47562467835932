import React from 'react'
import { InputAdornment, Snackbar, Tooltip } from '@mui/material'
import { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import ClipboardIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';

const CopyToClipboardButton = ({
  inviteToken,
  clinicSlug,
  handleRefreshToken
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    let origin = window.location.origin;
    navigator.clipboard.writeText(
      `${origin}/${clinicSlug}/invite_user_form?token=${inviteToken}`
    );
  };

  return (
    <>
      <TextField
        value={`${clinicSlug}/${inviteToken}`}
        className='mr-3'
        size='small'
        onClick={handleClick}
        variant='outlined'
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              <InputAdornment position='end'>
                <IconButton onClick={handleClick}>
                  <ClipboardIcon />
                </IconButton>
                <Tooltip placement='top' title='Refresh Token'>
                  <ReplayIcon
                    fontSize='small'
                    className='cursor-pointer'
                    onClick={handleRefreshToken}
                  />
                </Tooltip>
              </InputAdornment>
            </>
          )
        }}
      />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message='Copied to clipboard'
      />
    </>
  );
};

export default CopyToClipboardButton
