// /* eslint-disable import/no-named-as-default */
// /* eslint-disable import/no-named-as-default-member */
// /* eslint-disable react/prop-types */
// /* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import {
  ActionCableProvider,
  ActionCableConsumer
} from 'react-actioncable-provider';
import ActionCable from 'actioncable';

import { fetchPatientList, toggleActivePatient } from '../../actions/patients';
import {
  receiveNewMessageSuccess,
  markMessagesRead
} from '../../actions/messages';

import PatientListItem from '../../components/patients/PatientListItem';
import PatientDetails from '../../components/patients/PatientDetails';
import MessageBox from '../../components/messages/MessageBox';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
export class UnreadMessageTab extends React.Component {
  constructor(props) {
    super(props);

    let showSidebar = false; //hides window if screen is less than col-md

    if (window.innerWidth >= 768) {
      showSidebar = true;
    }
    this.state = {
      showSidebar: showSidebar,
      patientDetailsColClass: 'col-12',
      archived: false
    };
  }

  componentDidMount() {
       const element = document.querySelector(
         '[data-react-class="messages/UnreadMessages"]'
       );
       element.style.height = '100%';
    if (this.props.isOwner) {
      this.props.loadPatientList(this.props.clinicId);
      this.setState({ patientDetailsColClass: 'col-12 col-lg-10' });
    } else {
      this.props.loadPatient(this.props.clinicId, this.props.userId);
    }
  }

  receivedMessage(patient, message) {
    if ($('.message-tab-' + patient.id).hasClass('active')) {
      this.props.markMessagesRead(
        patient.conversation.id,
        this.props.isOwner,
        patient.clinic_id
      );
    }
    this.props.onMessageReceived(message);
  }

  toggleSidebar(event) {
    event.preventDefault();
    this.setState({
      showSidebar: this.state.showSidebar ? false : true,
      patientDetailsColClass: 'col-12'
    });
  }

  render() {
    let activePatient, patientRows;
    let patientList =
      this.props.filteredPatientList == null
        ? this.props.patients
        : this.props.filteredPatientList;

    if (this.props.isOwner) {
      patientRows = patientList.map(patient => {
        return (
          patient.archive == this.state.archived && (
            <PatientListItem
              key={patient.id}
              patient={patient}
              active={patient.id == this.props.activePatient}
              clinicId={this.props.clinicId}
              type={'unreadMessage'}
            />
          )
        );
      });

      activePatient = this.props.patients.find(
        patient => patient.id == this.props.activePatient
      );
    } else {
      activePatient = this.props.patients.find(
        patient => patient.id == this.props.userId
      );
    }

    const actionCables = this.props.patients.map(patient => {
      if (patient.conversation) {
        return (
          <ActionCableConsumer
            key={patient.conversation.id}
            channel={{
              channel: 'ConversationsChannel',
              conversation: patient.conversation.id,
              user_id: this.props.userId
            }}
            onReceived={this.receivedMessage.bind(this, patient)}
          />
        );
      }
    });

    let websocketProtocol =
      window.location.protocol == 'https:' ? 'wss:' : 'ws:';
    const cable = ActionCable.createConsumer(
      websocketProtocol + '//' + window.location.host + '/cable'
    );

    return (
      <ActionCableProvider cable={cable} className='h-100'>
        <div className='row h-100 mr-0 mr-lg-2'>
          {this.props.isOwner && (
            <div
              style={{ zIndex: 10 }}
              className={`col-lg-2 col-auto p-0 ${
                this.state.showSidebar ? 'show-nav h-100 d-lg-block'
                  : 'hide-nav'
              }`}
            >
              <div  className={`chat-wrapper h-100 sidenav-wrapper ${
                  this.state.showSidebar ? 'show-nav' : 'hide-nav'
                }`}>
                <div className='flex-grow-1 position-relative overflow-hidden'>
                  <div className='row no-gutters h-100'>
                    <div className='chat-sidebox col'>
                      <span className='d-flex w-100 mb-2 justify-content-end pt-2'>
                        <ArrowBackIosIcon
                          fontSize='small'
                          className='d-block d-lg-none nav-icon'
                          onClick={() => this.setState({ showSidebar: false })}
                        />
                      </span>
                      <div className='flex-grow-0 px-4'>
                        <h4 className='mt-3 text-center text-dark'>
                          Unread Messages
                        </h4>
                      </div>
                      <div className='flex-grow-1 position-relative'>
                        <div className='chat-contacts list-group chat-scroll py-3'>
                          {patientRows.length > 0 ? (
                            patientRows
                          ) : (
                            <h5 className='mt-3 text-center text-dark'>
                              No Unread Messages
                            </h5>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={`${this.state.patientDetailsColClass} ${
              this.state.showSidebar ? 'col-lg-10' : 'order-lg-first col-lg-12'
            } w-100 pr-0 pr-lg-2 scrollable-box`}
          >
            {this.props.isOwner && (
              <a
                href='#'
                className={` mb-2 ${
                  this.props.isOwner
                    ? 'd-inline-block border px-3 py-2 toggle-sidebar btn btn-primary'
                    : 'd-none'
                }
                `}
                onClick={event => this.toggleSidebar(event)}
              >
                {this.state.showSidebar ? (
                  <i class='mr-1 fe fe-arrow-left'></i>
                ) : (
                  <i class='mr-1 fe fe-arrow-right'></i>
                )}
              </a>
            )}
            {this.props.type === 'unreadMessages' ? (
              <MessageBox
                patient={activePatient}
                isOwner={this.props.isOwner}
              />
            ) : (
              <PatientDetails patient={activePatient} />
            )}
          </div>
        </div>
        {actionCables}
      </ActionCableProvider>
    );
  }
}

UnreadMessageTab.defaultProps = {
  patients: [],
  clinicId: '',
  csrfToken: '',
  activePatient: null,
  isOwner: false
};

const mapStateToProps = state => {
  return {
    patients: state.patientIndex.patients,
    clinicId: state.patientIndex.clinicId,
    clinicName: state.patientIndex.clinicName,
    activePatient: state.patientIndex.activePatient,
    isOwner: state.patientIndex.isOwner,
    userId: state.patientIndex.userId,
    filteredPatientList: state.patientIndex.filteredPatientList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadPatientList: clinicId => {
      dispatch(fetchPatientList(clinicId, 'unread'));
    },
    loadPatient: (clinicId, patientId) => {
      dispatch(toggleActivePatient(clinicId, patientId));
    },
    onMessageReceived: message => {
      dispatch(receiveNewMessageSuccess(message));
    },
    markMessagesRead: (conversationId, isClinic, clinicId) => {
      dispatch(markMessagesRead(conversationId, isClinic, clinicId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnreadMessageTab);
