import axios from 'axios';

export const getAllowedFoods = async (clinicId, setFoodList) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(`/${clinicId}/allowed_foods`, { headers });
    const foodList = response.data.foodList ?? [];

    const parsedList = foodList.map(food =>
      JSON.parse(food.replaceAll('=>', ':'))
    );

    const uniqueList = Array.from(new Set(parsedList.map(JSON.stringify))).map(
      JSON.parse
    );

    console.log(uniqueList);
    setFoodList(uniqueList);
  } catch (error) {
    toastr.error('Unable to fetch data!');
  }
};
