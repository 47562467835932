import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function CustomDialog({
  isOpen,
  handleSchedule,
  handleCancelDialog,
  cancelAppointment
}) {
  const isTimeSpanShort = () => {
    let currentTime = Date.parse(new Date());
    let appointmentTime = Date.parse(cancelAppointment.StartTime);
    let leftTime = Math.floor(
      ((appointmentTime - currentTime) / (1000 * 60 * 60))
    );
    return leftTime <= 4 && leftTime >= 0 ? true : false;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancelDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'Notice'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {isTimeSpanShort()
            ? 'Your appointment is within 4 hours, to reschdule or cancel this appointment call the office'
            : 'Before cancel an appointment you have to schedule new appointment'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={handleCancelDialog}>
          Cancel
        </Button>
        {!isTimeSpanShort() ? (
          <Button color='primary' onClick={handleSchedule}>
            Reschedule
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
