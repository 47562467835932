import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: 65,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& input[type=date]': {
      backgroundColor: 'transparent !important;',
    },
  },
}));

const DatePickers = () => {
  const classes = useStyles();
  const clinicId = useSelector((state) => state.clinicId);

  const urlString = window.location.href;
  const url = new URL(urlString);

  const options = {
    year: 'numeric', month: '2-digit', day: '2-digit',
  };
  const date = new Date();
  const firstDate = url.searchParams.get('start_date') || new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('fr-CA', options);
  const endDate = url.searchParams.get('end_date') || new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('fr-CA', options);

  return (
    <form action={`/${clinicId}/order_index`} method="get">
      <Grid className={classes.container} noValidate>
        <TextField
          id="start-date"
          label="Start Date"
          type="date"
          defaultValue={firstDate}
          className={`${classes.textField} report-date-picker`}
          name="start_date"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="end-date"
          label="End Date"
          type="date"
          name="end_date"
          defaultValue={endDate}
          className={`${classes.textField} report-date-picker`}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <IconButton type="submit">
          <Search />
        </IconButton>
      </Grid>
    </form>
  );
};

export default DatePickers;
