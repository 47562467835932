import React from 'react';

const RadioOptions = props => {
  const { heading, radioCheck, name, setRadioCheck } = props

  const onChangeValue = event => {
    setRadioCheck(event.target.value === 'yes' ? true : false);
  };

  return (
    <div onChange={e => onChangeValue(e)} className='mt-3'>
      <div
        dangerouslySetInnerHTML={{
          __html: heading
        }}
      />
      <div className='d-block mt-2'>
        <input
          type='radio'
          value='yes'
          checked={radioCheck}
          name={name}
          className='ml-2'
        />{' '}
        Yes
        <input
          type='radio'
          value='no'
          name={name}
          checked={!radioCheck}
          className='ml-2'
        />{' '}
        No
      </div>
    </div>
  );
};

export default RadioOptions;
