import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  IconButton, Toolbar, Tooltip, Typography,
} from '@material-ui/core';


const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const TableHeader = ({ resetFilters, title }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {title}
      </Typography>
      <Tooltip title='Clear Filter'>
        <IconButton aria-label='Clear Filter' onClick={resetFilters}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

TableHeader.propTypes = {
  resetFilters: PropTypes.func.isRequired,
};

export default TableHeader;
