import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import RadioOptions from './RadioOptions';
import Checkbox from './CheckBox';
import { agreementSignCall } from '../../../actions/weight_loss';
import axios from 'axios';

const AgreementModal = props => {
  const {
    show,
    setShow,
    weightlossprogram,
    csrfToken,
    setUpdatePatient,
    patient
  } = props;

  const handleClose = () => setShow(false);

  const [check, setCheck] = useState(false);

  const handleCheck = () => {
    setCheck(!check);
  };

  const updateAgreement = () => {
    const url = `/${weightlossprogram?.program?.clinic_id}/agreement_sign`;

    agreementSignCall(
      weightlossprogram,
      csrfToken,
      check,
      url,
      axios.patch,
      handleClose,
      setUpdatePatient
    );
  };

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header>
        <h4 className='bold-heading'>INFORMED CONSENT</h4>
      </Modal.Header>
      <Modal.Body>
        <div
          dangerouslySetInnerHTML={{
            __html: weightlossprogram?.program?.agreement
          }}
        />
        <div style={{ height: 200, width: '100%' }}>
          <div className='mb-3'>
            <h2>{patient.name}</h2>
            <br></br>
            <label>
              <Checkbox
                className='bold-heading'
                label='I have read the above informed consent form. By checking this box I agree that the benefits and risks of this weight loss program have been communicated to me, I fully understand them and agree to participate in this weight loss program.'
                value={check}
                onChange={handleCheck}
              />
            </label>
          </div>
        </div>
        <button
          className='btn btn-primary mt-2 ml-2'
          onClick={updateAgreement}
          disabled={!check}
        >
          Save
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default AgreementModal;
