import React from "react"
import styled from 'styled-components'

import MessageList from './MessageList'
import NewMessageForm from './NewMessageForm'

const Conversation = props => {
	return (
		<div className="card">
			<div className="card-body">
				{ props.patient.conversation &&
					<MessageListContainer>
						<MessageList messages={props.patient.conversation.messages} />
					</MessageListContainer>
				}
				<NewMessageForm patient={props.patient} />
			</div>
    </div>
	)
}

const MessageListContainer = styled.div`
	max-height: 500px;
	min-height: 200px;
	overflow-y: scroll;
	display: flex;
	flex-direction: column-reverse;
`;

export default Conversation
