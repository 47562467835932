import { GridActionsCellItem } from '@mui/x-data-grid';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { format } from 'date-fns';

export const intakeColumns = showStatus => {
  const columns = [
    {
      field: 'first_name',
      renderCell: value =>
        value.row.response
          ? `${value.row.response.data.firstName} ${value.row.response.data.lastName}`
          : value.row.name,
      headerName: 'Name',
      filterable: true,
      flex: 1
    },
    {
      field: 'email',
      renderCell: value =>
        value.row.response ? value.row.response.data.email : value.row.email,
      headerName: 'Email',
      flex: 1
    },
    showStatus && {
      field: 'send_email',
      renderCell: value => (value.row.send_email ? 'Enabled' : 'Disabled'),
      headerName: 'Daily Emails',
      flex: 1
    },
    {
      field: 'created_at',
      headerName: 'Date',
      flex: 1,
      valueGetter: params => params.row.created_at,
      renderCell: params =>
        format(new Date(params.row.created_at), 'dd-MMM-yyyy'),
      sortable: true
    },
    {
      headerName: 'Action',
      field: 'actions',
      filterable: false,
      type: 'actions',
      width: 80,
      renderCell: value => (
        <button
          className='btn btn-sm btn-outline-dark'
          onClick={() => {
            if (value.row.response) {
              window.location.href = `patient_responses/${value.id}/edit?dif_id=${value.row.dynamic_intake_form_id}`;
            } else window.location.href += `/${value.id}`;
          }}
        >
          Edit
        </button>
      )
    }
  ];

  // Create the "Status" column based on showStatus
  const statusColumn = showStatus
    ? {
        field: 'status',
        renderCell: value => value.row.status,
        headerName: 'Status',
        flex: 1
      }
    : {
        field: 'status',
        renderCell: value =>
          value.row.response
            ? value.row.response.data.status
            : value.row.status,
        headerName: 'Status',
        flex: 1
      };

  // Insert the "Status" column after index 1
  columns.splice(2, 0, statusColumn);

  return columns;
};

export const DYNAMIC_FORM_INTIAL_VALUES = [
  {
    name: 'firstName',
    type: 'text',
    title: 'First Name:',
    required: true,
    deleteable: false,
    colspan: 6
  },
  {
    name: 'lastName',
    type: 'text',
    title: 'Last Name:',
    required: true,
    deleteable: false,
    colspan: 6
  },
  {
    name: 'email',
    type: 'email',
    title: 'Email Address:',
    required: true,
    deleteable: false,
    colspan: 6
  },
  {
    name: 'phone',
    type: 'number',
    title: 'Phone Number:',
    required: true,
    deleteable: false,
    colspan: 6
  },
  {
    name: 'mailingAddress',
    type: 'heading',
    title: 'Mailing Address',
    required: false,
    deleteable: false,
    colspan: 12
  },
  {
    name: 'address1',
    type: 'text',
    title: 'Address 1:',
    required: false,
    deleteable: false,
    colspan: 12
  },
  {
    name: 'address2',
    type: 'text',
    title: 'Address 2:',
    required: false,
    deleteable: false,
    colspan: 12
  },
  {
    name: 'country',
    type: 'text',
    title: 'Country:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'city',
    type: 'text',
    title: 'City:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'state',
    type: 'text',
    title: 'State / Province:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'zipCode',
    type: 'number',
    title: 'Zip Code:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'genderCurrent',
    type: 'radio',
    title: 'Gender (Identity currently):',
    choices: ['Male', 'Female', 'Prefer not to answer'],
    required: false,
    deleteable: false,
    colspan: 12
  },
  {
    name: 'genderBirth',
    type: 'radio',
    title: 'Gender (Assigned at birth):',
    choices: ['Male', 'Female'],
    required: false,
    deleteable: false,
    colspan: 12
  },
  {
    name: 'hieght',
    type: 'number',
    title: 'Height:',
    required: false,
    deleteable: false,
    colspan: 4
  },

  {
    name: 'weight',
    type: 'number',
    title: 'Weight:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'dateOfBirth',
    type: 'text',
    title: 'Date of Birth:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'age',
    type: 'number',
    title: 'Age:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'occuptaion',
    type: 'text',
    title: 'Occuptaion:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'maritalStatus',
    type: 'text',
    title: 'Marital Status:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'numberOfChildren',
    type: 'number',
    title: 'Number of Children:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'whoreferredYou',
    type: 'text',
    title: 'Who referred you (If applicable):',
    required: false,
    deleteable: false,
    colspan: 8
  },
  {
    name: 'emergencyContactInformation',
    type: 'heading',
    title: 'Emergency Contact Information',
    required: false,
    deleteable: false,
    colspan: 12
  },
  {
    name: 'emergencyContactName',
    type: 'text',
    title: 'Emergency Contact Name:',
    required: false,
    deleteable: false,
    colspan: 4
  },
  {
    name: 'emergencyContactPhone',
    type: 'number',
    title: 'Emergency Contact Phone:',
    required: false,
    deleteable: false,
    colspan: 8
  },
  {
    name: 'sendSms',
    type: 'radio',
    title: 'I consent to receive sms text message appointment reminders:',
    choices: ['Yes', 'No'],
    required: true,
    deleteable: true,
    colspan: 12
  },
  {
    name: 'signature',
    type: 'text',
    title: 'Please sign below to continue:',
    required: true,
    deleteable: true,
    colspan: 12
  }
];

export const dynamicIntakeColumns = ({ handleDelete, handleEdit }) => [
  {
    field: 'id',
    headerName: 'Id',
    flex: 1
  },
  {
    field: 'form_name',
    headerName: 'Name',
    filterable: true,
    flex: 1
  },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Edit'
        showInMenu
        onClick={() => handleEdit(params.id)}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label='Delete'
        showInMenu
        onClick={() => handleDelete(params.id)}
      />
    ]
  }
];
