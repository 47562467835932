import { Link } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import React from 'react';

const TableBody = props => {
  const { clinicsList, handleOnChange } = props;
  return (
    <tbody>
      {clinicsList?.map((clinic, index) => (
        <tr key={clinic.id}>
          <td>{clinic.id}</td>
          <td>{clinic.name}</td>
          <td>{clinic?.owner?.name}</td>
          <td>
            <div className='actions'>
              <Link
                className='btn btn-sm btn-outline-success'
                href={`/${clinic.id}`}
              >
                Visit
              </Link>
              <Form onChange={e => handleOnChange(e, clinic, index)}>
                <div className='mb-3'>
                  <Form.Check
                    inline
                    label='Weight Loss Program'
                    name='wlp_available'
                    type='checkbox'
                    checked={clinic.wlp_available}
                    id={`clinic-${clinic.id}-wlp_available`}
                  />
                  <Form.Check
                    inline
                    label='Scheduler'
                    name='scheduler_available'
                    type='checkbox'
                    checked={clinic.scheduler_available}
                    id={`clinic-${clinic.id}-scheduler_available`}
                  />
                  <Form.Check
                    inline
                    label='Initial Training Emails'
                    name='initial_training_emails'
                    type='checkbox'
                    checked={clinic.initial_training_emails}
                    id={`clinic-${clinic.id}-initial_training_emails`}
                  />
                </div>
              </Form>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
