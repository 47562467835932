import React from 'react';
import PropTypes from 'prop-types';

const ActivePackageTable = (props) => {
  const { activePackages, selectPackage } = props;

  const tableRows = activePackages.map((activePackage) => (
    <tr key={activePackage.id}>
      <td>
        <button onClick={() => selectPackage(activePackage)} type="button" className="btn btn-link">
          {activePackage.name}
        </button>
      </td>
      <td>{activePackage.remaining_sessions}</td>
    </tr>
  ));

  return (
    <>
      <h4>Active Packages</h4>
      <table className="table">
        <thead>
          <tr>
            <td>Name</td>
            <td>Sessions Remaining</td>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    </>
  );
};

ActivePackageTable.propTypes = {
  activePackages: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    remaining_sessions: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  selectPackage: PropTypes.func.isRequired,
};

export default ActivePackageTable;
