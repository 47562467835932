import React from 'react'

import MeasurementDifferenceSummary from '../measurements/MeasurementDifferenceSummary'
import MeasurementComparison from '../measurements/MeasurementComparison'
import ProfileImageCarousel from '../measurements/ProfileImageCarousel'
import MeasurementComparisonSelect from '../measurements/MeasurementComparisonSelect'

const PatientSummary = props => {
	return (
		<>
			<div className="row pt-3 pr-3 pl-3">
				<div className="col card">
					<div className="card-body">
						<ProfileImageCarousel measurements={props.patient.measurements} />
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<MeasurementDifferenceSummary measurements={props.patient.measurements} />
				</div>
			</div>
			<div className="row">
				<div className="col">
					<div className="card mt-3">
						<div className="card-body">
							<div className="card-title">
								<h5 className="subheading">Compare Dates</h5>
							</div>
							<MeasurementComparisonSelect patient={props.patient} />
							<MeasurementComparison patient={props.patient} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PatientSummary
