/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import Conversation from '../messages/Conversation';
import SelectClient from '../../components/common/SelectClient';

const MessageBox = props => {
  const { patient, isOwner } = props;

  return (
    <div>
      {patient ? (
        <>
          <div className='tab-content' id='patientTabContent'>
            <div
              className='tab-pane fade show active'
              id='messages'
              role='tablpanel'
            >
              <Conversation patient={patient} />
            </div>
          </div>
        </>
      ) : (
        <SelectClient isOwner={isOwner} />
      )}
    </div>
  );
};

export default MessageBox;
