import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { reportColumns } from '../../helpers/scheduler';
import { deleteAppointment, updateAppointment } from '../../actions/scheduler';
import { toast } from 'react-toastify';

const ReportTable = ({
  data,
  clinicId,
  csrfToken,
  handleAppointments,
  dateRange,
  handleRecAppointment
}) => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const filterAppointments = data.filter(
      appointment => appointment.Subject != 'Not Available'
    );

    const modifiedAppointments = filterAppointments.map(appointment => {
      appointment['end_time'] = new Date(appointment.EndTime)
        .toLocaleTimeString()
        .slice(0, 5);
      appointment['start_time'] = new Date(appointment.StartTime)
        .toLocaleTimeString()
        .slice(0, 5);
        return appointment;
    });

    setAppointments([...modifiedAppointments]);
  }, [data]);

  const handleUpdateStatus = async (appointment, status) => {
    let result = await updateAppointment(
      clinicId,
      { ...appointment, status: status },
      csrfToken,
      false,
      [],
      false,
      false,
      true,
      dateRange[0].startDate,
      dateRange[0].endDate,
      true
    );
    handleAppointments(result);
  };

  const handleDelete = async appointmentId => {
    try {
      let result = await deleteAppointment(clinicId, appointmentId);
      handleAppointments(result);
    toast.success("Appointment Deleted Successfully")
    }
    catch(e){
      console.error(e.message)
      toast.error(e.message);
    }
  };

  return (
    <div className='card m-3 p-4'>
      <div className='card-header font-weight-bold '>
        <h4 className='font-weight-bold ml-3 d-inline'>Appointments Data</h4>
      </div>
      <div className='card-body'>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            getRowId={row => row.Id}
            rows={appointments}
            disableSelectionOnClick
            columns={reportColumns(
              handleUpdateStatus,
              handleDelete,
              handleRecAppointment
            )}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Toolbar: GridToolbar
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportTable;
