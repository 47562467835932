import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Collapse,
  makeStyles,
  Typography,
  Button,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody
} from '@material-ui/core';
import clsx from 'clsx';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import CreditCard from './CreditCard';
import StoreItem from './StoreItem';
import { payOrderWithBolt } from '../../actions/orders';
import ConnectingIssues from './ConnectingIssues';
import CashPayment from './CashPayment';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 700
  },
  media: {
    height: 0,
    maxWidth: 250,
    paddingTop: '32.25%',
    margin: 'auto'
  },
  mediaPicture: {
    backgroundSize: 'contain'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  },
  cardHeader: {
    textAlign: 'center'
  },
  rates: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  btnAction: {
    padding: theme.spacing(2)
  },
  payContainer: {
    justifyContent: 'space-around',
    '& > button': {
      width: 240
    }
  }
}));

const Order = ({ order, close, orderFor }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [creditCard, setCreditCard] = useState(false);
  const [payWithCash, setPayWithCash] = useState(false);
  const logo = useSelector(state =>
    state.patientIndex ? state.patientIndex.logo : state.logo
  );
  const token = useSelector(state =>
    state.patientIndex ? state.patientIndex.csrfToken : state.token
  );
  const clinicId = useSelector(state => state.clinicId);
  const [error, setError] = useState(null);

  const isActiveOwner = useSelector(state =>
    state.patientIndex
      ? state.patientIndex.isOwner && state.patientIndex.subscriptionActive
      : false
  );

  const dispatch = useDispatch();

  const formatRate = (number, sign = 1) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(number * sign);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandCreditCard = () => {
    setPayWithCash(false);
    setCreditCard(!creditCard);
  };

  const handleBoltPayments = () => {
    dispatch(
      payOrderWithBolt(order.clinic_id, token, order.id, close, setError)
    );
  };

  if (!order) return null;

  return (
    <Grid>
      <ConnectingIssues error={error} setError={setError} />
      <Card className={classes.card}>
        <CardHeader
          classes={{ root: classes.cardHeader }}
          title={order.client}
        />
        <CardHeader
          classes={{ root: classes.cardHeader }}
          title={`Order #${order.id}`}
          subheader={new Date(order.created_at).toDateString()}
        />
        <CardMedia
          className={`${classes.media} order-logo`}
          image={logo}
          title='My Ideal Traker'
          classes={{ root: classes.mediaPicture }}
        />
        <CardContent className={`${classes.cardContent} order-card-content`}>
          <Grid className={classes.rates}>
            <Typography
              variant='h5'
              color='textSecondary'
              component='h3'
              align='right'
              className='order-text'
            >
              Subtotal
            </Typography>
            <Typography
              variant='h5'
              color='textPrimary'
              component='h1'
              align='center'
              className='order-text'
            >
              {formatRate(order.subtotal)}
            </Typography>
          </Grid>
          <Grid className={classes.rates}>
            <Typography
              variant='h5'
              color='textSecondary'
              component='h3'
              align='right'
              className='order-text'
            >
              Discounts
            </Typography>
            <Typography
              variant='h5'
              color='textPrimary'
              component='h1'
              align='center'
              className='order-text'
            >
              {`${formatRate(order.discounts, -1)}`}
            </Typography>
          </Grid>
          <Grid className={classes.rates}>
            <Typography
              variant='h5'
              color='textSecondary'
              component='h3'
              className='order-text'
              align='right'
            >
              Taxes
            </Typography>
            <Typography
              variant='h5'
              color='textPrimary'
              component='h1'
              className='order-text'
              align='center'
            >
              {formatRate(order.taxes)}
            </Typography>
          </Grid>
          {order.refund_amount > 0 && (
            <Grid className={classes.rates}>
              <Typography
                variant='h5'
                color='textSecondary'
                component='h3'
                className='order-text'
                align='right'
              >
                Refund
              </Typography>
              <Typography
                variant='h5'
                color='textPrimary'
                component='h1'
                className='order-text'
                align='center'
              >
                {formatRate(order.refund_amount)}
              </Typography>
            </Grid>
          )}
          <Grid className={classes.rates}>
            <Typography
              variant='h5'
              color='textSecondary'
              component='h3'
              className='order-text'
              align='right'
            >
              Amount Paid
            </Typography>
            <Typography
              variant='h4'
              color='textPrimary'
              component='h1'
              className='order-text'
              align='center'
            >
              {formatRate(order.amount_paid - order.refund_amount)}
            </Typography>
          </Grid>
          <Grid className={classes.rates}>
            <Typography
              variant='h5'
              color='textSecondary'
              component='h3'
              className='order-text'
              align='right'
            >
              Total
            </Typography>
            <Typography
              variant='h4'
              color='textPrimary'
              component='h1'
              className='order-text'
              align='center'
            >
              {formatRate(order.total)}
            </Typography>
          </Grid>
        </CardContent>
        {order.status === 0 || order.status === 4 ? (
          <>
            <CardActions
              disableSpacing
              className={`${classes.payContainer} flex-column flex-md-row`}
            >
              <Button
                variant='contained'
                color='default'
                className='mr-1 btn-responsive mb-3 mb-md-0'
                classes={{ root: classes.btnAction }}
                onClick={handleExpandCreditCard}
                endIcon={
                  <ExpandMoreIcon
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: creditCard
                    })}
                    aria-expanded={creditCard}
                  />
                }
              >
                Pay With Card
              </Button>
              {isActiveOwner || !!clinicId ? (
                <>
                  <Button
                    variant='contained'
                    color='default'
                    className='mr-1 btn-responsive mb-3 mb-md-0'
                    classes={{ root: classes.btnAction }}
                    onClick={() => {
                      setPayWithCash(!payWithCash);
                      setCreditCard(false);
                    }}
                    endIcon={
                      <ExpandMoreIcon
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: payWithCash
                        })}
                        aria-expanded={payWithCash}
                      />
                    }
                  >
                    Pay With Cash
                  </Button>

                  <Button
                    variant='contained'
                    color='default'
                    classes={{ root: classes.btnAction }}
                    onClick={handleBoltPayments}
                    className='btn-responsive mb-3 mb-md-0'
                  >
                    Pay With Bolt
                  </Button>
                </>
              ) : null}
            </CardActions>
            <Collapse in={creditCard} timeout='auto' unmountOnExit>
              <CreditCard order={order} close={close} orderFor={orderFor} />
            </Collapse>
            {isActiveOwner || !!clinicId ? (
              <Collapse in={payWithCash} timeout='auto' unmountOnExit>
                <CashPayment
                  order={order}
                  close={close}
                  orderFor={orderFor}
                  formatRate={formatRate}
                />
              </Collapse>
            ) : null}
          </>
        ) : null}
        <CardActions disableSpacing>
          <Button
            fullWidth
            variant='text'
            color='default'
            classes={{ root: classes.btnAction }}
            onClick={handleExpandClick}
            endIcon={
              <ExpandMoreIcon
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
                aria-expanded={expanded}
              />
            }
          >
            Show Items
          </Button>
        </CardActions>
        <Collapse
          in={expanded}
          timeout='auto'
          unmountOnExit
          className='order-table-wrapper'
        >
          <CardContent>
            <Table className='table-responsive'>
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.order_items.map((obj, index) => (
                  <StoreItem
                    key={`item-${obj.id}`}
                    currentIndex={index}
                    itemList={order.order_items}
                    defaultListItem={obj}
                  />
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
};

Order.propTypes = {
  order: PropTypes.objectOf(
    oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.object)
    ])
  ),
  close: PropTypes.func.isRequired
};

Order.defaultProps = {
  order: null
};

export default Order;
