import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import { finishFetching, startFetching } from '../../actions/fetching';
import ConnectingIssues from './ConnectingIssues';
import { payOrderWithTerminal } from '../../actions/orders';

const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'Febuary' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

const useStyles = makeStyles(() => ({
  submitBtn: {
    marginTop: '1rem',
  },
}));

const CreditCard = ({ order, close, orderFor }) => {
  const cardUrl = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.cardUrl
      : state.cardUrl
  ));
  const csrfToken = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.csrfToken
      : state.token
  ));
  const usStates = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.usStates
      : state.usStates
  ));

  const isActiveOwner = useSelector(state =>
    state.patientIndex
      ? state.patientIndex.isOwner && state.patientIndex.subscriptionActive
      : false
  );
  const clinicId = useSelector(state => state.clinicId);

  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');
  const [partialPayment, setPartialPayment] = useState(false);

  const form = useRef(null);
  const handleCheckboxChange = (e) => {
    setPartialPayment(e.target.checked);
  };

  const classes = useStyles();
  useEffect(() => {
    if (!loaded) return;
    window.addEventListener('message', (event) => {
      if (event.origin !== `https://${cardUrl}.cardconnect.com`) return;
      if (event.data.action) return;
      const eventToken = JSON.parse(event.data);
      setToken(eventToken.message);
    }, false);
  }, [loaded]);

  const handleStartLoadingIFrame = () => {
    dispatch(startFetching());
  };

  const handleFinishLoadingIFrame = () => {
    dispatch(finishFetching());
    setLoaded(true);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    dispatch(
      payOrderWithTerminal(
        order.clinic_id,
        order.user_id,
        order.id,
        formData,
        close,
        setError,
        orderFor
      )
    );
  };

  const [amount, setAmount] = useState(order.total);
  const [cashPaid, setCashPaid] = useState(0);
  const handleCashPaid = () => {
    setCashPaid(event.target.value);
  };

   const handleAmount = (event) => {
    if (event.target.value == '' || parseFloat(event.target.value) <= order.total) {
      setAmount(event.target.value);
    }
   };

  return (
    <div className='container px-5 mx-3 mt-3'>
      {!loaded && (
        <div className='row justify-content-center'>
          <CircularProgress color='inherit' />
        </div>
      )}
      <div className={loaded ? '' : 'd-none'}>
        <form
          name='tokenform'
          id='tokenform'
          ref={form}
          onSubmit={submitHandler}
        >
          <ConnectingIssues error={error} setError={setError} />
          <input type='hidden' name='token' value={token} />
          <input type='hidden' name='authenticity_token' value={csrfToken} />
          <input type='hidden' name='_method' value='patch' />
          { isActiveOwner || !!clinicId ?
            <div className='form-row mb-3'>
              <div className='form-check pr-5'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='displayFormCheckbox'
                  onChange={handleCheckboxChange}
                />
                <label className='form-check-label' htmlFor='displayFormCheckbox'>
                  Partial Payment
                </label>
              </div>
              {
                partialPayment && (
                  <div className='form-row mb-3'>
                    <div className='col-auto pr-4'>
                      <label htmlFor='name'>
                        Amount
                        <input
                          required
                          onChange={() => handleCashPaid()}
                          type='number'
                          step='0.01'
                          min='0'
                          placeholder='0.00'
                          name='paid_amount'
                          id='paid_amount'
                          className='form-control'
                        />
                      </label>
                    </div>
                    <div className='col-auto pr-4'>
                      <label htmlFor='cardName'>
                        {cashPaid >= Number(order.total) ? 'Change Due' : 'Remaining Amount'}
                        <input
                          readOnly
                          value={
                            cashPaid >= Number(order.total)
                              ? (
                                  Math.round((cashPaid - order.total + Number.EPSILON) * 100) /
                                  100
                                ).toFixed(2)
                              : (Number(order.total) - cashPaid).toFixed(2)
                          }
                          type='number'
                          name={
                            cashPaid >= Number(order.total) ? 'change_due' : 'remaining_amount'
                          }
                          id={
                            cashPaid >= Number(order.total) ? 'change_due' : 'remaining_amount'
                          }
                          className='form-control'
                        />
                      </label>
                    </div>
                  </div>
                )
              }
            </div>
          : null }
          <div className='form-row mb-3'>
            <div className='col-auto pr-4'>
              <label htmlFor='name'>
                Name on Card
                <input
                  type='text'
                  name='name'
                  id='name'
                  className='form-control'
                />
              </label>
            </div>
            <div className='col-auto pr-4'>
              <label htmlFor='address'>
                Address
                <input
                  type='text'
                  name='address'
                  id='address'
                  className='form-control'
                />
              </label>
            </div>
          </div>
          <div className='form-row mb-3'>
            <div className='col-auto pr-4'>
              <label htmlFor='city'>
                City
                <input
                  type='text'
                  name='city'
                  id='city'
                  className='form-control'
                />
              </label>
            </div>
            <div className='col-auto pr-4'>
              <label htmlFor='region'>
                Region
                <select
                  className='form-control'
                  id='region'
                  defaultValue='ND'
                  name='region'
                >
                  {usStates.map(state => (
                    <option key={state[0]} value={state[0]}>
                      {state[0]}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className='col-auto'>
              <label htmlFor='postal'>
                Zip
                <input
                  type='text'
                  name='postal'
                  id='postal'
                  className='form-control'
                />
              </label>
            </div>
          </div>
          <div className='form-row mb-1 h-50'>
            <div className='col-5'>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <iframe
                title='CardConnect'
                id='ccnumfield'
                name='tokenFrame'
                style={{ height: 140 }}
                src={`https://${cardUrl}.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&cardnumbernumericonly=true&usecvv=true&orientation=horizontal&css=input%7Bmargin-top%3A5px%3Bmargin-bottom%3A10px%3Bbackground%3A%20%23F2F2F6%20%21important%3B%20border-radius%3A%205px%3Bborder%3A%201px%20solid%20%23ced4da%3Bpadding%3A%200.375rem%200.75rem%3Bfont-size%3A%201rem%3Bfont-weight%3A%20400%3Bline-height%3A%201.5%3Bcolor%3A%20%23495057%3Btransition%3A%20border-color%200.15s%20ease-in-out%2C%20box-shadow%200.15s%20ease-in-out%3B%7Dinput%3Afocus%7Bbox-shadow%3A%200%200%203px%20%24black%3Bborder%3A%201px%20solid%20%24black%3Boutline%3A%20none%20%21important%3B%7Dlabel%7Bmargin-bottom%3A0.5rem%3Bfont-family%3A%20-apple-system%2C%20BlinkMacSystemFont%2C%20%22Segoe%20UI%22%2C%20Roboto%2C%20%22Helvetica%20Neue%22%2C%20Arial%2C%20%22Noto%20Sans%22%2C%20sans-serif%2C%20%22Apple%20Color%20Emoji%22%2C%20%22Segoe%20UI%20Emoji%22%2C%20%22Segoe%20UI%20Symbol%22%2C%20%22Noto%20Color%20Emoji%22%7Dbody%7Bmargin%3A%200%7D%3B%7D`}
                frameBorder='0'
                scrolling='no'
                onLoadStart={handleStartLoadingIFrame}
                onLoadCapture={handleFinishLoadingIFrame}
              />
            </div>
          </div>
          <div className='form-row mb-1'>
            <div className='col-7'>
              <label htmlFor='date_month'>
                Expiration Date
                <div className='form-row mb-4'>
                  <div className='col-auto'>
                    <select
                      className='form-control'
                      id='date_month'
                      name='date[month]'
                      defaultValue='ND'
                    >
                      {months.map(month => (
                        <option key={month.value} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-auto'>
                    <select
                      className='form-control'
                      id='date_year'
                      name='date[year]'
                      defaultValue='ND'
                    >
                      {[...Array(6).keys()].map(key => {
                        const year = new Date().getFullYear();
                        return (
                          <option key={year + key} value={year + key}>
                            {year + key}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className='form-row mb-4'>
            <div className='col-3'>
              <Button
                className={classes.submitBtn}
                color='primary'
                type='submit'
                variant='contained'
                disabled={!token.length}
              >
                Pay
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

CreditCard.propTypes = {
  order: PropTypes.objectOf(oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.arrayOf(PropTypes.object),
  ])).isRequired,
  close: PropTypes.func.isRequired,
};

export default CreditCard;
