import React from 'react'
import { Formik } from 'formik';
import { connect } from 'react-redux'

import { onNewMessageSubmit } from '../../actions/messages'

class NewMessageForm extends React.Component {

	render() {
		return (
      <Formik
        initialValues={{ text: '' }}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            this.props.onSubmit(
              values.text,
              this.props.clinicId,
              this.props.patient.id,
              this.props.isOwner
            );
            resetForm({ text: '' });
            setSubmitting(false);
          }, 400);
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit} className='form-inline'>
            <div className='row w-100'>
              <div className='col-lg-10'>
                <div className='form-group w-100'>
                  <textarea
                    id='text'
                    required
                    className='form-control w-100'
                    cols='10'
                    {...formik.getFieldProps('text')}
                    value={formik.values.text}
                  />
                </div>
              </div>
              <div className='col-lg-2'>
                <button
                  type='submit'
                  className='btn btn-outline-primary mb-2 mt-2'
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
	}
}

const mapStateToProps = state => {
	return {
		clinicId: state.patientIndex.clinicId,
		isOwner: state.patientIndex.isOwner
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSubmit: (text, clinicId, patientId, sentByClinic) => {
			dispatch(onNewMessageSubmit(text, clinicId, patientId, sentByClinic))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewMessageForm)
