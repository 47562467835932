export const RESET_ALL_STATES = 'RESET_ALL_STATES';

// Fetching
export const START_FETCHING = 'START_FETCHING';
export const FINISH_FETCHING = 'FINISH_FETHING';

// ORDERS
export const GET_ORDERS_SUCCESSFULLY = 'GET_ORDERS_SUCCESSFULLY';
export const CANCEL_ORDER_SUCCESSFULLY = 'CANCEL_ORDER_SUCCESSFULLY';
export const PAY_ORDER_SUCCESSFULLY = 'PAY_ORDER_SUCCESSFULLY';

//CLIENT
export const FILTER_CLIENT = 'FILTER_CLIENT';
