import { combineReducers } from 'redux';
import { RESET_ALL_STATES } from '../actions/actionsType';
import initState from '../store/initState';
import fetchingReducer from './fetchingReducer';
import logoReducer from './logoReducer';
import ordersReducer from './ordersReducer';
import tokenReducer from './tokenReducer';
import storeItemsReducer from './storeItemsReducer';
import cardUrlReducer from './cardUrlReducer';
import usStateReducer from './usStateReducer';
import clientFilterReducer from './clientFilterReducer';
import clinicIdReducer from './clinicIdReducer';

const appReducer = combineReducers({
  fetching: fetchingReducer,
  orders: ordersReducer,
  token: tokenReducer,
  logo: logoReducer,
  storeItems: storeItemsReducer,
  clinicId: clinicIdReducer,
  clientFilter: clientFilterReducer,
  cardUrl: cardUrlReducer,
  usStates: usStateReducer,
});

const rootReducer = (rootState, action) => {
  let state = rootState;
  if (action.type === RESET_ALL_STATES) {
    state = initState;
  }
  return appReducer(state, action);
};

export default rootReducer;
