import configureStore from '../../../app/javascript/components/configure_store';

export const store = (
  clinicId,
  csrfToken,
  isOwner,
  userId,
  subscriptionActive,
  clinicName,
  cardUrl,
  usStates
) =>
  configureStore({
    patientIndex: {
      clinicId: clinicId,
      csrfToken: csrfToken,
      isOwner: isOwner,
      userId: userId,
      subscriptionActive: subscriptionActive,
      clinicName: clinicName,
      assessments: [],
      orders: [],
      fetching: 0,
      storeItems: [],
      logo: null,
      cardUrl: cardUrl,
      usStates: usStates
    }
  });
