import React, { useEffect, useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    width: '165px',
    paddingRight: theme.spacing(6),
    textAlign: 'right',
  },
  label: {
    width: '150px',
  },
}));

const TotalAmounts = ({ list }) => {
  const [subtotal, setSubtotal] = useState(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0));
  const [taxes, setTaxes] = useState(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0));
  const [discounts, setDiscounts] = useState(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0));
  const [total, setTotal] = useState(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0));

  const classes = useStyles();

  useEffect(() => {
    const summary = list.reduce((totals, obj) => {
      if (!obj.item) return totals;
      const { sub, ta, dis } = totals;
      const { item: { price, tax, discount, qty } } = obj;

      const tempPrice = parseFloat(price) * qty;
      const tempDisc = ((discount / 100) * tempPrice) || 0;
      
      const taxNum = parseFloat(tax);
      const tempTax = (taxNum / 100) * (tempPrice - tempDisc);
      return { sub: sub + tempPrice, ta: ta + tempTax, dis: dis + tempDisc };
    }, { sub: 0, ta: 0, dis: 0 });

    setSubtotal(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(summary.sub));
    setTaxes(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(summary.ta));
    setDiscounts(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(summary.dis));
    setTotal(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(summary.sub + summary.ta - summary.dis));
  }, [list]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h6 className={classes.label}>Subtotal:</h6>
        <h5 className={classes.value}>
          {subtotal}
        </h5>
      </div>
      <div className={classes.row}>
        <h6 className={classes.label}>Discounts:</h6>
        <h5 className={classes.value}>
          {discounts}
        </h5>
      </div>
      <div className={classes.row}>
        <h6 className={classes.label}>Taxes:</h6>
        <h5 className={classes.value}>
          {taxes}
        </h5>
      </div>
      <div className={classes.row}>
        <h6 className={classes.label}>Total:</h6>
        <h5 className={classes.value}>
          {total}
        </h5>
      </div>
    </div>
  );
};

TotalAmounts.propTypes = {
  list: PropTypes.arrayOf(oneOfType([PropTypes.object, PropTypes.number])),
};

TotalAmounts.defaultProps = {
  list: [],
};
export default TotalAmounts;
