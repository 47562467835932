import axios from 'axios';
import { endOfDay, endOfYear, startOfDay, startOfYear } from 'date-fns';
import { serialize } from 'object-to-formdata';
import { toast } from 'react-toastify';
import { zonedTimeToUtc } from 'date-fns-tz';

export const getClinicRooms = (clinicId, setClinicRooms) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/rooms`, { headers })
    .then(response => {
      setClinicRooms(response.data);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const convertDateToException = date => {
  const regex = /([:-])+/g;
  return new Date(date).toISOString().replaceAll(regex, '').split('.')[0] + 'Z';
};

const countTotalRecurrenceAppointment = (appointment, totalRecurrence) => {
  if (appointment.RecurrenceRule.toLowerCase().includes('until')) return totalRecurrence;
  return parseInt(appointment.RecurrenceRule.split(/COUNT=/)[1].slice(0, 1));
};


export const createNewClinicRoom = async (room, csrfToken) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };

  try {
    const response = await axios.post(
      `/${room.rooms.clinic_id}/rooms`,
      serialize(room),
      { headers }
    );
    return response.data.rooms;
  } catch (e) {
    throw e;
  }
};

export const getAllMeetings = async (clinicId, start_date, end_date) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.get(
      `/${clinicId}/appointments?start_time=${startOfDay(
        start_date
      ).toString()}&end_time=${endOfDay(end_date).toString()}`,
      { headers }
    );
    return response.data;
  } catch (e) {
    toastr.error('Unable to fetch data!');
  }
};

export const deleteRoom = async (clinicId, id, setRoomData) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  await axios
    .delete(`/${clinicId}/rooms/${id}`, { headers })
    .then(response => {
      toastr.success(response.data.message);
      setRoomData(response.data.rooms.sort((a, b) => a.number - b.number));
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};

export const updateClinicRoom = async (room, doctorId, token) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token
  };

  try {
    const response = await axios.patch(
      `/${room.rooms.clinic_id}/rooms/${doctorId}`,
      serialize(room),
      { headers }
    );
    return response.data.rooms;
  } catch (e) {
    console.log(e, 'Catch in clinic update');
    throw e;
  }
};

export const createOffTimeAppointment = async (clinicId, data, token, timezone) => {
  const appointment = {
    room_id: data.RoomId,
    end_time: zonedTimeToUtc(new Date(data.EndTime), timezone),
    start_time: zonedTimeToUtc(new Date(data.StartTime), timezone),
    is_all_day: data.IsAllDay,
    subject: data.Subject,
    clinic_id: clinicId,
    is_unavailable: true,
    recurrence_rule: data.RecurrenceRule ? data.RecurrenceRule : ''
  };
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token
  };
  try {
    let result = await axios.post(`/${clinicId}/appointments`, {
      appointment: appointment,
      headers
    });
    return result.data;
  } catch (e) {
    throw e;
  }
};

export const refreshInviteToken = async (clinicId, csrf) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrf
  };

  try {
    let response = await axios.post(
      `/${clinicId}/update_token`,
      {},
      {
        headers
      }
    );

    return response.data.token;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};

export const updateTimeZone = async (
  clinicId,
  csrf,
  schedulerId,
  newTimezone
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrf
  };

  try {

    let response = await axios.patch(
      `/${clinicId}/scheduler/${schedulerId}`,
      { scheduler: { timezone: newTimezone } },
      {
        headers
      }
    );

    return response.data.timezone;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};

export const updateOffTimeAppointment = async (clinicId, data, token, timezone) => {
  const appointment = {
    room_id: data.RoomId[0],
    end_time: zonedTimeToUtc(new Date(data.EndTime), timezone),
    start_time: zonedTimeToUtc(new Date(data.StartTime), timezone),
    is_all_day: data.IsAllDay,
    subject: data.Subject,
    clinic_id: clinicId,
    is_unavailable: true,
    recurrence_rule: ''
  };
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token
  };
  try {
    const result = await axios.patch(
      `/${clinicId}/appointments/${data.Id}`,
      appointment,
      { headers }
    );
    return result.data;
  } catch (e) {
    throw e;
  }
};

export const createNewAppointment = async (
  clinicId,
  args,
  csrfToken,
  isOwner,
  patients,
  isReccursive = false
) => {
  let patientName = null;
  let data = isReccursive ? args.changedRecords[0] : args.addedRecords[0];
  if (isOwner) {
    patients.map(patient => {
      if (data.patient_id === patient.id) {
        patientName = patient.name;
      }
    });
  } else {
    patientName = patients.name;
    data.patient_id = patients.id;
  }
  const appointment = {
    room_id: data.RoomId,
    end_time: zonedTimeToUtc(new Date(data.EndTime), args.timeZone),
    start_time: zonedTimeToUtc(new Date(data.StartTime), args.timeZone),
    is_all_day: data.IsAllDay,
    subject: patientName != null ? patientName : data.Subject,
    clinic_id: clinicId,
    is_unavailable: data.IsBlock ? data.IsBlock : false,
    user_id: data.IsBlock ? null : data.patient_id,
    appointment_type_id: data?.appointment_type?.id || data?.appointment_type,
    recurrence_rule: args.data.parent?.Id ? null : data.RecurrenceRule,
    recurrence_id: args.data.parent?.Id,
    recurrence_exception: data.RecurrenceException
  };
  if (isOwner) {
    appointment.description = data.description;
  } else {
    appointment.patient_notes = data.description;
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  try {
    let result = await axios.post(`/${clinicId}/appointments`, {
      appointment: appointment,
      headers
    });
    return result.data;
  } catch (e) {
    throw e;
  }
};

export const updateRecurrenceAppointmentStatus = async (
  clinicId,
  csrfToken,
  appointment,
  startTime,
  isOwner,
  patients,
  totalRecurrence,
  start_date = startOfDay(new Date()),
  end_date = endOfDay(new Date())
) => {
  try {
    await deleteRecursiveAppointment(
      clinicId,
      csrfToken,
      appointment,
      startTime,
      false,
      start_date,
      end_date,
      totalRecurrence
    );
  } catch (e) {
    console.error(e.message);
  }
  let patientName = null;
  if (isOwner) {
    patients.map(patient => {
      if (appointment.patient_id === patient.id) {
        patientName = patient.name;
      }
    });
  } else {
    patientName = patients.name;
    appointment.patient_id = patients.id;
  }
  const exception = appointment.RecurrenceException
    ? [appointment.RecurrenceException, convertDateToException(startTime)].join(
        ','
      )
    : convertDateToException(startTime);

    const data = {
      room_id: appointment.RoomId,
      end_time: zonedTimeToUtc(
        new Date(appointment.EndTime),
        appointment.timezone
      ),
      start_time: zonedTimeToUtc(
        new Date(appointment.StartTime),
        appointment.timezone
      ),
      is_all_day: appointment.IsAllDay,
      subject: patientName != null ? patientName : appointment.Subject,
      clinic_id: clinicId,
      is_unavailable: appointment.IsBlock ? appointment.IsBlock : false,
      user_id: appointment.IsBlock ? null : appointment.patient_id,
      appointment_type_id:
        appointment?.appointment_type?.id || appointment?.appointment_type,
      recurrence_rule: null,
      status: appointment.status,
      recurrence_id: appointment.Id,
      recurrence_exception: exception
    };
  if (isOwner) {
    data.description = appointment.description;
  } else {
    data.patient_notes = appointment.description;
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  try {
    let result = await axios.post(
      `/${clinicId}/appointments?start_time=${
        startOfYear(start_date)}&end_time=${endOfYear(end_date)}`,
      {
        appointment: data,
        headers
      }
    );
    return result.data;
  } catch (e) {
    throw e;
  }
};

export const getUnavailableAppointments = (
  clinicId,
  roomId,
  setUnavailableAppointments
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/rooms/${roomId}/unavailable_appointments`, { headers })
    .then(response => {
      setUnavailableAppointments(response.data);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const updateAppointment = async (
  clinicId,
  args,
  csrfToken,
  isOwner,
  patients,
  isReccursive = false,
  isSeries = false,
  isStatusChange = false,
  start_date,
  end_date,
  financial_report = false
) => {
  let appointment;
  let data;
  if (!isStatusChange) {
    data = isReccursive ? args.data.parent : args.changedRecords[0];
    if (isReccursive) {
      appointment = {
        recurrence_exception: args.data.parent.RecurrenceException
      };
    } else {
      let patientName = null;

      if (isOwner) {
        patients.map(patient => {
          if (data.patient_id === patient.id) {
            patientName = patient.name;
          }
        });
      } else {
        patientName = patients.name;
        data.patient_id = patients.id;
      }
      appointment = {
        id: data.Id,
        room_id: data.RoomId,
        end_time: zonedTimeToUtc(new Date(data.EndTime), args.timeZone),
        start_time: zonedTimeToUtc(new Date(data.StartTime), args.timeZone),
        is_all_day: data.IsAllDay,
        subject: patientName != null ? patientName : data.Subject,
        clinic_id: clinicId,
        is_unavailable: data.IsBlock ? data.IsBlock : false,
        user_id: data.IsBlock ? null : data.patient_id,
        appointment_type_id:
          data?.appointment_type?.id || data?.appointment_type,
        status: data.status,
        recurrence_id: !isSeries ? data.RecurrenceID : 0,
        recurrence_exception: !isSeries ? data.RecurrenceException : null,
        recurrence_rule: data.RecurrenceRule
      };
    }

    if (isOwner) {
      appointment.description = data.description;
    } else {
      appointment.patient_notes = data.description;
    }
  } else {
    data = { ...args };
    appointment = { ...args };
  }
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  try {
    if (isSeries) await deleteAppointmentByRecurrenceId(clinicId, data.Id);
    const result = await axios.patch(
      `/${clinicId}/appointments/${data.Id}?start_time=${startOfYear(
        start_date
      )}&end_time=${endOfYear(end_date)}&financial_report=${financial_report}`,
      appointment,
      { headers }
    );
    return result.data;
  } catch (e) {
    throw e;
  }
};

export const deleteRecursiveAppointment = async (
  clinicId,
  csrfToken,
  appointment,
  startTime,
  isSeries = false,
  start_date,
  end_date,
  totalRecurrence
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  const exception = appointment.RecurrenceException
    ? [appointment.RecurrenceException, convertDateToException(startTime)].join(
        ','
      )
    : convertDateToException(startTime);

  try {
    if (
      isSeries ||
      exception.split(',').length ===
        countTotalRecurrenceAppointment(appointment, totalRecurrence)
    ) {
      await deleteAppointmentByRecurrenceId(clinicId, appointment.Id);
      const result = await deleteAppointment(clinicId, appointment.Id);
      return result;
    } else {
      const result = await axios.patch(
        `/${clinicId}/appointments/${appointment.Id}?start_time=${
          startOfYear(start_date)}&end_time=${endOfYear(end_date)}`,
        { recurrence_exception: exception },
        { headers }
      );
      return result.data;
    }
  } catch (e) {
    throw e;
  }
};

export const deleteAppointment = async (clinicId, id) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.delete(`/${clinicId}/appointments/${id}`, {
      headers
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const deleteAppointmentByRecurrenceId = async (
  clinicId,
  recurrence_id
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.delete(
      `/${clinicId}/appointments/${recurrence_id}/delete_non_synced`,
      {
        headers
      }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getOffAppointment = (clinicId, setData) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/off_appointments`, { headers })
    .then(response => {
      setData(response.data);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const createNewAppointmentType = (
  clinicId,
  csrfToken,
  name,
  time,
  setData,
  setShowModal,
  color,
  isPublic
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  const totalTime = time.getHours() * 60 * 60 + time.getMinutes() * 60;
  const data = {
    appointment_type: {
      name,
      total_time: totalTime,
      color,
      is_public: isPublic
    }
  };

  axios
    .post(`/${clinicId}/appointment_types`, serialize(data), { headers })
    .then(response => {
      setShowModal(false);
      setData(response.data);
      toastr.success('Successfully Created');
    })
    .catch(
      ({
        response: {
          data: { message }
        }
      }) => {
        toastr.error(message);
      }
    );
};

export const updateAppointmentType = (
  clinicId,
  csrfToken,
  id,
  data,
  setData
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .patch(`/${clinicId}/appointment_types/${id}`, data, { headers })
    .then(response => {
      setData(response.data);
      toastr.success('Appointment Type Updated!');
    })
    .catch(
      ({
        response: {
          data: { message }
        }
      }) => {
        toastr.error(message);
      }
    );
};

export const deleteAppointmentType = async (clinicId, id, setData) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  await axios
    .delete(`/${clinicId}/appointment_types/${id}`, { headers })
    .then(response => {
      toastr.success(response.data.message);
      setData(response.data);
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};

export const getAllAppointmentTypes = (clinicId, setData) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/appointment_types`, { headers })
    .then(response => {
      setData(response.data);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const getSchedulerStats = async (clinicId, dateRange, patientId) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.get(
      `/${clinicId}/appointments_analytics?start_time=${dateRange[0].startDate}&end_time=${dateRange[0].endDate}&patient=${patientId}`,
      { headers }
    );
    return response.data;
  } catch (e) {
    toast.error(e.message);
    console.error(e.message);
  }
};
