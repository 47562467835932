import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { deleteRoom } from '../actions/scheduler';
import placeholder from '../../assets/images/placeholder.jpg';

export const roomsListColumns = (
  setDoctorId,
  setDrImage,
  setDrDesignation,
  setRoomNum,
  setDrName,
  setShowModal,
  clinicId,
  setRoomData,
  setSelectedOptions,
  setRoomCapacity,
  setIsMultipleAppointmentAllowed,
  clinicAppointmentTypes,
  setEndTo,
  setStartFrom
) => [
  {
    field: 'profile_image_url',
    headerName: 'Image',
    flex: 1,
    renderCell: params => (
      <img
        src={params.value ? params.value : placeholder}
        width='40px'
        height='40px'
        style={{ borderRadius: '50%' }}
      />
    )
  },
  { field: 'number', headerName: 'Number', flex: 1 },
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'is_multiple_appointment',
    headerName: 'Multiple Appoinment',
    flex: 1
  },
  { field: 'capacity', headerName: 'Capacity', flex: 1 },
  { field: 'designation', headerName: 'Description', flex: 1 },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Edit'
        showInMenu
        onClick={() => {
          setDoctorId(params.row.id);
          setDrName(params.row.name);
          setRoomNum(params.row.number);
          setDrDesignation(params.row.designation);
          setDrImage(params.row.drImage || undefined);
          setShowModal(true);
          setSelectedOptions(
            clinicAppointmentTypes.filter(function (item) {
              return params.row.allowed_types.indexOf(item.value) != -1;
            })
          );
          setRoomCapacity(params.row.capacity);
          setIsMultipleAppointmentAllowed(params.row.is_multiple_appointment);
          setEndTo(params.row.end_to);
          setStartFrom(params.row.start_from);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label='Delete'
        showInMenu
        onClick={() => {
          deleteRoom(clinicId, params.row.id, setRoomData);
        }}
      />
    ]
  }
];

export const handleDateFormat = (date, time) => {
    let splitTime = time.split(':');
    date.setHours(splitTime[0]);
    date.setMinutes(splitTime[1]);
    date.setSeconds(splitTime[2]);

    return date;
  };


  export const getDateByDayName = (time, targetDayOfWeek) => {
    const today = new Date();

    // Set the target day of the week
   const targetDate = new Date();
   targetDate.setDate(
     today.getDate() - (today.getDay() - targetDayOfWeek)
   );

    const extractedDate = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate()
    );

    const extractedTime = new Date(
      0,
      0,
      0,
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
      time.getMilliseconds()
    );

return new Date(extractedDate.getFullYear(), extractedDate.getMonth(), extractedDate.getDate(),
                  extractedTime.getHours(), extractedTime.getMinutes(), extractedTime.getSeconds(),
                  extractedTime.getMilliseconds());

  };
