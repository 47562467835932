import React, { useState } from 'react';
import Video from './Video';
import { createVideo, deleteVideo, updateVideo } from '../../actions/video';

const Index = (props) => {
  const { csrfToken, clinicId, videos } = props;

  const [clinicVideos, setClinicVideos] = useState(videos ?? []);
  const [formData, setFormData] = useState({
    name: '',
    url: '',
    is_visible: true,
  });

  const [errors, setErrors] = useState({
    name: '',
    url: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validation
    let errorMsg = '';
    if (name === 'name' && value.trim() === '') {
      errorMsg = 'Name is required';
    } else if (name === 'url' && value !== '') {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      if (!pattern.test(value)) {
        errorMsg = 'Enter a valid URL';
      }
    }

    setErrors({
      ...errors,
      [name]: errorMsg,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormData({
      name: '',
      url: '',
      is_visible: true,
    });

    createVideo(csrfToken, clinicId, formData, setClinicVideos);
  };

  const handleDelete = (index, video) => {
    const updatedVideos = [...clinicVideos];
    deleteVideo(csrfToken, clinicId, video);
    updatedVideos.splice(index, 1);
    setClinicVideos(updatedVideos);
  };

  const handleVisibilityToggle = (index) => {
    setClinicVideos((prevVideos) => prevVideos.map((video, i) => {
      if (i === index) {
        const updatedVideo = { ...video, is_visible: !video.is_visible };
        updateVideo(csrfToken, clinicId, updatedVideo);
        return updatedVideo;
      }
      return video;
    }));
  };

  return (
    <div className='videos card'>
      <div className='card-body'>
        <div className='videos card'>
          <div className='card-body'>
            <form onSubmit={handleSubmit}>
              <div className='form-row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label htmlFor='name'>Name:</label>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      className='form-control'
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                    {errors.name && (
                      <p style={{ color: 'red' }}>{errors.name}</p>
                    )}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label htmlFor='url'>URL:</label>
                    <input
                      type='url'
                      id='url'
                      className='form-control'
                      name='url'
                      value={formData.url}
                      onChange={handleChange}
                      required
                    />
                    {errors.url && <p style={{ color: 'red' }}>{errors.url}</p>}
                  </div>
                </div>
              </div>
              <button type='submit' className='btn btn-primary'>
                Add Video
              </button>
            </form>
          </div>
        </div>
        <Video
          videos={clinicVideos}
          handleDelete={handleDelete}
          handleVisibilityToggle={handleVisibilityToggle}
          clinicId={clinicId}
        />
      </div>
    </div>
  );
};

export default Index;
