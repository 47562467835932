import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { format, startOfDay } from 'date-fns';

const useStyles = makeStyles(theme => ({
  rangeContainer: {
    border: 'solid 1px #B3B3B3'
  }
}));

export const DatePicker = props => {
  const { handleChange, index, min, max, sendDate } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState(sendDate ? new Date(sendDate) : startOfDay(new Date()));
  const onToggle = event => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <input
        type='button'
        className='btn btn-sm-secondary btn-primary'
        value={`Date: ${format(date, 'dd-MMM-yy')}`}
        name='date'
        onClick={onToggle}
      />

      <Popper open={open} anchorEl={anchorEl} transition>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.rangeContainer}>
            <Calendar
              date={date}
              name='date'
              onChange={item => {
                setDate(item)
                handleChange(item, index);
              }}
              maxDate={max}
              minDate={min || new Date()}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DatePicker;
