import React, { useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      backgroundColor: 'transparent !important',
      border: '0',
      padding: 0,
      boxShadow: 'none',
      textAlign: 'center',
      '-moz-appearance': 'textfield',
      width: '50px',
    },
    '& input[type="number"]:focus': {
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
    },
    '& input:disabled': {
      color: 'black',
    },
  },
}));

const QtyItem = ({
  inputIndex, setItem, item, setPrice, disableField,
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const value = parseFloat(event.target.value);

    setItem({ ...item, qty: value < 1 || value > 500 ? item.qty : value });
    setPrice();
  };

  useEffect(() => {
    setPrice();
  }, []);

  return (
    <TextField
      name={`order[order_items_attributes][${inputIndex}][qty]`}
      variant="standard"
      className={classes.root}
      onChange={handleChange}
      type="number"
      disabled={!disableField}
      value={item.qty || ''}
      InputProps={{ inputProps: { min: 1 } }}
      required
    />
  );
};

QtyItem.propTypes = {
  inputIndex: PropTypes.number.isRequired,
  setItem: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
  item: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disableField: PropTypes.bool,
};

QtyItem.defaultProps = {
  disableField: true,
};

export default QtyItem;
