import React, { useState } from 'react';
import Select from 'react-select';
import { PRIMARY_COLOR } from '../../constants'
const FilterSelect = props => {
  const { setSelectedOption, selectedOption, dropDownOptions } = props;

  const [menuWidth, setMenuWidth] = useState();
  const [isCalculatingWidth, setIsCalculatingWidth] = useState(false);

  const reactSelectStyles = {
    menu: css => ({
      ...css,
      width: 'auto',
      ...(isCalculatingWidth && { height: 0, visibility: 'hidden' })
    }),
    control: css => ({ ...css, display: 'inline-flex ' }),
    valueContainer: css => ({
      ...css,
      ...(menuWidth && { width: menuWidth })
    })
  };

  return (
    <Select
      value={dropDownOptions.filter(
        option => option.label === selectedOption.label
      )}
      styles={reactSelectStyles}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: 'orangered',
          primary: PRIMARY_COLOR
        }
      })}
      options={dropDownOptions}
      onChange={e => setSelectedOption(e)}
    />
  );
};

export default FilterSelect;
