import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import logo from '../../../assets/images/logo_MIT_Ver_color.png';

const SchedulerInviteForm = ({ csrfToken, clinicId, token }) => {
  const handleSubmit = async event => {
    event.preventDefault();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    };
    try {
      await axios.post(
        `/${clinicId}/invite_user`,
        {
          clinicId: clinicId,
          email: event.target.email.value,
          name: event.target.name.value,
          token
        },
        {
          headers
        }
      );
      toastr.success(`Invitation has been send to your email`);

      window.location.replace(`${window.location.origin}/users/sign_in`);
    } catch (e) {
      toastr.error("Email is already taken!")
      console.error(e.message);
    }
  };

  return (
    <>
      <div className='authentication-wrapper authentication-1 px-4'>
        <div className='authentication-inner py-5'>
          <div className='login_wrapper'>
            <h5 className='text-center text-muted font-weight-normal mb-4 mt-4'>
              <img className='img-fluid' width={130} alt='logo' src={logo} />
            </h5>
            <div style={{ padding: '20px', paddingTop: '5px' }}>
              <form onSubmit={handleSubmit}>
                <div className='form-group'>
                  <label className='form-label'>Email</label>
                  <input
                    type='email'
                    className='form-control'
                    autoFocus={true}
                    autoComplete='email'
                    name='email'
                    placeholder='email'
                    required
                  />
                  <div className='clearfix'></div>
                </div>
                <div className='form-group'>
                  <label className='form-label'>Name</label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    placeholder='mike davidson'
                    required
                  />
                  <div className='clearfix mb-2'></div>
                  <div className='d-flex justify-content-center align-items-center pt-1'>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SchedulerInviteForm;
