/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { onNewSessionSubmit, hideSessionForm } from '../../actions/sessions';
import { format } from 'date-fns';

const NewSessionForm = (props) => {
  const {
    isOwner,
    patient,
    clinicPackages,
    sessionEdit,
    subscriptionActive,
    onSubmit,
    onCancel,
    activePackage
  } = props;

  const formRef = useState(React.createRef());

  useEffect(() => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const isPatient = !isOwner;
  let clinicPackage;

  if (activePackage && clinicPackages) {
    clinicPackage = clinicPackages.find((pkg) => pkg.id === activePackage.clinic_package_id);
  } else {
    clinicPackage = {};
  }

  const session = sessionEdit || {};
  clinicPackage = clinicPackage || {};

  const initialValues = {
    date: session.date
      ? format(new Date(session.date), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'),
    notes: session.notes || '',
    timeOnLaser: session.time_on_laser || clinicPackage.time_on_laser || '',
    face: session.face || clinicPackage.face || false,
    arms: session.arms || clinicPackage.arms || false,
    abdomen: session.abdomen || clinicPackage.abdomen || false,
    chest: session.chest || clinicPackage.chest || false,
    back: session.back || clinicPackage.back || false,
    hips: session.hips || clinicPackage.hips || false,
    thighs: session.thighs || clinicPackage.thighs || false,
    calves: session.calves || clinicPackage.calves || false,
    vibrationPlate:
      session.vibration_plate || clinicPackage.vibration_plate || false,
    timeOnVibrationPlate:
      session.time_on_vibration_plate ||
      clinicPackage.time_on_vibration_plate ||
      ''
  };

  const sessionId = sessionEdit ? sessionEdit.id : null;

  const formReadOnlyToggle = (isPatient || !subscriptionActive);

  const handleSelectAll = (formik) => {
    formik.setValues({
      ...formik.values,
      face: true,
      arms: true,
      abdomen: true,
      chest: true,
      back: true,
      hips: true,
      thighs: true,
      calves: true,
      vibrationPlate: true,
    });
  };

  const handleUnselectAll = (formik) => {
    formik.setValues({
      ...formik.values,
      face: false,
      arms: false,
      abdomen: false,
      chest: false,
      back: false,
      hips: false,
      thighs: false,
      calves: false,
      vibrationPlate: false,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          onSubmit(
            values.date,
            values.timeOnLaser,
            values.face,
            values.arms,
            values.abdomen,
            values.chest,
            values.back,
            values.hips,
            values.thighs,
            values.calves,
            values.vibrationPlate,
            values.timeOnVibrationPlate,
            values.notes,
            activePackage.id,
            patient.id,
            patient.clinic_id,
            sessionId
          );
          setSubmitting(false);
        }, 400);
      }}
    >
      {(formik) => (
        <div className='card mt-3'>
          <div className='card-body'>
            <form onSubmit={formik.handleSubmit} ref={formRef}>
              <div className='form-group'>
                <label htmlFor='date'>
                  Date
                  <input
                    readOnly={formReadOnlyToggle}
                    id='date'
                    type='date'
                    className='form-control'
                    {...formik.getFieldProps('date')}
                  />
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='timeOnLaser'>
                  Treatment Time
                  <input
                    readOnly={formReadOnlyToggle}
                    id='timeOnLaser'
                    type='number'
                    className='form-control'
                    {...formik.getFieldProps('timeOnLaser')}
                  />
                </label>
              </div>

              <div className='form-group row'>
                <div className='col-12'>
                  <button
                    type='button'
                    disabled={formReadOnlyToggle}
                    className='btn btn-link'
                    onClick={() => handleSelectAll(formik)}
                  >
                    Select All
                  </button>
                  <button
                    type='button'
                    disabled={formReadOnlyToggle}
                    className='btn btn-link'
                    onClick={() => handleUnselectAll(formik)}
                  >
                    Unselect All
                  </button>
                </div>
                <div className='col-6 col-sm-4'>
                  <div className='form-check'>
                    <label htmlFor='face' className='form-check-label'>
                      <input
                        disabled={formReadOnlyToggle}
                        id='face'
                        type='checkbox'
                        checked={formik.values.face}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Face
                    </label>
                  </div>

                  <div className='form-check'>
                    <label htmlFor='arms' className='form-check-label'>
                      <input
                        disabled={formReadOnlyToggle}
                        id='arms'
                        type='checkbox'
                        checked={formik.values.arms}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Arms
                    </label>
                  </div>

                  <div className='form-check'>
                    <label htmlFor='abdomen' className='form-check-label'>
                      <input
                        disabled={formReadOnlyToggle}
                        id='abdomen'
                        type='checkbox'
                        checked={formik.values.abdomen}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Abdomen
                    </label>
                  </div>
                </div>
                <div className='col-6 col-sm-4'>
                  <div className='form-check'>
                    <label htmlFor='chest' className='form-check-label'>
                      <input
                        disabled={formReadOnlyToggle}
                        id='chest'
                        type='checkbox'
                        checked={formik.values.chest}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Chest
                    </label>
                  </div>

                  <div className='form-check'>
                    <label htmlFor='back' className='form-check-label'>
                      <input
                        disabled={formReadOnlyToggle}
                        id='back'
                        type='checkbox'
                        checked={formik.values.back}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Back
                    </label>
                  </div>

                  <div className='form-check'>
                    <label htmlFor='hips' className='form-check-label'>
                      <input
                        disabled={formReadOnlyToggle}
                        id='hips'
                        type='checkbox'
                        checked={formik.values.hips}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Hips
                    </label>
                  </div>
                </div>
                <div className='col-6 col-sm-4'>
                  <div className='form-check'>
                    <label htmlFor='thighs' className='form-check-label'>
                      <input
                        disabled={formReadOnlyToggle}
                        id='thighs'
                        type='checkbox'
                        checked={formik.values.thighs}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Thighs
                    </label>
                  </div>

                  <div className='form-check'>
                    <label htmlFor='calves' className='form-check-label'>
                      <input
                        disabled={formReadOnlyToggle}
                        id='calves'
                        type='checkbox'
                        checked={formik.values.calves}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Calves
                    </label>
                  </div>

                  <div className='form-check'>
                    <label
                      htmlFor='vibrationPlate'
                      className='form-check-label'
                    >
                      <input
                        disabled={formReadOnlyToggle}
                        id='vibrationPlate'
                        type='checkbox'
                        checked={formik.values.vibrationPlate}
                        onChange={formik.handleChange}
                        className='form-check-input'
                      />
                      Vibration Plate
                    </label>
                  </div>
                </div>
              </div>
              {formik.values.vibrationPlate && (
                <div className='form-group'>
                  <label htmlFor='timeOnVibrationPlate'>
                    Time on Vibration Plate
                    <input
                      readOnly={formReadOnlyToggle}
                      id='timeOnVibrationPlate'
                      type='number'
                      className='form-control'
                      {...formik.getFieldProps('timeOnVibrationPlate')}
                    />
                  </label>
                </div>
              )}
              {isOwner && (
                <>
                  <div className='form-group'>
                    <label htmlFor='notes' className='w-100'>
                      Notes
                      <textarea
                        readOnly={formReadOnlyToggle}
                        id='notes'
                        className='form-control'
                        {...formik.getFieldProps('notes')}
                      />
                    </label>
                  </div>
                  {subscriptionActive && (
                    <div>
                      <button
                        type='submit'
                        className='btn btn-outline-success ml-1 mt-2'
                      >
                        Submit
                      </button>

                      <button
                        className='btn btn-outline-danger ml-1 mt-2'
                        type='button'
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  clinicPackages: state.patientIndex.availableClinicPackages,
  sessionEdit: state.patientIndex.sessionEdit,
  isOwner: state.patientIndex.isOwner,
  subscriptionActive: state.patientIndex.subscriptionActive,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (date,
    timeOnLaser,
    face,
    arms,
    abdomen,
    chest,
    back,
    hips,
    thighs,
    calves,
    vibrationPlate,
    timeOnVibrationPlate,
    notes,
    activePackageId,
    patientId,
    clinicId,
    sessionId) => {
    dispatch(onNewSessionSubmit(date,
      timeOnLaser,
      face,
      arms,
      abdomen,
      chest,
      back,
      hips,
      thighs,
      calves,
      vibrationPlate,
      timeOnVibrationPlate,
      notes,
      activePackageId,
      patientId,
      clinicId,
      sessionId));
  },
  onCancel: () => {
    dispatch(hideSessionForm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewSessionForm);
