import React from 'react';
import { onDeleteProfileImage } from '../../actions/measurements';
import { connect } from 'react-redux';

const ProfileImage = props => {
  const deleteProfileImage = (e, measurementId) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this photo?')) {
      props.deleteProfileImage(measurementId);
    }
  };

  return (
    <>
      {props.isOwner && (
        <button
          onClick={e => deleteProfileImage(e, props.measurementId)}
          style={{
            position: 'absolute',
            right: '0px',
            zIndex: '100',
            padding: '.375rem .75rem'
          }}
          className='button danger'
        >
          <span>&times;</span>
        </button>
      )}
      <img className={props.imageClass} src={props.url} />
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    deleteProfileImage: measurementId => {
      dispatch(onDeleteProfileImage(measurementId));
    }
  };
};

export default connect(null, mapDispatchToProps)(ProfileImage);
