import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      backgroundColor: 'transparent !important',
      border: '0',
      padding: 0,
      boxShadow: 'none',
      textAlign: 'center',
      width: '50px',
      '-moz-appearance': 'textfield',
    },
    '& input[type="number"]:focus': {
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
    },
    '& input[type="number"]:disabled': {
      color: 'black',
    },
  },
}));

const DiscountItem = ({
  inputIndex, setItem, item, disableField,
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    let value = parseFloat(event.target.value);
    value = value > 100 ? item.discount : value;
    value = value < 0 ? item.discount : value;
    setItem({ ...item, discount: value });
  };

  return (
    <TextField
      name={`order[order_items_attributes][${inputIndex}][discount]`}
      className={classes.root}
      type="number"
      onChange={handleChange}
      disabled={!disableField}
      value={item.discount == null ? '' : item.discount}
      InputProps={{
        inputProps: { min: 0, max: 100, step: 0.01 },
        endAdornment: (
          <InputAdornment position="end">%</InputAdornment>
        ),
      }}
      required
    />
  );
};

DiscountItem.propTypes = {
  inputIndex: PropTypes.number.isRequired,
  setItem: PropTypes.func.isRequired,
  item: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disableField: PropTypes.bool,
};

DiscountItem.defaultProps = {
  disableField: true,
};

export default DiscountItem;
