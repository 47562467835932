import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule';
import { addSeconds, format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import React, { useState } from 'react';
import { convertToOptions } from '../../helpers/scheduler';

const ModalTemplate = React.forwardRef(( props, ref ) => {
  const {  appointmentData, rooms, patients, appointmentTypes, isOwner} = props
  const [interval, setInterval] = useState(
    appointmentData.appointment_type
      ? appointmentData.appointment_type.total_time
      : null
  );

  const [startTime, setStartTime] = useState(appointmentData.StartTime);

  const AllowedAppointmentTypes = () => {
    if(Object.keys(appointmentData).length < 1) return;

    const room = rooms.find(room => room.id === appointmentData.RoomId);
    return appointmentTypes.filter(type => room.allowed_types.includes(type.id))
  }

  const defaultNotes = () =>{
   return isOwner ? appointmentData.description : appointmentData.patient_notes
  }

  return appointmentData != undefined ? (
    <table
      className='custom-event-editor'
      style={{ width: '100%' }}
      cellPadding={5}
    >
      <tbody>
        <tr>
          <td className='e-textlabel'>Room/Resource</td>
          <td colSpan={4}>
            <DropDownListComponent
              id='RoomId'
              placeholder='Choose Room/Resource'
              data-name='RoomId'
              className='e-field'
              value={appointmentData.RoomId}
              fields={{ text: 'text', value: 'value' }}
              style={{ width: '100%' }}
              dataSource={convertToOptions(rooms)}
            />
          </td>
        </tr>
        {isOwner && (
          <tr>
            <td className='e-textlabel'>Client</td>
            <td colSpan={4}>
              <DropDownListComponent
                id='patient_id'
                placeholder='Choose Client'
                data-name='patient_id'
                value={appointmentData.patient_id}
                className='e-field'
                style={{ width: '100%' }}
                fields={{ text: 'text', value: 'value' }}
                dataSource={convertToOptions(patients)}
              />
            </td>
          </tr>
        )}
        <tr>
          <td className='e-textlabel'>Type</td>
          <td colSpan={4}>
            <DropDownListComponent
              id='appointment_type'
              placeholder='Choose Appointment Type'
              data-name='appointment_type'
              value={appointmentData.appointment_type?.id}
              className='e-field'
              style={{ width: '100%' }}
              fields={{ text: 'text', value: 'value', time: 'time' }}
              dataSource={convertToOptions(AllowedAppointmentTypes())}
              onChange={e =>
                setInterval(
                  appointmentTypes.find(x => x.id == e.target.value).total_time
                )
              }
            />
          </td>
        </tr>
        <tr>
          <td className='e-textlabel'>From</td>
          <td colSpan={4}>
            <DateTimePickerComponent
              id='StartTime'
              format='MM/dd/yy hh:mm a'
              data-name='StartTime'
              value={new Date(appointmentData.StartTime)}
              className='e-field'
              onChange={e => setStartTime(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td className='e-textlabel'>To</td>
          <td colSpan={4}>
            {interval ? (
              <DateTimePickerComponent
                disabled={!isOwner}
                id='EndTime'
                format='MM/dd/yy hh:mm a'
                data-name='EndTime'
                value={addSeconds(toDate(startTime), interval)}
                className='e-field'
              />
            ) : (
              'End Time will be calculated automatically once you select type.'
            )}
          </td>
        </tr>
        {!appointmentData.Guid && (
          <tr>
            <td className='e-textlabel'>Repeat</td>
            <td colSpan={4}>
              <RecurrenceEditorComponent
                id='RecurrenceEditor'
                ref={ref}
              ></RecurrenceEditorComponent>
            </td>
          </tr>
          )}
        <tr>
          <td className='e-textlabel'>Note</td>
          <td colSpan={4}>
            <textarea
              id='description'
              className='e-field e-input'
              name='description'
              rows={3}
              cols={50}
              defaultValue={defaultNotes()}
              style={{
                width: '100%',
                height: '60px !important',
                resize: 'vertical'
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  ) : (
    <div></div>
  );
});

export default ModalTemplate;
