import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { format } from 'date-fns';

export const offListColumns = (
  setdrModal,
  clinicId,
  setAppointments,
  deleteAppointment,
  setStime,
  setEtime,
  setDr,
  setCheckk,
  setAppointmentId,
  setReason,
  setIsDayOff
) => [
  { field: 'RoomId', headerName: 'Room/Resource Id ', flex: 1 },
  { field: 'DrName', headerName: 'Room/Resource Name', flex: 1 },
  {
    field: 'StartTime',
    headerName: 'Start Time',
    flex: 1,
    valueGetter: params => format(new Date(params.value), 'dd-MMM-yyyy')
  },
  {
    field: 'EndTime',
    headerName: 'End Time',
    flex: 1,
    valueGetter: params => format(new Date(params.value), 'dd-MMM-yyyy')
  },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Edit'
        showInMenu
        onClick={() => {
          setReason(params.row.Subject);
          setStime(params.row.StartTime);
          setEtime(params.row.EndTime);
          setDr({
            name: params.row.DrName,
            id: params.row.RoomId
          });
          setAppointmentId(params.row.Id);
          setCheckk(true);
          setdrModal(true);
          setIsDayOff(params.row.IsAllDay);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label='Delete'
        showInMenu
        onClick={async () => {
          const data = { id: params.row.Id };
          try {
            const response = await deleteAppointment(clinicId, data.id);
            const offAppointments = response.filter(
              appointment => appointment.IsBlock === true
            );
            setAppointments(offAppointments);
            toastr.error('Off time Deleted!');
          } catch (e) {
            toastr.error(e.message);
          }
        }}
      />
    ]
  }
];
