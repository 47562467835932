import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  TextField,
  Stack,
  Checkbox,
  MenuItem,
  ListItemText,
  OutlinedInput,
  FormControl
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { convertDateToException } from '../../actions/scheduler';
import { DatePicker } from '@mui/x-date-pickers';
import { endOfDay, startOfDay } from 'date-fns';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { handleDateFormat } from '../../helpers/room';

const DoctorAvailibility = ({
  showdrModal,
  setdrModal,
  dr,
  addAvailibility,
  setDr,
  check,
  drs,
  sDate,
  eDate,
  appointmentReason,
  setIsDayOff,
  isDayOff
}) => {
  const [startTime, setStartTime] = useState(new Date(sDate) ?? new Date());
  const [endTime, setEndTime] = useState(new Date(eDate) ?? new Date());
  const [reason, setReason] = useState(appointmentReason);
  const [selectedOption, setSelectedOption] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    check ? setSelectedOption([{ value: dr?.id, label: dr?.name }]) : null;
  }, []);

  const handleAdd = () => {
    if ((error != "" && error != null) || startTime > endTime) {
      toast.error("Invalid Date")
       return;
    }
    const arrayOfData = selectedOption.map(option => {
      const startDate = startOfDay(new Date(startTime)).toISOString();
      const endDate = endOfDay(new Date(endTime));
      const data = {
        Subject: reason ?? 'Not Available',
        StartTime: `${isDayOff ? startDate : startTime.toISOString()}`,
        EndTime: `${
          isDayOff
            ? endDate
            : handleDateFormat(
                new Date(startTime),
                endTime.toLocaleTimeString('en-US', { hour12: false })
              )
        }`,
        IsAllDay: false,
        IsBlock: true,
        RoomId: option.value,
        IsAllDay: false,
        RecurrenceRule: `FREQ=DAILY;INTERVAL=1;UNTIL=${convertDateToException(
          isDayOff ? new Date(endTime) : endTime
        )};`
      };
      return data;
    });
    addAvailibility(arrayOfData);
    if (check) {
      setDr();
    }

    setdrModal(false);
  };

  const handleCloseModal = () => {
    setdrModal(false);
    setDr();
  };

  return (
    <Modal
      show={showdrModal}
      onHide={handleCloseModal}
      backdrop='static'
      keyboard={false}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Set Break Times and Holidays</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            {check ? (
              <TextField
                label='Room/Resource'
                disabled
                value={dr?.name}
                variant='outlined'
              />
            ) : (
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                isMulti
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                options={drs.map(obj => ({ value: obj.id, label: obj.name }))}
              />
            )}
            <div className='d-flex align-items-center'>
              <Checkbox
                checked={isDayOff}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() => setIsDayOff(!isDayOff)}
              />
              Is Day Off?
            </div>
            {!isDayOff ? (
              <>
                <DateTimePicker
                  renderInput={params => <TextField {...params} />}
                  value={startTime}
                  label={'Start Time'}
                  disablePast
                  onError={newError => setError(newError)}
                  onChange={newValue => {
                    setStartTime(newValue);
                  }}
                />
                <DateTimePicker
                  renderInput={params => <TextField {...params} />}
                  value={endTime}
                  label={'End Time'}
                  disablePast
                  onError={newError => setError(newError)}
                  onChange={newValue => {
                    setEndTime(newValue);
                  }}
                />
              </>
            ) : (
              <>
                <DatePicker
                  renderInput={params => <TextField {...params} />}
                  value={startTime}
                  label={'Start Date'}
                  disablePast
                  onError={newError => setError(newError)}
                  onChange={newValue => {
                    setStartTime(newValue);
                  }}
                />
                <DatePicker
                  renderInput={params => <TextField {...params} />}
                  value={endTime}
                  label={'End Date'}
                  disablePast
                  onError={newError => setError(newError)}
                  onChange={newValue => {
                    setEndTime(newValue);
                  }}
                />
              </>
            )}
            <TextField
              label='Reason'
              value={reason}
              variant='outlined'
              onChange={e => setReason(e.target.value)}
            />
          </Stack>
        </LocalizationProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant='primary' type='Submit' onClick={handleAdd}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DoctorAvailibility;
