import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { campaignListColumns } from '../../../helpers/campaign';
import CampaignStatsModal from './CampaignStatsModal';
import CampaignUsersModal from './CampaignUsersModal';

const CampaignsList = props => {
  const { url, campaigns, clinicId, csrfToken } = props;
  const [campaignsList, setCampaignList] = useState(campaigns);
  const [show, setShow] = useState(false);
  const [clientListModal, setClientListModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [clientType, setClientType] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleStatsViewClick = id => {
    setCampaignId(id);
    setShow(true);
  };

    const handleClientsListClick = id => {
      setCampaignId(id);
      setClientListModal(true);
    };
  return (
    <>
      <div className='card-body p-0 pt-3'>
        <h4 className='font-weight-bold ml-3 d-inline'>Campaigns</h4>
        <div className='clearfix mb-3'></div>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={campaignsList}
            disableSelectionOnClick
            columns={campaignListColumns(
              setCampaignList,
              handleStatsViewClick,
              handleClientsListClick,
              setClientType,
              clinicId
            )}
            onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </div>
        {show && (
          <CampaignStatsModal
            clinicId={clinicId}
            show={show}
            setShow={setShow}
            campaignId={campaignId}
          />
        )}
        {clientListModal && (
          <CampaignUsersModal
            csrfToken={csrfToken}
            clinicId={clinicId}
            show={clientListModal}
            setShow={setClientListModal}
            campaignId={campaignId}
            clientType={clientType}
          />
        )}
      </div>
    </>
  );
};

export default CampaignsList;
