import fetch from 'cross-fetch';

export const requestNewUserPackage = () => ({
  type: 'REQUEST_NEW_USER_PACKAGE',
});

export const receiveNewUserPackageSuccess = (activePackages, pastPackages, patientId) => ({
  type: 'RECEIVE_NEW_USER_PACKAGE_SUCCESS',
  activePackages,
  pastPackages,
  patientId,
});

export const newUserPackageSubmit = (
  clinicPackageId, patientId, clinicId,
) => function (dispatch, getState) {
  dispatch(requestNewUserPackage());

  const state = getState().patientIndex;

  return fetch(`${window.origin}/${clinicId}/clients/${patientId}/user_packages.json`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': state.csrfToken,
    },
    body: JSON.stringify({
      user_package: {
        clinic_package_id: clinicPackageId,
      },
    }),
  }).then((response) => response.json())
    .then((responseJson) => {
      dispatch(receiveNewUserPackageSuccess(
        responseJson.active_packages, responseJson.past_packages, responseJson.patient_id,
      ));
    });
};

export const requestRemoveActivePackage = () => ({
  type: 'REQUEST_REMOVE_ACTIVE_PACKAGE',
});

export const removeActivePackageSuccess = (patientId, activePackageId) => ({
  type: 'REMOVE_ACTIVE_PACKAGE_SUCCESS',
  patientId,
  activePackageId,
});

export const removeActivePackage = (
  clinicId, patientId, activePackageId,
) => function (dispatch, getState) {
  dispatch(requestRemoveActivePackage());

  const state = getState().patientIndex;

  return fetch(`${window.origin}/${clinicId}/clients/${patientId}/user_packages/${activePackageId}.json`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': state.csrfToken,
    },
  }).then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson.error) {
        dispatch(removeActivePackageSuccess(patientId, activePackageId));
      }
    });
};
