import React from 'react'
import { connect } from 'react-redux'
import { toggleActivePatient } from '../../actions/patients'
import { countUnreadMessages } from '../../lib/patient_utils'
import { markMessagesRead } from '../../actions/messages';

import styled from 'styled-components'
import { PRIMARY_COLOR } from '../../constants'
class PatientListItem extends React.Component {

  onItemClick() {
    $('#active-package-tab')[0] !== undefined && $('#active-package-tab').tab('show');
    this.props.onPatientToggle()
    if (this.props.type === 'unreadMessage') {
      this.props.markMessagesRead(
        this.props.patient.conversation.id,
        this.props.isOwner,
        this.props.clinicId
      );
    }
  }

  render() {
    let numUnreadMessages = countUnreadMessages(this.props.patient, this.props.isOwner);
    return (
      <PatientRow
        onClick={() => {
          this.onItemClick();
        }}
        active={this.props.active}
        className='list-group-item list-group-item-action media-body d-grid py-3 pl-3'
        style={{borderRadius: '10px'}}
      >
          <td className='patient-name'>{this.props.patient.name}</td>
          <td className='ml-auto ml-3'>
            {numUnreadMessages > 0 &&
              !this.props.isFetchingMessageReadRequest && (
                <UnreadMessages
                  className={
                    'badge badge-outline-success newMessage-' +
                    this.props.patient.id
                  }
                >
                  {countUnreadMessages(this.props.patient, this.props.isOwner)}
                </UnreadMessages>
              )}
          </td>
      </PatientRow>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOwner: state.patientIndex.isOwner,
    isFetchingMessageReadRequest: state.patientIndex.isFetchingMessageReadRequest
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onPatientToggle: () => {
      dispatch(toggleActivePatient(ownProps.clinicId, ownProps.patient.id));
    },
    markMessagesRead: (conversationId, isClinic, clinicId) => {
      dispatch(markMessagesRead(conversationId, isClinic, clinicId));
    }
  }
}


const PatientRow = styled.tr`
  color: ${props => (props.active ? '#fff' : '#000')} !important;
  background: ${props => (props.active ? PRIMARY_COLOR : '')} !important;
  border-radius: 10px;
  cursor: pointer;
`;

const UnreadMessages = styled.span`
  margin-right: 10px;
  padding: 3px 5px;
`

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientListItem)
