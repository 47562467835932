/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import SearchItemOption from './SearchItemOption';

const useStyles = makeStyles((theme) => (
  {
    root: {
      minWidth: '240px',
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    search: {
      display: 'flex',
      flexDirection: 'column',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
    inputField: {
      '& input[type=text]': {
        backgroundColor: 'transparent !important',
      },
      '& input[type=text]:focus': {
        border: 'none',
        boxShadow: 'none',
      },
      '& input[type=text]:disabled': {
        color: 'black',
      },
    },
    descriptionLabel: {
      paddingBottom: theme.spacing(1),
      color: 'rgba(0, 0, 0, 0.54)',
      padding: 0,
      fontSize: 'calc(1rem * .75)',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: '0.00938em',
      textAlign: 'left',
    },
    description: {
      textAlign: 'left',
    },
  }
));

const SearchItems = ({
  setItem, item, inputIndex, disableField,
}) => {
  const storeItems = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.storeItems
      : state.storeItems
  ));

  const classes = useStyles();

  const itemHandler = (event, newValue) => {
    if (!setItem) return;
    setItem({ ...newValue, qty: 1, discount: 0 });
  };



  return (
    <>
      <Autocomplete
        id={`store-items-select-${item ? item.id : 0}`}
        options={storeItems}
        classes={{
          option: classes.option
        }}
        disabled={!disableField}
        className={classes.root}
        autoHighlight
        disableClearable
        onChange={itemHandler}
        value={item}
        getOptionLabel={option => option.name}
        getOptionSelected={option => {
          if (!disableField) {
            return option.id === item.store_item_id;
          }
          return option.id === item.id;
        }}
        renderOption={option => (
          <SearchItemOption
            name={option.name}
            type={option.category}
            price={option.price}
          />
        )}
        renderInput={params => (
          <div className={classes.search}>
            <TextField
              {...params}
              placeholder='Select item'
              variant='standard'
              className={classes.inputField}
            />
            {item ? (
              <div className={classes.info}>
                <span className={classes.descriptionLabel}>Description</span>
                <span className={classes.description}>{item.description}</span>
              </div>
            ) : null}
          </div>
        )}
      />
      {item ? (
        <>
          <input
            type='hidden'
            name={`order[order_items_attributes][${inputIndex}][name]`}
            value={item.name}
          />
          <input
            type='hidden'
            name={`order[order_items_attributes][${inputIndex}][description]`}
            value={item.description}
          />
          {item.type === 0 && (
            <input
              type='hidden'
              name={`order[order_items_attributes][${inputIndex}][store_item_id]`}
              value={item.id}
            />
          )}
          {item.type === 1 && (
            <input
              type='hidden'
              name={`order[order_items_attributes][${inputIndex}][clinic_package_id]`}
              value={item.id}
            />
          )}
          {item.type === 2 && (
            <input
              type='hidden'
              name={`order[order_items_attributes][${inputIndex}][weight_loss_program_id]`}
              value={item.id}
            />
          )}
          <input
            type='hidden'
            name={`order[order_items_attributes][${inputIndex}][tax]`}
            value={item.tax}
          />
        </>
      ) : null}
    </>
  );
};

SearchItems.propTypes = {
  setItem: PropTypes.func,
  item: PropTypes.objectOf(oneOfType([PropTypes.object, PropTypes.number, PropTypes.string])),
  inputIndex: PropTypes.number.isRequired,
  disableField: PropTypes.bool,
};

SearchItems.defaultProps = {
  item: {},
  disableField: true,
  setItem: null,
};

export default SearchItems;
