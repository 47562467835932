import { composeWithDevTools } from 'redux-devtools-extension';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import initState from './initState';

export default function configureStore(
  fetching, orders, clientFilter, token, logo, storeItems, cardUrl, clinicId, usStates,
) {
  const store = createStore(
    reducers,
    initState(fetching, orders, clientFilter, token, logo, storeItems, cardUrl, clinicId, usStates),
    composeWithDevTools(
      applyMiddleware(
        thunk,
      ),
    ),
  );
  return store;
}
