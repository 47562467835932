import React from 'react';

const Checkbox = props => {
  const { label, value, onChange } = props;

  return (
    <label>
      <input type='checkbox' checked={value} onChange={onChange} />
      {" " + label}
    </label>
  );
};

export default Checkbox;
