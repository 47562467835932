import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    "&.MuiIconButton-root": {
      color: "#62d493",
      padding: '12px 2px'
    }
  }
}));

const WeightStatsCard = props => {
  const {
    weight,
    unit,
    text,
    colorClass,
    editable,
    csrfToken,
    patientId,
    clinicId,
    refreshPatient,
    className
  } = props;
  const [showInput, setShowInput] = useState(false);
  const [inputWeight, setInputWeight] = useState(weight);
  const classes = useStyles();

  const handleShowInput = () => {
    setShowInput(true);
  };

  const handleEdit = () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    };

    axios
      .patch(
        `/${clinicId}/clients/${patientId}/update_target_weight`,
        {
          target_weight: inputWeight
        },
        {
          headers
        }
      )
      .then(() => {
        setShowInput(false);
        toastr.success('Target weight Updated!');
        refreshPatient();
      })
      .catch(e => {
        toastr.error('Somthing went wrong!');
      });
  };

  return (
    <>
      <div className='col'>
        <div className='card card-size' style={{ minHeight: '8rem' }}>
          <div className='card-body text-center'>
            <h4 className={`${className}`}>
              {!showInput ? (
                <>
                  <span className={`text-${colorClass}`}>{weight}</span>
                  {unit}
                </>
              ) : (
                <div className='form-group'>
                  Weight:
                  <input
                    className='form-control'
                    type='number'
                    min={0}
                    max={1000}
                    step='0.01'
                    value={inputWeight}
                    onChange={(e) => {
                      e.target.reportValidity();
                      setInputWeight(e.target.value);
                    }}
                  />
                </div>
              )}
              {editable &&
                (showInput ? (
                  <Button
                    variant='btn btn-outline-success'
                    onClick={() => handleEdit()}
                  >
                    Update
                  </Button>
                ) : (
                  <Tooltip title='Edit'>
                    <IconButton
                      className={classes.iconStyle}
                      aria-label='Edit'
                      onClick={handleShowInput}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                ))}
            </h4>
            {text}
          </div>
        </div>
      </div>
    </>
  );
};

export default WeightStatsCard;
