import axios from 'axios';

export const createVideo = (csrfToken, clinicId, video, setClinicVideos) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .post(`/${clinicId}/videos`, video, {
      headers
    })
    .then(response => {
      toastr.success('Video added!');
      setClinicVideos(response.data);
    })
    .catch(e => {
      toastr.error(e);
    });
};

export const updateVideo = (csrfToken, clinicId, video) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .patch(`/${clinicId}/videos/${video.id}`, video, {
      headers
    })
    .then(response => {
      toastr.success('Video Updated!');
    })
    .catch(e => {
      toastr.error(e);
    });
};

export const deleteVideo = (csrfToken, clinicId, video) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .delete(`/${clinicId}/videos/${video.id}`, {
      headers
    })
    .then(response => {
      toastr.success('Video Deleted!');
    })
    .catch(e => {
      toastr.error(e);
    });
};
