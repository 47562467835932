import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { appointmentTypeListColumn } from '../../../helpers/appointmentType';
import CreateAppointmentType from './CreateAppointmentType';

const AppointmentType = (props) => {
  const { clinicId, csrfToken, appointmentTypes } = props;
  const [typeId, setTypeId] = useState()
  const [name, setName] = useState();
  const [time, setTime] = useState();
  const [color, setColor] = useState('#000');
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(appointmentTypes);
  const [isPublic, setIsPublic] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5);

  return (
    <div className='card'>
      <div className='card-header font-weight-bold '>
        <h4 className='font-weight-bold ml-3 d-inline'>Appointment Types</h4>
        <Button
          variant='btn btn-glow-secondary btn-primary float-right text-white'
          onClick={() => setShowModal(true)}
        >
          Create
        </Button>
      </div>
      <div className='card-body'>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={data}
            disableSelectionOnClick
            columns={appointmentTypeListColumn(
              setName,
              setTime,
              setShowModal,
              setTypeId,
              setData,
              setColor,
              clinicId,
              setIsPublic
            )}
            onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </div>
      </div>
      {showModal && (
        <CreateAppointmentType
          showModal={showModal}
          setShowModal={setShowModal}
          csrfToken={csrfToken}
          clinicId={clinicId}
          name={name}
          time={time}
          typeId={typeId}
          color={color}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          setColor={setColor}
          setTypeId={setTypeId}
          setName={setName}
          setTime={setTime}
          setData={setData}
        />
      )}
    </div>
  );
};

export default AppointmentType
