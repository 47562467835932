import React, { useEffect, useRef, useState } from "react";
import PropTypes, { oneOfType } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, Button, CircularProgress } from "@material-ui/core";
import { finishFetching, startFetching } from "../../actions/fetching";
import ConnectingIssues from "./ConnectingIssues";
import { payWithCash } from "../../actions/orders";

const useStyles = makeStyles(() => ({
  submitBtn: {
    marginTop: "1rem",
  },
}));

const CashPayment = ({ order, close, formatRate, orderFor }) => {
  const csrfToken = useSelector(state =>
    state.patientIndex ? state.patientIndex.csrfToken : state.token
  );

  const dispatch = useDispatch();

  const [error, setError] = useState(null);

  const form = useRef(null);

  const classes = useStyles();

  const handleStartLoadingIFrame = () => {
    dispatch(startFetching());
  };

  const handleFinishLoadingIFrame = () => {
    dispatch(finishFetching());
  };

  const [cashPaid, setCashPaid] = useState(0);
  const handleCashPaid = () => {
    setCashPaid(event.target.value);
  };

  const submitHandler = e => {
    e.preventDefault();
    const formData = new FormData(form.current);
    dispatch(
      payWithCash(
        order.clinic_id,
        order.user_id,
        order.id,
        formData,
        close,
        setError,
        orderFor
      )
    );
  };
  return (
    <div className='container px-5 mx-3 mt-3 mr-1'>
      <div>
        <form
          name='tokenform'
          id='tokenform'
          ref={form}
          onSubmit={submitHandler}
        >
          <ConnectingIssues error={error} setError={setError} />
          <input type='hidden' name='authenticity_token' value={csrfToken} />
          <input type='hidden' name='_method' value='patch' />
          <div className='form-row mb-3'>
            <div className='col-auto pr-4'>
              <label htmlFor='name'>
                Cash Paid
                <input
                  onChange={() => handleCashPaid()}
                  required
                  type='number'
                  step='0.01'
                  min='0'
                  placeholder='0.00'
                  name='paid_amount'
                  id='paid_amount'
                  className='form-control'
                />
              </label>
            </div>
            <div className='col-auto pr-4'>
              <label htmlFor='name'>
                {cashPaid >= Number(order.total)
                  ? 'Change Due'
                  : 'Remaining Amount'}
                <input
                  readOnly
                  value={
                    cashPaid >= Number(order.total)
                      ? (
                          Math.round(
                            (cashPaid - order.total + Number.EPSILON) * 100
                          ) / 100
                        ).toFixed(2)
                      : (Number(order.total) - cashPaid).toFixed(2)
                  }
                  type='number'
                  name={
                    cashPaid >= Number(order.total)
                      ? 'change_due'
                      : 'remaining_amount'
                  }
                  id={
                    cashPaid >= Number(order.total)
                      ? 'change_due'
                      : 'remaining_amount'
                  }
                  className='form-control'
                />
              </label>
            </div>
          </div>
          <div className='form-row mb-4'>
            <div className='col-3'>
              {cashPaid > 0 ? (
                <button className='btn btn-outline-success' type='submit'>
                  Pay
                </button>
              ) : (
                <Button
                  className={classes.submitBtn}
                  disabled
                  color='primary'
                  type='submit'
                  variant='contained'
                >
                  Pay
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

CashPayment.propTypes = {
  order: PropTypes.objectOf(
    oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.object),
    ])
  ).isRequired,
  close: PropTypes.func.isRequired,
};

export default CashPayment;
