import fetch from 'cross-fetch';

export const requestNewSession = () => ({
  type: 'REQUEST_NEW_SESSION',
});

export const receiveNewSessionSuccess = (activePackages, pastPackages, patientId) => ({
  type: 'RECEIVE_NEW_SESSION_SUCCESS',
  activePackages,
  pastPackages,
  patientId,
});

export const onNewSessionSubmit = (
  date,
  timeOnLaser,
  face,
  arms,
  abdomen,
  chest,
  back,
  hips,
  thighs,
  calves,
  vibrationPlate,
  timeOnVibrationPlate,
  notes,
  userPackageId,
  patientId,
  clinicId,
  sessionId,
) => function (dispatch, getState) {
  dispatch(requestNewSession());

  const state = getState().patientIndex;

  let url = `${window.origin}/${clinicId}/clients/${patientId}/user_packages/${userPackageId}`;
  let verb;

  if (sessionId) {
    url += `/sessions/${sessionId}`;
    verb = 'PATCH';
  } else {
    url += '/sessions.json';
    verb = 'POST';
  }

  return fetch(url, {
    method: verb,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': state.csrfToken,
    },
    body: JSON.stringify({
      session: {
        date,
        time_on_laser: timeOnLaser,
        face,
        arms,
        abdomen,
        chest,
        back,
        hips,
        thighs,
        calves,
        vibration_plate: vibrationPlate,
        time_on_vibration_plate: timeOnVibrationPlate,
        notes,
      },
    }),
  }).then((response) => response.json())
    .then((responseJson) => {
      dispatch(receiveNewSessionSuccess(responseJson.active_packages,
        responseJson.past_packages,
        responseJson.patient_id));
    });
};

export const showNewSessionForm = () => ({
  type: 'SHOW_NEW_SESSION_FORM',
});

export const hideSessionForm = () => ({
  type: 'HIDE_SESSION_FORM',
});

export const showEditSessionForm = (session) => ({
  type: 'SHOW_EDIT_SESSION_FORM',
  session,
});
