import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Checkbox, TextField } from '@mui/material';
import ColorPicker from 'material-ui-color-picker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createNewAppointmentType, updateAppointmentType } from '../../../actions/scheduler';
import { secondsToInterval } from '../../../helpers/scheduler';
import { toast, ToastContainer } from 'react-toastify';

const CreateAppointmentType = ({
  showModal,
  setShowModal,
  csrfToken,
  clinicId,
  name,
  time,
  color,
  typeId,
  isPublic,
  setIsPublic,
  setTypeId,
  setName,
  setTime,
  setData,
  setColor
}) => {
  const [timee, setTimee] = useState(time ?? new Date());
  const [timeCondition, setTimeConditioin] = useState(!!timee);

  const handleCreate = () => {
    if (color === '#3cb46b' || color === '#f2de01') {
      toast.warning('This is reserved color');
      return;
    }
    createNewAppointmentType(
      clinicId,
      csrfToken,
      name,
      timee,
      setData,
      setShowModal,
      color,
      isPublic
    );
    resetData();
  };

  const handleUpdate = () => {
    if (color === '#3cb46b' || color === '#f2de01') {
      toast.warning('This is reserved color');
      return;
    }
    const totalTime = timee.getHours() * 60 * 60 + timee.getMinutes() * 60;
    updateAppointmentType(
      clinicId,
      csrfToken,
      typeId,
      {
        appointment_type: {
          name,
          total_time: totalTime,
          color,
          is_public: isPublic
        }
      },
      setData
    );
    resetData();
    setShowModal(false);
  };

  const resetData = () => {
    setName(null);
    setTime(new Date());
    setTypeId(null);
    setColor('#000')
    setShowModal(false);
  };

  return (
  <>
    <Modal
      show={showModal}
      onHide={() => resetData()}
      backdrop='static'
      keyboard={false}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Appointment Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='form-group'>
          Name:
          <input
            className='form-control'
            type='text'
            value={name ?? ''}
            required
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              ampm={false}
              value={
                typeId && !timeCondition ? secondsToInterval(timee) : timee
              }
              maxTime={new Date(0, 0, 0, 12, 0)}
              onChange={newValue => {
                setTimee(newValue);
                setTimeConditioin(true);
              }}
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className='form-group'>
          Is Public?
          <Checkbox checked={isPublic} onChange={e => setIsPublic(e.target.checked)} />
        </div>
        <div className='form-group d-flex'>
          <ColorPicker
            name='color'
            defaultValue='Type Color'
            value={color}
            onChange={color => setColor(color)}
          />

          <div style={{ width: '50px', height: '32px', background: color }} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => resetData()}>
          Close
        </Button>
        {typeId ? (
          <Button
            variant='primary'
            type='Submit'
            onClick={() => handleUpdate()}
          >
            Update
          </Button>
        ) : (
          <Button
            variant='primary'
            type='Submit'
            onClick={() => handleCreate()}
          >
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
    <ToastContainer />
    </>
  );
};

export default CreateAppointmentType;
