import axios from 'axios';
import {
  CANCEL_ORDER_SUCCESSFULLY, CREATE_ORDER_SUCCESSFULLY,
  GET_LOGO_SUCCESSFULLY, GET_ORDERS_SUCCESSFULLY, PAY_ORDER_SUCCESSFULLY
} from './actionTypes';
import { finishFetching, startFetching } from './fetching';

const getLogoSuccessfully = (json) => ({
  type: GET_LOGO_SUCCESSFULLY,
  payload: json,
});

const payOrderSuccessfully = (response) => ({
  type: PAY_ORDER_SUCCESSFULLY,
  order: response.data.order,
  active_packages: response.data.active_packages,
});

const getOrdersSuccessfully = (json) => ({
  type: GET_ORDERS_SUCCESSFULLY,
  payload: json,
});

const cancelOrdersSuccessfully = (json) => ({
  type: CANCEL_ORDER_SUCCESSFULLY,
  payload: json,
});

const createOrdersSuccessfully = (json) => ({
  type: CREATE_ORDER_SUCCESSFULLY,
  payload: json,
});

const getOrders = (clinic, client) => {
  const url = `/${clinic}/clients/${client}/orders`;

  return ((dispatch) => {
    dispatch(startFetching());
    axios
      .get(url)
      .then((response) => {
        dispatch(getOrdersSuccessfully(response.data.orders));
        dispatch(getLogoSuccessfully(response.data.logo));
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch(finishFetching());
      });
  });
};

const createOrder = (clinic, client, form) => {
  const url = `/${clinic}/clients/${client}/orders`;
  return ((dispatch) => {
    dispatch(startFetching());
    axios
      .post(url, form, { withCredentials: true })
      .then((response) => {
        dispatch(createOrdersSuccessfully(response.data));
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch((error) => {
      // eslint-disable-next-line no-console
        console.log(error);
        dispatch(finishFetching());
      });
  });
};

const cancelOrder = (clinic, client, orderId, form) => {
  const url = `/${clinic}/clients/${client}/orders/${orderId}`;
  return ((dispatch) => {
    dispatch(startFetching());
    axios
      .post(url, form, { withCredentials: true })
      .then((response) => {
        dispatch(cancelOrdersSuccessfully(response.data));
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch((error) => {
      // eslint-disable-next-line no-console
        console.log(error);
        dispatch(finishFetching());
      });
  });
};

const payOrderWithBolt = (clinic, token, orderId, close, error) => {
  const url = `/${clinic}/make_bolt_payment`;
  const form = new FormData();
  form.append('order_id', orderId);
  form.append('authenticity_token', token);
  return ((dispatch) => {
    dispatch(startFetching());
    axios
      .post(url, form, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          dispatch(payOrderSuccessfully(response));
          return close();
        }
        return error(response.data.error);
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch((errors) => {
      // eslint-disable-next-line no-console
        console.log(errors);
        dispatch(finishFetching());
      });
  });
};

const payWithCash = (clinic, client, order, form, close, error, orderFor) => {
  const url = `/${clinic}/clients/${client}/orders/${order}/pay_with_cash`;
  return dispatch => {
    dispatch(startFetching());
    axios
      .post(url, form, { withCredentials: true })
      .then(response => {
        if (response.status === 200) {
          dispatch(payOrderSuccessfully(response));
          if (orderFor === 'clinic') {
            window.location.reload();
          } else {
            dispatch(getOrders(clinic, client));
          }
          return close();
        }
        return error(`error: ${response.data.error}`);
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch(errors => {
        // eslint-disable-next-line no-console
        console.log(errors);
        dispatch(finishFetching());
      });
  };
};

const payOrderWithTerminal = (
  clinic,
  client,
  order,
  form,
  close,
  error,
  orderFor
) => {
  const url = `/${clinic}/clients/${client}/orders/${order}`;
  return dispatch => {
    dispatch(startFetching());
    axios
      .post(url, form, { withCredentials: true })
      .then(response => {
        if (response.status === 200) {
          dispatch(payOrderSuccessfully(response));
          if (orderFor === 'clinic') {
            window.location.reload();
          } else {
            dispatch(getOrders(clinic, client));
          }
          return close();
        }
        return error(`error: ${response.data.error}`);
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch(errors => {
        // eslint-disable-next-line no-console
        console.log(errors);
        dispatch(finishFetching());
      });
  };
};



const cashRefund = (
  order,
  form,
  close,
  error,
) => {
  const url = `/${order.clinic_id}/clients/${order.user_id}/orders/${order.id}/refund`;
  return dispatch => {
    dispatch(startFetching());
    axios
      .post(url, form, { withCredentials: true })
      .then(response => {
        if (response.status === 200) {
          dispatch(payOrderSuccessfully(response));
          dispatch(getOrders(order.clinic_id, order.user_id));
          return close();
        }
        return error(`error: ${response.data.error}`);
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch(errors => {
        // eslint-disable-next-line no-console
        console.log(errors);
        dispatch(finishFetching());
      });
  };
};

const cardRefund = (order, form, close, error) => {
  const url = `/${order.clinic_id}/clients/${order.user_id}/orders/${order.id}/refund`;
  return dispatch => {
    dispatch(startFetching());
    axios
      .post(url, form, { withCredentials: true })
      .then(response => {
        if (response.status === 200) {
          dispatch(payOrderSuccessfully(response));
          dispatch(getOrders(order.clinic_id, order.user_id));
          return close();
        }
        return error(`error: ${response.data.error}`);
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch(errors => {
        // eslint-disable-next-line no-console
        console.log(errors);
        dispatch(finishFetching());
      });
  };
};

export {
  getOrders,
  createOrder,
  cancelOrder,
  payOrderWithBolt,
  payOrderWithTerminal,
  payWithCash,
  cashRefund,
  cardRefund
};
