import axios from "axios";

const createDailyLog = (
  dailyLog,
  csrfToken,
  url,
  method,
  refreshPatient,
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken,
  };

  method(
    url,
    { daily_log: dailyLog },
    { headers },
  )
    .then(({ data: { message } }) => {
      toastr.success(message);
      refreshPatient();
    })
    .catch((e) => {
      toastr.error(e.response.data.message);
    });
};

const deleteDailyLog = (url, refreshPatient, csrfToken) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .delete(url, { headers })
    .then(response => {
      toastr.success(response.data.message);
      refreshPatient();
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};

export { createDailyLog, deleteDailyLog };
