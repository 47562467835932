import React from 'react';
import { PRIMARY_COLOR } from '../../constants';

const AnalyticCard = ({ title, value, textColor = PRIMARY_COLOR }) => {
  return (
    <>
      <div className='col-sm-6 mb-4'>
        <div className='card card-wrapper'>
          <div className='card-body'>
            <h5 className='card-title text-center fw-bold text-black-50'>
              {title} Appointments
            </h5>
            <p
              style={{ color: value == 0 ? '#A9A9A9' : textColor }}
              className='card-text card-description text-center mt-4'
            >
              {value}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default AnalyticCard;
