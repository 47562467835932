import React from 'react';

const HealthProgressBar = ({ progress }) => {
  const getColor = () => {
    if (progress <= 20) {
      return 'progress-danger';
    } else if (progress <= 70) {
      return 'progress-warning';
    } else {
      return 'progress-success';
    }
  };

  return (
    <div id='projectbox'>
      <div className='battery'>
        {Array(10)
          .fill('')
          .map((value, index) => (
            <div
              className={`bar ${progress >= (index + 1) * 10 && getColor()}`}
              data-power={(index + 1) * 10}
            ></div>
          ))}
      </div>
    </div>
  );
};

export default HealthProgressBar;
