import { faAngleDown, faAngleUp, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

const DailyLogCard = props => {
  const { dailyLog, open, setShowEdit, handleSelectedLog, handleDelete } =
    props;
  const [arrowIcon, setArrowIcon] = useState(faAngleDown);
  const [isOpen, setIsOpen] = useState(open);
  const mealList = [];
  const snackList = [];

  Object.values(dailyLog.meals).forEach(listItem =>
    mealList.push(listItem.map(item => <li>{item}</li>))
  );

  Object.values(dailyLog.snacks).forEach(listItem =>
    snackList.push(listItem.map(item => <li>{item}</li>))
  );

  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h4 className='font-weight-bold d-inline float-left mb-0 mt-2 mr-2'>
          {new Date(dailyLog.date).toLocaleDateString('en-US')}
        </h4>
        <button
          className='btn btn-sm-secondary btn-primary float-right'
          onClick={() => {
            setIsOpen(!isOpen);
            setArrowIcon(!isOpen ? faAngleUp : faAngleDown);
          }}
        >
          <FontAwesomeIcon icon={arrowIcon} />
        </button>
        <button
          className='btn btn-sm-secondary btn-primary float-right mr-2'
          onClick={() => {
            handleSelectedLog(dailyLog);
            setShowEdit(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button
          className='btn btn-sm-secondary btn-primary float-right mr-2'
          onClick={() => {
            handleDelete(dailyLog.id);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
      <Collapse in={isOpen}>
        <div className='card-body'>
          <ul>
            <li>Weight: {dailyLog.weight}</li>
            <li>Yesterday's Water Intake: {dailyLog.water_intake}</li>
            <li>Hours Slept Last Night: {dailyLog.sleeping_hours}</li>
            <li>Blood Sugar: {dailyLog.blood_sugar}</li>
            <li>Blood Pressure: {dailyLog.blood_pressure}</li>
            <li>
              Foods List:
              { mealList.length < 1 && snackList.length < 1 && 'No food available!' }
            </li>

            {mealList.length > 0 && (
              <ul>
                <li>Meals:</li>
                <ul>{mealList}</ul>
              </ul>
            )}
            {snackList.length > 0 && (
              <ul>
                <li>Snacks:</li>
                <ul>{snackList}</ul>
              </ul>
            )}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};

export default DailyLogCard;
