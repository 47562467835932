import { FINISH_FETHING, START_FETCHING } from './actionTypes';

const startFetching = () => ({
  type: START_FETCHING,
});

const finishFetching = () => ({
  type: FINISH_FETHING,
});

export { startFetching, finishFetching };
