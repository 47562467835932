import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'


import Message from './Message'

class MessageList extends React.Component {
	render() {
		return (
			this.props.messages.sort((a,b) => new Date(b.created_at) - new Date(a.created_at)).map ( (message) => {
				let sent_by_me = this.props.isOwner == message.sent_by_clinic
				return (
					<MessageRow key={message.id} className={"row " + (sent_by_me ? "justify-content-end" : "justify-content-start") }>
						<div className="col-5">
							<Message message={message} sent_by_me={ sent_by_me } />
						</div>
					</MessageRow>
				)
			})
		)
	}
}

const MessageRow = styled.div`
	margin-bottom: 10px;
`;

const mapStateToProps = state => {
	return {
		isOwner: state.patientIndex.isOwner,
	}
}

export default connect(
	mapStateToProps,
	null
)(MessageList)