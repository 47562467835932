export const countUnreadMessages = (patient, isOwner) => {
	let conversation = patient.conversation;
	var lastViewed;

	if (!conversation) { return 0; }

	if (isOwner && conversation.clinic_last_viewed) {
		lastViewed = new Date(conversation.clinic_last_viewed);
	}
	else if (conversation.patient_last_viewed) {
		lastViewed = new Date(conversation.patient_last_viewed);
	}

	if (lastViewed) {
		return conversation.messages.filter( message => (new Date(message.created_at) > lastViewed) && (message.sent_by_clinic != isOwner) ).length
	}
	else {
		return conversation.messages.filter( message => message.sent_by_clinic != isOwner ).length
	}
}

export const createPatientFlashMessage = (content, error) => ({
	content,
	error,
	timestamp: Date.now()
})
