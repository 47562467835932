import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik';

import { selectMeasurementCompare } from '../../actions/measurements'

export class MeasurementComparisonSelect extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {};
	}

	onStartSelect(event) {
		const measurement = this.props.patient.measurements.find(measurement => measurement.id == event.target.value);
		if(measurement)
		{
			this.setState({ start: { date: measurement.date, id: measurement.id }});
		}
	}

	render() {
		const measurements = this.props.patient.measurements;

		if (!measurements.length) return null;

		let start;
		if(this.state.start)
		{
			start = this.state.start;
		}
		else {
			start = { date: this.props.patient.measurements[0].date, id: this.props.patient.measurements[0].id }
		}

		let startOptions = measurements.map( measurement => { return (
			<option key={measurement.id} value={measurement.id}>{new Date(measurement.date).toLocaleDateString('en-US', {timeZone: 'UTC'})}</option>
		)});

		let endOptions, endSelected;

		if (start.date) {
			endOptions = measurements.map( measurement => {
				if(measurement.date > start.date) {
					endSelected = measurement.id;
					return <option key={measurement.id} value={measurement.id}>{new Date(measurement.date).toLocaleDateString('en-US', {timeZone: 'UTC'})}</option>
				}
			})
		}

		const initialValues = {
			startId: start.id,
			endId:   endSelected,
		}

		return (
			<Formik
		      initialValues={initialValues}
		      enableReinitialize={true}
		      onSubmit={(values, { setSubmitting }) => {
		        setTimeout(() => {
		          this.props.onSubmit(values.startId, values.endId, this.props.patient.id);
		          setSubmitting(false);
		        }, 400);
		      }}
		    >
		    {formik => (
		        <form className="form-inline compare-form" onSubmit={formik.handleSubmit}>
		        	<div className="row pt-3">
		        <div className="col-md-4 text-center">
				      <select id="startId" className="form-control mb-2" onChange={this.onStartSelect.bind(this)} value={formik.values.startId} >
								{startOptions}
							</select>
						</div>
						<div className="col-md-2">
							<h5 className="subheading text-center mb-2">- to -</h5>
						</div>
						<div className="col-md-4 text-center">
							<select id="endId" name="endId" className="form-control mb-2" value={formik.values.endId} onChange={formik.handleChange}>
								{endOptions}
							</select>
						</div>
						<div className="col-md-2 mb-2">
				        	<button type="submit" className="btn btn-outline-success btn-responsive" disabled={!formik.values.startId || !formik.values.endId || (formik.values.startId == this.props.patient.measurementCompareStartId && formik.values.endId == this.props.patient.measurementCompareEndId)}>
				            	Compare
				          </button>
				        </div>
			        </div>
		        </form>
		      )}
		    </Formik>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSubmit: (start, end, patientId) => {
			dispatch(selectMeasurementCompare(start, end, patientId));
		}
	}
}

export default connect(
	null,
	mapDispatchToProps
)(MeasurementComparisonSelect)
