import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { generateFoodList } from '../../../helpers/dailyLog';
import { orderBy } from 'lodash';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import DatePicker from '../../common/DatePicker';
import { createDailyLog, deleteDailyLog } from '../../../actions/dailyLog';
import QuantityModal from './QuantityModal';
import { toDate } from 'date-fns-tz';
import DailyLogCard from './DailyLogCard';
import { DEFAULT_LOG_ENTRY_FORM_STATE, PRIMARY_COLOR } from '../../../constants';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

const LogsEntryModal = props => {
  const {
    clinicId,
    csrfToken,
    refreshPatient,
    setShow,
    userWlProgram,
    isAllLogs = false,
    handleClose = () => {}
  } = props;


  const [formState, setFormState] = useState(() => {
    const todayDate = new Date();
    return {
    ...DEFAULT_LOG_ENTRY_FORM_STATE,
    date:
      todayDate >= new Date(userWlProgram?.start_date) &&
      todayDate <= new Date(userWlProgram?.end_date)
        ? todayDate
        : new Date(userWlProgram?.end_date)
  }
});
  const [options, setOptions] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedDailyLog, setSelectedDailyLog] = useState(0);
  const [isQuantityModalOpen, setIsQuantityModalOpen] = useState(false);
  const [selectedFood, setSelectedFood] = useState({});

  const key = Object.keys(selectedFood);
  let selectedOptions = []
  if(key.length > 0 )
    selectedOptions = formState[key][selectedFood[key]];

  const clearForm = () => {
    const todayDate = new Date();
    setFormState({
      ...DEFAULT_LOG_ENTRY_FORM_STATE,
      date:
        todayDate >= new Date(userWlProgram?.start_date) &&
        todayDate <= new Date(userWlProgram?.end_date)
          ? todayDate
          : new Date(userWlProgram?.end_date)
    });
  };

  const currentPhase = useMemo(() => {
    if (!userWlProgram) return;

    let start = new Date(userWlProgram.start_date);
    let today = new Date(formState.date);
    let diff = Math.round((today - start) / (1000 * 60 * 60 * 24)) + 1;

    const { phases } = userWlProgram.weight_loss_program;
    let totalDays = 0;
    let current_phase = 0;

    for (let i = 0; i < phases?.length; i++) {
      totalDays += phases[i].days;
      current_phase = i;
      if (diff <= totalDays) break;
    }

    return phases[current_phase];
  }, [formState.date]);

  useEffect(() => {
    if (userWlProgram) {
      setOptions(generateFoodList(userWlProgram, formState.date));
    }
  }, [formState.date]);

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const foodSpliter = foodList => {
    const {meals, snacks} = foodList;
    const mData = {}
    const sData = {};

  Object.values(meals).forEach((meal, index) => {
    mData[index] = meal.map(food => { return { label: food, value: food.split('-')[1] }})
    });

  Object.values(snacks).forEach((snack, index) => {
      sData[index] =
        snack.map(food => {
          return { label: food, value: food.split('-')[1] };
        })
    });
    return {meals: mData, snacks: sData};
  };

  const extractFoodLabel = () => {
    const meals = {};
    const snacks = {};

    for (const [key, value] of Object.entries(formState.meals)) {
      meals[key] = value.map(option => option.label);
    }

    for (const [key, value] of Object.entries(formState.snacks)) {
      snacks[key] = value.map(option => option.label);
    }

    return { meals, snacks }
  }

  const handleDailyLog = e => {
    const { meals, snacks } = extractFoodLabel();
    const dailyLog = {
      date: formState.date.toString(),
      weight: formState.weight,
      water_intake: formState.waterIntake,
      sleeping_hours: formState.sleepingHours,
      blood_sugar: formState.bloodSugar,
      blood_pressure: formState.bloodPressure,
      meals,
      snacks
    };

    const url = `/${clinicId}/user_wl_program/${userWlProgram?.id}/daily_logs`;

    createDailyLog(dailyLog, csrfToken, url, axios.post, refreshPatient);
    setShow(false);
    clearForm();
  };

  const handleEdit = () => {
    const { meals, snacks } = extractFoodLabel();
    const dailyLog = {
      date: formState.date.toString(),
      weight: formState.weight,
      water_intake: formState.waterIntake,
      sleeping_hours: formState.sleepingHours,
      blood_sugar: formState.bloodSugar,
      blood_pressure: formState.bloodPressure,
      meals,
      snacks
    };

    const url = `/${clinicId}/user_wl_program/${userWlProgram?.id}/daily_logs/${selectedDailyLog.id}`;
    createDailyLog(dailyLog, csrfToken, url, axios.patch, refreshPatient);
    setShowEdit(false);
    clearForm();
  };

  const addQuantity = quantity => {
    const index = selectedOptions.length - 1;
    const copyOption = selectedOptions.map(el => el);

    copyOption[index].label = `${quantity}x-${copyOption[index].label}`;
       setFormState(prevState => ({
         ...prevState,
         [key]: {
           ...prevState[key],
           [selectedFood[key]]: [...copyOption]
         }
       }));

    toggleQuantityModal();
  };

  const handleFoodIntake = (e, action, foodNumber, isMeal) => {
    const intakeItem = isMeal ? 'meals' : 'snacks';
    setSelectedFood({ [intakeItem]: foodNumber });

    action.action !== "remove-value" && toggleQuantityModal();

    setFormState(prevState => ({
      ...prevState,
      [intakeItem]: {
        ...prevState[intakeItem],
        [foodNumber]: e
      }
    }));
  };

  const handleSelectedLog = dailyLog => {
    setFormState({
      ...DEFAULT_LOG_ENTRY_FORM_STATE,
      date: toDate(dailyLog.date),
      weight: dailyLog.weight,
      waterIntake: dailyLog.water_intake,
      sleepingHours: dailyLog.sleeping_hours,
      bloodSugar: dailyLog.blood_sugar,
      bloodPressure: dailyLog.blood_pressure
    });

    setSelectedDailyLog(dailyLog);
    setFormState(prevState => ({...prevState, ...foodSpliter(dailyLog)}));
    setOptions(generateFoodList(userWlProgram, new Date(dailyLog.date)));
  };

  const handleDelete = id => {
    const url = `/${clinicId}/user_wl_program/${userWlProgram?.id}/daily_logs/${id}`;
    deleteDailyLog(url, refreshPatient, csrfToken);
  };

  const toggleQuantityModal = () => {
    setIsQuantityModalOpen(!isQuantityModalOpen);
  };

  const handleFocus = () => {
    setOptions(generateFoodList(userWlProgram, formState.date));
  };

  const closeModal = () => {
      clearForm();
    if (isAllLogs) {
      setShowEdit(false);
      handleClose();
    } else {
      setShow(false);
    }
  };

  return (
    <>
      <Modal
        show={props.show || isAllLogs}
        onHide={closeModal}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Modal.Header>
          <Modal.Title>
            Weight Loss Program: {userWlProgram?.weight_loss_program?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='logs-modal-body'>
          {!showEdit && isAllLogs ? (
            orderBy(userWlProgram?.daily_logs, ['date'], ['desc']).map(
              (dailyLog, key) => (
                <DailyLogCard
                  dailyLog={dailyLog}
                  key={'daily-log-' + key}
                  open={key === 0}
                  setShowEdit={setShowEdit}
                  handleSelectedLog={handleSelectedLog}
                  handleDelete={handleDelete}
                />
              )
            )
          ) : (
            <>
              <div className='form-group'>
                Date:
                <DatePicker
                  date={formState.date}
                  setDate={value =>
                    setFormState(prevState => ({
                      ...prevState,
                      ['date']: value
                    }))
                  }
                  min={new Date(userWlProgram?.start_date)}
                  max={new Date(userWlProgram?.end_date)}
                />
              </div>
              <div className='form-group'>
                Weight <span className='required' />:
                <input
                  className='form-control'
                  type='number'
                  name='weight'
                  min={0}
                  max={1000}
                  step='any'
                  value={formState.weight}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                Yesterday's Water Intake <span className='required' />:
                <input
                  className='form-control'
                  type='number'
                  min={0}
                  max={1000}
                  step='any'
                  name='waterIntake'
                  value={formState.waterIntake}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                Hours Slept Last Night
                <span className='required' />:
                <input
                  className='form-control'
                  type='number'
                  min={0}
                  max={1000}
                  step='any'
                  name='sleepingHours'
                  value={formState.sleepingHours}
                  required
                  onChange={handleChange}
                />
              </div>

              <div className='form-group'>
                Today's Blood Sugar (if applicable):
                <input
                  className='form-control'
                  type='number'
                  min={0}
                  max={1000}
                  step='any'
                  value={formState.bloodSugar}
                  name='bloodSugar'
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                Today's Blood Pressure (if applicable):
                <input
                  className='form-control'
                  value={formState.bloodPressure}
                  name='bloodPressure'
                  onChange={handleChange}
                />
              </div>
              {options?.length != 0 ? (
                <div style={{ width: '100%' }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography>Log Meals</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {new Array(currentPhase.meals)
                        .fill('')
                        .map((val, index) => (
                          <div className='form-group' key={index}>
                            Log Meal {index + 1}:
                            <Select
                              isMulti
                              defaultValue={formState.meals[index]}
                              onFocus={handleFocus}
                              onChange={(items, action) =>
                                handleFoodIntake(items, action, index, true)
                              }
                              placeholder='Start Typing...'
                              options={options}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: PRIMARY_COLOR
                                }
                              })}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <Typography>Log Snacks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {new Array(currentPhase.snacks)
                        .fill('')
                        .map((val, index) => (
                          <div className='form-group' key={index}>
                            Log Snack {index + 1}:
                            <Select
                              isMulti
                              defaultValue={formState.snacks[index]}
                              onFocus={handleFocus}
                              onChange={(items, action) =>
                                handleFoodIntake(items, action, index, false)
                              }
                              placeholder='Start Typing...'
                              options={options}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: PRIMARY_COLOR
                                }
                              })}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography>Log Meals</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {new Array(currentPhase?.meals || 0)
                        .fill('')
                        .map((val, index) => (
                          <div className='form-group' key={index}>
                            Log Meal {index + 1}:
                            <CreatableSelect
                              isMulti
                              defaultValue={formState.meals[index]}
                              onChange={(items, action) =>
                                handleFoodIntake(items, action, index, true)
                              }
                              placeholder='Type food name'
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: PRIMARY_COLOR
                                }
                              })}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <Typography>Log Snacks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {new Array(currentPhase?.snacks || 0)
                        .fill('')
                        .map((val, index) => (
                          <div className='form-group' key={index}>
                            Log Snack {index + 1}:
                            <CreatableSelect
                              isMulti
                              onChange={(items, action) =>
                                handleFoodIntake(items, action, index, false)
                              }
                              placeholder='Type food name'
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: PRIMARY_COLOR
                                }
                              })}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
          <Button
            variant='primary'
            type='Submit'
            onClick={isAllLogs ? handleEdit : handleDailyLog}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <QuantityModal
        isOpen={isQuantityModalOpen}
        handleOpen={toggleQuantityModal}
        addQuantity={addQuantity}
        label={
          selectedOptions?.length > 0
            ? selectedOptions[selectedOptions.length - 1].label
            : 'Food Label'
        }
      />
    </>
  );
};

export default LogsEntryModal;
