import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { showEditSessionForm } from '../../actions/sessions'
import PastPackages from '../packages/PastPackages'

class SessionsTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {activeRow: ""};
	}

	onRowClick = (session) => {
		this.props.showEditSessionForm(session);
		this.setState({activeRow: session.id})
	}
	render () {
		const sessions = this.props.sessions.map ( session => {
			let activeRow = (this.props.sessionEdit && this.state.activeRow == session.id) ? "active-row" : "";
			return (
				<SessionRow key={session.id} onClick={() => {this.onRowClick(session)}} className={activeRow}>
					<td>{new Date(session.date).toLocaleDateString('en-US', {timeZone: 'UTC'})}</td>
					{this.props.isOwner && <td>{session.notes}</td>}
					<td>{session.time_on_laser}</td>
				</SessionRow>
			)
		})

		return (
			<table className="table table-hover">
				<thead>
					<tr>
						<th>Date</th>
						{this.props.isOwner && <th>Notes</th> }
						<th>Time on Laser</th>
					</tr>
				</thead>
				<tbody>
					{sessions}
				</tbody>
			</table>
		)
	}
}

const SessionRow = styled.tr`
	cursor: pointer;
`;

const mapStateToProps = state => {
	return {
		sessionEdit: state.patientIndex.sessionEdit,
		isOwner: state.patientIndex.isOwner,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		showEditSessionForm: session => {
			dispatch(showEditSessionForm(session))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SessionsTable)
