import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import {
  TableBody, TableCell, TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getComparator, stableSort } from './tableFunctions';
import IndexStatusField from './IndexStatusField';

const IndexTableBody = ({
  order, orderBy, page, rowsPerPage, classes, openOrder, setOrder,
}) => {
  const orders = useSelector((state) => state.patientIndex.orders);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, orders.length - page * rowsPerPage);

  const handleOpen = (selected) => {
    openOrder();
    setOrder(selected);
  };

  const calculateTotalUnpaidAndPaid = () => {
    let totalUnpaid = 0;
    let totalPaid = 0;
    let totalRefunds = 0;
    let totalCharges = 0;

    orders.forEach((row) => {
      const unpaidValue = parseFloat(row.total);
      const paidValue = parseFloat(row.amount_paid);
      const refundValue = parseFloat(row.refund_amount);

      if (!isNaN(unpaidValue)) {
        totalUnpaid += unpaidValue;
      }
      if (!isNaN(refundValue)) {
        totalRefunds += refundValue;
      }
      if (!isNaN(paidValue)) {
        totalPaid += paidValue;
      }
    });

      totalCharges = totalPaid + totalUnpaid

    totalRefunds = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalRefunds);
    totalCharges = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalCharges);
    totalUnpaid = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalUnpaid);
    totalPaid = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalPaid);


    return {
      totalUnpaid,
      totalPaid,
      totalRefunds,
      totalCharges
    };
  };

  const { totalUnpaid, totalPaid, totalRefunds, totalCharges } = calculateTotalUnpaidAndPaid();

  return (
    <TableBody>
      {stableSort(orders, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const {
            total, amount_paid, created_at: date, id, status, refund_amount
          } = row;
          const sumTotal =(parseFloat(total)+parseFloat(amount_paid)) || 0;
          const charges = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sumTotal);
          const balance = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
          const amountPaid = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(amount_paid);
          const refunded = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(refund_amount);
          return (
            <TableRow
              className={classes.tableRow}
              role='checkbox'
              tabIndex={-1}
              key={id}
              onClick={() => handleOpen(row)}
            >
              <TableCell align='right'>
                {new Date(date).toLocaleDateString('en-US', {
                  timeZone: 'UTC'
                })}
              </TableCell>
              <TableCell align='right'>{charges}</TableCell>
              <TableCell align='right'>{refunded}</TableCell>
              <TableCell align='right'>{amountPaid}</TableCell>
              <TableCell align='right'>{balance}</TableCell>
              <TableCell align='right'>
                <IndexStatusField
                  close={openOrder}
                  status={status}
                  order={row}
                />
              </TableCell>
            </TableRow>
          );
        })}
         <TableRow>
      <TableCell align='right'>Totals: </TableCell>
      <TableCell align='right' style={{color: 'red'}}>
          {totalCharges}
        </TableCell>
        <TableCell align='right' style={{color: 'red'}}>
          {totalRefunds}
        </TableCell>
        <TableCell align='right' style={{color: 'red'}}>
          {totalPaid}
        </TableCell>
        <TableCell align='right' style={{color: 'red'}}>
          {totalUnpaid}
        </TableCell>
        <TableCell align='right' style={{color: 'red'}}></TableCell>
      </TableRow>
      {emptyRows > 0 && (
      <TableRow style={{ height: 53 * emptyRows }}>
        <TableCell colSpan={6} />
      </TableRow>
      )}
    </TableBody>
  );
};

IndexTableBody.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  openOrder: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default IndexTableBody;
