import React, { useRef } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { cancelOrder } from '../../actions/orders';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
  },
}));

const CancelForm = ({ order }) => {
  const token = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.csrfToken
      : state.token
  ));

  const isActiveOwner = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.isOwner && state.patientIndex.subscriptionActive
      : false
  ));

  const form = useRef(null);

  const classes = useStyles();

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    dispatch(cancelOrder(order.clinic_id, order.user_id, order.id, formData));
  };

  const handlePreventSettingOrder = (e) => {
    e.stopPropagation();
  };

  if (!isActiveOwner) return null;

  return (
    <form ref={form} onSubmit={submitHandler}>
      <input type="hidden" name="authenticity_token" value={token} />
      <input type="hidden" name="_method" value="delete" />
      <IconButton
        aria-label="delete"
        onClick={handlePreventSettingOrder}
        type="submit"
        title="cancel order"
        className={classes.root}
      >
        <CancelOutlinedIcon />
      </IconButton>
    </form>
  );
};

CancelForm.propTypes = {
  order: PropTypes.objectOf(oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.arrayOf(PropTypes.object),
  ])).isRequired,
};

export default CancelForm;
