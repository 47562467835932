import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import {
  TableBody, TableCell, TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getComparator, stableSort } from './tableFunctions';

const IndexTableBody = ({
  order, orderBy, page, rowsPerPage, classes, openAssessment,
}) => {
  const assessments = useSelector((state) => state.patientIndex.assessments);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, assessments.length - page * rowsPerPage);

  const newAssessment = assessments.map((row) => {
    const {
      answer1, answer2, answer3, answer4, answer5,
      answer6, answer7, answer8, answer9, answer10,
      created_at: createdAt,
    } = row;

    const scoresArr = [answer1, answer2, answer3, answer4, answer5,
      answer6, answer7, answer8, answer9, answer10];
    const scores = scoresArr.reduce((a, b) => parseInt(a, 10) + parseInt(b, 10));
    const date = new Date(createdAt).toDateString();

    return { ...row, scores, date };
  });

  return (
    <TableBody>
      {stableSort(newAssessment, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const { date, scores, id } = row;
          return (
            <TableRow
              className={classes.tableRow}
              role="checkbox"
              tabIndex={-1}
              key={id}
              onClick={() => openAssessment(row)}
            >
              <TableCell align="right">{date}</TableCell>
              <TableCell align="right">{scores}</TableCell>
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
      <TableRow style={{ height: 53 * emptyRows }}>
        <TableCell colSpan={6} />
      </TableRow>
      )}
    </TableBody>
  );
};

IndexTableBody.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  openAssessment: PropTypes.func.isRequired,
};

export default IndexTableBody;
