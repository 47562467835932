import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate
} from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CampaignsList from './components/CampaignsList.jsx';
import TemplatesList from './templates/TemplatesList.jsx';
import CampaignNew from './CampaignNew.jsx';
import {
  CUSTOM_CAMPAIGN,
  AUTOMATED_CAMPAIGN
} from '../../actions/actionTypes.js';

const Campaign = props => {
  const { url, campaignsList, templatesList, clinicId, csrfToken, clinicSlug } =
    props;
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentTab = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('tab') || 'campaigns';
  };

  const handleSelect = key => {
    navigate(`/${clinicSlug}/campaigns?tab=${key}`);
  };

  useEffect(() => {
    const activeTab = getCurrentTab();
    if (activeTab !== getCurrentTab()) {
      navigate(`/${clinicSlug}/campaigns?tab=${activeTab}`);
    }
  }, [location.search, clinicSlug]);

  return (
    <div className='layout-content'>
      <div className='container-fluid flex-grow-1 container-p-y'>
        <div className='card'>
          <div className='card-header'>
            <Tabs
              activeKey={getCurrentTab()}
              className='center-nav-tabs'
              onSelect={handleSelect}
              unmountOnExit
            >
              <Tab
                eventKey='campaigns'
                title='Campaigns'
                className='tab-content'
              >
                <CampaignsList
                  url={url}
                  csrfToken={csrfToken}
                  campaigns={campaignsList}
                  clinicId={clinicId}
                />
              </Tab>
              <Tab
                eventKey='templates'
                title='Build and Manage Templates'
                unmountOnExit
              >
                <TemplatesList
                  templates={templatesList}
                  clinicId={clinicId}
                  csrfToken={csrfToken}
                />
              </Tab>
              <Tab
                eventKey='dailyCampaigns'
                title='Promotional campaigns'
                unmountOnExit
              >
                <CampaignNew
                  csrfToken={csrfToken}
                  redirect_url={url.replace('/new', '')}
                  clinicId={clinicId}
                  templatesList={templatesList}
                  campaignType={CUSTOM_CAMPAIGN}
                />
              </Tab>
              <Tab
                eventKey='dripCampaigns'
                title='Informational campaigns'
                unmountOnExit
              >
                <CampaignNew
                  csrfToken={csrfToken}
                  redirect_url={url.replace('/new', '')}
                  clinicId={clinicId}
                  templatesList={templatesList}
                  campaignType={AUTOMATED_CAMPAIGN}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

const CampaignRouter = props => (
    <Router>
      <Routes>
        <Route
          path='/:clinicSlug/campaigns'
          element={<Campaign {...props} />}
        />
      </Routes>
    </Router>
  );

export default CampaignRouter;
