import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from '../../../common/DatePicker';
import { convertDate } from '../../../../helpers/weight_loss';

const StartUserWLPModal = props => {
  const {
    handleStartProgram,
    selectedPatient,
    setDate,
    show,
    handleClose,
    date
  } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='sm'
      >
        <Modal.Header>
          <Modal.Title>Set Start Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset>
            <div className='form-group'>
              <label>Patient:</label>
              <span className='text-primary'>
                {` ${selectedPatient?.patient_name}`}
              </span>
            </div>
            <div className='form-group'>
              <label>Weight Loss Program:</label>
              <span className='text-primary'>
                {` ${selectedPatient?.weight_loss_program_name}`}
              </span>
            </div>
            <div className='form-group'>
              Start Date:
              <span className='text-primary'>
                {` ${
                  selectedPatient?.start_date &&
                  new Date(selectedPatient?.start_date).toLocaleDateString(
                    'en-US'
                  )
                }`}
              </span>
              <DatePicker date={date} setDate={setDate} />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleStartProgram}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StartUserWLPModal;
