import {
  Delete, Mail, PersonAdd,
  PersonRemove, PriorityHigh, Visibility, Edit
} from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { addDays, startOfDay } from 'date-fns';
import * as React from 'react';
import { CUSTOM_CAMPAIGN } from '../actions/actionTypes';
import { deleteCampaign } from '../actions/campaign';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const campaignDropDownOptions = [
  { value: 'allClients', label: 'All Clients' },
  { value: 'archivedClients', label: 'Archived Clients' },
  { value: 'enrolledClients', label: 'Enrolled Clients' }
];

export const campaignClientsTableColumns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 2 },
  {
    field: 'archive',
    headerName: 'Archive',
    sortable: false,
    flex: 1,
    valueGetter: params => (params.value == true ? 'archive' : 'non-archive' )
  },
  { field: 'state', headerName: 'State', width: 300 },
  { field: 'city', headerName: 'City', width: 300 }
];

export const campaignListColumns = (
  setCampaignList,
  handleStatsViewClick,
  handleClientsListClick,
  setClientType,
  clinicId
) => [
  { field: 'title', headerName: 'Title', flex: 1 },
  {
    field: 'clicks',
    headerName: 'Clicks',
    flex: 1
  },
  {
    field: 'days',
    headerName: 'Days',
    flex: 1
  },
  {
    field: 'campaign_type',
    headerName: 'Type',
    flex: 1,
    valueGetter: params =>
      params.value == 'automated'
        ? 'Informational'
        : 'Promotional'
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    valueGetter: params =>
      params.value
        ? 'Active'
        : 'In-Active'
  },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: params => [
      <GridActionsCellItem
        icon={<Visibility />}
        label='View Stats'
        showInMenu
        onClick={() => {
          handleStatsViewClick(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label='Edit'
        showInMenu
        onClick={() => {
          window.location.href = `/${clinicId}/campaigns/${params.row.id}/edit`;
        }}
      />,
      <GridActionsCellItem
        icon={<PersonAdd />}
        label='Add Client'
        showInMenu
        onClick={() => {
          handleClientsListClick(params.row.id);
          setClientType('add');
        }}
      />,
      <GridActionsCellItem
        icon={<PriorityHigh />}
        label='Restart Campaign'
        showInMenu
        disabled={params.row.campaign_type === CUSTOM_CAMPAIGN}
        onClick={() => {
          handleClientsListClick(params.row.id);
          setClientType('restart');
        }}
      />,
      <GridActionsCellItem
        icon={<PersonRemove />}
        label='Remove Clients'
        showInMenu
        onClick={() => {
          handleClientsListClick(params.row.id);
          setClientType('remove');
        }}
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label='Delete'
        showInMenu
        onClick={() => {
          deleteCampaign(params.row.clinic_id, params.row.id, setCampaignList);
        }}
      />
    ]
  }
];

export const campaignUsersListColumns = (
  setEmail,
  setEmailModal,
  setEmailUsersModal
) => [
  { field: 'subject', headerName: 'Subject', flex: 1 },
  { field: 'clicks', headerName: 'Clicks', flex: 1 },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: params => [
      <GridActionsCellItem
        icon={<Mail />}
        label='View Email'
        showInMenu
        onClick={() => {
          setEmail({
            id: params.row.id,
            subject: params.row.subject,
            body: params.row.body
          });
          setEmailModal(true);
        }}
      />,
      <GridActionsCellItem
        icon={<Visibility />}
        label='View Clients'
        showInMenu
        onClick={() => {
          setEmail({
            id: params.row.id
          });
          setEmailUsersModal(true);
        }}
      />
    ]
  }
];

export const findDifferenceBetweenDates = (endDate, startDate) =>
  (endDate - startDate) / (1000 * 3600 * 24);

export const calculateDays = (totalDays, startDate = null) => {
  const date =
    startDate && startOfDay(new Date()) < new Date(startDate)
      ? new Date(startDate)
      : new Date();

  const dates = [];
  for (var i = 0; i < totalDays; i++) {
    dates.push(addDays(date, i));
  }
  return dates;
};

export const getStartDate = inputList => {
  const minDate = inputList.reduce(function (a, b) {
    return a['date'] < b['date'] ? a : b;
  });
  return minDate['date'];
};

export const getEndDate = inputList => {
  const maxDate = inputList.reduce(function (a, b) {
    return a['date'] > b['date'] ? a : b;
  });
  return maxDate['date'];
};
