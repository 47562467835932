import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreItems } from '../../actions/storeItems';
import StoreItem from './StoreItem';
import TotalAmounts from './TotalAmounts';
import { createOrder } from '../../actions/orders';
import SaveOrderDialog from './SaveOrderDialog';
import MustBeSubscribed from './MustBeSubscribed';

const useStyles = makeStyles((theme) => ({
  form: {
    backgroundColor: '#f9f9f9',
    padding: theme.spacing(2),
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    backgroundColor: 'white',
    marginBottom: theme.spacing(3),
  },
  tableHeader: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    flex: '1 1 100%',
  },
  bottomSection: {
    display: 'flex',
  },
  btnContainers: {
    padding: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const Store = ({ closeWindow, newOrder }) => {
  const clinicId = useSelector((state) => state.patientIndex.clinicId);
  const clientId = useSelector((state) => state.patientIndex.activePatient);
  const token = useSelector((state) => state.patientIndex.csrfToken);
  const subscriptionActive = useSelector((state) => state.patientIndex.subscriptionActive);

  const [list, setList] = useState([{ objId: 0, item: null }]);

  const form = useRef(null);

  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(getStoreItems(clinicId));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    await dispatch(createOrder(clinicId, clientId, formData));
    closeWindow();
    newOrder(true);
  };

  if (!subscriptionActive) return (<MustBeSubscribed close={closeWindow} open />);

  return (
    <div className={classes}>
      <form ref={form} onSubmit={handleSubmit} className={classes.form}>
        <input type='hidden' name='authenticity_token' value={token} />
        <input type='hidden' name='_method' value='post' />
        <TableContainer className={classes.table}>
          <Toolbar className={classes.tableHeader}>
            <Typography
              className={classes.title}
              variant='h6'
              id='tableTitle'
              component='div'
            >
              New Order
            </Typography>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((obj, index) => (
                <StoreItem
                  key={`item-${obj.objId}`}
                  setItemList={setList}
                  itemList={list}
                  currentIndex={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container className={classes.bottomSection}>
          <Grid container className={`${classes.btnContainers} store-btn-container`}>
            <SaveOrderDialog submitForm={handleSubmit} form={form} />
            <button type='button' className='btn btn-outline-danger btn-responsive' onClick={() => closeWindow()}>
              Cancel
            </button>
          </Grid>
          <TotalAmounts list={list} />
        </Grid>
      </form>
    </div>
  );
};

Store.propTypes = {
  closeWindow: PropTypes.func.isRequired,
  newOrder: PropTypes.func.isRequired,
};

export default Store;
