import React from 'react';
import { toast } from 'react-toastify';

function LogoUploadInput({onChange, logo}) {

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // Check if the file size is less than 5MB (5 * 1024 * 1024 bytes)
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxFileSize) {
      toast.error('Image file size exceeds the 5MB limit.');
    }
    if (file) {
      onChange(file);
    }
  };

  const previewImage = typeof(logo) == 'string'  ? logo : URL.createObjectURL(logo);

  return (
    <>
      <div
        className='image-upload-button'
        style={{ marginBottom: '1rem' }}
      >
        {previewImage ? (
          <label htmlFor='file-input' style={{ cursor: 'pointer' }}>
            <img src={previewImage} alt='Uploaded' />
          </label>
        ) : (
          <label htmlFor='file-input' style={{ cursor: 'pointer' }}>
            <span>+ Click to upload</span>
          </label>
        )}
        <input
          type='file'
          id='file-input'
          style={{ display: 'none' }}
          accept='image/*'
          onChange={handleImageUpload}
        />
      </div>
    </>
  );
  };

export default LogoUploadInput;
