import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
  rangeContainer: {
    border: 'solid 1px #B3B3B3'
  }
}));

const DateRange = props => {
  const { handleDateRangeChange, dateRange } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const onToggle = event => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <input
        type='button'
        className='btn btn-sm-secondary btn-primary'
        name='date'
        onClick={onToggle}
        value={`${format(dateRange[0].startDate, 'dd-MMM-yyyy')} ~ ${format(
          dateRange[0].endDate,
          'dd-MMM-yyyy'
        )}`}
      />

      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        style={{ zIndex: 1060 }}
        placement='bottom-end'
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.rangeContainer}>
            <DateRangePicker
              onChange={handleDateRangeChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              direction='horizontal'
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DateRange;
