/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ActivePackage from './ActivePackage';
import ActivePackageTable from './ActivePackageTable';
import NewClinicPackageSelect from './NewClinicPackageSelect';

const getDefaultPackage = (packages) => {
  if (!packages.length) return null;

  if (packages.length === 1) return packages[0];

  return packages.sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
  )[0];
};

const ActivePackageIndex = (props) => {
  const [forceTable, setForceTable] = useState(false);
  const [userSelectedPackageId, setUserSelectedPackageId] = useState(null);

  const { patient: { active_packages }, subscriptionActive } = props;

  useEffect(() => {
    setForceTable(false);
    setUserSelectedPackageId(null);
  }, [props.patient.id, active_packages.length]);

  const userSelectPackage = (selectedPackage) => {
    setForceTable(false);
    setUserSelectedPackageId(selectedPackage.id);
  };

  let packagesElement = null;

  // Show table
  if (forceTable) {
    packagesElement = (
      <ActivePackageTable
        activePackages={active_packages}
        selectPackage={userSelectPackage}
      />
    );
  }

  // Show selected package
  if (userSelectedPackageId && !packagesElement) {
    packagesElement = (
      <ActivePackage
        activePackage={active_packages.find((p) => p.id === userSelectedPackageId)}
        backToTable={() => setForceTable(true)}
        patient={props.patient}
      />
    );
  }

  // Show default package
  const defaultSelectedPackage = getDefaultPackage(active_packages);
  if (defaultSelectedPackage && !packagesElement) {
    packagesElement = (
      <ActivePackage
        activePackage={defaultSelectedPackage}
        backToTable={() => setForceTable(true)}
        patient={props.patient}
      />
    );
  }


  // Show empty message
  return (
    <>
      <div className="row pt-3 pr-3 pl-3">
        <div className="col card px-0 px-md-2">
          <div className="card-body px-2 px-md-4">
            {packagesElement || 'No packages currently active for this client.'}
          </div>
        </div>
      </div>
      { subscriptionActive
        && (
        <div className="row pt-3 pr-3 pl-3">
          <div>
            <div className="card-body px-2 px-md-4">
              <NewClinicPackageSelect patient={props.patient} />
            </div>
          </div>
        </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionActive: state.patientIndex.subscriptionActive,
});

ActivePackageIndex.propTypes = {
  patient: PropTypes.shape({
    active_packages: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      remaining_sessions: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      updated_at: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  subscriptionActive: PropTypes.bool,
};

ActivePackageIndex.defaultProps = {
  subscriptionActive: false,
};

export default connect(
  mapStateToProps,
  null,
)(ActivePackageIndex);
