import React, { useEffect, useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import {
  IconButton, makeStyles, TableCell, TableRow,
} from '@material-ui/core';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import SearchItems from './SearchItem';
import PriceItem from './PriceItem';
import QtyItem from './QtyItem';
import DiscountItem from './DiscountItem';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
    border: '1px lightgray solid !important',
    '&:first-child': {
      borderLeft: '1px lightgray solid',
    },
    '&:nth-child(n+1)': {
      borderLeft: '0px lightgray solid',
    },
    '&:last-child': {
      borderRight: '0px lightgray solid !important',
      borderTop: '0px lightgray solid !important',
      borderBottom: '0px lightgray solid !important',
      padding: theme.spacing(1),
    },
  },
  label: {
    paddingBottom: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 'calc(1rem * .75)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  lastItem: {
    padding: 0,
    border: '0 !important',
  },
}));

const StoreItem = ({
  itemList, setItemList, currentIndex, defaultListItem,
}) => {
  const [item, setItem] = useState(null);
  const classes = useStyles();
  const [price, setPrice] = useState(0);

  const handleTotal = () => {
    const qty = item.qty || 1;
    const discount = item.discount || 0;
    const subtotal = qty * item.price;

    return subtotal - (subtotal * (discount / 100));
  };

  const handlePrice = () => {
    setPrice(handleTotal());
  };

  useEffect(() => {
    if (!price) return;
    setItem({ ...item, amount: price });
  }, [price]);

  useEffect(() => {
    if (!item || defaultListItem) return;
    setPrice(handleTotal());
    if (!item.qty) return;
    let tempList = [...itemList];
    if (itemList[currentIndex].item === null) {
      tempList = [...itemList, { objId: currentIndex + 1, item: null }];
    }
    setItemList(Object.assign([], tempList, {
      [currentIndex]: { objId: currentIndex, item },
    }));
  }, [item]);

  useEffect(() => {
    const currentIndexItem = itemList[currentIndex].item;
    if (!item || defaultListItem) return;
    if (currentIndexItem
       && currentIndexItem.id === item.id
       && currentIndexItem.category === item.category) return;

    setItem(itemList[currentIndex].item);
  }, [itemList]);

  useEffect(() => {
    if (defaultListItem) setItem(defaultListItem);
  }, []);

  const handleRemove = () => {
    const tempList = [...itemList];
    delete tempList[currentIndex];
    const newList = [...tempList.filter((el) => el.item != null), { objId: -1, item: null }]
      .map((obj, index) => ({ ...obj, objId: index }));
    setItemList(newList);
  };

  return (
    <TableRow>
      <TableCell className={classes.item}>
        <SearchItems
          setItem={setItem}
          item={item}
          inputIndex={currentIndex}
          disableField={!!setItemList}
        />
      </TableCell>
      <TableCell className={classes.item}>
        <PriceItem price={item ? item.price : null} />
        { item ? <input type="hidden" name={`order[order_items_attributes][${currentIndex}][price]`} value={item.price} /> : null}
      </TableCell>
      <TableCell className={classes.item}>
        {item ? (
          <DiscountItem
            inputIndex={currentIndex}
            setItem={setItem}
            setPrice={handlePrice}
            item={item}
            disableField={!!setItemList}
          />
        ) : null}
      </TableCell>
      <TableCell className={classes.item}>
        {item ? (
          <QtyItem
            inputIndex={currentIndex}
            item={item}
            setItem={setItem}
            setPrice={handlePrice}
            disableField={!!setItemList}
          />
        ) : null}
      </TableCell>
      <TableCell className={classes.item}>
        <PriceItem price={item ? price : null} />
      </TableCell>
      {setItemList ? (
        <TableCell className={classes.item}>
          <IconButton aria-label="remove" style={{ padding: '0' }} onClick={handleRemove}>
            <HighlightOffOutlinedIcon style={{ color: 'gray' }} />
          </IconButton>
        </TableCell>
      ) : null}
    </TableRow>
  );
};

StoreItem.propTypes = {
  setItemList: PropTypes.func,
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentIndex: PropTypes.number.isRequired,
  defaultListItem: PropTypes.objectOf(oneOfType(
    [PropTypes.object, PropTypes.number, PropTypes.string],
  )),
};

StoreItem.defaultProps = {
  setItemList: null,
  defaultListItem: null,
};

export default StoreItem;
