import {
  GET_ORDERS_SUCCESSFULLY, CANCEL_ORDER_SUCCESSFULLY,
  PAY_ORDER_SUCCESSFULLY
} from '../actions/actionsType';

export default (state = '', action) => {
  console.log(state, action, "action")
  switch (action.type) {
    case GET_ORDERS_SUCCESSFULLY:
      return { ...state, orders: [...action.payload] };
    case CANCEL_ORDER_SUCCESSFULLY: {
      const newState = state.map((obj) => {
        if (obj.id === action.payload.id) return action.payload;
        return obj;
      });
      return newState;
    }
    case PAY_ORDER_SUCCESSFULLY: {
      const listOfPackages = [];
      const newState = state.map((obj) => {
        if (obj.id === action.payload) return { ...obj, status: 1 };
        obj.order_items.map((item) => {
          if (item.store_item_id) return item;
          listOfPackages.push(item);
          return item;
        });
        return obj;
      });
      return newState;
    }
    default:
      return state;
  }
};
