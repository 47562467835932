import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '25%',
  },
}));

const SaveOrderDialog = ({ form }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    form.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const classes = useStyles();

  return (
    <>
      <button
        className='btn btn-outline-success btn-responsive mb-2'
        onClick={() => handleClickOpen}
      >
        Check Out
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-dialog-title'
        className={classes.root}
      >
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          Proceed to Checkout
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            autoFocus
            onClick={() => handleClose}
            className='btn btn-outline-danger btn-responsive'
          >
            Cancel
          </button>
          <button
            onClick={() => handleSubmit}
            className='btn btn-outline-success btn-responsive'
            type='submit'
          >
            Proceed
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SaveOrderDialog.propTypes = {
  form: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default SaveOrderDialog;
