import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Paper,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
}

const useStylesOwner = makeStyles(() => ({
  root: {
    zIndex: 999,
    paddingLeft: '25%',
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 999,
    paddingLeft: 0,
  },
}));

const MustBeSubscribed = ({ open, close }) => {
  const clinicId = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.clinicId
      : state.order[0].clinicId
  ));
  const isOwner = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.isOwner
      : false
  ));

  const handleClose = () => {
    close();
  };

  const classes = isOwner ? useStylesOwner() : useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className={classes.root}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Subscription Inactive
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You must renew your subscription to use this feature
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" href={`${clinicId}/subscriptions/new`}>
          Proceed to Payment Gateway
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MustBeSubscribed.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default MustBeSubscribed;
