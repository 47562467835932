import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { createTemplate, updateTemplate } from '../../../actions/template';
import TemplateEditor from './TemplateEditor.jsx';

const Template = props => {
  const {
    csrfToken,
    clinicId,
    selectedTemplate,
    clinicSlug
  } = props;
  const redirectUrl = `/${clinicSlug}/campaigns?tab=templates`;
  const [title, setTitle] = useState(
    selectedTemplate ? selectedTemplate.title : null
  );
  const [body, setBody] = useState(
    selectedTemplate ? selectedTemplate.body : null
  );
  const [subject, setSubject] = useState(
    selectedTemplate ? selectedTemplate.subject : null
  );
  const [selectedFiles, setSelectedFiles] = useState(null);

  const getFormDate = () => {
    const template = new FormData();
    template.set('template[clinic_id]', clinicId);
    template.set('template[title]', title);
    template.set('template[subject]', subject);
    if (selectedFiles) {
      template.set('template[file]', selectedFiles);
    }
    template.set('template[body]', body);
    return template;
  };
  const handleCreateTemplate = () => {
    createTemplate(getFormDate(), csrfToken, clinicId, redirectUrl);
  };

  const handleUpdateTemplate = () => {
    updateTemplate(
      clinicId,
      getFormDate(),
      csrfToken,
      selectedTemplate.id,
      redirectUrl
    );
  };

  return (
    <div className='layout-content'>
      <div className='container-fluid flex-grow-1 container-p-y'>
        <div className='card mb-3'>
          <div className='card-header font-weight-bold '>
            {selectedTemplate ? 'Update Tempate' : 'Create Template'}
          </div>
          <div className='card-body'>
            <div className='form-row'>
              <div className='col form-group'>
                <TemplateEditor
                  setBody={setBody}
                  body={body}
                  setSubject={setSubject}
                  setTitle={setTitle}
                  title={title}
                  subject={subject}
                />
                {selectedTemplate ? (
                  <Button
                    variant='btn btn-outline-warning mt-2 float-right'
                    onClick={handleUpdateTemplate}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant='btn btn-outline-success mt-2 float-right'
                    onClick={handleCreateTemplate}
                  >
                    Create
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
