import { format } from "date-fns";

export const campaignUserEmailColumns = [
  { field: 'user_email', headerName: 'Email', flex: 1 },
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    valueGetter: params => format(new Date(params.value), 'dd-MMM-yyyy')
  },
  { field: 'clicks', headerName: 'Clicks', flex: 1 }
];
