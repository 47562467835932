import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { deleteTemplate, getTemplate } from '../actions/template';
import { Button } from 'react-bootstrap';

export const templatesListColumns = (setTemplatesList) => [
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'subject', headerName: 'Subject', flex: 1 },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Edit'
        showInMenu
        onClick={() => {
          window.location.href = `/${params.row.clinic_id}/templates/${params.row.id}/edit`;
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label='Delete'
        showInMenu
        onClick={() => {
          deleteTemplate(params.row.clinic_id, params.row.id, setTemplatesList);
        }}
      />
    ]
  }
];

export const campaignTemplatesColumns = handleTemplateUse => [
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'subject', headerName: 'Subject', flex: 1 },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 80,
    renderCell: params => (
      <Button
        variant='btn btn-sm-secondary btn-primary '
        size='small'
        onClick={() => {
          handleTemplateUse(params.row.subject, params.row.body);
        }}
      >
        Use
      </Button>
    )
  }
];

export const editorConfig = (videoLink) => {
  return {
    readonly: false,
    toolbarButtonSize: 'middle',
    theme: 'default',
    width: 'auto',
    height: 'auto',
    minHeight: 300,
    enableDragAndDropFileToEditor: true,
    spellcheck: true,
    triggerChangeEvent: true,
    direction: 'ltr',
    language: 'en',
    debugLanguage: false,
    i18n: 'en',
    tabIndex: -1,
    toolbar: true,
    enter: 'P',
    useSplitMode: false,
    imageDefaultWidth: 100,
    removeButtons: ['fullsize', 'about'],
    // uploader: {
    //   insertImageAsBase64URI: true
    // },
    link: {
      processVideoLink: videoLink
    },
    placeholder: ''
  };
};

export const validateSize = (input) => {
  const fileSize = input.target.files[0].size / 1024 / 1024 ;
  if (fileSize > 5) {
    alert('File size exceeds 5 MB');
    return false
  }
  return true
};
