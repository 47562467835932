import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';


const ConsentForm = props => {
  const { show, setShow, userWlProgram } = props;

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} size='lg' className='p-3'>
      <Modal.Header closeButton>
        <Modal.Title className='bold-heading'>Consent Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='bold-heading'>INFORMED CONSENT</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: userWlProgram?.program?.agreement
          }}
        />
        <button
          className='btn btn-primary mt-2 ml-2'
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ConsentForm;
