import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AUTOMATED_CAMPAIGN } from '../../../actions/actionTypes';
import {
  getCampaignClients,
  updateCampaign,
  restartCampaign,
  getCampaignSpecificClients
} from '../../../actions/campaign';
import {
  calculateDays,
  campaignClientsTableColumns,
  campaignDropDownOptions
} from '../../../helpers/campaign';
import DateModal from './DateModal';
import Select from 'react-select';
import { PRIMARY_COLOR } from '../../../constants';

const CampaignUsersModal = props => {
  const { show, setShow, clinicId, campaignId, csrfToken, clientType } = props;

  const [users, setUsers] = useState([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [ids, setIds] = useState([]);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [dateModal, setDateModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedOption, setSelectedOption] = useState('All Clients');
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (clientType === 'restart') {
      getCampaignSpecificClients(
        clinicId,
        campaignId,
        setUsers,
        setCurrentCampaign,
        setIds
      );
    } else {
      getCampaignClients(
        clinicId,
        campaignId,
        setUsers,
        setSelectedUsersIds,
        setCurrentCampaign,
        setIds
      );
    }
  }, []);

  const handleCampaignUpdate = () => {
    if (date == null || date.getDay() < new Date().getDay()) {
      toastr.error('Invalid Date');
    } else if (clientType === 'restart') {
      restartCampaign(
        clinicId,
        campaignId,
        calculateDays(currentCampaign.days, date),
        selectedUsersIds,
        handleClose
      );
    } else {
      updateCampaign(
        clinicId,
        selectedUsersIds,
        csrfToken,
        campaignId,
        calculateDays(currentCampaign.days, date),
        handleClose
      );
    }
  };

  const handleCustomCampaignUpdate = () => {
    updateCampaign(
      clinicId,
      selectedUsersIds,
      csrfToken,
      campaignId,
      null,
      handleClose
    );
  };

  const handleClose = () => {
    setShow(false);
  };

  const filterClients = () => {
    const searchText = search.toLocaleLowerCase();

    if (selectedOption == 'Archived Clients') {
      return users.filter(
        client =>
          client.archive && client.name.toLowerCase().includes(searchText)
      );
    } else if (selectedOption == 'Enrolled Clients') {
      return users.filter(
        client =>
          !client.archive && client.name.toLowerCase().includes(searchText)
      );
    } else {
      return users.filter(client =>
        client.name.toLowerCase().includes(searchText)
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size='xl' centered>
        <Modal.Header closeButton>
          <Modal.Title>Campaign Clients</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: 550}}>
          <div style={{ height: 400, maxWidth: '100%' }}>
            <div className='mt-3 mb-3'>
              <input
                type='text'
                className='form-control chat-search my-3 mb-2'
                style={{maxWidth: '200px'}}
                placeholder='Search for Client'
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <Select
                value={campaignDropDownOptions.filter(
                  option => option.label === selectedOption
                )}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: PRIMARY_COLOR
                  }
                })}
                options={campaignDropDownOptions}
                onChange={e => setSelectedOption(e.label)}
              />
            </div>
            <DataGrid
              rows={filterClients()}
              disableSelectionOnClick
              columns={campaignClientsTableColumns}
              onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)}
              pageSize={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              selectionModel={selectedUsersIds}
              isRowSelectable={params => {
                if (clientType == 'restart') {
                  return true;
                }
                if (clientType == 'add' && !ids.includes(params.row.id)) {
                  return true;
                }
                if (clientType == 'remove' && ids.includes(params.row.id)) {
                  return true;
                }
              }}
              onSelectionModelChange={e => setSelectedUsersIds(e)}
              checkboxSelection
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='btn btn-outline-danger mt-4' onClick={handleClose}>
            Close
          </Button>
          {selectedUsersIds.length > 0 && (
            <Button
              variant='btn btn-outline-success mt-4'
              onClick={() =>
                currentCampaign?.campaign_type === AUTOMATED_CAMPAIGN &&
                (clientType == 'add' || clientType == 'restart')
                  ? setDateModal(true)
                  : handleCustomCampaignUpdate()
              }
            >
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <DateModal
        date={date}
        show={dateModal}
        setShow={setDateModal}
        handleCampaignUpdate={handleCampaignUpdate}
        setDate={setDate}
      />
    </>
  );
};

export default CampaignUsersModal;
