import React from 'react';

const Content = props => {
  const { appointment, isOwner, currentViewAppointments } = props;

  const showNextAppointmet = () => {
    const appointmentsLength = currentViewAppointments.length;
    if (appointmentsLength === 0) return 'No next Appointment';

    const index = currentViewAppointments
      .map(appointment => appointment.StartTime)
      .indexOf(appointment.StartTime);

    return index + 1 < appointmentsLength
      ? new Date(
          currentViewAppointments[index + 1].StartTime
        ).toLocaleDateString('en-US', { hour: 'numeric', minute: 'numeric' })
      : 'No next Appointment';
  };

  return (
    <div className='quick-info-content'>
      {appointment.elementType === 'cell' ? (
        <div className='e-cell-content'> </div>
      ) : (
        <div className='event-content'>
          <div className='notes-wrap'>
            <label style={{ fontWeight: '600', marginRight: '3px' }}>
              {'Appointment Type: '}
            </label>
            <span>{appointment.appointment_type?.name}</span>
          </div>
          {appointment.patient_notes !== '' ? (
            <div className='notes-wrap'>
              <label style={{ fontWeight: '600', marginRight: '3px' }}>
                {'Patient Notes: '}
              </label>
              <span>{appointment.patient_notes}</span>
            </div>
          ) : null}
          {isOwner && appointment.description !== '' ? (
            <div className='notes-wrap'>
              <label style={{ fontWeight: '600', marginRight: '3px' }}>
                {'Clinic Notes: '}
              </label>
              <span>{appointment.description}</span>
            </div>
          ) : null}
          <div className='notes-wrap'>
            <label style={{ fontWeight: '600', marginRight: '3px' }}>
              {'Clinic Status: '}
            </label>
            <span>
              {appointment.status.charAt(0).toUpperCase() +
                appointment.status.slice(1)}
            </span>
          </div>
          <div className='notes-wrap'>
            <label style={{ fontWeight: '600', marginRight: '3px' }}>
              {'Next Appointment: '}
            </label>
            <span>{showNextAppointmet()}</span>
          </div>
          {
            isOwner &&
            <div className='notes-wrap'>
              <label style={{ fontWeight: '600', marginRight: '3px' }}>
                {'Patient Status: '}
              </label>
              {appointment.status === 'confirmed' || appointment.status === null ? (
                <span>Confirmed by Patient</span>
                ) : <span>Pending</span>}           
            </div>
          }        
        </div>
      )}
    </div>
  );
};
export default Content;
