import React, { useState, useEffect, useRef } from "react";

function TodoForm(props) {
  const [input, setInput] = useState(props.edit ? props.edit.value : "");
  const [category, setCategory] = useState(props.edit ? props.edit.category : "");

  const inputRef = useRef(null);
  const categoryRef = useRef(null);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (categoryRef.current.reportValidity() && inputRef.current.reportValidity()) {
      props.onSubmit({
        id: Math.floor(Math.random() * 10000),
        name: input,
        category,
      });
      setInput('');
      setCategory('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className='todo-form'>
      {props.edit ? (
        <>
          <input
            value={category}
            onChange={e => setCategory(e.target.value)}
            name='category'
            ref={categoryRef}
            className='todo-input edit'
          />
          <input
            value={input}
            onChange={handleChange}
            name='name'
            ref={inputRef}
            className='todo-input edit'
          />
          <button
            onClick={handleSubmit}
            className='btn btn-outline-primary btn-md'
          >
            Update
          </button>
        </>
      ) : (
        <>
          <input
            placeholder='Category'
            value={category}
            onChange={e => setCategory(e.target.value)}
            name='category'
            ref={categoryRef}
            required={true}
            className='todo-input todo-category'
          />
          <input
            placeholder='Enter food names separated by comma (,)'
            value={input}
            onChange={handleChange}
            name='name'
            required={true}
            className='todo-input'
            ref={inputRef}
          />
          <button
            onClick={handleSubmit}
            className='btn btn-outline-primary btn-md'
          >
            Add
          </button>
        </>
      )}
    </form>
  );
}

export default TodoForm;
