import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { DataGrid } from '@mui/x-data-grid';
import { dynamicIntakeColumns } from '../../helpers/intakeForm';
import FormBuilder from '../formBuilder/FormBuilder';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const Dynamic = ({ dynamicIntakeForms, clinicId, campaignsList, csrfToken }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editForm, setEditForm] = useState({});
  const [intakeForms, setIntakeForms] = useState(dynamicIntakeForms);

  const handleDelete = async id => {
    const formsCopy = [...intakeForms];
    const filterData = intakeForms.filter(form => form.id !== id);
    try {
      setIntakeForms(filterData);
      await axios.delete(`dynamic_intake_forms/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      });
      toast.success('Intake form deleted')
    } catch (e) {
      setIntakeForms(formsCopy);
      console.error(e);
    }
  };

  const handleIsEdit = updatedForms => {
    setIntakeForms(updatedForms);
    setIsEdit(!isEdit);
  };

  const handleEdit = id => {
    const response = intakeForms.find(form => form.id == id);
    setEditForm(response);
    setIsEdit(!isEdit);
  };

  return (
    <div className='card'>
      <div className='card-header font-weight-bold '>
        <h4 className='font-weight-bold ml-3 d-inline'>
          {isEdit ? 'Edit Form' : 'Intake Forms'}
        </h4>
        <Button
          variant='btn btn-glow-secondary btn-primary float-right text-white'
          onClick={() =>
            isEdit
              ? setIsEdit(false)
              : (window.location.href = `/${clinicId}/dynamic_intake_forms/new`)
          }
        >
          {isEdit ? 'Back' : 'Create'}
        </Button>
      </div>
      <div className='card-body'>
        {isEdit ? (
          <FormBuilder
            clinicId={clinicId}
            isEditMode={isEdit}
            editFormValues={editForm}
            csrfToken={csrfToken}
            campaignsList={campaignsList}
            handleIsEdit={handleIsEdit}
          />
        ) : (
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={intakeForms}
              columns={dynamicIntakeColumns({ handleDelete, handleEdit })}
              disableSelectionOnClick
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
export default Dynamic;
