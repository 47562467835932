import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AreYouSureModal = ({action, handleClose, open }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='are-you-sure-modal-title'
        aria-describedby='are-you-sure-modal-description'
      >
        <DialogTitle id='are-you-sure-modal-title'>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='are-you-sure-modal-description'>
            This action cannot be undone. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='inherit'>
            Cancel
          </Button>
          <Button onClick={action} color='inherit'>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AreYouSureModal;
