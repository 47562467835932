import {
  extend,
  L10n,
  closest,
  Internationalization,
  addClass,
  removeClass
} from '@syncfusion/ej2-base';

import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import {
  AppBarComponent,
  ItemDirective,
  ItemsDirective,
  ToolbarComponent
} from '@syncfusion/ej2-react-navigations';
import { GridComponent, Toolbar } from '@syncfusion/ej2-react-grids';
import timezones from 'timezones-list';
import Select from 'react-select';
import {
  Day,
  DragAndDrop,
  ExcelExport,
  Inject,
  Print,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
  Month,
  TimelineViews,
  TimelineMonth,
  TimelineYear
} from '@syncfusion/ej2-react-schedule';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import placeholder from '../../../../app/assets/images/placeholder.jpg';
import {
  createNewAppointment,
  deleteAppointment,
  deleteRecursiveAppointment,
  getAllMeetings,
  refreshInviteToken,
  updateAppointment,
  updateRecurrenceAppointmentStatus
} from '../../actions/scheduler';
import { headerTemplate } from '../../helpers/scheduler';
import Options from './Options';
import Footer from './quickInfo/Footer';
import Content from './quickInfo/Content';
import ModalTemplate from './ModalTemplate';
import CustomDialog from '../common/CustomDialog';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  ButtonComponent,
  CheckBoxComponent
} from '@syncfusion/ej2-react-buttons';
import CopyToClipboardButton from '../common/CopyToClipboardButton';
import { updateTimeZone } from '../../actions/scheduler';

const Index = props => {
  const {
    clinicId,
    clinicScheduler,
    csrfToken,
    rooms = [],
    isOwner,
    appointmentTypes,
    patients = [],
    isReporting = false,
    recAppointments = [],
    inviteToken,
    clinicSlug
  } = props;

  const [appointments, setAppointments] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [currentView, setCurrentView] = useState(isReporting ? 'Month' : `Day`);
  const [isTimelineView, setIsTimelineView] = useState(false);
  const scheduleObj = useRef(null);
  const recurrObject = useRef(null);
  const timeBtn = useRef(null);
  const workWeekObj = useRef(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [appStatus, setAppStatus] = useState({});
  const [token, setToken] = useState(inviteToken);
  const [isDescVisible, setIsDescVisible] = useState(true);
  const [clinicTimeZone, setClinicTimeZone] = useState(
    clinicScheduler?.timezone
  );
  let islayoutChanged = true;
  let liveTimeInterval;

  const breakTimes = useMemo(() => {
    return rooms
      .flatMap(
        room =>
          room.break_time &&
          Object.values(room.break_time).map(val => {
            return {
              Subject: JSON.parse(val.IsAllDay) ? 'Not Available' : 'Break',
              StartTime: val.StartTime,
              EndTime: val.EndTime,
              IsAllDay: JSON.parse(val.IsAllDay),
              IsBlock: true,
              RoomId: parseInt(val.RoomId),
              RecurrenceRule: val.RecurrenceRule
            };})
      )
      .filter(value => value !== null && value !== '');
  }, [rooms.length]);

  useEffect(() => {
    if (isReporting) {
      setAppointments([...recAppointments, ...breakTimes]);
      return;
    }
    handleMeetings(new Date(), new Date());
  }, [patients]);

  useEffect(() => {
    return () => {
      if (liveTimeInterval) {
        clearInterval(liveTimeInterval);
      }
    };
  }, []);

  const handleCancelDialog = () => {
    setShowCancelDialog(!showCancelDialog);
  };

  const updateLiveTime = () => {
    let scheduleTimezone = scheduleObj
      ? scheduleObj.current.timezone
      : 'Etc/GMT';
    let liveTime;
    if (scheduleObj.current.isAdaptive) {
      liveTime = new Date().toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: scheduleTimezone
      });
    } else {
      liveTime = new Date().toLocaleTimeString('en-US', {
        timeZone: scheduleTimezone
      });
    }
    timeBtn.current.innerHTML = liveTime;
  };

  const handleRefreshToken = async () => {
    try {
      let updatedToken = await refreshInviteToken(clinicId, csrfToken);
      setToken(updatedToken);
      toast.success('Token Refreshed!');
    } catch (e) {
      toast.error('There is an error, Try again!');
    }
  };

  const handleTimeZoneUpdate = async timeZone => {
    try {
      let updatedTimeZone = await updateTimeZone(
        clinicId,
        csrfToken,
        clinicScheduler.id,
        timeZone
      );
      setClinicTimeZone(updatedTimeZone);
      toast.success(`TimeZone set to ${timeZone}`);
    } catch (e) {
      toast.error('There is an error, Try again!');
    }
  };

  // const refreshEventReminder = () => {
  //   const eventCollection = scheduleObj.current?.getCurrentViewEvents();
  //   eventCollection?.forEach((event, i) => {
  //     const dateFormat = date =>
  //       new Date(
  //         date.getFullYear(),
  //         date.getMonth(),
  //         date.getDate(),
  //         date.getHours(),
  //         date.getMinutes(),
  //         date.getSeconds()
  //       );
  //     const startTime = dateFormat(
  //       event[scheduleObj.current.eventFields.startTime]
  //     );
  //     const now = new Date(new Date().setSeconds(0));

  //     const fiveMinutesFromNow = new Date(
  //       new Date(new Date().getTime() + 5 * 60 * 1000).setSeconds(0)
  //     ); // 5 minutes from now
  //     const oneMinutesFromNow = new Date(
  //       new Date(new Date().getTime() + 1 * 60 * 1000).setSeconds(0)
  //     ); // 5 minutes from now

  //     if (
  //       startTime >= now &&
  //       startTime.toString() == fiveMinutesFromNow.toString()
  //     ) {
  //       toast.info(toastTemplate(startTime, event.EndTime, event.Subject));
  //     }

  //     if (
  //       startTime >= now &&
  //       startTime.toString() == oneMinutesFromNow.toString()
  //     ) {
  //       toast.info(toastTemplate(startTime, event.EndTime, event.Subject));
  //     }
  //   });
  //   let now = new Date();
  //   let nextMinute = new Date(
  //     now.getFullYear(),
  //     now.getMonth(),
  //     now.getDate(),
  //     now.getHours(),
  //     now.getMinutes() + 1,
  //     0,
  //     0
  //   );
  //   let delay = nextMinute - now;

  //   // setTimeout(refreshEventReminder, delay);
  // };

  const handleMeetings = async (startDate, endDate) => {
    const result = isReporting
      ? recAppointments
      : await getAllMeetings(clinicId, startDate, endDate);
    setAppointments([...result, ...breakTimes]);
  };

  const getEmployeeName = value =>
    value?.resourceData[value?.resource?.textField];

  const getEmployeeDesignation = value => value?.resourceData?.designation;

  const handleGlobalSearch = event => {
    let searchString = event.target.value;
    setGlobalSearch(searchString);
    if (searchString !== '') {
      new DataManager(scheduleObj.current.getEvents(null, null, true))
        .executeQuery(
          new Query().search(
            searchString,
            ['description', 'status', 'DrName', 'patient_name'],
            null,
            true,
            true
          )
        )
        .then(e => {
          if (e.result.length > 0) {
            showSearchEvents('show', e.result);
          } else {
            showSearchEvents('hide');
          }
        });
    } else {
      showSearchEvents('hide');
    }
  };

  const showSearchEvents = (type, data) => {
    if (type === 'show') {
      if (document.getElementById('grid').classList.contains('e-grid')) {
        let gridObj = document.querySelector('#grid').ej2_instances[0];
        gridObj.dataSource = data;
        gridObj.dataBind();
      } else {
        let gridObj = new GridComponent({
          dataSource: data,
          height: 505,
          toolbar: ['Print'],
          width: 'auto',
          columns: [
            {
              field: 'patient_name',
              headerText: 'Client',
              width: 60,
              textAlign: 'center'
            },
            {
              field: 'description',
              headerText: 'Description',
              width: 60,
              textAlign: 'center'
            },
            {
              field: 'type_name',
              headerText: 'Appointment Type',
              width: 60,
              textAlign: 'center'
            },
            {
              field: 'StartTime',
              headerText: 'Start Time',
              width: 60,
              textAlign: 'center',
              format: { type: 'dateTime', format: 'M/d/y hh:mm a' }
            },
            {
              field: 'EndTime',
              headerText: 'End Time',
              width: 60,
              textAlign: 'center',
              format: { type: 'dateTime', format: 'M/d/y hh:mm a' }
            }
          ]
        });
        gridObj.setInjectedModules([Toolbar]);
        gridObj.appendTo(document.querySelector('#grid'));
        scheduleObj.current.element.style.display = 'none';
      }
    } else {
      let gridObj = document.querySelector('#grid').ej2_instances;
      if (gridObj && gridObj.length > 0 && !gridObj[0].isDestroyed) {
        gridObj[0].destroy();
      }
      scheduleObj.current.element.style.display = 'block';
    }
  };

  const clearOnClick = () => {
    document.getElementById('form-search-1').reset();
    showSearchEvents('hide');
    setGlobalSearch('');
  };

  const resourceHeaderTemplate = props => {
    const imageUrl = props?.resourceData?.profile_image_url ?? placeholder;
    return (
      <>
        <div className='template-wrap p-0'>
          <div className='employee-category scheduler-header'>
            <img className={'employee-image ' + props} src={imageUrl} />
            <span>
              <div className='employee-name' style={{ padding: '0px' }}>
                {getEmployeeName(props)}
              </div>
              {isDescVisible && (
                <div className='employee-designation'>
                  {getEmployeeDesignation(props)}
                </div>
              )}
            </span>
          </div>
        </div>
      </>
    );
  };

  const validateRoomCapacity = (
    currentRoom,
    startTime,
    endTime,
    patientId,
    id
  ) => {
    const capacity = scheduleObj.current
      .getCurrentViewEvents()
      .filter(
        appointment =>
          appointment.patient_id !== patientId &&
          appointment.RoomId === currentRoom.id &&
          appointment.Guid !== id &&
          !(
            new Date(appointment.StartTime).getTime() >=
            new Date(endTime).getTime()
          ) &&
          !(
            new Date(startTime).getTime() >=
            new Date(appointment.EndTime).getTime()
          )
      );

    if (currentRoom.capacity < capacity.length + 1) {
      toast.warning(`${currentRoom.name} is not available!`);
      return true;
    }

    return false;
  };

  const validateDrAvailablity = (currentRoom, startTime, endTime) => {
    const currentDay = new Date(startTime).getDay();
    //get the room start and end time
    const roomStartTime = new Date(
      Date.parse(
        `${new Date(startTime).toDateString()} ${
          currentRoom.hours[currentDay].startHour
        }`
      )
    ).getTime();

    const roomEndTime = new Date(
      Date.parse(
        `${new Date(endTime).toDateString()} ${
          currentRoom.hours[currentDay].endHour
        }`
      )
    ).getTime();

    //compare dates with room start and end date
    if (
      startTime.getTime() < roomStartTime ||
      endTime.getTime() > roomEndTime
    ) {
      toast.warning(`${currentRoom.name} is not available!`);
      return true;
    }

    return false;
  };

  const validateOverlapTime = (patientId, startTime, endTime, id) => {
    const isValid = scheduleObj.current
      .getCurrentViewEvents()
      .find(
        appointment =>
          appointment.patient_id === patientId &&
          appointment.Id !== id &&
          !(
            new Date(appointment.StartTime).getTime() >=
            new Date(endTime).getTime()
          ) &&
          !(
            new Date(startTime).getTime() >=
            new Date(appointment.EndTime).getTime()
          )
      );

    if (isValid) {
      toast.warning(
        'Another appointment has been scheduled for this patient at the same time!'
      );
      return true;
    }
    return false;
  };

  const validateRoomAppointment = (
    patientId,
    startTime,
    endTime,
    id,
    roomId
  ) => {
    //get the current room
    const currentRoom = rooms.find(room => room.id === roomId);
    //validating appointment
    if (
      validateDrAvailablity(currentRoom, startTime, endTime) ||
      validateOverlapTime(patientId, startTime, endTime, id) ||
      (!currentRoom.is_multiple_appointment &&
        validateRoomCapacity(currentRoom, startTime, endTime, patientId, id))
    ) {
      return true;
    }
    return false;
  };

  const onActionBegin = async args => {
    args.timeZone = clinicTimeZone;

    let selectedDates = scheduleObj?.current?.activeView?.renderDates;
    switch (args.requestType) {
      case 'toolbarItemRendering' && isOwner: {
        const exportFields = [
          {
            name: 'Id',
            text: 'Id'
          },
          {
            name: 'Subject',
            text: 'Summary'
          },
          {
            name: 'StartTime',
            text: 'Start Date'
          },
          {
            name: 'EndTime',
            text: 'End Date'
          }
        ];
        const exportValues = {
          fieldsInfo: exportFields
        };
        let exportItem = {
          align: 'Right',
          showTextOn: 'Both',
          prefixIcon: 'e-icon-schedule-excel-export',
          text: 'Excel Export',
          cssClass: 'e-excel-export',
          click: () => scheduleObj.current.exportToExcel(exportValues)
        };
        args.items.push(exportItem);
        let printItem = {
          align: 'Right',
          showTextOn: 'Both',
          prefixIcon: 'e-icon-schedule-print',
          text: 'Print',
          cssClass: 'e-schedule-print',
          click: () => scheduleObj.current.print()
        };
        args.items.push(printItem);
        break;
      }
      case 'eventCreate': {
        let isExist = validateRoomAppointment(
          args.addedRecords[0].patient_id,
          args.addedRecords[0].StartTime,
          args.addedRecords[0].EndTime,
          args.addedRecords[0].Id,
          args.addedRecords[0].RoomId
        );

        if (isExist) {
          handleMeetings(
            selectedDates[0],
            selectedDates[selectedDates.length - 1]
          );
          return;
        }
        try {
          let appointments = await createNewAppointment(
            clinicId,
            args,
            csrfToken,
            isOwner,
            patients
          );
          if (appStatus.isCancelled && !isOwner) {
            await handleUpdateStatus(appStatus.appointment, 'cancelled');
          }
          if (appointments) {
            setAppointments([...appointments, ...breakTimes]);
            handleMeetings(
              selectedDates[0],
              selectedDates[selectedDates.length - 1]
            );
            toast.success('Appointment Created');
          }
        } catch ({
          response: {
            data: { message }
          }
        }) {
          console.error(message);
          toastr.error(message);
          handleMeetings(
            selectedDates[0],
            selectedDates[selectedDates.length - 1]
          );
        }
        break;
      }
      case 'eventChange': {
        let isExist = validateRoomAppointment(
          args.changedRecords[0].patient_id,
          args.changedRecords[0].StartTime,
          args.changedRecords[0].EndTime,
          args.changedRecords[0].Id || args.data?.parent.Id,
          args.changedRecords[0].RoomId
        );
        if (isExist) {
          handleMeetings(
            selectedDates[0],
            selectedDates[selectedDates.length - 1]
          );
          return;
        }
        try {
          let updatedAppointments = [];
          if (args.data?.parent) {
            await createNewAppointment(
              clinicId,
              args,
              csrfToken,
              isOwner,
              patients,
              true
            );
            updatedAppointments = await updateAppointment(
              clinicId,
              args,
              csrfToken,
              isOwner,
              patients,
              true,
              false,
              false,
              selectedDates[0],
              selectedDates[selectedDates.length - 1]
            );
            setAppointments([...updatedAppointments, ...breakTimes]);
            toastr.success('Appointment Updated');
          } else {
            let isSeries =
              scheduleObj.current.currentAction === 'EditOccurrence' ||
              scheduleObj.current.currentAction == null
                ? false
                : true;
            const updatedAppointments = await updateAppointment(
              clinicId,
              args,
              csrfToken,
              isOwner,
              patients,
              false,
              isSeries,
              false,
              selectedDates[0],
              selectedDates[selectedDates.length - 1]
            );
            setAppointments([...updatedAppointments, ...breakTimes]);
            handleMeetings(
              selectedDates[0],
              selectedDates[selectedDates.length - 1]
            );
            toastr.success('Appointment Updated');
          }
        } catch (e) {
          console.error(e.response.data.message);
          toastr.error(e.response.data.message);
          handleMeetings(
            selectedDates[0],
            selectedDates[selectedDates.length - 1]
          );
        }
        break;
      }
      case 'eventRemove': {
        const data =
          args.deletedRecords.length > 0
            ? args.data[0]
            : args.data[0].occurrence;
        try {
          let result = [];
          if (
            args.data[0]?.parent ||
            scheduleObj.current.currentAction === 'DeleteSeries'
          ) {
            let isSeries =
              scheduleObj.current.currentAction === 'DeleteSeries'
                ? true
                : false;
            const totalRecurrence = getRecurrenceAppointmentCount(
              args.data[0].parent?.Id || args.data[0].Id
            );
            result = await deleteRecursiveAppointment(
              clinicId,
              csrfToken,
              args.data[0].parent || args.data[0],
              args.changedRecords[0].StartTime,
              isSeries,
              selectedDates[0],
              selectedDates[selectedDates.length - 1],
              totalRecurrence
            );
          } else {
            result = await deleteAppointment(clinicId, data.Id);
          }
          if (isReporting) {
            setAppointments([
              ...result.filter(
                app =>
                  app.Id == recAppointments[0].Id ||
                  app.RecurrenceId == recAppointments[0].Id
              ),
              ...breakTimes
            ]);
          } else setAppointments([...result, ...breakTimes]);
          toastr.success('Appointment Deleted');
        } catch (e) {
          console.error(e);
          toastr.error(e);
          handleMeetings(
            selectedDates[0],
            selectedDates[selectedDates.length - 1]
          );
        }
        break;
      }
      default:
        break;
    }
  };

  const handleCancelModal = () => {
    handleCancelDialog();
    let cellData = {
      startTime: new Date(),
      endTime: new Date()
    };
    scheduleObj.current.openEditor(cellData, 'Add');
  };

  const getRecurrenceAppointmentCount = id => {
    const deleteEvents = scheduleObj.current.getDeletedOccurrences(id);
    return (
      scheduleObj.current.getOccurrencesByID(id).length + deleteEvents.length
    );
  };

  const handleUpdateStatus = async (
    appointmentData,
    newStatus,
    isUpdate = false
  ) => {
    if (newStatus === 'cancelled' && !isOwner && isUpdate) {
      setAppStatus({ isCancelled: true, appointment: appointmentData });
      handleCancelDialog();
    } else if (newStatus === 'confirmed' && !isOwner && isUpdate) {
      try {
        let result = [];
        let selectedDates = scheduleObj?.current?.activeView?.renderDates;
        result = await updateAppointment(
          clinicId,
          { ...appointmentData, status: newStatus },
          csrfToken,
          false,
          [],
          false,
          false,
          true,
          selectedDates[0],
          selectedDates[selectedDates.length - 1]
        );
        setAppointments([...result, ...breakTimes]);
        toastr.success('Appointment Status Changed');
      } catch (e) {
        toastr.error(e.message);
        console.error(e.message);
      }
    } else {
      try {
        let result = [];
        let selectedDates = scheduleObj?.current?.activeView?.renderDates;
        if (appointmentData.RecurrenceRule) {
          let exceptionData = appointments.find(
            appointment => appointment.Id === appointmentData.Id
          );
          const totalRecurrence = getRecurrenceAppointmentCount(
            appointmentData.Id
          );
          result = await updateRecurrenceAppointmentStatus(
            clinicId,
            csrfToken,
            {
              ...appointmentData,
              timezone: clinicTimeZone,
              status: newStatus,
              RecurrenceException: exceptionData.RecurrenceException
            },
            appointmentData.StartTime,
            isOwner,
            patients,
            totalRecurrence
          );
        } else {
          result = await updateAppointment(
            clinicId,
            { ...appointmentData, status: newStatus },
            csrfToken,
            false,
            [],
            false,
            false,
            true,
            selectedDates[0],
            selectedDates[selectedDates.length - 1]
          );
        }
        if (isReporting) {
          setAppointments([
            ...result.filter(
              app =>
                app.Id == recAppointments[0].Id ||
                app.RecurrenceId == recAppointments[0].Id
            ),
            ...breakTimes
          ]);
        } else setAppointments([...result, ...breakTimes]);
        scheduleObj.current.closeQuickInfoPopup();
        toastr.success('Appointment Status Changed');
      } catch (e) {
        toastr.error(e.message);
        console.error(e.message);
      }
    }
  };

  const onNavigate = async args => {
    if (args.currentView == 'Day') {
      setIsDescVisible(false);
      setCurrentDate(args.currentDate);
      const data = isReporting
        ? recAppointments
        : await getAllMeetings(
            clinicId,
            startOfDay(new Date(args.currentDate)),
            endOfDay(new Date(args.currentDate))
          );
      setAppointments([...data, ...breakTimes]);
    } else if (args.currentView == 'Month') {
      setIsDescVisible(false);
      setCurrentDate(args.currentDate);
      const data = isReporting
        ? recAppointments
        : await getAllMeetings(
            clinicId,
            startOfMonth(new Date()),
            endOfMonth(new Date())
          );
      setAppointments([...data, ...breakTimes]);
    } else if (args.currentView == 'Week') {
      setIsDescVisible(true);
      setCurrentDate(args.currentDate);
      const data = isReporting
        ? recAppointments
        : await getAllMeetings(
            clinicId,
            startOfWeek(new Date()),
            endOfWeek(new Date())
          );
      setAppointments([...data, ...breakTimes]);
    } else if (args.previousDate && args.currentDate && !args.currentView) {
      setCurrentDate(args.currentDate);

      if (args.currentDate === args.previousDate) return;
      const dates = scheduleObj.current?.getCurrentViewDates();

      const endDate = dates[dates.length - 1];
      const startDate = dates[0];
      const data = isReporting
        ? recAppointments
        : await getAllMeetings(clinicId, startDate, endDate);
      setAppointments([...data, ...breakTimes]);
    }
  };

  L10n.load({
    'en-US': {
      schedule: {
        saveButton: 'Save',
        cancelButton: 'Close',
        deleteButton: 'Remove',
        newEvent: 'Add Appointment',
        editEvent: 'Edit Appointment'
      }
    }
  });

  const searchOnclick = () => {
    let searchObj = [];
    let startDate;
    let endDate;
    let formElements = [].slice.call(
      document.querySelectorAll('.event-search .search-field')
    );
    formElements.forEach(node => {
      let fieldOperator;
      let predicateCondition;
      let fieldValue;
      let fieldInstance;
      if (
        node.value &&
        node.value !== '' &&
        !node.classList.contains('e-datepicker')
      ) {
        fieldOperator = 'contains';
        predicateCondition = 'or';
        fieldValue = node.value;
        searchObj.push({
          field: node.getAttribute('data-name'),
          operator: fieldOperator,
          value: fieldValue,
          predicate: predicateCondition,
          matchcase: true
        });
      }
      if (
        node.classList.contains('e-datepicker') &&
        node.ej2_instances[0].value
      ) {
        fieldInstance = node.ej2_instances[0];
        fieldValue = fieldInstance.value;
        if (node.classList.contains('e-start-time')) {
          fieldOperator = 'greaterthanorequal';
          predicateCondition = 'and';
          startDate = new Date(+fieldValue);
        } else {
          fieldOperator = 'lessthanorequal';
          predicateCondition = 'and';
          let date = new Date(+fieldInstance.value);
          fieldValue = new Date(date.setDate(date.getDate() + 1));
          endDate = fieldValue;
        }
        searchObj.push({
          field: node.getAttribute('data-name'),
          operator: fieldOperator,
          value: fieldValue,
          predicate: predicateCondition,
          matchcase: false
        });
      }
    });
    if (searchObj.length > 0) {
      let filterCondition = searchObj[0];
      let predicate = new Predicate(
        filterCondition.field,
        filterCondition.operator,
        filterCondition.value,
        filterCondition.matchcase
      );
      for (let i = 1; i < searchObj.length; i++) {
        predicate = predicate.and(
          searchObj[i].field,
          searchObj[i].operator,
          searchObj[i].value,
          searchObj[i].matchcase
        );
      }
      let result = new DataManager(
        scheduleObj.current.getEvents(startDate, endDate, true)
      ).executeLocal(new Query().where(predicate));
      showSearchEvents('show', result);
    } else {
      showSearchEvents('hide');
    }
  };
  const handleAppointmentColor = args => {
    if (args.type === 'blockEvent') return '#c5c5c5';
    else if (args.data.status === 'completed') return '#1cba96';
    else if (args.data.status === 'missed') return '#ea7a57';
    else if (args.data.status === 'confirmed') return '#0000FF';
    else return args.data.appointment_type?.color;
  };

  const onEventRendered = args => {
    let appointmentColor = handleAppointmentColor(args);
    if (!args.element || !appointmentColor) return;

    if (scheduleObj.current.currentView === 'Agenda') {
      args.element.firstChild.style.borderLeftColor = appointmentColor;
      args.element.firstChild.style.color = '#FFFFFF';
    } else {
      args.element.style.backgroundColor = appointmentColor;
      args.element.firstChild.style.color = '#FFFFFF';
    }
  };

  const buttonClickActions = e => {
    const eventDetails = scheduleObj.current.activeEventData.event;
    const quickPopup = closest(e.target, '.e-quick-popup-wrapper');
    if (e.target.id === 'delete') {
      let currentAction = 'Delete';
      if (eventDetails.RecurrenceRule) {
        currentAction = 'DeleteOccurrence';
      }
      scheduleObj.current.deleteEvent(eventDetails, currentAction);
    } else {
      const isCellPopup =
        quickPopup.firstElementChild.classList.contains('e-cell-popup');
      let currentAction = isCellPopup ? 'Add' : 'Save';
      if (eventDetails.RecurrenceRule) {
        currentAction = 'EditOccurrence';
      }
      scheduleObj.current.openEditor(eventDetails, currentAction, true);
    }
    scheduleObj.current.closeQuickInfoPopup();
  };

  const renderFooter = event => {
    return (
      <Footer
        appointment={event}
        handleUpdateStatus={handleUpdateStatus}
        isOwner={isOwner}
      />
    );
  };

  const handleEditorOpen = e => {
    if (e.type === 'Editor')
      scheduleObj.current.eventWindow.recurrenceEditor = recurrObject.current;

    if (!recurrObject?.current?.endType) return;

    if (!e.data.Guid)
      recurrObject.current.setRecurrenceRule(e.data.RecurrenceRule);

    let endList = recurrObject.current.endType?.dataSource;
    if (endList.length === 3) {
      let newDatasource = [
        { text: 'Until', value: 'until' },
        { text: 'Count', value: 'count' }
      ];
      recurrObject.current.endType.dataSource = newDatasource;
    }
  };

  const renderContent = event => {
    if (event.elementType === 'cell') {
      let hideCreationPopUp = document.getElementsByClassName('e-cell-popup');
      hideCreationPopUp[0]?.classList?.add('d-none');
    }
    const filterEventByRoom = scheduleObj.current
      .getCurrentViewEvents()
      .filter(
        appointment =>
          appointment.status === 'pending' &&
          appointment.patient_id === event.patient_id
      );
    return (
      <Content
        appointment={event}
        isOwner={isOwner}
        currentViewAppointments={filterEventByRoom}
      />
    );
  };

  const getTimeString = value => {
    let instance = new Internationalization();
    return instance.formatDate(value, { skeleton: 'hm' });
  };

  const gridlineTemplate = useCallback(() => {
    return (
      <div className='template'>
        <div className='icon-child'>
          <CheckBoxComponent
            id='timeSlots'
            checked={true}
            change={args => {
              scheduleObj.current.timeScale.enable = args.checked;
            }}
          />
        </div>
        <div className='text-child'>Gridlines</div>
      </div>
    );
  }, []);

  // const onCreated = () => {
  //   refreshEventReminder();
  // };
  const handleSort = (a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const renderEventTemplate = event => {
    return (
      <div className='template-wrap'>
        <div
          className={`subject d-flex ${
            event.status === 'cancelled' ? 'complete' : null
          }`}
        >
          {patients.id === event.patient_id || isOwner
            ? event.Subject
            : 'Blocked'}
        </div>
        <div>
          {getTimeString(!!event.data ? event.data.StartTime : event.StartTime)}{' '}
          - {getTimeString(!!event.data ? event.data.EndTime : event.EndTime)}
        </div>
      </div>
    );
  };

  const toastTemplate = (StartTime, EndTime, Subject) => {
    return (
      <div className='e-toast-template'>
        <div className='e-toast-message'>
          <div className='e-toast-title'>{Subject}</div>
          <div className='e-toast-content'>
            {`${StartTime.toLocaleTimeString()} - ${EndTime.toLocaleTimeString()}`}
          </div>
        </div>
      </div>
    );
  };

  const tooltipTemplate = args => {
    return (
      <div className='tooltip-wrap'>
        <div className='content-area'>
          <div className='name'>{args.Subject}</div>
          {(args.description || args.patient_notes) && (
            <div className='name'>
              Note: {args.description || args.patient_notes}
            </div>
          )}
          <div className='name'>Type: {args.type_name}</div>
          <div className='time'>
            {new Date(args.StartTime).toLocaleString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })}
          </div>
          <div className='time'>
            {format(new Date(args.StartTime), "hh:mmaaaaa'm'")} -{' '}
            {format(new Date(args.EndTime), "hh:mmaaaaa'm'")}
          </div>
        </div>
      </div>
    );
  };

  const onDataBound = args => {
    if (islayoutChanged) {
      const renderedDates = scheduleObj.current.activeView.getRenderDates();
      scheduleObj.current.resetWorkHours();

      for (let i = 0; i < renderedDates.length; i++) {
        const dayIndex = renderedDates[i].getDay();
        if (dayIndex !== 0 && dayIndex !== 6) {
          for (let j = 0; j < rooms.length; j++) {
            scheduleObj.current.setWorkHours(
              [new Date(renderedDates[i])],
              rooms[j].hours[dayIndex].startHour,
              rooms[j].hours[dayIndex].endHour,
              j
            );
          }
        }
      }
      islayoutChanged = false;
    }
  };

  const btnClick = () => {
    let settingsPanel = document.querySelector(
      '.overview-content .right-panel'
    );
    if (settingsPanel.classList.contains('hide')) {
      removeClass([settingsPanel], 'hide');
      workWeekObj.current.refresh();
    } else {
      addClass([settingsPanel], 'hide');
    }
    scheduleObj.current.refreshEvents();
  };

  const onToolbarItemClicked = args => {
    switch (args.item.text) {
      case 'Day':
        setCurrentView(isTimelineView ? 'TimelineDay' : 'Day');
        break;
      case 'Week':
        setCurrentView(isTimelineView ? 'TimelineWeek' : 'Week');
        break;
      case 'WorkWeek':
        setCurrentView(isTimelineView ? 'TimelineWorkWeek' : 'WorkWeek');
        break;
      case 'Month':
        setCurrentView(isTimelineView ? 'TimelineMonth' : 'Month');
        break;
    }
  };

  const timelineTemplate = useCallback(() => {
    return (
      <div className='template'>
        <div className='icon-child'>
          <CheckBoxComponent
            id='timeline_views'
            checked={isTimelineView}
            change={onTimelineChange}
          />
        </div>
        <div className='text-child'>Timeline Views</div>
      </div>
    );
  }, []);

  const onTimelineChange = args => {
    setIsTimelineView(args.checked);
  };

  useEffect(() => {
    let updatedView = currentView;
    switch (currentView) {
      case 'Day':
      case 'TimelineDay':
        updatedView = isTimelineView ? 'TimelineDay' : 'Day';
        break;
      case 'Week':
      case 'TimelineWeek':
        updatedView = isTimelineView ? 'TimelineWeek' : 'Week';
        break;
      case 'WorkWeek':
      case 'TimelineWorkWeek':
        updatedView = isTimelineView ? 'TimelineWorkWeek' : 'WorkWeek';
        break;
      case 'Month':
      case 'TimelineMonth':
        updatedView = isTimelineView ? 'TimelineMonth' : 'Month';
        break;
    }
    scheduleObj.current.currentView = updatedView;
  }, [isTimelineView]);

  return (
    <>
      <div className='schedule-control-section'>
        <div className='control-section'>
          <div className='content-wrapper'>
            <div className='schedule-overview'>
              <AppBarComponent colorMode='Primary'>
                <span className='time e-icons e-time-zone'></span>
                <span id='timezoneBtn' className='time'>
                  {clinicTimeZone}
                </span>
                <span className='time e-icons e-clock'></span>
                <span
                  id='timeBtn'
                  className='time current-time'
                  ref={timeBtn}
                ></span>
                {isOwner && (
                  <>
                    <div className='e-appbar-spacer'></div>
                    <Options
                      handleGlobalSearch={handleGlobalSearch}
                      searchOnclick={searchOnclick}
                      clearOnClick={clearOnClick}
                      globalSearch={globalSearch}
                    />
                    <ButtonComponent
                      id='settingsBtn'
                      cssClass='overview-toolbar-settings e-inherit'
                      iconCss='e-icons e-settings'
                      iconPosition='Top'
                      content=''
                      onClick={btnClick}
                    />
                  </>
                )}
              </AppBarComponent>
              <ToolbarComponent
                id='toolbarOptions'
                cssClass='overview-toolbar text-right'
                width='100%'
                height={70}
                overflowMode='Scrollable'
                scrollStep={100}
                created={() =>
                  (liveTimeInterval = setInterval(() => {
                    updateLiveTime();
                  }, 1000))
                }
                clicked={onToolbarItemClicked}
              >
                <ItemsDirective>
                  <ItemDirective
                    prefixIcon='e-icons e-day'
                    tooltipText='Day'
                    text='Day'
                    tabIndex={0}
                  />
                  <ItemDirective
                    prefixIcon='e-icons e-week'
                    tooltipText='Week'
                    text='Week'
                    tabIndex={0}
                  />
                  <ItemDirective
                    prefixIcon='e-icons e-week'
                    tooltipText='WorkWeek'
                    text='WorkWeek'
                    tabIndex={0}
                  />
                  <ItemDirective
                    prefixIcon='e-icons e-month'
                    tooltipText='Month'
                    text='Month'
                    tabIndex={0}
                  />

                  <ItemDirective type='Separator' />

                  <ItemDirective
                    tooltipText='Timeline Views'
                    text='Timeline Views'
                    template={timelineTemplate}
                  />

                  <ItemDirective
                    tooltipText='Timme Slots'
                    text='Timme Slots'
                    template={gridlineTemplate}
                  />
                </ItemsDirective>
              </ToolbarComponent>
              <div className='overview-content'>
                <div className='left-panel'>
                  <div className='overview-scheduler'>
                    {rooms.length > 0 && (
                      <ScheduleComponent
                        cssClass='block-events'
                        width='100%'
                        height='1000px'
                        dataBound={onDataBound}
                        editorTemplate={e => (
                          <ModalTemplate
                            appointmentData={e}
                            rooms={rooms.sort((a, b) => a.number - b.number)}
                            patients={
                              Array.isArray(patients)
                                ? [...patients].sort((a, b) => handleSort(a, b))
                                : patients
                            }
                            appointmentTypes={[...appointmentTypes].sort(
                              (a, b) => handleSort(a, b)
                            )}
                            isOwner={isOwner}
                            ref={recurrObject}
                          />
                        )}
                        timeScale={{
                          enable: true,
                          interval: 60,
                          slotCount: 4
                        }}
                        selectedDate={currentDate}
                        // created={onCreated}
                        workDays={[1, 2, 3, 4, 5]}
                        timezone={clinicTimeZone}
                        enableAllDayScroll={true}
                        currentView={currentView}
                        ref={scheduleObj}
                        resourceHeaderTemplate={resourceHeaderTemplate}
                        eventSettings={{
                          dataSource: extend([], appointments, null, true),
                          template: renderEventTemplate,
                          enableTooltip: true,
                          tooltipTemplate: tooltipTemplate
                        }}
                        popupOpen={handleEditorOpen}
                        eventRendered={e => onEventRendered(e)}
                        quickInfoTemplates={{
                          header: e =>
                            headerTemplate(
                              { ...e, isOwner },
                              buttonClickActions,
                              isReporting
                            ),
                          content: renderContent,
                          footer: renderFooter
                        }}
                        navigating={onNavigate}
                        actionBegin={onActionBegin}
                        group={{
                          byDate: true,
                          enableCompactView: false,
                          resources: ['Doctor']
                        }}
                      >
                        <ResourcesDirective>
                          <ResourceDirective
                            field='RoomId'
                            title='Appointment'
                            name='Doctor'
                            allowMultiple={true}
                            dataSource={rooms.sort(
                              (a, b) => a.number - b.number
                            )}
                            textField='name'
                            idField='id'
                            colorField='#bbdc00'
                          ></ResourceDirective>
                        </ResourcesDirective>
                        <ViewsDirective>
                          <ViewDirective option='Day' />
                          <ViewDirective option='Week' />
                          <ViewDirective option='WorkWeek' />
                          <ViewDirective option='Month' />
                          <ViewDirective option='TimelineDay' />
                          <ViewDirective option='TimelineWeek' />
                          <ViewDirective option='TimelineWorkWeek' />
                          <ViewDirective option='TimelineMonth' />
                          <ViewDirective option='TimelineYear' />
                        </ViewsDirective>
                        <Inject
                          services={
                            isOwner
                              ? [
                                  Day,
                                  Week,
                                  WorkWeek,
                                  Month,
                                  TimelineViews,
                                  TimelineMonth,
                                  TimelineYear,
                                  Resize,
                                  DragAndDrop,
                                  ExcelExport,
                                  Print
                                ]
                              : [Day, Week, WorkWeek, Month]
                          }
                        />
                      </ScheduleComponent>
                    )}
                    <ToastContainer />
                    <div id='grid'></div>
                  </div>
                </div>
                {isOwner && (
                  <>
                    <div className='right-panel hide'>
                      <div className='control-panel e-css'>
                        <div className='col-row'>
                          <div className='col-left'>
                            <label style={{ lineHeight: '34px', margin: '0' }}>
                              Timezone
                            </label>
                          </div>
                          <div className='col-right'>
                            <Select
                              width={100}
                              value={timezones.filter(
                                option => option.tzCode === clinicTimeZone
                              )}
                              options={timezones.map(zone => {
                                return {
                                  label: zone.label,
                                  value: zone.tzCode
                                };
                              })}
                              onChange={e => handleTimeZoneUpdate(e.value)}
                            />
                          </div>
                        </div>
                        <div className='col-row'>
                          <div className='col-left'>
                            <label style={{ lineHeight: '34px', margin: '0' }}>
                              Scheduler User
                            </label>
                          </div>
                          <div className='col-right'>
                            <CopyToClipboardButton
                              inviteToken={token}
                              clinicSlug={clinicSlug}
                              handleRefreshToken={handleRefreshToken}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCancelDialog && (
        <CustomDialog
          isOpen={showCancelDialog}
          handleSchedule={handleCancelModal}
          handleCancelDialog={handleCancelDialog}
          cancelAppointment={appStatus.appointment}
        />
      )}
    </>
  );
};
export default Index;
