import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Select from 'react-select';
import {
  campaignClientsTableColumns,
  campaignDropDownOptions
} from '../../helpers/campaign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'react-bootstrap';
import { PRIMARY_COLOR } from '../../constants';

const CampaignClientsList = props => {
  const {
    clients,
    handleClientTabArrow,
    clientListIcon,
    clientListTab,
    isArrowVisible,
    handleRowSelection,
    selectedUsers
  } = props;

  const [clientsList, setClientsList] = useState(clients);
  const [selectedOption, setSelectedOption] = useState('All Clients');
  const [menuWidth, setMenuWidth] = useState();
  const [isCalculatingWidth, setIsCalculatingWidth] = useState(false);
  const [selectionModel, setSelectionModel] = useState(selectedUsers);

  const styles = {
    menu: css => ({
      ...css,
      width: 'auto',
      ...(isCalculatingWidth && { height: 0, visibility: 'hidden' })
    }),
    control: css => ({ ...css, display: 'inline-flex ' }),
    valueContainer: css => ({
      ...css,
      ...(menuWidth && { width: menuWidth })
    })
  };

  const onClientFilterChange = event => {
    setSelectedOption(event.label);
    if (event.label == 'Archived Clients') {
      setClientsList(
        clients.filter(client => {
          return client.archive;
        })
      );
    } else if (event.label == 'Enrolled Clients') {
      setClientsList(
        clients.filter(client => {
          return !client.archive;
        })
      );
    } else {
      setClientsList(clients);
    }
  };

  return (
    <div className='card mb-3 mt-3'>
      <div className='card-header'>
        <h6 className='font-weight-bold d-inline float-left mb-0'>
          Clients
        </h6>
        {isArrowVisible && (
          <button
            className='btn btn-primary float-right'
            onClick={() => handleClientTabArrow()}
          >
            <FontAwesomeIcon icon={clientListIcon} />
          </button>
        )}
        <div className='clearfix'></div>
        <Collapse in={clientListTab}>
          <div className='mt-3'>
            <Select
              value={campaignDropDownOptions.filter(
                option => option.label === selectedOption
              )}
              styles={styles}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  text: 'orangered',
                  primary: PRIMARY_COLOR
                }
              })}
              options={campaignDropDownOptions}
              onChange={e => onClientFilterChange(e)}
            />
          </div>
        </Collapse>
      </div>
      <Collapse in={clientListTab}>
        <div className='card-body'>
          <div style={{ height: 650, width: '100%' }}>
            <DataGrid
              rows={clientsList}
              columns={campaignClientsTableColumns}
              selectionModel={selectionModel}
              onSelectionModelChange={e => {
                handleRowSelection(e);
                setSelectionModel(e);
              }}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CampaignClientsList;
