import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';

import {
  onDeleteFile,
  onNewMeasurementSubmit,
  toggleMeasurementForm,
} from '../../actions/measurements';

import ProfileImage from './ProfileImage';
import SupplementaryImage from './SupplementaryImage';

class NewMeasurementForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formRef: React.createRef() };
  }

  componentDidMount() {
    this.state.formRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  componentDidUpdate() {
    this.state.formRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const measurement = this.props.measurementEdit || {};

    const initialValues = {
      date: measurement.date
        ? measurement.date.split('T')[0]
        : new Date().toISOString().split('T')[0],
      leftArm: measurement.left_arm || '',
      rightArm: measurement.right_arm || '',
      neck: measurement.neck || '',
      leftThigh: measurement.left_thigh || '',
      rightThigh: measurement.right_thigh || '',
      leftCalf: measurement.left_calf || '',
      rightCalf: measurement.right_calf || '',
      hips: measurement.hips || '',
      aboveBb: measurement.above_bb || '',
      belowBb: measurement.below_bb || '',
      bellyButton: measurement.bellybutton || '',
      chest: measurement.chest || '',
      bloodPressure: measurement.blood_pressure || '',
      weight: measurement.weight || '',
      bmi: measurement.bmi || '',
      bodyFat: measurement.body_fat || '',
      leanMuscle: measurement.lean_muscle || '',
      water: measurement.water || '',
      metabolicAge: measurement.metabolic_age || '',
      visceralFat: measurement.visceral_fat || '',
      profileImage: [],
      supplementalImages: [],
      files:[]
    };

    const measurementId = this.props.measurementEdit
      ? this.props.measurementEdit.id
      : null;

    let supplementalImages = [];
    let profileImage;
    if (measurement) {
      if (measurement.supplemental_image_urls) {
        supplementalImages = measurement.supplemental_image_urls.map(url => (
          <Carousel.Item key={url}>
            {url && (
              <SupplementaryImage
                isOwner={this.props.isOwner}
                measurementId={measurement.id}
                url={url}
                imageClass='d-block m-auto supplementary-image set-width-height'
              />
            )}
          </Carousel.Item>
        ));
      }

      profileImage = (
        <Carousel
          controls={false}
          indicators={false}
          keyboard={false}
          slide={false}
          touch={false}
          interval={null}
        >
          <Carousel.Item>
            {measurement.profile_image_url && (
              <ProfileImage
                isOwner={this.props.isOwner}
                measurementId={measurement.id}
                url={measurement.profile_image_url}
                imageClass='d-block m-auto set-width-height'
              />
            )}
          </Carousel.Item>
        </Carousel>
      );
    }
    const handleDeleteFile = (url) => {
      if (window.confirm('Are you sure you want to delete this file?')) {
        this.props.deleteFile(measurement.id, url);
      }
    };

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            this.props.onSubmit(
              values.date,
              values.leftArm,
              values.rightArm,
              values.neck,
              values.leftThigh,
              values.rightThigh,
              values.leftCalf,
              values.rightCalf,
              values.hips,
              values.aboveBb,
              values.belowBb,
              values.bellyButton,
              values.chest,
              values.bloodPressure,
              values.weight,
              values.bmi,
              values.bodyFat,
              values.leanMuscle,
              values.water,
              values.metabolicAge,
              values.visceralFat,
              values.profileImage,
              values.supplementalImages,
              values.files,
              this.props.patient.id,
              this.props.patient.clinic_id,
              measurementId
            );
            setSubmitting(false);
          }, 400);
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit} ref={this.state.formRef}>
            <div className='form-row'>
              <div className='col-6 col-lg-9'>
                <div className='form-group'>
                  <label htmlFor='date'>Date</label>
                  <input
                    id='date'
                    type='date'
                    disabled={!this.props.isOwner}
                    className='form-control'
                    {...formik.getFieldProps('date')}
                  />
                </div>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='neck'>Neck</label>
                <input
                  id='neck'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('neck')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='chest'>Chest</label>
                <input
                  id='chest'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('chest')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='leftArm'>Left Arm</label>
                <input
                  id='leftArm'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('leftArm')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='rightArm'>Right Arm</label>
                <input
                  id='rightArm'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('rightArm')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='bellyButton'>Belly Button</label>
                <input
                  id='bellyButton'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('bellyButton')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='hips'>Hips</label>
                <input
                  id='hips'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('hips')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='aboveBb'>2" Above Belly Button</label>
                <input
                  id='aboveBb'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('aboveBb')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='belowBb'>2" Below Belly Button</label>
                <input
                  id='belowBb'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('belowBb')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='leftThigh'>Left Thigh</label>
                <input
                  id='leftThigh'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('leftThigh')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='rightThigh'>Right Thigh</label>
                <input
                  id='rightThigh'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('rightThigh')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='leftCalf'>Left Calf</label>
                <input
                  id='leftCalf'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('leftCalf')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='rightCalf'>Right Calf</label>
                <input
                  id='rightCalf'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('rightCalf')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='bloodPressure'>Blood Pressure</label>
                <input
                  id='bloodPressure'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('bloodPressure')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='weight'>Weight</label>
                <input
                  id='weight'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('weight')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='bmi'>BMI</label>
                <input
                  id='bmi'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('bmi')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='bodyFat'>% Body Fat</label>
                <input
                  id='bodyFat'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('bodyFat')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='leanMuscle'>% Lean Muscle</label>
                <input
                  id='leanMuscle'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('leanMuscle')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='water'>% Water</label>
                <input
                  id='water'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('water')}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='metabolicAge'>Metabolic Age</label>
                <input
                  id='metabolicAge'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('metabolicAge')}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='visceralFat'>Visceral Fat</label>
                <input
                  id='visceralFat'
                  type='text'
                  disabled={!this.props.isOwner}
                  className='form-control'
                  {...formik.getFieldProps('visceralFat')}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-9'>
                <div className='card patient-images-carousel'>
                  <div className='card-body'>
                    <h5 className='card-title'>Profile Photo</h5>
                    {formik.values.profileImage.length == 0 && profileImage}
                    <div className='card-text'>
                      <ul className='list-group list-group-flush'>
                        {formik.values.profileImage.map(image => (
                          <li key={image.name} className='list-group-item'>
                            {image.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='float-right pt-2'>
                      <HiddenFileInput
                        type='file'
                        id='profileImage'
                        onChange={event => {
                          formik.setFieldValue('profileImage', [
                            ...event.currentTarget.files
                          ]);
                        }}
                      />
                      <label
                        className='btn btn-outline-primary btn-responsive'
                        htmlFor='profileImage'
                      >
                        {profileImage ? 'Change' : 'Add'}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 mt-4'>
                <div className='card patient-images-carousel'>
                  <div className='card-body'>
                    <h5 className='card-title'>Supplemental Images</h5>
                    {supplementalImages.length > 0 && (
                      <Carousel
                        interval={null}
                        id='supplemental-images-carousel'
                      >
                        {supplementalImages}
                      </Carousel>
                    )}
                    <div className='card-text'>
                      <ul className='list-group list-group-flush'>
                        {formik.values.supplementalImages.map(image => (
                          <li key={image.name} className='list-group-item'>
                            {image.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='float-right pt-2'>
                      <HiddenFileInput
                        type='file'
                        id='supplementalImages'
                        multiple
                        onChange={event => {
                          formik.setFieldValue(
                            'supplementalImages',
                            formik.values.supplementalImages.concat([
                              ...event.currentTarget.files
                            ])
                          );
                        }}
                      />
                      <label
                        className='btn btn-outline-primary btn-responsive'
                        htmlFor='supplementalImages'
                      >
                        Add
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 mt-4'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>Files</h5>
                    <div className='card-text'>
                      <ul className='list-group list-group-flush'>
                        {measurement?.file_urls?.map((url, index) => (
                          <li
                            key={index}
                            className='list-group-item d-flex justify-content-between justify-item-center pt-2'
                          >
                            <a
                              href={url}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {url.split('/').pop()}
                            </a>
                            {this.props.isOwner && (
                              <span
                                className='btn btn-outline-danger btn-sm mb-2 mr-2 btn-responsive'
                                onClick={() => handleDeleteFile(url)}
                              >
                                x
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='card-text'>
                      <ul className='list-group list-group-flush'>
                        {formik.values.files.map(file => (
                          <li
                            key={file.name}
                            className='list-group-item d-flex justify-content-between justify-item-center pt-2'
                          >
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {this.props.isOwner && (
                      <div className='float-right pt-2'>
                        <HiddenFileInput
                          type='file'
                          id='files'
                          multiple
                          onChange={event => {
                            formik.setFieldValue(
                              'files',
                              formik.values.files.concat([
                                ...event.currentTarget.files
                              ])
                            );
                          }}
                        />
                        <label
                          className='btn btn-outline-primary btn-responsive'
                          htmlFor='files'
                        >
                          Upload
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col'>
                <button
                  type='submit'
                  className='btn btn-outline-primary btn-responsive'
                >
                  Submit
                </button>
                <button
                  className='btn btn-outline-danger ml-1 btn-responsive'
                  type='button'
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const HiddenFileInput = styled.input`
  display: none;
`;

const mapStateToProps = (state) => ({
  measurementEdit: state.patientIndex.measurementEdit,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (
    date,
    leftArm,
    rightArm,
    neck,
    leftThigh,
    rightThigh,
    leftCalf,
    rightCalf,
    hips,
    aboveBb,
    belowBb,
    bellyButton,
    chest,
    bloodPressure,
    weight,
    bmi,
    bodyFat,
    leanMuscle,
    water,
    metabolicAge,
    visceralFat,
    profileImage,
    supplementalImages,
    patientId,
    clinicId,
    measurementId,
    files
  ) => {
    dispatch(
      onNewMeasurementSubmit(
        date,
        leftArm,
        rightArm,
        neck,
        leftThigh,
        rightThigh,
        leftCalf,
        rightCalf,
        hips,
        aboveBb,
        belowBb,
        bellyButton,
        chest,
        bloodPressure,
        weight,
        bmi,
        bodyFat,
        leanMuscle,
        water,
        metabolicAge,
        visceralFat,
        profileImage,
        supplementalImages,
        patientId,
        clinicId,
        measurementId,
        files
      )
    );
  },
  onCancel: () => {
    dispatch(toggleMeasurementForm());
  },
  deleteFile: (measurementId, blobUrl) => {
    dispatch(onDeleteFile(measurementId, blobUrl));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMeasurementForm);
