import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';

const VideoRow = ({
  videoUrl,
  title,
  onDeleteClick,
  onVisibilityToggle,
  isVisible,
  clinicId
}) => {
  const embedUrl = getEmbedUrl(videoUrl);

  return (
    <>
      <div className='embed-responsive embed-responsive-16by9'>
        <iframe
          title={title}
          src={embedUrl}
          className='embed-responsive-item'
          width='640'
          height='564'
          frameBorder='0'
          allow='autoplay; fullscreen'
          allowFullScreen
        />
      </div>

      <div className='d-flex justify-content-between align-items-center mt-2'>
        <h5 className='subheading'>{title}</h5>
        {clinicId && (
          <div>
            <button onClick={onDeleteClick} className='btn btn-danger mr-2'>
              <FontAwesomeIcon icon={faTrash} />
            </button>
            {isVisible ? (
              <button onClick={onVisibilityToggle} className='btn btn-success '>
                <FontAwesomeIcon icon={faEye} />
              </button>
            ) : (
              <button
                onClick={onVisibilityToggle}
                className='btn btn-secondary'
              >
                <FontAwesomeIcon icon={faEyeSlash} />
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const Video = props => {
  const { videos, handleDelete, handleVisibilityToggle, clinicId } = props;

  return (
    <div className='videos card'>
      <div className='card-body'>
        <div className='row'>
          {videos.map((video, index) => (
            <div key={index} className='col-12 col-lg-6 mb-3'>
              <VideoRow
                videoUrl={video.url}
                title={video.name}
                onDeleteClick={() => handleDelete(index, video)}
                onVisibilityToggle={() => handleVisibilityToggle(index)}
                isVisible={video.is_visible}
                clinicId={clinicId}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const getEmbedUrl = videoUrl => {
  if (videoUrl.includes('youtube.com/watch')) {
    const videoId = getVideoIdFromYoutubeUrl(videoUrl);
    return `https://www.youtube.com/embed/${videoId}`;
  }
  return videoUrl;
};

const getVideoIdFromYoutubeUrl = url => {
  const videoId = url.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition !== -1) {
    return videoId.substring(0, ampersandPosition);
  }
  return videoId;
};

export default Video;
