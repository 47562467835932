export const PRIMARY_COLOR = '#00ba9b';
export const SECONDARY_COLOR = '#003a94';

export const VIDEO_URLS = [
  {
    url: 'https://player.vimeo.com/video/760163197',
    title: 'How Red-Light Therapy Works'
  },
  {
    url: 'https://player.vimeo.com/video/760163525',
    title: 'Red Light Intro and 11 Tips for Great Results'
  },
  {
    url: 'https://player.vimeo.com/video/760163976',
    title: 'LED | LASER | MICROCHIP'
  },
  {
    url: 'https://player.vimeo.com/video/760164568',
    title: 'Dangers of Visceral Fat'
  },
  {
    url: 'https://player.vimeo.com/video/760162555',
    title: 'Balloon Maintenance'
  },
  {
    url: 'https://player.vimeo.com/video/760164896',
    title: 'Exercise Is Not For Weight Loss'
  },
  {
    url: 'https://player.vimeo.com/video/760165204',
    title: 'Guidelines For A Healthy Diet'
  }
];
export const DEFAULT_HOURS = {
  endHour: Date.parse(`${new Date().toDateString()} 17:00`),
  startHour: Date.parse(`${new Date().toDateString()} 09:00`)
};

export const DEFAULT_BREAK_HOURS = {
  endHour: Date.parse(`${new Date().toDateString()} 13:00`),
  startHour: Date.parse(`${new Date().toDateString()} 12:00`)
};

export const WEEK_DAYS = [
  {
    id: 1,
    startHour: DEFAULT_HOURS.startHour,
    endHour: DEFAULT_HOURS.endHour,
    isBreakTimeVisible: false,
    breakStartTime: DEFAULT_BREAK_HOURS.startHour,
    breakEndTime: DEFAULT_BREAK_HOURS.endHour
  },
  {
    id: 2,
    startHour: DEFAULT_HOURS.startHour,
    endHour: DEFAULT_HOURS.endHour,
    isBreakTimeVisible: false,
    breakStartTime: DEFAULT_BREAK_HOURS.startHour,
    breakEndTime: DEFAULT_BREAK_HOURS.endHour
  },
  {
    id: 3,
    startHour: DEFAULT_HOURS.startHour,
    endHour: DEFAULT_HOURS.endHour,
    isBreakTimeVisible: false,
    breakStartTime: DEFAULT_BREAK_HOURS.startHour,
    breakEndTime: DEFAULT_BREAK_HOURS.endHour
  },
  {
    id: 4,
    startHour: DEFAULT_HOURS.startHour,
    endHour: DEFAULT_HOURS.endHour,
    isBreakTimeVisible: false,
    breakStartTime: DEFAULT_BREAK_HOURS.startHour,
    breakEndTime: DEFAULT_BREAK_HOURS.endHour
  },
  {
    id: 5,
    startHour: DEFAULT_HOURS.startHour,
    endHour: DEFAULT_HOURS.endHour,
    isBreakTimeVisible: false,
    breakStartTime: DEFAULT_BREAK_HOURS.startHour,
    breakEndTime: DEFAULT_BREAK_HOURS.endHour
  },
  {
    id: 6,
    startHour: DEFAULT_HOURS.startHour,
    endHour: DEFAULT_HOURS.endHour,
    isBreakTimeVisible: false,
    breakStartTime: DEFAULT_BREAK_HOURS.startHour,
    breakEndTime: DEFAULT_BREAK_HOURS.endHour
  },
  {
    id: 7,
    startHour: DEFAULT_HOURS.startHour,
    endHour: DEFAULT_HOURS.endHour,
    isBreakTimeVisible: false,
    breakStartTime: DEFAULT_BREAK_HOURS.startHour,
    breakEndTime: DEFAULT_BREAK_HOURS.endHour
  }
];

export const WEEK_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const DEFAULT_LOG_ENTRY_FORM_STATE = {
  weight: '',
  waterIntake: '',
  sleepingHours: '',
  bloodPressure: '',
  bloodSugar: '',
  meals: {},
  snacks: {}
};
