import React, { useState, useEffect } from 'react';

const PaymentForm = ({ cardConnectSite, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    month: '',
    year: new Date().getFullYear(),
    token: ''
  });

  useEffect(() => {
    const handleMessage = event => {
      console.log('Received message:', event);

      if (event.origin === `https://${cardConnectSite}.cardconnect.com`) {
        try {
          const messageData = JSON.parse(event.data);
          console.log('Parsed Data:', messageData);

          if (messageData.message) {
            setFormData(prevState => ({
              ...prevState,
              token: messageData.message
            }));
          } else {
            console.warn('Token not found in parsed data');
          }
        } catch (error) {
          console.error('Failed to parse message data', error);
        }
      } else {
        console.warn('Origin mismatch:', event.origin);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [cardConnectSite]);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.token) {
      const cardData = {
        token: formData.token,
        month: formData.month,
        year: formData.year,
        name: formData.name
      };

      onSubmit(cardData);
    } else {
      alert('Tokenization failed. Please try again.');
    }
  };

  return (

      <form
        onSubmit={handleSubmit}

      >
        <input type='hidden' id='token' name='token' value={formData.token} />


          <div className='form-row mb-3'>
            <div className='col-12'>
              <label htmlFor='name'>Name on Card</label>
              <input
                type='text'
                name='name'
                className='form-control'
                value={formData.name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12'>
              <iframe
                id='tokenFrame'
                name='tokenFrame'
                src={`https://${cardConnectSite}.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&cardnumbernumericonly=true&usecvv=true&css=input%7Bmargin-top%3A5px%3Bmargin-bottom%3A10px%3Bbackground%3A%20%23F2F2F6%20%21important%3B%20border-radius%3A%205px%3Bborder%3A%201px%20solid%20%23ced4da%3Bpadding%3A%200.375rem%200.75rem%3Bfont-size%3A%201rem%3Bfont-weight%3A%20400%3Bline-height%3A%201.5%3Bcolor%3A%20%23495057%3Btransition%3A%20border-color%200.15s%20ease-in-out%2C%20box-shadow%200.15s%20ease-in-out%3B%7Dinput%3Afocus%7Bbox-shadow%3A%200%200%203px%20%24black%3Bborder%3A%201px%20solid%20%24black%3Boutline%3A%20none%20%21important%3B%7Dlabel%7Bmargin-bottom%3A0.5rem%3Bfont-family%3A%20-apple-system%2C%20BlinkMacSystemFont%2C%20%22Segoe%20UI%22%2C%20Roboto%2C%20%22Helvetica%20Neue%22%2C%20Arial%2C%20%22Noto%20Sans%22%2C%20sans-serif%2C%20%22Apple%20Color%20Emoji%22%2C%20%22Segoe%20UI%20Emoji%22%2C%20%22Segoe%20UI%20Symbol%22%2C%20%22Noto%20Color%20Emoji%22%7Dbody%7Bmargin%3A%200%7D%3B%7D`}
                frameBorder='0'
                scrolling='no'
                style={{ height: '150px', width: '100%' }}
                onLoad={() =>
                  (document.getElementById(
                    'clinic-subscription-form-card'
                  ).style.display = 'block')
                }
              ></iframe>
            </div>
          </div>

          <label htmlFor='card_expiration'>Card Expiration</label>
          <br />
          <div className='form-row mb-4'>
            <div className='col-auto'>
              <select
                name='month'
                className='form-control'
                value={formData.month}
                onChange={handleChange}
              >
                <option value=''>Select Month</option>
                {[...Array(12).keys()].map(m => (
                  <option key={m + 1} value={String(m + 1).padStart(2, '0')}>
                    {new Date(0, m).toLocaleString('default', {
                      month: 'long'
                    })}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-auto'>
              <select
                name='year'
                className='form-control'
                value={formData.year}
                onChange={handleChange}
              >
                {[...Array(10).keys()].map(y => (
                  <option key={y} value={new Date().getFullYear() + y}>
                    {new Date().getFullYear() + y}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button type='submit' className='btn btn-outline-success btn-block'>
            Pay
          </button>
      </form>

  );
};

export default PaymentForm;
