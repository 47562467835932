import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import React from 'react';

const Footer = props => {
  const { appointment, handleUpdateStatus, isOwner } =
    props;
 return (
   <div className='quick-info-footer pr-4'>
     {appointment.elementType == 'cell' ? null : (
       <div className='event-footer w-100 d-flex align-items-center'>
         <div className='w-100 d-flex-col justify-content-around'>
           {isOwner && (
             <div className='w-100'>
               <ButtonComponent
                 name='completed'
                 content='Go to client Record'
                 className='w-100 mb-2'
                 style={{ backgroundColor: '#00138C', color: 'white' }}
                 onClick={() =>
                   (window.location.href = `/${
                     window.location.pathname.split('/')[1]
                   }?patient_id=${appointment.patient_id}`)
                 }
               />
             </div>
           )}
           <div className='d-flex w-100 justify-content-between'>
             {isOwner && (
               <>
                 <ButtonComponent
                   id='more-details'
                   style={{ backgroundColor: '#00ba9b' }}
                   content='Completed'
                   name='completed'
                   disabled={appointment.status == 'completed'}
                   isPrimary={true}
                   onClick={e => {
                     appointment.status != e.target.name &&
                       handleUpdateStatus(appointment, e.target.name);
                   }}
                 />
                 <ButtonComponent
                   id='more-details'
                   style={{ backgroundColor: '#F0B740' }}
                   content='Missed'
                   name='missed'
                   disabled={appointment.status == 'missed'}
                   isPrimary={true}
                   onClick={e => {
                     appointment.status != e.target.name &&
                       handleUpdateStatus(appointment, e.target.name);
                   }}
                 />
               </>
             )}
             
             {
              !isOwner &&
                <ButtonComponent
                  id='more-details'
                  style={{ backgroundColor: '#90EE90' }}
                  content='YES'
                  disabled={appointment.status == 'confirmed'}
                  name='confirmed'
                  isPrimary={true}
                  onClick={e => {
                    appointment.status != e.target.name &&
                      handleUpdateStatus(appointment, e.target.name, true);
                  }}
              />
             }

             <ButtonComponent
               id='more-details'
               style={{ backgroundColor: '#D22D24' }}
               content={isOwner ? 'Cancelled' : 'No'}
               disabled={appointment.status == 'cancelled' || appointment.status == null}
               name='cancelled'
               isPrimary={true}
               onClick={e => {
                 appointment.status != e.target.name &&
                   handleUpdateStatus(appointment, e.target.name, true);
               }}
             />
             
           </div>
         </div>
       </div>
     )}
   </div>
 );
};
export default Footer;
