import React from 'react';

const FormCard = ({ id, name, handleClick }) => {
  const copyToClipBoard = (e, btnId) => {
    const parts = window.location.pathname.split('/');
    navigator.clipboard.writeText(
      window.location.origin +
        parts.slice(0, -1).join('/') +
        `/dynamic_intake_forms/${id}`
    );
    e.target.innerHTML = '✔';
    setTimeout(() => {
      document.getElementById(btnId).innerHTML = '📋';
    }, 2000);
  };

  return (
    <div className='btn-group mb-2 ml-md-1' key={id}>
      <button
        className='btn btn-secondary float-left btn-responsive'
        key={id}
        onClick={() => handleClick(id)}
      >
        {name}
      </button>
      <button
        className='btn btn-secondary float-left copy-link-btn btn-responsive '
        id={`btn-${id}`}
        onClick={e => copyToClipBoard(e, `btn-${id}`)}
      >
        📋
      </button>
    </div>
  );
};

export default FormCard;
