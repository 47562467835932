import React from 'react';

const TitleCard = props => {
  const { title, setTitle, placeholder } = props;

  return (
    <div className='card mb-3'>
      <div className='card-header font-weight-bold '>Title</div>
      <div className='card-body'>
        <div className='form-row'>
          <div className='col form-group'>
            <input
              type='text'
              className='form-control'
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder={placeholder}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleCard;
