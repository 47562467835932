import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { campaignTemplatesColumns, templatesListColumns } from '../../../helpers/template';
import { Link } from '@material-ui/core';

const TemplatesList = (props) => {
  const { templates, clinicId, fromCampaign, handleTemplateUse } =
    props;

  const [templatesList, setTemplatesList] = useState(templates);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  return (
    <>
      <div className='card-body p-0 pt-3'>
        {fromCampaign ? (
          <div style={{ height: 400, width: '100%', padding: '20px' }}>
            <DataGrid
              rows={templatesList}
              disableSelectionOnClick
              columns={campaignTemplatesColumns(handleTemplateUse)}
              onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)}
              pageSize={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </div>
        ) : (
          <>
            <h4 className='font-weight-bold ml-3 d-inline'>Templates</h4>
            <Link
              className='btn btn-primary float-right text-white btn-responsive'
              href={`/${clinicId}/templates/new`}
            >
              Create
            </Link>
            <div className='clearfix mb-3' />
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={templatesList}
                disableSelectionOnClick
                columns={templatesListColumns(setTemplatesList)}
                onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)}
                pageSize={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </div>{' '}
          </>
        )}
      </div>
    </>
  );
};

export default TemplatesList;
