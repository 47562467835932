import {
  Backdrop, CircularProgress, Dialog, makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../actions/orders';
import IndexTable from './IndexTable';
import NavBar from './NavBar';
import Order from './Order';
import Store from './Store';

const useStyles = makeStyles(() => ({
  loading: {
    zIndex: 9999,
    paddingLeft: 0,
  },
  dialog: {
    zIndex: 999,
    paddingLeft: 0,
  },
  paper: { maxWidth: '750px' },
}));

const useStylesOwner = makeStyles(() => ({
  loading: {
    zIndex: 9999,
    paddingLeft: '25%',
  },
  dialog: {
    zIndex: 999,
  },
  paper: { maxWidth: '750px' },
}));

const Orders = () => {
  const clinicId = useSelector((state) => state.patientIndex.clinicId);
  const clientId = useSelector((state) => state.patientIndex.activePatient);
  const fetching = useSelector((state) => state.patientIndex.fetching);
  const NewOrder = useSelector((state) => state.patientIndex.orders);

  const [orderStatus, setOrderStatus] = useState(false);
  const [newOrder, setNewOrder] = useState(false);
  const [order, setOrder] = useState(null);
  const [storeStatus, setStoreStatus] = useState(false);

  const isActiveOwner = useSelector((state) => (
    state.patientIndex
      ? state.patientIndex.isOwner && state.patientIndex.subscriptionActive
      : false
  ));

  const dispatch = useDispatch();

  const classes = isActiveOwner ? useStylesOwner() : useStyles();

  const handleStore = () => {
    if(!isActiveOwner) {
      window.location.replace(`/${clinicId}/clients/${clientId}/package_list`);
    }else{
      setStoreStatus(!storeStatus);
    }
  };

  const handleOrder = () => {
    if (orderStatus) setOrder(null);
    setOrderStatus(!orderStatus);
    setNewOrder(false);
  };

  const handleNewOrder = () => {
    if (newOrder) {
      setOrderStatus(!orderStatus);
    }
  };

  useEffect(() => {
    setOrder(NewOrder[0]);
  }, [NewOrder]);

  useEffect(() => {
    dispatch(getOrders(clinicId, clientId));
  }, [clientId]);

  return (
    <div>
      <Dialog
        open={orderStatus}
        className={`${classes.dialog}`}
        onClose={handleOrder}
        classes={{ paper: classes.paper }}
      >
        <Order
          order={order}
          close={handleOrder}
        />
      </Dialog>
      <Dialog
        open={storeStatus}
        className={`${classes.dialog} assessment-modal`}
        onClose={handleStore}
        classes={{ paper: classes.paper }}
        onExited={handleNewOrder}
      >
        <Store
          closeWindow={handleStore}
          newOrder={setNewOrder}
          setOrder={setOrder}
        />
      </Dialog>
      <Backdrop open={!!fetching} className={classes.loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <NavBar openStore={handleStore} />
      <IndexTable
        orderStatus={handleOrder}
        setCurrentOrder={setOrder}
      />
    </div>
  );
};

export default Orders;
