import { Internationalization } from '@syncfusion/ej2-base';
import React from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';

const getHeaderStyles = data => {
  if (data.elementType === 'cell') {
    return { alignItems: 'center', color: '#919191', padding: '10px' };
  } else {
    return {
      background: data.appointment_type?.color,
      color: '#FFFFFF',
      padding: '5px',
    };
  }
};

const getHeaderTitle = data =>
  data.elementType === 'cell' ? 'Add Appointment' : data.patient_name;

const getHeaderDetails = data => {
  let intl = new Internationalization();

  return (
    intl.formatDate(data.StartTime, { type: 'date', skeleton: 'full' }) +
    ' (' +
    intl.formatDate(data.StartTime, { skeleton: 'hm' }) +
    ' - ' +
    intl.formatDate(data.EndTime, { skeleton: 'hm' }) +
    ')'
  );
};
export const headerTemplate = (props, buttonClickActions, isReporting) => (
  <div className='quick-info-header'>
    <div className='quick-info-header-content' style={getHeaderStyles(props)}>
      <div
        className='quick-info-title w-100 d-flex justify-content-between align-items-center'
        style={{ fontWeight: '600' }}
      >
        {getHeaderTitle(props)}
        <span>
          {!isReporting && <ButtonComponent
            cssClass='e-edit e-control e-btn e-lib e-flat e-round e-small e-icon-btn e-icons e-edit-icon text-white'
            id='more-details'
            onClick={buttonClickActions}
            style={{ fontSize: '0.8rem' }}
          />}
          {props.isOwner && (
            <ButtonComponent
              id='delete'
              cssClass='e-delete e-control e-btn e-lib e-flat e-round e-small e-icon-btn e-btn-icon e-icons e-delete-icon text-white'
              onClick={buttonClickActions}
              style={{ fontSize: '0.8rem' }}
            />
          )}
        </span>
      </div>
      <div className='duration-text'>{getHeaderDetails(props)}</div>
    </div>
  </div>
);



export const convertToOptions = types => {
  let tmp = [];
  types &&
    types.length > 0 &&
    types.map(type => tmp.push({ text: type.name, value: type.id }));
  return tmp;
};

export const convertReactSelectToOptions = types => {
  let tmp = [];
  types &&
    types.length > 0 &&
    types.map(type => tmp.push({ label: type.name, value: type.id }));
  return tmp;
};

export const secondsToInterval = seconds =>
  new Date(1000 * seconds).toISOString().substr(11, 5);

export const reportColumns = (
  handleUpdateStatus,
  handleDelete,
  handleRecAppointment
) => [
  { field: 'RoomId', headerName: 'Room#', filterable: false },
  {
    field: 'Subject',
    headerName: 'Patient Name',
    flex: 1
  },
  {
    field: 'StartTime',
    renderCell: value =>
      new Date(value.row.StartTime).toLocaleDateString('en-US'),
    headerName: 'Start Date',
    flex: 1,
    filterable: false
  },
  {
    field: 'EndTime',
    renderCell: value =>
      value.row.RecurrenceRule == null || value.row.RecurrenceRule == ''
        ? new Date(value.row.EndTime).toLocaleDateString('en-US')
        : '',
    headerName: 'End Date',
    flex: 1,
    filterable: false
  },
  {
    field: 'start_time',
    renderCell: value =>
      value.row.RecurrenceRule == null || value.row.RecurrenceRule == '' ? value.row.start_time : '',
    headerName: 'Start Time',
    flex: 1,
    filterable: false
  },
  {
    field: 'end_time',
    renderCell: value =>
      value.row.RecurrenceRule == null || value.row.RecurrenceRule == '' ? value.row.end_time : '',
    headerName: 'End Time',
    flex: 1,
    filterable: false
  },
  { field: 'type_name', headerName: 'Type' },
  {
    field: 'status',
    renderCell: value =>
      value.row.RecurrenceRule == null || value.row.RecurrenceRule == ''
        ? value.row.status
        : '',
    headerName: 'Status'
  },

  {
    field: 'Action',
    renderCell: cellValues => {
      return cellValues.row.RecurrenceRule == null ||
        cellValues.row.RecurrenceRule == '' ? (
        <>
          <ButtonComponent
            id='more-details'
            cssClass='e-success'
            className='mr-1'
            content='Completed'
            name='complete'
            disabled={cellValues.row.status == 'completed'}
            isPrimary={true}
            onClick={() => handleUpdateStatus(cellValues.row, 'completed')}
          />
          <ButtonComponent
            id='more-details'
            cssClass='e-warning'
            className='mr-1'
            content='Missed'
            name='miss'
            disabled={cellValues.row.status == 'missed'}
            isPrimary={true}
            onClick={() => handleUpdateStatus(cellValues.row, 'missed')}
          />
          <ButtonComponent
            id='more-details'
            cssClass='e-danger'
            className='mr-1'
            content='Cancelled'
            name='cancel'
            disabled={cellValues.row.status == 'cancelled'}
            isPrimary={true}
            onClick={() => handleUpdateStatus(cellValues.row, 'cancelled')}
          />
          <ButtonComponent
            id='more-details'
            cssClass='e-info'
            className='mr-1'
            content='Client Record'
            name='record'
            onClick={() =>
              (window.location.href = `/${
                window.location.pathname.split('/')[1]
              }?patient_id=${cellValues.row.patient_id}`)
            }
          />
        </>
      ) : (
        <>
          <ButtonComponent
            id='more-details'
            cssClass='e-warning'
            className='mr-1'
            content='Modify'
            name='modify'
            isPrimary={true}
            onClick={() => handleRecAppointment(cellValues.row)}
          />
          <ButtonComponent
            id='more-details'
            cssClass='e-info'
            className='mr-1'
            content='Client Record'
            name='record'
            onClick={() =>
              (window.location.href = `/${
                window.location.pathname.split('/')[1]
              }?patient_id=${cellValues.row.patient_id}`)
            }
          />
        </>
      );
    },
    width: 450,
    filterable: false
  },
  {
    headerName: 'More',
    field: 'actions',
    filterable: false,
    type: 'actions',
    width: 80,
    getActions: params => [
      // <GridActionsCellItem
      //   icon={<EditIcon />}
      //   label='Reschedule'
      //   showInMenu
      // />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label='Delete'
        showInMenu
        onClick={() => handleDelete(params.row.Id)}
      />
    ]
  }
];
