import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow, TableCell, Typography,
} from '@material-ui/core';

const OrdersSummaryRow = ({ label, value }) => (
  <TableRow>
    <TableCell variant="footer" style={{cursor: 'pointer'}}>
      <Typography>
        {label}
        :
      </Typography>
    </TableCell>
    <TableCell variant="footer" style={{cursor: 'pointer'}}>
      <Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)}</Typography>
    </TableCell>
  </TableRow>
);

OrdersSummaryRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default OrdersSummaryRow;
