import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
}
const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: window.location.href.includes('order_index') ? 0 : '25%',
  },
  btn: {
    width: 200,
  },
  actionContainer: {
    justifyContent: 'center',
  },
  warning: {
    color: '#c7c753',
    textAlign: 'center',
  },
}));

const ConnectingIssues = ({ error, setError }) => {
  const handleClose = () => {
    setError(null);
  };

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={!!error}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className={classes.root}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Proceed Checkout
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.actionContainer }}>
          <button
            onClick={() => handleClose()}
            className='btn btn-outline-danger'
          >
            {<WarningIcon className={classes.warning} />}
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConnectingIssues.propTypes = {
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

ConnectingIssues.defaultProps = {
  error: null,
};
export default ConnectingIssues;
