import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

const ProfileImageCarousel = props => {
  const measurements = props.measurements;
  let firstImage = {};
  let lastImage = {};
  let middleImages = [];

  for (var i = 0; i < measurements.length; i++) {
    if (measurements[i].profile_image_url) {
      if (firstImage.url) {
        middleImages.push({
          url: measurements[i].profile_image_url,
          date: measurements[i].date
        });
      } else {
        firstImage.url = measurements[i].profile_image_url;
        firstImage.date = measurements[i].date;
      }
    }
  }

  if (middleImages.length) {
    lastImage = middleImages.pop();
  }

  let firstImageElm, lastImageElm, middleImageElms, middleImagesContainer;
  if (firstImage.url) {
    firstImageElm = (
      <Carousel
        controls={false}
        indicators={false}
        keyboard={false}
        slide={false}
        touch={false}
        interval={null}
      >
        <Carousel.Item>
          <div className='d-flex flex-column align-items-center'>
            <p className='mt-3 text-center image-date'>
              {new Date(firstImage.date).toLocaleDateString('en-US', {
                timeZone: 'UTC'
              })}
            </p>
            <img className='set-width-height' src={firstImage.url} />
          </div>
        </Carousel.Item>
      </Carousel>
    );

    if (lastImage.url) {
      lastImageElm = (
        <Carousel
          controls={false}
          indicators={false}
          keyboard={false}
          slide={false}
          touch={false}
          interval={null}
        >
          <Carousel.Item>
            <div className='d-flex flex-column align-items-center'>
              <p className='mt-3 text-center image-date'>
                {new Date(lastImage.date).toLocaleDateString('en-US', {
                  timeZone: 'UTC'
                })}
              </p>
              <img className='set-width-height' src={lastImage.url} />
            </div>
          </Carousel.Item>
        </Carousel>
      );
      if (middleImages.length) {
        middleImageElms = middleImages.map(image => {
          return (
            <Carousel.Item key={image.url}>
              <div className='d-flex flex-column align-items-center'>
                <p className='mt-3 text-center image-date'>
                  {new Date(image.date).toLocaleDateString('en-US', {
                    timeZone: 'UTC'
                  })}
                </p>
                <img className='set-width-height' src={image.url} />
              </div>
            </Carousel.Item>
          );
        });
        middleImagesContainer = (
          <Carousel interval={null}>{middleImageElms}</Carousel>
        );
      }
    }
  }

  return (
    <div
      id='patient-images-summary-carousel'
      className='row patient-images-carousel'
    >
      {firstImage.url ? (
        <>
          <div className='col center-profile-image'>{firstImageElm}</div>
          {middleImages.length > 0 && (
            <div className='col middleimag'>{middleImagesContainer}</div>
          )}
          {lastImage.url && <div className='col lastimag'>{lastImageElm}</div>}
        </>
      ) : (
        'No Images Available'
      )}
    </div>
  );
};

export default ProfileImageCarousel;
