import React from 'react';
import { connect } from 'react-redux';
import { onDeleteSupplementaryImage } from '../../actions/measurements';

const SupplementaryImage = (props) => {
  const deleteSupplementaryImage = (e, measurementId, url) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this photo?')) {
      props.deleteSupplementaryImage(measurementId, url);
    }
  };

  return (
    <>
      {props.isOwner && (
        <button
          onClick={e =>
            deleteSupplementaryImage(e, props.measurementId, props.url)
          }
          style={{
            position: 'absolute',
            right: '0px',
            zIndex: '100',
            padding: '.375rem .75rem'
          }}
          className='button danger'
        >
          <span>&times;</span>
        </button>
      )}
      <img
        className={props.imageClass}
        src={props.url}
        style={{
          width: 'inherit'
        }}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteSupplementaryImage: (measurementId, blobUrl) => {
    dispatch(onDeleteSupplementaryImage(measurementId, blobUrl));
  },
});

export default connect(null, mapDispatchToProps)(SupplementaryImage);
