import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const WeightLossGraph = ({ x, y }) => {

 const chartOptions = {
   responsive: true,
   plugins: {
     legend: {
       position: 'top'
     },
   },
   scales: {
     x: {
       ticks: {
         callback: (value, index, values) => {
           if (values.length < 1) {
             return value;
           } else {
             return '';
           }
         }
       }
     }
   }
 };

const data = {
  label: x.length,

  datasets: [
    {
      label: 'Daily Weight',
      data: x.sort((a, b) => new Date(a.x) - new Date(b.x)),
      borderColor: 'rgb(0,186,155)',
      backgroundColor: 'rgb(0,186,155)',
      pointStyle: 'rectRot'
    },
    {
      label: 'Target Weight',
      data: y.sort((a, b) => new Date(a.x) - new Date(b.x)),
      borderColor: 'rgb(0, 0, 0)',
      backgroundColor: 'rgba(0, 0, 0, 1)'
    }
  ]
};

  return <Line options={chartOptions} data={data} />
;
};

export default WeightLossGraph;
