import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid gray',
    borderRadius: '5px',
    padding: theme.spacing(3),
    backgroundColor: 'white',
    margin: theme.spacing(2, 0)
  },
  label: {
    marginBottom: theme.spacing(4)
  },
  slider: {
    color: '#FE4900',
    '&$disabled': {
      color: '#FE4900'
    }
  },
  disabled: {}
}));

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
];

function valuetext(value) {
  return `${value} pts`;
}

const Question = ({ question, index, answer }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleValueChange = (e, val) => {
    setValue(val);
  };

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-always" gutterBottom className={classes.label}>
        {question}
      </Typography>
      <Slider
        getAriaValueText={valuetext}
        step={1}
        max={10}
        min={0}
        value={answer ? Number(answer) : value}
        disabled={!!answer}
        marks={marks}
        valueLabelDisplay="on"
        onChange={handleValueChange}
        classes={{ root: classes.slider, disabled: classes.disabled }}
      />
      <input type="hidden" name={`assessment[question${index}]`} value={question} />
      <input type="hidden" name={`assessment[answer${index}]`} value={value} />
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  answer: PropTypes.string,
};

Question.defaultProps = {
  answer: null,
};

export default Question;
