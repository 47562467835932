import React from 'react';

export const SelectClient = props => {
  const { isOwner } = props;
  return (
    <div className='card'>
      <div className='card-body'>
        {isOwner ? 'Select a client from the list.' : 'Loading...please wait'}
      </div>
    </div>
  );
};

export default SelectClient;
