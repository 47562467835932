import React, { useState } from 'react'
import { PatientFlashMessage } from '../shared/PatientFlashMessage'
import { createPatientFlashMessage } from '../../lib/patient_utils'

import { connect } from 'react-redux'

const ReferralForm = (props) => {
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState(null);

    const submitReferral = e => {
        e.preventDefault();

        fetch(props.clinicId + '/intake_form_referral', {
            method: "POST",
            headers: {
                'X-CSRF-Token': props.csrfToken,
                'content-type': 'application/json',
                'accept': 'application/json'
            },
            body: JSON.stringify({
                intake_form_referral: {
                    email
                }
            })
        }).then(response => response.json())
        .then(data => {
            if(data.success) {
                setResponseMessage(createPatientFlashMessage(data.success, false));
            }
            else if (data.error) {
                setResponseMessage(createPatientFlashMessage(data.error, true));
            }
        })
    }



    return (
      <>
        <PatientFlashMessage
          message={responseMessage}
          clearMessage={() => {
            setResponseMessage(null);
          }}
        />
        <div className='card mt-3 pt-3 pr-3 pl-3'>
          <div className='card-body'>
            <form onSubmit={submitReferral}>
              <div className='form-row'>
                <div className='col-12'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='text'
                    name='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className='form-control'
                    required={true}
                  />
                </div>
              </div>

              <div className='row mt-3 mb-2'>
                <div className='col'>
                  <button type='submit' className='btn btn-outline-success'>
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
}

const mapStateToProps = state => {
    return {
        csrfToken: state.patientIndex.csrfToken,
        clinicId: state.patientIndex.clinicId
    }
}

export default connect(
    mapStateToProps,
    null
)(ReferralForm)
