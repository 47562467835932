import React from 'react';
import { Calendar } from 'react-date-range';

export const DatePicker = props => {
  const { date, setDate, min, max } = props;

  return (
    <Calendar
      date={date}
      name='date'
      onChange={item => {
        setDate(item);
      }}
      maxDate={max}
      minDate={min}
    />
  );
};

export default DatePicker;
