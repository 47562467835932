import axios from 'axios';

export const createTemplate = (template, csrfToken, clinicId, redirectUrl) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .post(`/${clinicId}/templates`, template, {
      headers
    })
    .then(response => {
      toastr.success(response.data.message);
      window.location.href = redirectUrl;
    })
    .catch(e => {
      toastr.error('All fields are required!');
    });
};

export const updateTemplate = (
  clinicId,
  template,
  csrfToken,
  templateId,
  redirectUrl
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .patch(`/${clinicId}/templates/${templateId}`, template, {
      headers
    })
    .then(response => {
      toastr.success(response.data.message);
      window.location.href = redirectUrl;
    })
    .catch(() => {
      toastr.error('All fields are required!');
    });
};

export const deleteTemplate = async (
  clinicId,
  templateId,
  setTemplatesList
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  await axios
    .delete(`/${clinicId}/templates/${templateId}`, { headers })
    .then(response => {
      setTemplatesList(response.data.templates);
      toastr.success(response.data.message);
    })
    .catch(e => {
      toastr.error('Unable to delete template!');
    });
};

export const getTemplate = async (
  clinicId,
  templateId,
  setTitle,
  setBody,
  setSubject,
  handleShow,
  setShowUpdateButton
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  await axios
    .get(`/${clinicId}/templates/${templateId}/edit`, { headers })
    .then(response => {
      setTitle(response.data.template.title);
      setBody(response.data.template.body);
      setSubject(response.data.template.subject);
      setShowUpdateButton(true);
      handleShow();
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};
