import axios from "axios";

export const getEmailUsers = (clinicId, emailId, setUsers) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/email/${emailId}`, { headers })
    .then(response => {
      setUsers(response.data);
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};
