import React, { useEffect, useState } from 'react';
import DateRange from '../campaigns/components/DateRange';
import AnalyticCard from './AnalyticCard';
import PieChart from './PieChart';
import { addDays, startOfMonth } from 'date-fns';
import { endOfMonth } from 'date-fns/esm';
import Select from 'react-select';
import { convertReactSelectToOptions } from '../../helpers/scheduler';
import { getAllMeetings, getSchedulerStats } from '../../actions/scheduler';
import ReportTable from './ReportTable';
import { cloneDeep } from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { PRIMARY_COLOR } from '../../constants';
import { Modal } from 'react-bootstrap';
import Index from './Index';

const Analytics = ({
  clinicId,
  patients,
  csrfToken,
  isOwner,
  rooms,
  appointmentTypes,
  inviteToken,
  clinicSlug,
  clinicScheduler
}) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection'
    }
  ]);
  const [analyticData, setAnalyticData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [filterAppointments, setFilterAppointments] = useState([]);
  const [recAppointment, setRecAppointment] = useState([]);
  const [recRoom, setRecRoom] = useState([]);
  const [show, setShow] = useState(false);

  const handleModal = () => {
    setShow(!show);
  }

  const handleRecAppointment = (appointment) => {
    setRecAppointment(appointment);
    setRecRoom([rooms.find(room => room.name === appointment.DrName)]);
    handleModal();
  }

  useEffect(() => {
    (async () => {
      const data = await getSchedulerStats(clinicId, dateRange, selectedPatient);
      setAnalyticData(data);
    })()
  }, [dateRange, selectedPatient, appointments]);

  useEffect(()=> {
    (async () => {
      const data = await getAllMeetings(
        clinicId,
        dateRange[0].startDate,
        dateRange[0].endDate
      );
      const filterData = _filter(data);
      setAppointments([...filterData]);
    })();
  },[]);

  useEffect(() => {
    const filterData = _filter(appointments);
    setFilterAppointments([...filterData]);
  }, [selectedPatient]);

  useEffect(() => {
    (async () => {
      const data = await getAllMeetings(
        clinicId,
        dateRange[0].startDate,
        dateRange[0].endDate
      );
      const filterData = _filter(data);
      setAppointments([...filterData]);
      setFilterAppointments([...filterData]);
    })();
  }, [dateRange]);

  const handleDate = value => {
    setDateRange([value.selection]);
  };

  const isDateInRange = (start, end) => {
    if (
      new Date(start).getTime() > new Date(dateRange[0].startDate).getTime() &&
      new Date(end).getTime() <
        addDays(new Date(dateRange[0].endDate), 1).getTime()
    )
      return true;
    else return false;
  }

  const handleAppointments = (result) => {
    const data = _filter(result)
    let sortedData =  _sort(result).filter(data => data.status !== 'cancelled' && isDateInRange(data.StartTime, data.EndTime));
    setAppointments([...sortedData]);
    setFilterAppointments([...data]);
  }

  const _sort = (data) => {
    let clone = cloneDeep(data);
    let sortedData = clone.sort((a, b) => a.Id - b.Id);
    return sortedData
  }

  const _filter = (data) => {
     if (!selectedPatient) return _sort(data).filter(data => data.status !== 'cancelled' && isDateInRange(data.StartTime, data.EndTime));

    let filterAppoint = data.filter(
      appointment =>
        appointment.patient_id === selectedPatient &&
        appointment.status != 'cancelled' &&
        isDateInRange(appointment.StartTime, appointment.EndTime)
    );
    return _sort(filterAppoint);
  }

  const onClientFilterChange = event => {
    if (event) setSelectedPatient(event.value);
    else setSelectedPatient(event);
  };

  return (
    <>
      <div className='card m-3 p-4'>
        <div className='form-group'>
          <Select
            options={convertReactSelectToOptions(patients)}
            onChange={e => onClientFilterChange(e)}
            isClearable
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: 'orangered',
                primary: PRIMARY_COLOR
              }
            })}
          />
        </div>
        <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
          <DateRange dateRange={dateRange} handleDateRangeChange={handleDate} />
        </div>
        <div className='row'>
          <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
            <PieChart analyticData={analyticData} />
          </div>
          <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
            <div className='row px-4'>
              <AnalyticCard
                title='Completed'
                value={
                  analyticData['completed'] ? analyticData['completed'] : 0
                }
                textColor='#5EDD70'
              />
              <AnalyticCard
                title='Pending'
                value={analyticData['pending'] ? analyticData['pending'] : 0}
                textColor='#36A2EC'
              />
              <AnalyticCard
                title='Missed'
                value={analyticData['missed'] ? analyticData['missed'] : 0}
                textColor='#FFCD55'
              />
              <AnalyticCard
                title='Cancelled'
                value={
                  analyticData['cancelled'] ? analyticData['cancelled'] : 0
                }
                textColor='#FF6384'
              />
              <AnalyticCard
                title='Confirmed'
                value={
                  analyticData['confirmed'] ? analyticData['confirmed'] : 0
                }
                textColor='#F333FF'
              />
            </div>
          </div>
        </div>
      </div>
      <ReportTable
        data={filterAppointments}
        clinicId={clinicId}
        csrfToken={csrfToken}
        handleAppointments={handleAppointments}
        dateRange={dateRange}
        handleRecAppointment={handleRecAppointment}
      />
      <ToastContainer />
      <Modal
        show={show}
        onHide={handleModal}
        size='xl'
        style={{ height: '70%' }}
      >
        <Modal.Body>
          <Index
            clinicId={clinicId}
            csrfToken={csrfToken}
            rooms={recRoom}
            isOwner={isOwner}
            appointmentTypes={appointmentTypes}
            patients={patients}
            isReporting={true}
            recAppointments={[recAppointment]}
            inviteToken={inviteToken}
            clinicSlug={clinicSlug}
            clinicScheduler={clinicScheduler}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Analytics;
