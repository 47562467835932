import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {Pie} from 'react-chartjs-2'
const PieChart = ({analyticData}) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: ['Cancelled', 'Pending', 'Missed', 'Completed', 'Confirmed', 'no-data'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [analyticData['cancelled']? analyticData['cancelled'] : 0 ,
          analyticData['pending'] ? analyticData['pending'] : 0 ,
          analyticData['missed'] ? analyticData['missed'] : 0,
          analyticData['completed'] ? analyticData['completed'] : 0,
          analyticData['confirmed'] ? analyticData['confirmed'] : 0,
          Object.keys(analyticData).length < 1 ? 1 : 0
        ],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
          'rgb(94, 221, 112)',
          'rgb(243,v51,v255)',
          'rgb(169,169,169)'
        ],
        hoverOffset: 4
      }
    ]
  };
  return (
    <>
      <div className='mb-4 w-100' style={{maxWidth: 500}}>
        <Pie
          data={data}
          options={{
            maintainAspectRatio: true,
            plugins: {
              legend: {
                onHover: (event, chartElement) =>
                  (event.native.target.style.cursor = 'pointer'),
                onLeave: (event, chartElement) =>
                  (event.native.target.style.cursor = 'default')
              }
            }
          }}
        />
      </div>
    </>
  );
};
export default PieChart;
