import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Collapse } from "react-bootstrap";
import placeholder from "../../../../app/assets/images/placeholder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const MeasurementSummary = (props) => {
  let measurement = props.measurement;

  let supplementalImages = props.measurement.supplemental_image_urls.map(
    (url) => {
      return (
        <Carousel.Item key={url}>
          <img className="d-block w-100 set-width-height" src={url} />
        </Carousel.Item>
      );
    }
  );

  let profileImage = (
    <Carousel
      controls={false}
      indicators={false}
      keyboard={false}
      slide={false}
      touch={false}
      interval={null}
    >
      <Carousel.Item>
        <img className="d-block w-100 set-width-height" src={measurement.profile_image_url} />
      </Carousel.Item>
    </Carousel>
  );

  const [open, setOpen] = useState(false);
  const [clientInfoIcon, setClientInfoIcon] = useState(faAngleDown);

  const handleArrow = () => {
    if (!open) {
      setClientInfoIcon(faAngleRight);
      setOpen(true);
    } else {
      setClientInfoIcon(faAngleDown);
      setOpen(false);
    }
  };

  return (
    <>
      <div className="card mt-3">
        <div className="card-body">
          <h5 style={{ display: "inline" }}>
            {new Date(measurement.date).toLocaleDateString("en-US", {
              timeZone: "UTC",
            })}
          </h5>
          <button
            className="btn btn-primary float-right"
            onClick={() => handleArrow()}
          >
            <FontAwesomeIcon icon={clientInfoIcon} />
          </button>

          <Collapse in={open}>
            <div className="card-body">
              <div>
                <br />
                <div className="row">
                  <div className="col-6 col-lg-9">Neck: {measurement.neck}</div>
                  <div className="col-6 col-lg-9">
                    Chest: {measurement.chest}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">
                    Left Arm: {measurement.left_arm}
                  </div>
                  <div className="col-6 col-lg-9">
                    Right Arm: {measurement.right_arm}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">
                    Above Umbilicus: {measurement.above_bb}
                  </div>
                  <div className="col-6 col-lg-9">
                    Below Umbilicus: {measurement.below_bb}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">
                    Umbilicus: {measurement.bellybutton}
                  </div>
                  <div className="col-6 col-lg-9">Hips: {measurement.hips}</div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">
                    Left Thigh: {measurement.left_thigh}
                  </div>
                  <div className="col-6 col-lg-9">
                    Right Thigh: {measurement.right_thigh}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">
                    Left Calf: {measurement.left_calf}
                  </div>
                  <div className="col-6 col-lg-9">
                    Right Calf: {measurement.right_calf}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">
                    Blood Pressure: {measurement.blood_pressure}
                  </div>
                  <div className="col-6 col-lg-9">
                    Weight: {measurement.weight}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">BMI: {measurement.bmi}</div>
                  <div className="col-6 col-lg-9">
                    % Body Fat: {measurement.body_fat}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">
                    % Lean Muscle: {measurement.lean_muscle}
                  </div>
                  <div className="col-6 col-lg-9">
                    % Water: {measurement.water}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-lg-9">
                    Metabolic Age: {measurement.metabolic_age}
                  </div>
                  <div className="col-6 col-lg-9">
                    Visceral Fat: {measurement.visceral_fat}
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="card mt-3 image-card">
        <div className="card-body">
          <div className="card-title">
            <h5>
              Profile Images:{" "}
              {new Date(measurement.date).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
            </h5>
          </div>
          <div className="row mb-1 center-image">
            <div className="col-6 col-lg-9 mt-3 image-height" style={{ width: "inherit" }}>
              {measurement.profile_image_url ? (
                profileImage
              ) : (
                <img className="d-block w-100 set-width-height" src={placeholder} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-3 supplemental-card">
        <div className="card-body">
          <div className="card-title">
            <h5>
              Supplemental Images:{" "}
              {new Date(measurement.date).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
            </h5>
          </div>
          <div className="row mb-1 center-image">
            <div className="col-6 col-lg-9 mt-3 image-height" style={{ width: "inherit" }}>
              {props.measurement.supplemental_image_urls != 0 ? (
                <Carousel interval={null}>{supplementalImages}</Carousel>
              ) : (
                <img className="d-block w-100 set-width-height" src={placeholder} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MeasurementSummary;
