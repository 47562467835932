import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Question from './Question';
import { createAssessment } from '../../actions/assesstments';
import MustBeSubscribed from '../orders/MustBeSubscribed';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f1f1f185',
  },
  formContainer: {
    padding: theme.spacing(4),
    '& > h2, h5': {
      textAlign: 'center',
    },
  },
  btnContainers: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > button': {
      width: '100px',
    },
  },
}));

const NewAssessment = ({ closeForm, assessment }) => {
  const clinicId = useSelector((state) => state.patientIndex.clinicId);
  const patientId = useSelector((state) => state.patientIndex.activePatient);
  const token = useSelector((state) => state.patientIndex.csrfToken);
  const subscriptionActive = useSelector((state) => state.patientIndex.subscriptionActive);

  const defaultQuestions = ['I generally have plenty of energy',
    'Overall I am generally in a good mood and never depressed',
    'I never have difficulty falling asleep',
    'I have no problems staying trim and fit',
    'My overall health and well-being is excellent',
    'I am generally a very happy person',
    'I never get sick',
    'I have no issues with swollen ankles or other joints',
    "Generally speaking I don't have issues with cold hands and/or feet",
    'I do not suffer with pain anywhere in my body',
  ];

  const [arrOfQuestions, setArrOfQuestions] = useState(defaultQuestions);
  const [arrOfAnswers, setArrOfAnswers] = useState(null);

  useEffect(() => {
    if (assessment) {
      const {
        question1, question2, question3, question4, question5,
        question6, question7, question8, question9, question10,
      } = assessment;
      const {
        answer1, answer2, answer3, answer4, answer5,
        answer6, answer7, answer8, answer9, answer10,
      } = assessment;

      setArrOfQuestions([
        question1, question2, question3, question4, question5,
        question6, question7, question8, question9, question10,
      ]);
      setArrOfAnswers([
        answer1, answer2, answer3, answer4, answer5,
        answer6, answer7, answer8, answer9, answer10,
      ]);
    }
  }, []);

  const form = useRef(null);

  const dispatch = useDispatch();

  const classes = useStyles();

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    dispatch(createAssessment(clinicId, patientId, formData));
    form.current.reset();
    closeForm();
  };

  const date = new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });

  const questions = arrOfQuestions.map((q, i) => (
    <Question
      question={q}
      index={i + 1}
      key={`key-${i + 1}`}
      answer={arrOfAnswers ? arrOfAnswers[i] : null}
    />
  ));

  if (!subscriptionActive) return (<MustBeSubscribed close={closeForm} open />);

  return (
    <form ref={form} onSubmit={submitHandler} className={classes.root}>
      <input type='hidden' name='authenticity_token' value={token} />
      <input type='hidden' name='_method' value='post' />
      <Grid className={`${classes.formContainer} form-container`}>
        <h2>Client Assessment</h2>
        <h5>{date}</h5>
        {questions}
        {assessment ? (
          <Grid container className={classes.btnContainers}>
            <button
              className='btn btn-outline-danger btn-responsive'
              onClick={() => closeForm}
            >
              Close
            </button>
          </Grid>
        ) : (
          <Grid container className={classes.btnContainers}>
            <input
              type='submit'
              className='btn btn-outline-success'
              value='Save'
            />
            <button
              className='btn btn-outline-danger btn-responsive'
              onClick={() => closeForm}
            >
              Close
            </button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

NewAssessment.propTypes = {
  closeForm: PropTypes.func.isRequired,
  assessment: PropTypes.objectOf(oneOfType(PropTypes.string, PropTypes.number)),
};

NewAssessment.defaultProps = {
  assessment: null,
};
export default NewAssessment;
