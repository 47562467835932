import axios from "axios";

export async function uploadFileToCloudinary(file) {
 const URL =
   'https://api.cloudinary.com/v1_1/brilliant-software-inc/image/upload';
  // Prepare the image data for upload
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', 'g1hsq1bi'); // Set your Cloudinary upload preset
  try {
    const response = await axios.post(URL, formData);
    return response.data.url;
  } catch (error) {
    throw new Error(`Error uploading image to Cloudinary: ${error.message}`);
  }
}
