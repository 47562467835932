/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { showNewSessionForm } from '../../actions/sessions';
import { removeActivePackage } from '../../actions/user_packages';

import NewSessionForm from '../sessions/NewSessionForm';
import SessionsTable from '../sessions/SessionsTable';

const ActivePackage = (props) => {
  const {
    patient,
    activePackage,
    isOwner,
    subscriptionActive,
    newSessionShow,
    sessionEdit,
    onUseSessionClick,
    backToTable,
  } = props;

  const onRemoveActivePackage = () => {
    if (window.confirm('Are you sure? Removing this package will delete all associated sessions.')) {
      backToTable();
      props.removeActivePackage(patient.clinic_id, patient.id, activePackage.id);
    }
  };

  const remainingSessions = patient && activePackage
    ? activePackage.remaining_sessions : 0;

  let newSessionContent = null;

  const hasEditPermission = isOwner && subscriptionActive;

  let shouldShowSessionForm = newSessionShow;

  if (sessionEdit) {
    shouldShowSessionForm = activePackage.sessions.some(
      (session) => session.id === sessionEdit.id,
    );
  }
  newSessionContent = shouldShowSessionForm ? (
    <NewSessionForm patient={patient} activePackage={activePackage} />
  ) : (
    hasEditPermission && (
      <div className='row  mt-3 mr-1 mr-md-3 justify-content-end'>
        <button
          type='button'
          className='btn btn-outline-success mr-2 mr-md-0 btn-responsive'
          onClick={onUseSessionClick}
        >
          Use Session
        </button>
        <button
          type='button'
          className='btn btn-outline-danger ml-1 btn-responsive'
          onClick={onRemoveActivePackage}
        >
          Remove package
        </button>
      </div>
    )
  );

  return (
    <div className="row px-1 px-md-3">
      <div className="col">
        <button type="button" onClick={backToTable} className="btn btn-link">Back To All Active Packages</button>
        { activePackage && (
        <>
          <div className="mt-3">
            <div className="table-responsive px-2 px-md-4">
              <h5 className="subheading mb-3">
                {activePackage.name}
                - Sessions Remaining:
                {' '}
                <span className="highlighted-text">{remainingSessions}</span>
              </h5>
              <SessionsTable sessions={ activePackage.sessions.sort(function(a,b){ return new Date(b.date) - new Date(a.date); }) } />
            </div>
          </div>
          { newSessionContent }
        </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUseSessionClick: () => {
    dispatch(showNewSessionForm());
  },
  removeActivePackage: (clinicId, patientId, packageId) => {
    dispatch(removeActivePackage(clinicId, patientId, packageId));
  },
});

const mapStateToProps = (state) => ({
  newSessionShow: state.patientIndex.newSessionShow,
  isOwner: state.patientIndex.isOwner,
  sessionEdit: state.patientIndex.sessionEdit,
  subscriptionActive: state.patientIndex.subscriptionActive,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivePackage);
