import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar, ListItem, ListItemIcon, ListItemText, Toolbar,
} from '@material-ui/core';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { useSelector } from 'react-redux';
import { PRIMARY_COLOR } from '../../constants'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: PRIMARY_COLOR,
    color: 'white',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    backgroundClip: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.125)',
    borderRadius: '0.25rem',
    '& > div': {
      padding: 0
    }
  },

  link: {
    padding: 0,
    position: 'relative',
    fontFamily:
      '"-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontWeight: '400',
    '& > a': {
      color: 'black'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:hover > a': {
      display: 'flex',
      paddingLeft: '0',
      paddingRight: '0',
      justifyContent: 'center'
    },
    '&:hover > a > div': {
      paddingLeft: '16px',
      paddingRight: '16px'
    },
    toolbar: {
      padding: '0'
    }
  },

  text: {
    fontWeight: 400,
    fontSize: '1.2rem'
  }
}));

const NavBar = ({ openStore }) => {
  const classes = useStyles();
  const isOwner = useSelector((state) => (
    state.patientIndex.isOwner
  ));

  return (
    <div>
      <AppBar position="static" className={classes.root}>
        <Toolbar variant="dense">
          <ListItem button onClick={openStore}>
            <ListItem>
              <ListItemText 
                primary={isOwner ? "Create New Order" : "Proceed to Packages"}
                classes={{ primary: classes.text }}/>
                {isOwner && (
                  <ListItemIcon>
                    <ShoppingCartOutlinedIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                )}
            </ListItem>
          </ListItem>
        </Toolbar>
      </AppBar>
    </div>
  );
};

NavBar.propTypes = {
  openStore: PropTypes.func.isRequired,
};

export default NavBar;
