import {
  Backdrop, CircularProgress, Dialog, makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import IndexTable from './IndexTable';
import Order from '../../orders/Order';

const useStyles = makeStyles(() => ({
  loading: {
    zIndex: 9999,
  },
  dialog: {
    zIndex: 999,
  },
  paper: { maxWidth: '750px' },
}));

const Orders = ({ orderFor }) => {
  const fetching = useSelector(state => state.fetching);

  const [orderStatus, setOrderStatus] = useState(false);
  const [order, setOrder] = useState(null);

  const classes = useStyles();

  const handleOrder = () => {
    if (orderStatus) setOrder(null);
    setOrderStatus(!orderStatus);
  };

  return (
    <>
      <Dialog
        open={orderStatus}
        className={classes.dialog}
        onClose={handleOrder}
        classes={{ paper: classes.paper }}
      >
        <Order order={order} close={handleOrder} orderFor={orderFor} type={1} />
      </Dialog>
      <Backdrop open={!!fetching} className={classes.loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <IndexTable orderStatus={handleOrder} setCurrentOrder={setOrder} />
    </>
  );
};

export default Orders;
