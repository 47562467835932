import React, { useEffect, useState } from 'react';
import { faCheckSquare, faDotCircle, faFont, faHeading } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToolBar = ({handleAdd, field, isEditMode}) => {
  const [selectType, setSelectType] = useState(field.type);
  const [fieldTitle, setFieldTitle] = useState(field.title);
  const [fieldChoice, setFieldChoice] = useState(field.choices);
  const [isRequired, setIsRequired] = useState(field.required);
  const [textDecoration, setTextDecoration] = useState({bold: false, italic: false, underline: false});
  const [colspan, setColspan] = useState(12);

useEffect(()=>{
   setFieldTitle(field.title);
   setSelectType(field.type);
   setFieldChoice(field.choices?.join() || []);
   setIsRequired(field.required);
},[field])

  const fieldTypes = [
    { value: 'text', icon: faFont, label: "Text Input"},
    { value: 'radio', icon: faDotCircle, label: "Multiple Choice" },
    { value: 'checkbox', icon:  faCheckSquare, label: 'Checkboxes'},
    { value: 'heading', icon: faHeading, label: 'Heading'}
  ];

  const handleTextDecorationClick = (e) => {
    const name = e.target.getAttribute('name');;
    setTextDecoration({...textDecoration, [name]: !textDecoration[name]})
  }

  const addTextField = () => {

    const data = {
      name: field.name ? field.name : `el-${new Date().getTime()}`,
      type: selectType,
      title: `${fieldTitle}`,
      required: isRequired,
      deleteable: true,
      text_decoration: textDecoration,
      colspan
    };

    if (selectType == 'checkbox' || selectType == 'radio')
      data.choices = fieldChoice.split(',');

    handleAdd(data);
    setFieldTitle('');
    setSelectType(null);
    setFieldChoice('')
    setIsRequired(false);
    setTextDecoration({ bold: false, italic: false, underline: false });
  };

  return (
    <>
      <div className='mb-4 toolbar-wrapper'>
        <h3>Toolbar</h3>
        {fieldTypes.map((type, index) => (
          <li
            key={`${index}-${type.value}`}
            className={`toolbar-list-item mb-1 ${
              selectType == type.value ? 'toolbar-selected' : 'toolbar-default'
            }`}
            onClick={() => setSelectType(type.value)}
          >
            <FontAwesomeIcon icon={type.icon} className='toolbar-icon' />
            <span className='toolbar-text'>{type.label}</span>
          </li>
        ))}
      </div>
      <div className='toolbar-settings-wrapper'>
        {selectType && <h4>Settings:</h4>}
        {selectType && (
          <>
            {selectType !== 'heading' && (
              <div className='form-group'>
                <span className='mb-3'>Required</span>
                <label className='w-100'>
                  <input
                    type='radio'
                    name='isRequired'
                    className='mr-2'
                    value={true}
                    checked={isRequired == true}
                    onChange={() => setIsRequired(true)}
                  />
                  True
                </label>
                <label className='w-100'>
                  <input
                    type='radio'
                    name='isRequired'
                    className='mr-2'
                    value={false}
                    checked={isRequired == false}
                    onChange={() => setIsRequired(false)}
                  />
                  False
                </label>
              </div>
            )}
            <div className='form-group'>
              <label htmlFor='title' className='w-100'>
                Title
                <input
                  type='text'
                  name='title'
                  className='form-control'
                  value={fieldTitle}
                  onChange={e => setFieldTitle(e.target.value)}
                />
                <div className='heading-decoration'>
                  <strong
                    onClick={handleTextDecorationClick}
                    name='bold'
                    className={`${
                      textDecoration['bold'] &&
                      'heading-text-decoration-selected'
                    }`}
                  >
                    B
                  </strong>
                  <i
                    onClick={handleTextDecorationClick}
                    name='italic'
                    className={`${
                      textDecoration['italic'] &&
                      'heading-text-decoration-selected'
                    }`}
                  >
                    I
                  </i>
                  <span
                    style={{ textDecoration: 'underline' }}
                    onClick={handleTextDecorationClick}
                    name='underline'
                    className={`${
                      textDecoration['underline'] &&
                      'heading-text-decoration-selected'
                    }`}
                  >
                    U
                  </span>
                </div>
              </label>
            </div>
            {(selectType == 'checkbox' || selectType == 'radio') && (
              <div className='form-group'>
                <label htmlFor='choice' className='w-100'>
                  Choices (comma seprated choices)
                  <input
                    type='text'
                    name='choice'
                    className='form-control'
                    value={fieldChoice}
                    onChange={e => setFieldChoice(e.target.value)}
                  />
                </label>
              </div>
            )}
            {selectType == 'text' && <div className='form-group'>
              <label className='w-100'>
                Blocks
                <input
                  type='number'
                  min={1}
                  max={12}
                  name='colspan'
                  className='form-control'
                  value={colspan}
                  onChange={e => setColspan(e.target.value)}
                />
                <small style={{ color: '#b3b3b3' }}>
                  How many blocks this field will take out of 12
                </small>
              </label>
            </div>}
            <button
              className='btn btn-outline-primary mb-2 mt-2'
              onClick={() => addTextField()}
            >
              {isEditMode ? 'Update Field' : 'Add Field'}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ToolBar;
