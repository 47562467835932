import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import {
  TableBody, TableCell, TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getComparator, stableSort } from '../../orders/tableFunctions';
import IndexStatusField from '../../orders/IndexStatusField';

const IndexTableBody = ({
  order, orderBy, page, rowsPerPage, classes, openOrder, setOrder,
}) => {
  const orders = useSelector((state) => state.orders);
  const clientFilter = useSelector((state) => state.clientFilter.clientFilter);
  const relevantOrders = (clientFilter && clientFilter.length)
  ? orders.filter((order) => order.client == clientFilter)
  : orders;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, relevantOrders.length - page * rowsPerPage);


  const handleOpen = (selected) => {
    openOrder();
    setOrder(selected);
  };

  const calculateTotalUnpaidAndPaid = () => {
    let totalUnpaid = 0;
    let totalPaid = 0;

    relevantOrders.forEach((row) => {
      const unpaidValue = parseFloat(row.total);
      const paidValue = parseFloat(row.amount_paid);

      if (!isNaN(unpaidValue)) {
        totalUnpaid += unpaidValue;
      }

      if (!isNaN(paidValue)) {
        totalPaid += paidValue;
      }
    });

    totalUnpaid = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(totalUnpaid);
    totalPaid = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalPaid);
    return {
      totalUnpaid,
      totalPaid,
    };
  };

  const { totalUnpaid, totalPaid } = calculateTotalUnpaidAndPaid();


  return (
    <TableBody>
      {stableSort(relevantOrders, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const {
            total,
            amount_paid,
            created_at: date,
            id,
            status,
            client
          } = row;
          const temTotal = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
          const amountPaid = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(amount_paid);
          return (
            <TableRow
              className={classes.tableRow}
              role='checkbox'
              tabIndex={-1}
              key={id}
              onClick={() => handleOpen(row)}
            >
              <TableCell align='right'>{client}</TableCell>
              <TableCell align='right'>
                {new Date(date).toLocaleDateString('en-US', {
                  timeZone: 'UTC'
                })}
              </TableCell>
              <TableCell align='right'>{temTotal}</TableCell>
              <TableCell align='right'>{amountPaid}</TableCell>
              <TableCell align='right'>
                <IndexStatusField
                  close={openOrder}
                  status={status}
                  order={row}
                />
              </TableCell>
            </TableRow>
          );
        })}
      <TableRow>
      <TableCell align='right'></TableCell>
      <TableCell align='right'></TableCell>
        <TableCell align='right'>
          Total Unpaid:{' '}
          {totalUnpaid.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </TableCell>
        <TableCell align='right'>
          Total Paid:{' '}
          {totalPaid.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </TableCell>
        <TableCell align='right'></TableCell>
      </TableRow>
      {emptyRows > 0 && (
      <TableRow style={{ height: 53 * emptyRows }}>
        <TableCell colSpan={6} />
      </TableRow>
      )}
    </TableBody>
  );
};

IndexTableBody.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  openOrder: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default IndexTableBody;
