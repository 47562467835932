import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { DragDropContext } from 'react-beautiful-dnd';
import ToolBar from './ToolBar';
import DropZone from './DropZone';
import DraggableField from './DraggableField';
import { Field, Form, Formik } from 'formik';
import { DYNAMIC_FORM_INTIAL_VALUES } from '../../helpers/intakeForm';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import LogoUploadInput from '../common/LogoUploadInput';
import { uploadFileToCloudinary } from '../../lib/cloudinary';
import Loader from '../common/Loader';
import { weightLosscampaignModalColumns } from '../../helpers/weight_loss';
import CampaignTableCard from '../weight_loss/components/Clinic/CampaignTableCard';

const FormBuilder = props => {
  const {
    csrfToken,
    clinicId,
    campaignsList,
    submitUrl,
    isEditMode,
    editFormValues,
    handleIsEdit = () => {}
  } = props;

  const [fields, setField] = useState(() => {
    if (isEditMode) {
      return editFormValues.form_data.map(jsonStr => {
        const cleanedJsonStr = jsonStr
          .replace(/"=>/g, '":')
          .replace(/\\\"/g, '"');
        return JSON.parse(cleanedJsonStr);
      });
    } else {
      return DYNAMIC_FORM_INTIAL_VALUES;
    }
  });
  const [formName, setFormName] = useState(editFormValues.form_name);
  const [isCampaignAssigned, setIsCampaignAssigned] = useState(
    editFormValues.campaign_id == null ? false : true
  );
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [editField, setEditField] = useState({});
  const [isEditField, setIsEditField] = useState(false);
  const [logo, setLogo] = useState(editFormValues.logo_url || '');
  const [helplineNumber, setHelplineNumber] = useState(editFormValues.helpline);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignId, setCampaignId] = useState(
    editFormValues.campaign_id ? [editFormValues.campaign_id] : []
  );

  const handleCampaignId = (selection) => {
    if (selection.length > 1) {
      const selectionSet = new Set(campaignId);
      const result = selection.filter((s) => !selectionSet.has(s));
      setCampaignId(result);
    } else {
      setCampaignId([null]);
    }
  };

  const handleAddField = data => {
    let isFieldReplaced = false;
    let updatedFields = [];
    if (isEditField) {
      updatedFields = fields.map(field => {
        if (field.name !== data.name) return field;
        else {
          isFieldReplaced = true;
          return data;
        }
      });
      isFieldReplaced
        ? setField([...updatedFields])
        : setField([...updatedFields, data]);
    } else {
      setField([...fields, data]);
    }
    setIsEditField(false);
    setEditField({});
  };

  const handleRadioChange = (event) => {
    setIsCampaignAssigned(event.target.value === 'yes');
  };

  const onDragEnd = result => {
    if (!result.destination) return;

    const { source, destination } = result;
    const updatedFields = [...fields];
    const [movedField] = updatedFields.splice(source.index, 1);
    updatedFields.splice(destination.index, 0, movedField);

    setField(updatedFields);
  };

  const handleDelete = fieldName => {
    const updatedFields = fields.filter(field => field.name !== fieldName);
    setField(updatedFields);
  };

  const handleEdit = fieldName => {
    const editField = fields.find(field => field.name == fieldName);
    setEditField(editField);
    setIsEditField(true);
  };

  const initialValues = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: '' }),
    {}
  );

  const handleSaveForm = async () => {
    if (!formName) {
      setIsFormModalOpen(true);
      return;
    }
    setIsLoading(true);
    try {
      let logoUrl = ''
      if(logo !== ""){
        logoUrl = await uploadFileToCloudinary(logo);
      }
      setIsLoading(false);

      const saveFormValues = [...fields];
      let method = 'post';
      const editUrl = `dynamic_intake_forms/${editFormValues.id}`;

      if (isEditMode) {
        method = 'put';
      }

      axios[method](
        isEditMode ? editUrl : submitUrl,
        {
          dynamic_intake_form: {
            form_data: saveFormValues,
            clinic_id: clinicId,
            form_name: formName,
            campaign_id: isCampaignAssigned ? campaignId[0]: null,
            logo_url: logoUrl,
            helpline: helplineNumber
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          }
        }
      )
        .then(response => {
          if (response.status === 200) {
            toast.success(
              `${isEditMode ? 'Updated' : 'Created'}`
            );
            isEditMode
              ? handleIsEdit(response.data.dynamic_intake_forms)
              : (window.location.href = `/${clinicId}/dynamic_intake_forms`);
          } else {
            console.log(response.data);
          }
        })
        .catch(error => {
          console.error(error.response.data);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast.error('There is an error uploading file!');
    }
  };

  return (
    <div className='card m-3 p-4'>
      {isLoading && <Loader />}
      <Grid container spacing={2} style={{ minHeight: '100vh' }}>
        <Grid
          item
          xs={9}
          className='px-2'
          style={{ backgroundColor: '#FAFAFA' }}
        >
          {fields.length <= 0 && (
            <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
              <h2 className='font-weight-bold' style={{ color: '#dbdbdb' }}>
                + Add Field
              </h2>
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={() => setIsFormModalOpen(true)}
          >
            <Form>
              <DragDropContext onDragEnd={onDragEnd}>
                <DropZone>
                  <div className='flex-container'>
                    <div className={`flex-col-${9}`}>
                      <label className='mb-3'>Upload your Logo</label>
                      <LogoUploadInput onChange={setLogo} logo={logo} />
                    </div>
                    <div className={`flex-col-${3}`}>
                      <label className='mb-3 w-100'>
                        Helpline Number
                        <Field
                          type='text'
                          name='helpLine'
                          className='form-control'
                          value={helplineNumber}
                          onChange={e => setHelplineNumber(e.target.value)}
                        />
                      </label>
                    </div>
                    {fields.map((field, index) => (
                      <DraggableField
                        key={`${field.name}-${index}`}
                        index={index}
                        field={field}
                        id={`${field.name}-${index}`}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                      />
                    ))}
                    <div className="container">
                      <p>Would you like to assign a campaign to this intake form?</p>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="yesNoRadio"
                          id="yesRadio"
                          value="yes"
                          checked={isCampaignAssigned}
                          onChange={handleRadioChange}
                        />
                        <label className="form-check-label" htmlFor="yesRadio">
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="yesNoRadio"
                          id="noRadio"
                          value="no"
                          checked={!isCampaignAssigned}
                          onChange={handleRadioChange}
                        />
                        <label className="form-check-label" htmlFor="noRadio">
                          No
                        </label>
                      </div>
                      <div className="row">
                      <div className="col-12">
                        {isCampaignAssigned && 
                          <CampaignTableCard
                            list={campaignsList}
                            handleCampaignId={handleCampaignId}
                            selectedCampaign={campaignId}
                            title='Clinic Campaigns'
                            columns={weightLosscampaignModalColumns(setCampaignId)}
                          />
                        }
                      </div>
                    </div>
                    </div>



                    <div
                      className={`d-flex justify-content-between align-items-center flex-col-${9}`}
                    >
                      {fields.length > 0 && (
                        <button
                          className='btn btn-outline-primary mb-2 mt-2'
                          type='submit'
                        >
                          {isEditMode ? 'Update Form' : 'Save Form'}
                        </button>
                      )}
                    </div>
                  </div>
                </DropZone>
              </DragDropContext>
            </Form>
          </Formik>
        </Grid>
        <Grid item xs={3}>
          <ToolBar
            handleAdd={handleAddField}
            isEditMode={isEditField}
            field={editField}
          />
        </Grid>
      </Grid>
      <Modal
        show={isFormModalOpen}
        onHide={() => setIsFormModalOpen(false)}
        size='lg'
        className='p-3'
      >
        <Modal.Header closeButton>
          <Modal.Title>Form Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <label className='form-label'>Name</label>
            <input
              type='text'
              name='form-name'
              className='form-control'
              onChange={e => setFormName(e.target.value)}
              value={formName}
              placeholder='Enter name'
              size='md'
              required
              centered
            />
            <div className='clearfix mb-2'></div>
            <div className='d-flex justify-content-center align-items-center pt-1'>
              <button
                onClick={() => {
                  handleSaveForm();
                  setIsFormModalOpen(false);
                }}
                className='btn btn-primary'
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FormBuilder;
