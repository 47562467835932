import React from 'react';
import { Provider } from 'react-redux';
import UnreadMessageTab from '../common/UnreadMessageTab';
import { store } from '../../helpers/store';

const UnreadMessages = props => {
  const {
    clinicId,
    csrfToken,
    isOwner,
    subscriptionActive,
    clinicName,
    usStates,
    patients,
    userId
  } = props;

  return (
    <Provider
      store={store(
        clinicId,
        csrfToken,
        isOwner,
        userId,
        subscriptionActive,
        clinicName,
        usStates
      )}
    >
      <UnreadMessageTab
        isOwner={isOwner}
        clinicId={clinicId}
        patients={patients}
        userId={userId}
        type={'unreadMessages'}
      />
    </Provider>
  );
};

export default UnreadMessages;
