import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';

const ProgressCircleChart = ({ progress = 40, size = 150 }) => {
  ChartJS.register(ArcElement, Legend);
const normalizedProgress = progress % 100; // Normalize progress within 0-100 range

const backgroundColor =
  normalizedProgress < progress
    ? ['#36A2EB', '#00ba9b']
    : ['#00ba9b', '#EDEDED'];

  const data = {
    labels: [
      `${normalizedProgress < progress ? 'Over Achieved':'Goal Achieved'}`,
      `${normalizedProgress < progress ? 'Goal Achieved' : 'Remaining'}`
    ],
    datasets: [
      {
        data: [normalizedProgress, 100 - normalizedProgress],
        backgroundColor: backgroundColor
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: '70%',
    plugins: {
      legend: {
        display: true
      },
      // tooltip: {
      //   enabled: false
      // }
    }
  };

  return (
      <Doughnut data={data} options={options} />
  );
};

export default ProgressCircleChart;
