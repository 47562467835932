//
// FETCHING
//
export const START_FETCHING = 'START_FETCHING';
export const FINISH_FETHING = 'FINISH_FETHING';

//
// PATIENT ASSESSMENTS
//
export const GET_ASSESSMENTS_SUCCESSFULLY = 'GET_ASSESSMENTS_SUCCESSFULLY';
export const CREATE_ASSESSMENT_SUCCESSFULLY = 'ADD_NEW_ASSESSMENT_SUCCESSFULLY';

//
// STORE & ORDERS
//
export const CREATE_ORDER_SUCCESSFULLY = 'CREATE_ORDER_SUCCESSFULLY';
export const GET_LOGO_SUCCESSFULLY = 'GET_LOGO_SUCCESSFULLY';
export const GET_STORE_ITEMS_SUCCESSFULLY = 'GET_STORE_ITEMS_SUCCESSFULLY';
export const GET_ORDERS_SUCCESSFULLY = 'GET_ORDERS_SUCCESSFULLY';
export const CANCEL_ORDER_SUCCESSFULLY = 'CANCEL_ORDER_SUCCESSFULLY';
// PAYMENTS
export const PAY_ORDER_SUCCESSFULLY = 'PAY_ORDER_SUCCESSFULLY';
export const PAY_ORDER_UNSUCCESSFULLY = 'PAY_ORDER_UNSUCCESSFULLY';
///CAMPAIGN
export const CUSTOM_CAMPAIGN = 'custom';
export const AUTOMATED_CAMPAIGN = 'automated';
