import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { disableAllSelection } from '../../../../helpers/weight_loss';

const CampaignTableCard = props => {
  const {
    title,
    columns,
    list,
    selectedCampaign,
    handleCampaignId
  } = props;

  const classes = disableAllSelection();

  return (
    <div className='card mt-2'>
      <div className='card-header font-weight-bold '>{title}</div>
      <div className='card-body'>
        <div className='form-row'>
          <div className='col form-group'>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={list}
                columns={columns}
                className={classes.root}
                selectionModel={selectedCampaign}
                onSelectionModelChange={e => handleCampaignId(e)}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTableCard;
