import React, { useEffect, useState } from 'react'
import { PatientFlashMessage } from '../shared/PatientFlashMessage'
import { createPatientFlashMessage } from '../../lib/patient_utils'

import { connect } from 'react-redux'

const ReferralIndex = (props) => {
    const [referrals, setReferrals] = useState([]);
    const [responseMessage, setResponseMessage] = useState(null);

    useEffect(() => {
        fetch(props.clinicId + '/clients/' + props.patientId + '/intake_form_referrals', {
            method: "GET",
            headers: {
                'content-type': 'application/json',
                'accept': 'application/json'
            }
        }).then(response => response.json())
        .then(data => {
            if (data.error) {
                setResponseMessage(createPatientFlashMessage(data.error, true));
            }
            else {
                setReferrals(data)
            }
        })
    }, [props.patientId]);

    const referralElements = referrals.map(referral => {
        var content;

        if (referral.intake_form.first_name && referral.intake_form.last_name)
        {
            content = (
                <a href={`/${props.clinicId}/intake_forms/${referral.intake_form.id}`}>
                    {`${referral.intake_form.first_name} ${referral.intake_form.last_name}`}
                </a>
            )
        }
        else {
            content = referral.intake_form.email
        }
        return (
            <li className="list-group-item">
                {content}
            </li>
        )
    })

    return (
        <>
            <PatientFlashMessage message={responseMessage}  
                                  clearMessage={() => {setResponseMessage(null)}} />
            <div className="card mt-3 pt-3 pr-3 pl-3">
                <div className="card-body">
                    <ul className="list-group list-group-flush">
                        {referralElements}
                    </ul>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinicId: state.patientIndex.clinicId,
        patientId: state.patientIndex.activePatient,
    }
}

export default connect(
    mapStateToProps,
    null
)(ReferralIndex)