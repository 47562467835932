import React, { useEffect, useState } from 'react';
import { getTemplateMessages } from '../../actions/weight_loss';
import PatientWLPDetail from './components/Clinic/PatientWLPDetail';
import UserWLP from './components/Clinic/UserWLP';

const WeightLoss = props => {
  const { csrfToken, clinicId } = props;

  const [templateMessagesList, setTemplateMessagesList] = useState([]);

  useEffect(() => {
    getTemplateMessages(clinicId, setTemplateMessagesList);
  }, [clinicId]);

  return (
    <div>
      <div className='layout-content'>
        <PatientWLPDetail
          clinicId={clinicId}
          csrfToken={csrfToken}
          templateMessagesList={templateMessagesList}
          setTemplateMessagesList={setTemplateMessagesList}
        />
        <UserWLP clinicId={clinicId} csrfToken={csrfToken} />
      </div>
    </div>


  );
};

export default WeightLoss;
