import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import React, { useState } from 'react';

const DropDown = props => {
  const {
    dropDownOptions,
    onSelect,
    data,
    fontSize = '12px'
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const anchorProperties = { vertical: 'bottom', horizontal: 'right' };
  const transformProperties = { vertical: 'top', horizontal: 'right' };
  const paperProperties = {
    style: {
      borderRadius: '3px',
      border: '1px solid #d3d4d5'
    }
  };

  return (
    <div onMouseLeave={handleClose} className='table float-right'>
      <MoreVertIcon
        fontSize='medium'
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        className='opacity-90 mt-1 mr-1'
        onClose={handleClose}
        elevation={0}
        anchorOrigin={anchorProperties}
        transformOrigin={transformProperties}
        PaperProps={paperProperties}
      >
        {dropDownOptions.map((option, index) => {
          return (
            <MenuItem
              key={index}
              sx={{ fontSize: { fontSize } }}
              onClick={() => onSelect(option, data)}
            >
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default DropDown;
