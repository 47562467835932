import React, { useEffect, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import { editorConfig } from '../../helpers/template';

const Editor = (props) => {
  const { subject, body, index, handleChange } = props;
  const editor = useRef(null);
  const [dummy, setDummy] = useState(body ? body : '');

  useEffect(() => {
    handleChange(dummy, index);
  },[dummy])
  return (
    <>
      <div className='form-group'>
        Subject:
        <input
          name='subject'
          className='form-control'
          value={subject}
          onChange={e => handleChange(e, index)}
        />
      </div>
      <JoditEditor
        name='body'
        ref={editor}
        value={body}
        config={editorConfig(false)}
        tabIndex={1}
        onBlur={newContent => setDummy(newContent)}
      />
    </>
  );
};

export default Editor;
