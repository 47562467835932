import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { deleteAppointmentType } from "../actions/scheduler";
import { secondsToInterval } from '../helpers/scheduler'

export const appointmentTypeListColumn = (
  setName,
  setTime,
  setShowModal,
  setTypeId,
  setData,
  setColor,
  clinicId,
  setIsPublic
) => [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'total_time', headerName: 'Total Time', flex: 1, renderCell: params => secondsToInterval(params.formattedValue) },
    { field: 'is_public', headerName: 'Public', flex: 1, renderCell: (params) => params.value ? 'Yes' : 'No' },
    {
      field: 'color', headerName: 'Color', width: 200, renderCell: (params) => {
        return (
          <div style={{ width: '30px', height: '30px', background: params.value }}>

          </div>
        )
      }
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label='Edit'
          showInMenu
          onClick={() => {
            setTypeId(params.row.id)
            setColor(params.row.color);
            setName(params.row.name);
            setIsPublic(params.row.is_public)
            const rowTime = new Date(1000 * params.row.total_time).toISOString().substr(11, 5);
            const seperatedHoursMin = rowTime.split(':');
            setTime(new Date(new Date().setHours(seperatedHoursMin[0], seperatedHoursMin[1])));
            setShowModal(true);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Delete'
          showInMenu
          onClick={() => deleteAppointmentType(clinicId, params.row.id, setData)}
        />
      ]
    }
  ];
