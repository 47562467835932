import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from '../../common/DatePicker.jsx';

const DateModal = props => {
  const { show, setShow, setDate, handleCampaignUpdate, date } = props;

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size='sm'>
        <Modal.Header>
          <Modal.Title>Select Start Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker date={date} setDate={setDate} min={new Date()} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='btn btn-outline-danger' onClick={handleClose}>
            Close
          </Button>

          <Button
            variant='btn btn-outline-success'
            onClick={() => handleCampaignUpdate()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DateModal;
