import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Button, Collapse, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import CancelForm from './CancelForm';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { addDays } from 'date-fns';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { cardRefund, cashRefund } from '../../actions/orders';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  0: {
    color: '#002192'
  },
  1: {
    color: '#19c33f',
    marginRight: '5px'
  },
  2: {
    color: '#790c0c'
  },
  3: {
    color: '#f4ab55'
  },
  4: {
    color: '#002192'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  btnAction: {
    padding: theme.spacing(2)
  }
}));

const IndexStatusField = ({close, status, order }) => {
  const [amountStatus, setAmountStatus] = useState(status);
  const [isPartial, setIsPartial] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const isActiveOwner = useSelector(state =>
    state.patientIndex
      ? state.patientIndex.isOwner && state.patientIndex.subscriptionActive
      : false
  );
  const classes = useStyles();

  useEffect(()=>{
    setAmountStatus(status)
  },[status])

  const showStatus = () => {
    let currentDate = new Date();
    let nextDate = addDays(new Date(order.payment_time), 1);
    let monthDate = new Date(addDays(new Date(order.payment_time), 30));
    if((order.retref != '' && order.payment_time != null) && currentDate.getTime() == nextDate.getTime())
      return (
        <button
          className='btn btn-outline-warning btn-responsive'
          onClick={handleCardRefund}
        >
          Refund
        </button>
      );
    else if (order.retref != '' && currentDate.getTime() > monthDate.getTime())
      return null;
    else if (order.retref != '' && currentDate.getTime() < nextDate.getTime()) {
      return (
        <Tooltip title='You can refund after 24 hours.' placement='top-start'>
          <IconButton>
            <InfoIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      );
    } else
      return (
        <button
          className='btn btn-outline-warning btn-responsive'
          onClick={() => setShow(true)}
        >
          Refund
        </button>
      );
  };

  const token = useSelector(state =>
    state.patientIndex ? state.patientIndex.csrfToken : state.token
  );

  const handleCardRefund = async () => {
    try {
      const form = new FormData();
      form.append('authenticity_token', token);
      dispatch(cardRefund(order, form, close, setError));
      if (error != '') {
        toastr.error("Transaction not settled yet!")
        setError('');
        return;
      } else {
        setAmountStatus(3);
        toastr.success("Amount refunded!");
      }
    } catch (e) {
      console.error(e.message);
      toastr.error(e.message);
    }
  };

  const handleCashRefund = async (amount) => {
    if(Number(amount) > ((Number(order.amount_paid) - Number(order.refund_amount)).toFixed(2)) ){
      toastr.warning("Refund amount can't be greater then remaining amount!")
      return;
    }
    try {
      const form = new FormData();
      form.append('authenticity_token', token);
      form.append('refund_amount', amount);
      dispatch(cashRefund(order, form, close, setError));
      setShow(false);
      if(error != '') {
        toastr.error(error);
        setError('');
        return
      };
      setRefundAmount(0);
      toastr.success('Amount refunded!');
    } catch (e) {
      console.error(e.message);
      toastr.error(e.message);
    }
  };

  const statusField = {
    0: { statusString: 'Pending', button: () => <CancelForm order={order} /> },
    1: {
      statusString: 'Paid',
      button: () =>
        isActiveOwner &&
        Number(order.amount_paid) !== Number(order.refund_amount)
          ? showStatus()
          : null
    },
    2: { statusString: 'Cancelled', button: () => null },
    3: { statusString: 'Refunded', button: () => null },
    4: { statusString: 'Pending', button: () => <CancelForm order={order} /> }
  };

  const { statusString, button } = statusField[amountStatus];

  return (
    <>
      <div className={classes.root} onClick={e => e.stopPropagation()}>
        <span className={classes[status]}>{statusString}</span>
        {button()}
      </div>
      <div onClick={e => e.stopPropagation()}>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size='lg'
        className='p-3'
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Amount to Refund</Modal.Title>
        </Modal.Header>
        <div className='d-flex flex-column px-4 py-3'>
          <div className='d-flex justify-content-between'>
            <Button
              variant='contained'
              color='default'
              className='mr-1 btn-responsive mb-3 mb-md-0'
              classes={{ root: classes.btnAction }}
              onClick={() => {
                setIsPartial(!isPartial);
              }}
              endIcon={
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: isPartial
                  })}
                  aria-expanded={isPartial}
                />
              }
            >
              Partial Payment
            </Button>
            <Button
              variant='contained'
              color='default'
              classes={{ root: classes.btnAction }}
              className='btn-responsive mb-3 mb-md-0'
              onClick={() => {
                handleCashRefund(
                  (
                    Number(order.amount_paid) - Number(order.refund_amount)
                  ).toFixed(2)
                );
              }}
            >
              Full Payment
            </Button>
          </div>

          <Collapse in={isPartial} timeout='auto' unmountOnExit>
            <div className='d-flex justify-content-start flex-md-row flex-column pt-3'>
              <div className='d-flex flex-column flex-md-row alig-items-md-center pr-md-4'>
                <label htmlFor='name'>
                  Refund
                  <input
                    onChange={e => setRefundAmount(e.target.value)}
                    required
                    type='number'
                    step='0.01'
                    min='0'
                    className='ml-1'
                    placeholder='0.00'
                  />
                </label>
              </div>
              <div className='d-flex flex-column flex-md-row pr-md-4'>
                <label htmlFor='name'>
                  Remaining
                  <input
                    readOnly
                    type='number'
                    className='ml-1'
                    value={(
                      Number(order.amount_paid) -
                      refundAmount -
                      Number(order.refund_amount)
                    ).toFixed(2)}
                  />
                </label>
              </div>
              <button
                className='btn btn-outline-warning btn-responsive'
                onClick={() => handleCashRefund(refundAmount)}
              >
                Refund
              </button>
            </div>
          </Collapse>
        </div>
      </Modal>
      </div>
    </>
  );
};

IndexStatusField.propTypes = {
  status: PropTypes.number.isRequired,
  order: PropTypes.objectOf(oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.arrayOf(PropTypes.object),
  ])).isRequired,
};

export default IndexStatusField;
