import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const QuantityModal = ({
  label = 'Label',
  isOpen,
  handleOpen,
  addQuantity
}) => {
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity(1);
  }, [isOpen]);

  return (
    <Modal
      show={isOpen}
      onHide={handleOpen}
      keyboard={false}
      size='sm'
      centered
    >
      <Modal.Header>
        <Modal.Title>Item: {label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='form-group'>
          Quantity:
          <input
            className='form-control'
            type='number'
            min={1}
            max={1000}
            step='any'
            value={quantity}
            required
            onChange={e => setQuantity(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => addQuantity(1)}>
          Close
        </Button>
        <Button variant='primary' onClick={() => addQuantity(quantity)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuantityModal;
