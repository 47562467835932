import {
  CANCEL_ORDER_SUCCESSFULLY,
  CREATE_ASSESSMENT_SUCCESSFULLY, CREATE_ORDER_SUCCESSFULLY,
  FINISH_FETHING, GET_ASSESSMENTS_SUCCESSFULLY,
  GET_LOGO_SUCCESSFULLY,
  GET_ORDERS_SUCCESSFULLY, GET_STORE_ITEMS_SUCCESSFULLY, PAY_ORDER_SUCCESSFULLY, START_FETCHING,
} from '../actions/actionTypes';
import { countUnreadMessages } from '../lib/patient_utils';

export default function patientIndex(state = {
  patients: [],
  filteredPatientList: null,
  isFetching: false,
  isFetchingMessageReadRequest: false,
  fetching: 0,
  clinicId: '',
  clinicName: 'Clinic Name',
  activePatient: null,
  availableClinicPackages: [],
  newSessionShow: false,
  newMeasurementFormShow: false,
  csrfToken: null,
  isOwner: false,
  userId: null,
  sessionEdit: null,
  subscriptionActive: false,
  assessments: [],
  cardUrl: '',
}, action) {
  switch (action.type) {
    case 'RECEIVE_PATIENT_LIST_SUCCESS':
      return {
        ...state,
        activePatient: null,
        filteredPatientList: null,
        patients: action.patients,
        availableClinicPackages: action.availableClinicPackages,
        isFetching: false
      };
    case 'RECEIVE_PATIENT_SUCCESS':
      return {
        ...state,
        activePatient: action.patient.id,
        newMeasurementFormShow: false,
        newSessionShow: false,
        sessionEdit: null,
        isFetching: false,
        patients:
          !state.patients || state.patients.length === 0
            ? [action.patient]
            : state.patients.map(patient =>
                patient.id === action.patient.id ? action.patient : patient
              )
      };
    case 'FILTER_PATIENT_LIST':
      if (action.unreadMessages) {
      return {
        ...state,
        filteredPatientList: state.patients?.filter(
          item =>
            countUnreadMessages(item, true) > 0 &&
            item.name.toLowerCase().search(action.filter.toLowerCase()) !== -1
        )
      }
     }
     else {
       return {
         ...state,
         filteredPatientList: state.patients.filter(
           item =>
             item.name.toLowerCase().search(action.filter.toLowerCase()) !== -1
         )
       };
     }
    case 'SHOW_NEW_SESSION_FORM':
      return {
        ...state,
        newSessionShow: true,
        sessionEdit: null
      };
    case 'HIDE_SESSION_FORM':
      return {
        ...state,
        newSessionShow: false,
        sessionEdit: null
      };
    case 'SHOW_EDIT_SESSION_FORM':
      return {
        ...state,
        sessionEdit: action.session,
        newSessionShow: true
      };
    case 'TOGGLE_MEASUREMENT_FORM':
      return {
        ...state,
        measurementEdit: null,
        newMeasurementFormShow: !state.newMeasurementFormShow
      };
    case 'SHOW_MEASUREMENT_EDIT_FORM':
      return {
        ...state,
        measurementEdit: action.measurement,
        newMeasurementFormShow: true
      };
    case 'SELECT_MEASUREMENT_COMPARE':
      return {
        ...state,
        patients: state.patients.map(patient =>
          patient.id === action.patientId
            ? {
                ...patient,
                measurementCompareStartId: action.start,
                measurementCompareEndId: action.end
              }
            : patient
        )
      };
    case 'REQUEST_NEW_MEASUREMENT':
    case 'REQUEST_NEW_USER_PACKAGE':
    case 'REQUEST_PATIENT_LIST':
    case 'REQUEST_NEW_SESSION':
    case 'REQUEST_NEW_MESSAGE':
    case 'REQUEST_REMOVE_ACTIVE_PACKAGE':
    case 'REQUEST_PATIENT':
      return {
        ...state,
        isFetching: true
      };
    case 'REQUEST_MARK_MESSAGE_READ':
      return {
        ...state,
        isFetchingMessageReadRequest: true
      };
    case 'RECEIVE_NEW_USER_PACKAGE_SUCCESS':
    case 'RECEIVE_NEW_SESSION_SUCCESS':
      return {
        ...state,
        isFetching: false,
        patients: state.patients.map(patient =>
          patient.id === action.patientId
            ? {
                ...patient,
                active_packages: action.activePackages,
                past_packages: action.pastPackages
              }
            : patient
        ),
        newSessionShow: false,
        sessionEdit: null
      };
    case 'REMOVE_ACTIVE_PACKAGE_SUCCESS':
      return {
        ...state,
        isFetching: false,
        patients: state.patients.map(patient =>
          patient.id === action.patientId
            ? {
                ...patient,
                active_packages: patient.active_packages.filter(
                  userPackage => userPackage.id !== action.activePackageId
                )
              }
            : patient
        ),
        newSessionShow: false,
        sessionEdit: null
      };
    case 'RECEIVE_NEW_MEASUREMENT_SUCCESS':
      return {
        ...state,
        isFetching: false,
        patients: state.patients.map(patient =>
          patient.id === action.patientId
            ? { ...patient, measurements: action.measurements }
            : patient
        ),
        newMeasurementFormShow: false
      };
    case 'RECEIVE_NEW_MESSAGE_SUCCESS':
      return {
        ...state,
        isFetching: false,
        patients: state.patients.map(patient =>
          patient.conversation &&
          patient.conversation.id === action.message.conversation_id
            ? {
                ...patient,
                conversation: {
                  ...patient.conversation,
                  messages: [...patient.conversation.messages, action.message]
                }
              }
            : patient
        )
      };
    case 'RECEIVE_NEW_CONVERSATION_METADATA':
      return {
        ...state,
        isFetchingMessageReadRequest: false,
        patients: state.patients.map(patient =>
          patient.conversation &&
          patient.conversation.id === action.conversation.id
            ? { ...patient, conversation: action.conversation }
            : patient
        )
      };
    case 'RECEIVE_NEW_CONTACT_INFO_SUCCESS':
      return {
        ...state,
        isFetching: false,
        patients: state.patients.map(patient =>
          patient.id === action.patient.id
            ? {
                ...patient,
                name: action.patient.name,
                email: action.patient.email,
                address: action.patient.address,
                city: action.patient.city,
                state: action.patient.state,
                zipcode: action.patient.zipcode,
                phone_number: action.patient.phone_number
              }
            : patient
        )
      };
    case START_FETCHING: {
      const newFetchingState = state.fetching + 1;
      return { ...state, fetching: newFetchingState };
    }
    case FINISH_FETHING: {
      const { fetching } = state;
      const newFetchingState = fetching > 0 ? fetching - 1 : 0;
      return { ...state, fetching: newFetchingState };
    }
    case GET_ASSESSMENTS_SUCCESSFULLY:
      return { ...state, assessments: [...action.payload] };
    case CREATE_ASSESSMENT_SUCCESSFULLY:
      return {
        ...state,
        assessments: [...state.assessments, action.payload.assessment],
        patients: state.patients.map(patient =>
          patient.id === action.payload.assessment.user_id
            ? { ...patient, score: action.payload.assessment.score }
            : patient
        )
      };
    case GET_ORDERS_SUCCESSFULLY:
      return { ...state, orders: [...action.payload] };
    case GET_STORE_ITEMS_SUCCESSFULLY:
      return { ...state, storeItems: [...action.payload.store_items] };
    case CREATE_ORDER_SUCCESSFULLY:
      return { ...state, orders: [action.payload, ...state.orders] };
    case GET_LOGO_SUCCESSFULLY:
      return { ...state, logo: action.payload };
    case CANCEL_ORDER_SUCCESSFULLY: {
      const newState = state.orders.map(obj => {
        if (obj.id === action.payload.id) return action.payload;
        return obj;
      });
      return { ...state, orders: [...newState] };
    }
    case PAY_ORDER_SUCCESSFULLY: {
      const newState = state.orders.map(obj => {
        if (obj.id === action.order.id) return { ...obj, status: 1 };
        return obj;
      });
      return {
        ...state,
        orders: [...newState],
        patients: state.patients.map(patient =>
          patient.id === action.order.client_id
            ? { ...patient, active_packages: action.active_packages }
            : patient
        )
      };
    }
    default:
      return state;
  }
}
