import axios from 'axios';
import {
  CREATE_ASSESSMENT_SUCCESSFULLY, GET_ASSESSMENTS_SUCCESSFULLY,
} from './actionTypes';
import { finishFetching, startFetching } from './fetching';

const getAssessmentSuccessfully = (json) => ({
  type: GET_ASSESSMENTS_SUCCESSFULLY,
  payload: json,
});

const createAssessmentSuccessfuly = (json) => ({
  type: CREATE_ASSESSMENT_SUCCESSFULLY,
  payload: json,
});

const createAssessment = (clinic, patient, form) => {
  const url = `/${clinic}/clients/${patient}/patient_assessments`;
  return ((dispatch) => {
    dispatch(startFetching());
    axios
      .post(url, form, { withCredentials: true })
      .then((response) => {
        dispatch(createAssessmentSuccessfuly(response.data));
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch((error) => {
      // eslint-disable-next-line no-console
        console.log(error);
        dispatch(finishFetching());
      });
  });
};

const getAssessments = (clinic, patient) => {
  const url = `/${clinic}/clients/${patient}/patient_assessments`;

  return ((dispatch) => {
    dispatch(startFetching());
    axios
      .get(url)
      .then((response) => {
        dispatch(getAssessmentSuccessfully(response.data));
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch(finishFetching());
      });
  });
};

export { getAssessments, createAssessment };
