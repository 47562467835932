import { format } from 'date-fns';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toggleActivePatient } from '../../../actions/patients';
import DailyLogsFormModal from './DailyLogsFormModal';
import WeightStatsCard from './WeightStatsCard';
import WLPMessage from './WLPMessage';
import { orderBy } from 'lodash';
import LogsEntryModal from './LogsEntryModal';
import AgreementModal from './AgreementModal.jsx';

const DailyLogs = props => {
  const { csrfToken, patient, refreshPatient } = props;
  const {
    id,
    active_weight_loss_program_package,
    start_weight,
    weight_today,
    clinic_id,
    user_wl_programs,
    target_weight,
    goal_achieved
  } = patient;

  const [show, setShow] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);

  const handleShow = () => {
    if (
      userActivePackage?.package?.is_agreement_attached &&
      !userActivePackage?.package?.user_accepted_agreement
    ) {
      setShowAgreement(true);
      setShow(false);
    } else {
      setShow(true);
      setShowAgreement(false);
    }
  };

  const [userActivePackage, setUpdatePatient] = useState(
    active_weight_loss_program_package
  );

  let sortedPrograms = orderBy(user_wl_programs, ['start_date'], ['desc']);

  return (
    <>
      {sortedPrograms?.length == 0 ? (
        <WLPMessage message='No Weight Loss Program currently active for this client.' />
      ) : (
        <>
          {userActivePackage && (
            <div className='card mt-3'>
              <div className='card-header'>
                <h4 className='font-weight-bold ml-3 d-inline'>
                  Active Weight Loss Program:
                  <span className='text-primary'>
                    {userActivePackage?.program?.name}
                  </span>
                </h4>

                <Button
                  variant='btn btn-primary float-right text-white'
                  onClick={() => handleShow()}
                >
                  Log Daily Weight
                </Button>
              </div>
            </div>
          )}
          <div class='row mt-3 justify-content-between'>
            <WeightStatsCard
              weight={start_weight}
              unit='lbs'
              text='Program Start Weight'
              colorClass='danger'
              className='mt-2'
            />
            <WeightStatsCard
              weight={weight_today}
              unit='lbs'
              text="Today's Weight"
              colorClass='primary'
              className='mt-2'
            />
            <WeightStatsCard
              csrfToken={csrfToken}
              clinicId={clinic_id}
              patientId={id}
              editable={true}
              refreshPatient={() => refreshPatient(clinic_id, id)}
              weight={target_weight}
              unit='lbs'
              text='Target Weight'
              colorClass='info'
              className='m-0'
            />
            <WeightStatsCard
              weight={goal_achieved}
              unit='%'
              text='% of Goal Achieved'
              colorClass='success'
              className='mt-2'
            />
          </div>
          <LogsEntryModal
            clinicId={clinic_id}
            csrfToken={csrfToken}
            show={show}
            setShow={setShow}
            refreshPatient={() => refreshPatient(clinic_id, id)}
            userWlProgram={user_wl_programs.find(
              x => x.id === active_weight_loss_program_package?.package?.id
            )}
          />
          <AgreementModal
            csrfToken={csrfToken}
            show={showAgreement}
            setShow={setShowAgreement}
            weightlossprogram={userActivePackage}
            setUpdatePatient={setUpdatePatient}
            patient={patient}
          />
          {sortedPrograms?.map((userWlProgram, index) =>
            userWlProgram.start_date != null ? (
              new Date(userWlProgram.start_date) > new Date() ? (
                <WLPMessage
                  userWlProgram={userWlProgram}
                  showPhasesButton={true}
                  message={`Weight Loss Program ${
                    userWlProgram.weight_loss_program.name
                  } will start on date <span class='text-primary'> ${format(
                    new Date(userWlProgram.start_date),
                    'PPpp'
                  )} </span>.`}
                />
              ) : (
                <DailyLogsFormModal
                  show={show}
                  setUpdatePatient={setUpdatePatient}
                  showModal={show}
                  setShow={setShow}
                  userActivePackage={userActivePackage}
                  showAgreement={showAgreement}
                  setShowAgreement={setShowAgreement}
                  clinicId={clinic_id}
                  csrfToken={csrfToken}
                  refreshPatient={() => refreshPatient(clinic_id, id)}
                  userWlProgram={userWlProgram}
                  weightToday={weight_today}
                  dataLogRemaining={
                    patient.data_log_remaining[userWlProgram.id]
                  }
                  patient={patient}
                />
              )
            ) : (
              <WLPMessage
                message={`Weight Loss Program <span class='text-primary'> ${userWlProgram.weight_loss_program.name} </span> is not started by Clinic yet!`}
              />
            )
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    refreshPatient: (clinic_id, patient_id) => {
      dispatch(toggleActivePatient(clinic_id, patient_id));
    }
  };
};

export default connect(null, mapDispatchToProps)(DailyLogs);
