import React, { useState, useEffect, useRef } from 'react';
import { Collapse, Modal, Card, Form, Button } from 'react-bootstrap';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TodoForm from './Clinic/FoodIntake/TodoForm';
import Todo from './Clinic/FoodIntake/Todo';
import RadioOptions from './RadioOptions';
import JoditEditor from 'jodit-react';
import Papa from 'papaparse';
import { getAllowedFoods as fetchAllowedFoods } from '../../../actions/clinic';

const WeightLossEntryCard = ({
  data,
  index,
  handleInputChange,
  inputList,
  setInputList,
  clinicId,
  isFormError,
  hidePhaseNumer
}) => {
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);

  const [todos, setTodos] = useState([]);
  const [instructions, setInstructions] = useState(data?.instructions || '');
  const [arrowIcon, setArrowIcon] = useState(faAngleRight);
  const [isOpen, setIsOpen] = useState(false);
  const [foodList, setFoodList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isFoodListEnabled, setIsFoodListEnabled] = useState(
    inputList[index].allowed_food.length >= 1
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const updateFoodList = newFoodList => {
    setInputList(prevState => {
      const updatedState = prevState.map(item =>
        item.id === inputList[index].id
          ? { ...item, allowed_food: newFoodList }
          : item
      );
      return updatedState;
    });
  };

  const addTodo = todo => {
    if (!todo.name || /^\s*$/.test(todo.name)) return;

    setTodos(prevTodos => {
      const newTodos = [todo, ...prevTodos];
      updateFoodList(newTodos);
      return newTodos;
    });
  };

  const transformCSVData = data => {
    const groupedData = data.reduce((acc, item) => {
      const category = item.CATEGORY.trim();
      if (!acc[category]) acc[category] = [];
      acc[category].push(item.FOOD);
      return acc;
    }, {});

    Object.keys(groupedData).forEach(category =>
      addTodo({
        id: Math.floor(Math.random() * 1000000),
        name: groupedData[category].join(', '),
        category
      })
    );
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: results => transformCSVData(results.data),
        error: error => console.error('Error parsing CSV file:', error)
      });
    }
  };

  useEffect(() => {
    fetchAllowedFoods(clinicId, setFoodList);
    const initialTodos = inputList[index].allowed_food.map((food, j) => {
      const parsedFood = food.name
        ? food
        : JSON.parse(food.replaceAll('=>', ':'));
      return {
        id: j + index + 1,
        name: parsedFood.name,
        category: parsedFood.category
      };
    });
    updateFoodList(initialTodos);
    setTodos(initialTodos);
  }, []);

  useEffect(() => {
    updateFoodList(todos);
  }, [todos]);

  useEffect(() => {
    updateFoodList(isFoodListEnabled ? todos : []);
  }, [isFoodListEnabled]);

  const updateTodo = (todoId, newValue) => {
    if (!newValue.name || /^\s*$/.test(newValue.name)) return;

    setTodos(prevTodos =>
      prevTodos.map(item => (item.id === todoId ? newValue : item))
    );
  };

  const removeTodo = id => {
    setTodos(prevTodos => prevTodos.filter(todo => todo.id !== id));
  };

  const completeTodo = id => {
    setTodos(prevTodos => {
      const updatedTodos = prevTodos.map(todo => {
        if (todo.id === id) {
          todo.isComplete = !todo.isComplete;
        }
        return todo;
      });
      updateFoodList(updatedTodos);
      return updatedTodos;
    });
  };

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const handleFoodUse = (category, name) => {
    addTodo({
      id: Math.floor(Math.random() * 10000),
      name,
      category
    });
  };

  const renderSelect = (label, name, value) => (
    <Form.Group as='li' className='p-1'>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as='select'
        className='col-md-1'
        value={value}
        name={name}
        onChange={e => handleInputChange(e, index)}
      >
        {[...Array(7).keys()].map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );

  return (
    <Card className='mt-3'>
      <Card.Header>
        <h4 className='font-weight-bold d-inline float-left mb-0 mt-2 mr-2'>
          {hidePhaseNumer ? 'Phase' : `Phase - ${index + 1}`}
        </h4>
        <Button
          className='btn btn-sm-secondary btn-primary float-right'
          onClick={() => {
            setIsOpen(!isOpen);
            setArrowIcon(!isOpen ? faAngleDown : faAngleRight);
          }}
        >
          <FontAwesomeIcon icon={arrowIcon} />
        </Button>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          <ol>
            <Form.Control
              type='text'
              name='name'
              className={`form-control col-md-3 ${
                isFormError && !data.name ? 'form-input-error' : ''
              }`}
              placeholder='Enter Phase Name...'
              value={data?.name}
              onChange={e => handleInputChange(e, index)}
            />
            <Form.Text
              className={`form-text ${
                isFormError && !data.name ? 'text-danger' : 'text-muted'
              }`}
            >
              {isFormError && !data.name
                ? 'Name is required'
                : 'Name must be greater than 5 characters'}
            </Form.Text>
            <Form.Group as='li' className='p-1'>
              <Form.Label>How many days are in this phase?</Form.Label>
              <Form.Control
                type='number'
                onChange={e => handleInputChange(e, index)}
                className='form-control w-auto'
                name='days'
                value={data?.days}
                min={1}
                max={100}
                step={1}
              />
            </Form.Group>
            {renderSelect(
              'How many meals per day are allowed in this phase?',
              'meals',
              data?.meals
            )}
            {renderSelect(
              'How many snacks are allowed in this phase?',
              'snacks',
              data?.snacks
            )}
            <Form.Group as='li' className='p-1'>
              <Form.Label>
                Type the specific instructions/goals for this phase that you
                want your clients to be able to read on their portal.
              </Form.Label>
              <JoditEditor
                ref={editorRef}
                value={instructions}
                tabIndex={1}
                onBlur={newContent =>
                  handleInputChange(
                    { target: { name: 'instructions', value: newContent } },
                    index
                  )
                }
              />
            </Form.Group>
            <Form.Group as='li'>
              <RadioOptions
                heading='Is there an allowed foods list for this phase of the weight loss program?'
                radioCheck={isFoodListEnabled}
                name={`foodList${index}`}
                setRadioCheck={setIsFoodListEnabled}
              />
              {isFoodListEnabled && (
                <Collapse in={isFoodListEnabled}>
                  <div className='todo-app'>
                    <Button
                      style={{ width: '150px', fontSize: '10px' }}
                      onClick={handleModalShow}
                      className='btn btn-primary float-right mb-2'
                    >
                      Select Food List
                    </Button>
                    <Button
                      style={{ width: '150px', fontSize: '10px' }}
                      onClick={handleButtonClick}
                      className='btn btn-primary float-right mb-2'
                    >
                      Import Food List From CSV
                    </Button>
                    <input
                      type='file'
                      accept='.csv'
                      onChange={handleFileUpload}
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                    />
                    <TodoForm onSubmit={addTodo} />
                    <Todo
                      name={`todo${index}`}
                      todos={todos}
                      completeTodo={completeTodo}
                      removeTodo={removeTodo}
                      updateTodo={updateTodo}
                    />
                    <Modal
                      show={showModal}
                      onHide={handleModalClose}
                      size='xl'
                      className='p-3'
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Allowed Food</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <table className='table table-hover table-responsive'>
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>List</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {foodList.map(obj => (
                              <tr key={obj.id}>
                                <td>{obj.category}</td>
                                <td>{obj.name}</td>
                                <td>
                                  <Button
                                    variant='outline-danger'
                                    onClick={() =>
                                      handleFoodUse(obj.category, obj.name)
                                    }
                                  >
                                    Use
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Collapse>
              )}
            </Form.Group>
          </ol>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default WeightLossEntryCard;
