import React, { useEffect } from 'react'

export const PatientFlashMessage = (props) => {
    const { message, clearMessage } = props;

    if (!message) return null;
    
    useEffect(() => {
        const timer = setTimeout(() => {
            clearMessage();
        }, 4000);
        return () => clearTimeout(timer);
    }, [message.timestamp]);

    return (
        <div className={"alert " + (message.error ? "alert-danger" : "alert-success")} role="alert">
            {message.content}
            <button type="button" className="close" onClick={props.clearMessage}><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>
        </div>
    )
}
