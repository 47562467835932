import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const Options = props => {
  const { handleGlobalSearch, searchOnclick, clearOnClick, globalSearch } = props;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const onToggle = event => {
      setOpen(!open);
      setAnchorEl(event.currentTarget);
    };

  return (
    <div className='d-flex align-items-center justify-content-end'>
      <button
        className='btn btn-sm-secondary'
        style={{ color: 'white', boxShadow: 'unset' }}
        onClick={onToggle}
      >
        <FilterAltOutlinedIcon />
      </button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        style={{ zIndex: 1060 }}
        placement='bottom-end'
      >
        <div className=' card align-items-center d-flex px-2 filter-wrapper pt-2'>
          <span className='w-100 d-flex justify-content-end align-items-center'>
            <button
              className='btn text-danger font-weight-bold'
              onClick={onToggle}
              style={{ fontSize: '1rem' }}
            >
              <CloseOutlinedIcon />
            </button>
          </span>
          <div className='d-flex flex-column flex-md-row justify-content-start w-100 mb-2'>
            <Tooltip title='Search using Description, Status, DrName, PatientName'>
              <p className='col-md-4 col-12 pl-0 pl-md-3'> Search by Event:</p>
            </Tooltip>
            <input
              className='e-input col-md-8 col-12'
              type='text'
              placeholder='Enter the Search text'
              onChange={handleGlobalSearch}
              value={globalSearch}
            />
          </div>

          <form
            className='event-search d-flex flex-column flex-md-row d-inline justify-content-start w-100 mb-2 single-line'
            id='form-search-1'
          >
            <p className='col-md-4 col-12 pl-0 pl-md-3'> Search by Date</p>
            <div className='d-flex flex-row col-12 col-md-12 px-0'>
              <span className='col-6 pl-0 pr-2'>
                <DatePickerComponent
                  className='search-field e-start-time'
                  value={''}
                  data-name='StartTime'
                  showClearButton={false}
                  placeholder='Start Time'
                />
              </span>
              <span className=' col-6 px-0'>
                <DatePickerComponent
                  className='search-field e-end-time'
                  value={''}
                  data-name='EndTime'
                  showClearButton={false}
                  placeholder='End Time'
                />
              </span>
            </div>
          </form>
          <div className='d-flex mb-2'>
            <Button
              variant='btn btn-glow-secondary btn-primary float-right text-white button-gap'
              title='Search'
              type='button'
              onClick={searchOnclick}
            >
              Search
            </Button>
            <Button
              variant='btn btn-glow-secondary btn-primary float-right text-white'
              title='Clear'
              type='button'
              onClick={clearOnClick}
            >
              Clear
            </Button>
          </div>
        </div>
      </Popper>
    </div>
  );
};
export default Options;
