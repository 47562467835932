import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Grid } from '@material-ui/core';

import storeConfig from './store';

import Orders from './components/Orders';
import OrdersSummary from './components/OrdersSummary';
import DatePickers from './components/DatePickers';
import ClientFilter from './components/ClientFilter';

const App = ({
  fetching, orders, token, logo, clientFilter, storeItems, cardUrl, usStates, clinicId
}) => {
  const store = storeConfig(fetching, orders, clientFilter, token, logo, storeItems, cardUrl, clinicId, usStates);

  return (
    <Provider store={store}>
      <div class='layout-content'>
        <div class='container-fluid flex-grow-1 container-p-y'>
          <div class='card'>
            <div class='card-header'>
              <div class='row'>
                <div class='form-group d-inline-flex'>
                  <Grid container spacing={2} className='flex-start flex-md-end'>
                    <Grid item>
                      <ClientFilter />
                    </Grid>
                    <Grid item>
                      <DatePickers />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
            <Grid container>
              <Grid item xs={12} md={3}>
                <OrdersSummary />
              </Grid>
              <Grid item xs={12} md={9}>
                <Orders orderFor='clinic'/>
              </Grid>
            </Grid>
        </div>
      </div>
    </Provider>
  );
};

App.propTypes = {
  fetching: PropTypes.number,
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  token: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  cardUrl: PropTypes.string.isRequired,
  storeItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  usStates: PropTypes.arrayOf(PropTypes.array).isRequired,
};

App.defaultProps = {
  fetching: 0,
};

export default App;
