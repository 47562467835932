import FirstPageIcon from '@mui/icons-material/FirstPage';
import ForumIcon from '@mui/icons-material/Forum';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { sendMessage } from '../../../../actions/messages';
import {
  getUserWLPStats, updateUserWLPStatus
} from '../../../../actions/weight_loss';
import { getCurrentPhase, getMealLogs, getSnackLogs, userWLPStatusDropDownOptions
} from '../../../../helpers/weight_loss';
import DropDown from '../../../common/DropDown';
import FilterSelect from '../../../common/FilterSelect';
import TemplateMessages from './TemplateMessages';
import WeightLossGraph from '../WeightLossGraph';
import TimelineIcon from '@mui/icons-material/Timeline';
import ProgressCircleChart from '../ProgressCircleChart';
import { Tooltip } from '@material-ui/core';
const PatientWLPDetail = (props) => {
  const {
    clinicId, csrfToken, templateMessagesList, setTemplateMessagesList,
  } = props;
  const [userWeightLossList, setUserWeightLossList] = useState([]);
  const [userId, setUserId] = useState([]);
  const [instantMessage, setMessage] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    userWLPStatusDropDownOptions[0],
  );
  const [show, setShow] = useState(false);
  const [message, setCustomMessage] = useState(false);
  const [showImPopUp, setShowImPopUp] = useState(false);
  const [graphData, setGraphData] = useState({ x: [], y: [] });
  const [isGraphModalOpen, setIsGraphModalOpen] = useState(false);
  const [goalAchieved, setGoalAchieved]= useState(0);

  const handleClose = () => {
    setShow(false);
    setShowImPopUp(false);
    setMessage('');
    setSelectedMessage(null);
  };

  const handleShow = (id, createNewMessage = true) => {
    setCustomMessage(createNewMessage);
    setUserId(id);
    setShow(true);
  };
  
  const handleShowIm = (id) => {
    setUserId(id);
    setShowImPopUp(true);
  };

  const handleMessageSend = (message) => {
    sendMessage((instantMessage || message), clinicId, userId, true, csrfToken);
    handleClose();
  };


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelect = (option, data) => {
    switch (option) {
      case 'Mark Reviewed':
        updateUserWLPStatus(
          clinicId,
          data.id,
          setUserWeightLossList,
          csrfToken
        );
        break;
      case 'Custom Message':
        handleShow(data.user_id, false);
        break;
      case 'Instant Message':
        handleShowIm(data.user_id);
        break;
      default:
        break;
    }
  };

  const generateGraphData = row => {
    const dailyLogs = [];
    const targetLogs = [];
    row.logs.forEach(log => {
      dailyLogs.push({ x: new Date(log.date).toDateString(), y: log.weight });
      targetLogs.push({
        x: new Date(log.date).toDateString(),
        y: log.target_weight || 0
      });
    });
    return [dailyLogs, targetLogs];
  };

  const openGraphModal = (row) => {
    setGoalAchieved(row.goal_achieved);
    const data = generateGraphData(row);
    setGraphData({ x: data[0], y: data[1] });
    setIsGraphModalOpen(true)
  }

  const closeGraphModal = () => {
    setIsGraphModalOpen(false)
    setGraphData({ x: [], y: [] });
  }

  useEffect(() => {
    getUserWLPStats(clinicId, setUserWeightLossList, selectedOption.value);
  }, [clinicId, selectedOption, message]);

  const handleRowClick = (id) => {
    const clinicName = window.location.pathname.split('/')[1];
    const origin = window.location.origin;
    window.location.href = `${origin}/${clinicName}?patientId=${id}`
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            <Tooltip title='View Graph'>
              <IconButton size='small' onClick={() => openGraphModal(row)}>
                <TimelineIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell
            component='th'
            scope='row'
            onClick={() => handleRowClick(row.user_id)}
          >
            <div className='weight-loss' style={{ display: 'inline-flex' }}>
              {row.patient_name}
              {row.last_message_recieved && (
                <Tooltip title={row.last_message_recieved}>
                  <ForumIcon sx={{ m: 1 }}>Default Width [300px]</ForumIcon>
                </Tooltip>
              )}
            </div>
          </TableCell>
          <TableCell align='right'>{row.daily_change}</TableCell>
          <TableCell align='right'>{row.last_day_logged}</TableCell>
          <TableCell align='right'>{row.days_on_program}</TableCell>
          <TableCell align='right'>{row.total_weight_loss ?? '--'}</TableCell>
          <TableCell align='right'>
            {(row.total_weight_loss / row.logs.length).toFixed(2) ?? '--'}
          </TableCell>
          <TableCell align='right'>{row.target_weight ?? '--'}</TableCell>
          <TableCell align='right'>{row.goal_achieved ?? '--'}</TableCell>
          <TableCell align='right'>
            <DropDown
              dropDownOptions={
                selectedOption.value != userWLPStatusDropDownOptions[1].value
                  ? ['Mark Reviewed', 'Custom Message', 'Instant Message']
                  : ['Custom Message', 'Instant Message']
              }
              data={row}
              onSelect={handleSelect}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  Logs History
                </Typography>
                <Table size='small' aria-label='purchases'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='right'>Date</TableCell>
                      <TableCell>Weight&nbsp;(LB)</TableCell>
                      <TableCell>Water Intake&nbsp;(oz)</TableCell>
                      <TableCell align='right'>Sleep&nbsp;(HRS)</TableCell>
                      <TableCell align='right'>Meal Log</TableCell>
                      <TableCell align='right'>Snack Log</TableCell>
                      <TableCell align='right'>Blood Sugar</TableCell>
                      <TableCell align='right'>Blood Pressure</TableCell>
                      <TableCell align='right'>Current Phase</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderBy(row.logs, ['date'], ['desc']).map((log, index) => (
                      <TableRow key={log.id}>
                        <TableCell align='right'>
                          {new Date(log.date).toLocaleDateString('en-US')}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {log.weight}
                        </TableCell>
                        <TableCell>
                          {parseFloat(log.water_intake).toFixed(2)}
                        </TableCell>
                        <TableCell align='right'>
                          {log.sleeping_hours}
                        </TableCell>
                        <Tooltip placement='top' title={getMealLogs(log)}>
                        <TableCell className='truncate-text' align='right'>
                          {getMealLogs(log)}
                        </TableCell>
                        </Tooltip>
                        <Tooltip placement='top' title={getSnackLogs(log)}>
                          <TableCell className='truncate-text' align='right'>
                            {getSnackLogs(log)}
                          </TableCell>
                        </Tooltip>
                        <TableCell align='right'>{log.blood_sugar}</TableCell>
                        <TableCell align='right'>
                          {log.blood_pressure}
                        </TableCell>
                        <TableCell align='right'>
                          {getCurrentPhase(row, log.date)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  function TablePaginationActions(props) {
    const theme = useTheme();
    const {
      count, page, rowsPerPage, onPageChange,
    } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  return (
    <div className='flex-grow-1 container-p-y'>
      <div className='card pb-3 pb-md-0'>
        <div className='d-flex justify-content-between'>
          <div className='card-header px-2 px-md-4'>
            <h4 className='font-weight-bold wlp-heading'>
              Clients Weight Loss Details
            </h4>
            <FilterSelect
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              dropDownOptions={userWLPStatusDropDownOptions}
            />
          </div>
          <div className='card-header px-2 px-md-4'>
            <div className='float-right'>
              <button
                className='btn btn-sm-secondary btn-primary mr-1 btn-responsive mb-2 mb-md-0'
                onClick={() => {
                  handleShow();
                }}
              >
                Create Custom Message
              </button>
            </div>
          </div>
        </div>
        <div className='card-body p-0 px-2 px-md-0'>
          <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell>Client</TableCell>
                  <TableCell align='right'>Daily Change(LB)</TableCell>
                  <TableCell align='right'>Last Day Logged</TableCell>
                  <TableCell align='right'>Days On Program</TableCell>
                  <TableCell align='right'>Overall Loss&nbsp;(LB)</TableCell>
                  <TableCell align='right'>
                    Average Loss/Day&nbsp;(LB)
                  </TableCell>
                  <TableCell align='right'>Target Weight&nbsp;(LB)</TableCell>
                  <TableCell align='right'>Goal Achieved&nbsp;(%)</TableCell>
                  <TableCell align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userWeightLossList.map(
                  row => row.logs.length != 0 && <Row key={row.id} row={row} />
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      "ALL",
                      { label: 'All', value: -1 }
                    ]}
                    colSpan={12}
                    count={userWeightLossList.length}
                    rowsPerPage={rowsPerPage}
                    className='mb-0 mt-0'
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page'
                      },
                      native: true
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size='lg' className=''>
        <Modal.Body className='p-0'>
          <TemplateMessages
            clinicId={clinicId}
            csrfToken={csrfToken}
            handleMessageSend={handleMessageSend}
            templateMessagesList={templateMessagesList}
            setTemplateMessagesList={setTemplateMessagesList}
            istrue={message}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showImPopUp} onHide={handleClose} size='sm' className=''>
        <Modal.Header closeButton>
          <Modal.Title>Send Instant Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <label text='name'>Message:</label>
            <textarea
              onChange={e => setMessage(e.target.value)}
              value={instantMessage}
              className='form-control'
              placeholder='Enter Message...'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleMessageSend}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isGraphModalOpen}
        onHide={closeGraphModal}
        size='xl'
        centered
        disableScrollLock
      >
        <Modal.Body>
          <div className='d-flex'>
            <span className='mr-md-4 weight-loss-graph weight-loss-chart'>
              <WeightLossGraph x={graphData.x} y={graphData.y} />
            </span>
            <span className='weight-loss-graph progressive-chart'>
              <ProgressCircleChart progress={goalAchieved ? goalAchieved : 0} />
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatientWLPDetail;
