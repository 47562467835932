import React from "react";
import { Button, Modal } from "react-bootstrap";
import PhasesCard from "./PhasesCard";

const PhasesModal = (props) => {
  const { handleClose, userWlProgram, show } = props;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Body className="container-p-0">
        {userWlProgram?.weight_loss_program?.phases?.map((phase, phaseIndex) => (
          <PhasesCard phase={phase} phaseIndex={phaseIndex} />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PhasesModal;
