import React from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { filterClient } from '../actions/clientFilter'

const ClientFilter = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);

  const clients = [...new Set(orders.map(order => order.client))];

  return (
    <Autocomplete
      options={clients}
      style={{ width: 300 }}
      onChange={(event, newValue) => { dispatch(filterClient(newValue)) }}
      renderInput={(params) => <TextField {...params} label="Client Filter" variant="outlined" />}
    />
  )
}

export default ClientFilter