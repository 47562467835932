import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper, Table, TableContainer, TablePagination,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import IndexTableHeader from './IndexTableHeader';
import IndexTableHead from './IndexTableHead';
import IndexTableBody from './IndexTableBody';
import { indexHeadCells } from '../../helpers/patients';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const IndexTable = ({ orderStatus, setCurrentOrder }) => {
  const orders = useSelector(state => state.patientIndex.orders);

  const classes = useStyles();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleResetFilters = () => {
    setOrder('desc');
    setOrderBy('created_at');
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    let selectedRows = event.target.value === 'ALL' ? orders.length : parseInt(event.target.value, 10);
    selectedRows = Math.min(selectedRows, orders.length);
    selectedRows = Math.max(selectedRows, 1); // Ensure at least 1 row is shown
    setRowsPerPage(selectedRows);
    setPage(0);
  };  

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <IndexTableHeader resetFilters={handleResetFilters} />
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            size='medium'
            aria-label='enhanced table'
          >
            <IndexTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              columns={indexHeadCells}
              onRequestSort={handleRequestSort}
            />
            <IndexTableBody
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              classes={classes}
              openOrder={orderStatus}
              setOrder={setCurrentOrder}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, "ALL"]}
          component='div'
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

IndexTable.propTypes = {
  orderStatus: PropTypes.func.isRequired,
  setCurrentOrder: PropTypes.func.isRequired,
};

export default IndexTable;
