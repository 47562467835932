import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  TableContainer,
  TableBody,
  Typography,
  Table,
  Toolbar,
  Dialog
} from '@material-ui/core';

import OrdersSummaryRow from './OrdersSummaryRow';
import PendingFundTable from '../../orders/PendingFundTable';
import Order from '../../orders/Order';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}));

const getCategoryTotal = (orders, category) =>
  orders.reduce((sum, currentOrder) => {
    const categoryItems = currentOrder.order_items.filter(
      item => item.item_category === category
    );
    return (
      categoryItems.reduce(
        (itemSum, currentItem) =>
          parseFloat(currentItem.price) * currentItem.qty + itemSum,
        0
      ) + sum
    );
  }, 0);

const sumOrdersProperty = (orders, property) =>
  orders.reduce(
    (sum, currentOrder) => sum + parseFloat(currentOrder[property]),
    0
  );

const OrdersSummary = () => {
  const orders = useSelector(state => state.orders);
  const clientFilter = useSelector(state => state.clientFilter.clientFilter);
  const [pendingFunds, setPendingFunds] = useState(false);
  const [totalPendingFunds, setTotalPendingFunds] = useState(0);
  const [order, setOrder] = useState(null);
  const [orderStatus, setOrderStatus] = useState(false);

  const handleOrder = useCallback(() => {
    if (orderStatus) setOrder(null);
    setOrderStatus(prevStatus => !prevStatus);
  }, [orderStatus]);

  useEffect(() => {
    const total = orders.reduce(
      (sum, order) => sum + parseFloat(order.total),
      0
    );
    setTotalPendingFunds(total);
  }, [orders]);

  const relevantOrders = useMemo(
    () =>
      orders.filter(
        order =>
          (order.status === 1 || order.status === 3) &&
          (!clientFilter || order.client === clientFilter)
      ),
    [orders, clientFilter]
  );

  const ordersPending = useMemo(
    () =>
      orders.filter(
        order =>
          order.status === 0 && (!clientFilter || order.client === clientFilter)
      ),
    [orders, clientFilter]
  );

  const handleFundClose = useCallback(() => {
    setPendingFunds(prev => !prev);
  }, []);

  const classes = useStyles();

  const packageTotal = useMemo(
    () => getCategoryTotal(relevantOrders, 'package'),
    [relevantOrders]
  );
  const productTotal = useMemo(
    () => getCategoryTotal(relevantOrders, 'product'),
    [relevantOrders]
  );
  const serviceTotal = useMemo(
    () => getCategoryTotal(relevantOrders, 'service'),
    [relevantOrders]
  );
  const weightLossProgramTotal = useMemo(
    () => getCategoryTotal(relevantOrders, 'weight_loss_program'),
    [relevantOrders]
  );

  const totalSubtotal = useMemo(
    () => sumOrdersProperty(relevantOrders, 'subtotal'),
    [relevantOrders]
  );
  const totalTax = useMemo(
    () => sumOrdersProperty(relevantOrders, 'taxes'),
    [relevantOrders]
  );
  const totalDiscount = useMemo(
    () => sumOrdersProperty(relevantOrders, 'discounts'),
    [relevantOrders]
  );
  const refund = useMemo(
    () => sumOrdersProperty(relevantOrders, 'refund_amount'),
    [relevantOrders]
  );
  const total = useMemo(
    () => sumOrdersProperty(relevantOrders, 'amount_paid') - refund,
    [relevantOrders, refund]
  );

  return (
    <>
      <Dialog
        open={orderStatus}
        className={classes.dialog}
        onClose={handleOrder}
        classes={{ paper: classes.paper }}
      >
        <Order order={order} close={handleOrder} orderFor={'clinic'} type={1} />
      </Dialog>
      <Dialog
        open={pendingFunds}
        className={`${classes.dialog}`}
        onClose={handleFundClose}
        classes={{ paper: classes.paper }}
      >
        <PendingFundTable
          data={ordersPending}
          orderStatus={handleOrder}
          setCurrentOrder={setOrder}
        />
      </Dialog>
      <Grid className={`${classes.root} px-0 px-md-2`}>
        <Paper className={classes.paper}>
          <Toolbar>
            <Typography variant='h6'>Accounts Receivable Report</Typography>
          </Toolbar>
          <TableContainer>
            <Table>
              <TableBody onClick={handleFundClose}>
                <OrdersSummaryRow
                  label='Total Pending Amount'
                  value={totalPendingFunds}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid className={`${classes.root} px-0 px-md-2`}>
        <Paper className={classes.paper}>
          <Toolbar>
            <Typography variant='h6'>Paid Orders</Typography>
          </Toolbar>
          <TableContainer>
            <Table>
              <TableBody>
                <OrdersSummaryRow label='Products' value={productTotal} />
                <OrdersSummaryRow label='Services' value={serviceTotal} />
                <OrdersSummaryRow label='Packages' value={packageTotal} />
                <OrdersSummaryRow
                  label='Weight Loss Programs'
                  value={weightLossProgramTotal}
                />
                <OrdersSummaryRow label='Subtotal' value={totalSubtotal} />
                <OrdersSummaryRow label='Discounts' value={totalDiscount} />
                <OrdersSummaryRow label='Refund' value={refund} />
                <OrdersSummaryRow label='Taxes' value={totalTax} />
                <OrdersSummaryRow label='Total' value={total} />
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
};

export default OrdersSummary;
