import React from 'react'
import { connect } from 'react-redux'

import MeasurementDifferenceSummary from './MeasurementDifferenceSummary'
import MeasurementSummary from './MeasurementSummary'

class MeasurementComparison extends React.Component {

	render() {
		const measurements = this.props.patient.measurements;
		const startId = this.props.patient.measurementCompareStartId;
		const endId = this.props.patient.measurementCompareEndId;
		let startMeasurement, endMeasurement;

		if (startId && endId)
		{
			for(var i = 0; i < measurements.length; i++)
			{
				if(measurements[i].id == startId){
					startMeasurement = measurements[i];
				}
				else if(measurements[i].id == endId){
					endMeasurement = measurements[i];
					break;
				}
			}
		}

		return (
			<div>
				{ startMeasurement && endMeasurement &&
					<>
						<MeasurementDifferenceSummary measurements={[startMeasurement, endMeasurement]} />
						<div className="row">
							<div className="col-md-6">
								<MeasurementSummary measurement={startMeasurement} />
							</div>
							<div className="col-md-6">
								<MeasurementSummary measurement={endMeasurement} />
							</div>
						</div>
					</>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		startId: state.patientIndex.measurementCompareStartId,
		endId:   state.patientIndex.measurementCompareEndId
	}
}

export default connect(
	mapStateToProps,
	null
)(MeasurementComparison)
