import fetch from 'cross-fetch'

export const onNewMeasurementSubmit = (date, leftArm, rightArm, neck, leftThigh, rightThigh, leftCalf, rightCalf, hips, aboveBb, belowBb, bellyButton, chest, bloodPressure, weight, bmi, bodyFat, leanMuscle, water, metabolicAge, visceralFat, profileImage, supplementalImages,files, patientId, clinicId, measurementId) => {
	return function(dispatch, getState) {
		dispatch(requestNewMeasurment());


		const state = getState().patientIndex;

		let formData = new FormData();
		for (var i = 0; i < supplementalImages.length; i++) {
			formData.append("measurement[supplemental_images["+i+"]]", supplementalImages[i]);
		}
		for (let i = 0; i < files.length; i++) {
			formData.append(
				'measurement[files[' + i + ']]',
				files[i]
			);
		}

		formData.append('measurement[date]', date);
		formData.append('measurement[left_arm]', leftArm);
		formData.append('measurement[right_arm]', rightArm);
		formData.append('measurement[neck]', neck);
		formData.append('measurement[left_thigh]', leftThigh);
		formData.append('measurement[right_thigh]', rightThigh);
		formData.append('measurement[left_calf]', leftCalf);
		formData.append('measurement[right_calf]', rightCalf);
		formData.append('measurement[hips]', hips);
		formData.append('measurement[above_bb]', aboveBb);
		formData.append('measurement[below_bb]', belowBb);
		formData.append('measurement[bellybutton]', bellyButton);
		formData.append('measurement[chest]', chest);
		formData.append('measurement[blood_pressure]', bloodPressure);
		formData.append('measurement[weight]', weight);
		formData.append('measurement[bmi]', bmi);
		formData.append('measurement[body_fat]', bodyFat);
		formData.append('measurement[lean_muscle]', leanMuscle);
		formData.append('measurement[water]', water);
		formData.append('measurement[metabolic_age]', metabolicAge);
		formData.append('measurement[visceral_fat]', visceralFat);
		if (profileImage[0])
		{
			formData.append('measurement[profile_image][0]', profileImage[0]);
		}

		let url =  window.origin + '/' + clinicId + '/clients/' + patientId;
		let verb;

		if (measurementId) {
			url += '/measurements/' + measurementId;
			verb = 'PATCH'
		}
		else {
			url += '/measurements.json';
			verb = 'POST';
		}

		return fetch(url, {
			method: verb,
			headers: {
				Accept: 'application/json',
				'X-CSRF-Token': state.csrfToken
			},
			processData: false,
			contentType: false,
			body: formData
		}).then((response) => response.json())
		.then(responseJson => {
			dispatch(receiveNewMeasurementSuccess(responseJson.measurements, responseJson.patient_id));
		})
	}
}

export const requestNewMeasurment = () => {
	return {
		type: 'REQUEST_NEW_MEASUREMENT',
	}
}

export const receiveNewMeasurementSuccess = (measurements, patientId) => {
	return {
		type: "RECEIVE_NEW_MEASUREMENT_SUCCESS",
		measurements,
		patientId
	}
}

export const showMeasurementEditForm = measurement => {
	return {
		type: "SHOW_MEASUREMENT_EDIT_FORM",
		measurement
	}
}

export const toggleMeasurementForm = () => {
	return {
		type: "TOGGLE_MEASUREMENT_FORM"
	}
}

export const selectMeasurementCompare = (start, end, patientId) => {
	return {
		type: "SELECT_MEASUREMENT_COMPARE",
		start,
		end,
		patientId
	}
}

export const onDeleteProfileImage = measurementId => {
	return function(dispatch, getState) {
		const state = getState().patientIndex;
		if (state.clinicId && state.activePatient) {
			dispatch(requestNewMeasurment());

			let url =  new URL(window.origin + '/' + state.clinicId + '/clients/' + state.activePatient + '/measurements/' + measurementId);
			let params = {profile_image: true}
			Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

			return fetch(url, {
				method: "DELETE",
				headers: {
					Accept: 'application/json',
					contentType: 'application/json',
					'X-CSRF-Token': state.csrfToken
				}
			}).then(response => response.json())
			.then(responseJson =>
				dispatch(receiveNewMeasurementSuccess(responseJson.measurements, responseJson.patient_id))
			)
		}
	}
}

export const onDeleteSupplementaryImage = (measurementId, blobUrl) => {
	return function(dispatch, getState) {
		const state = getState().patientIndex;
		if (state.clinicId && state.activePatient) {
			dispatch(requestNewMeasurment());

			let url =  new URL(window.origin + '/' + state.clinicId + '/clients/' + state.activePatient + '/measurements/' + measurementId);
			let params = {supplemental_image: blobUrl}
			Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

			return fetch(url, {
				method: "DELETE",
				headers: {
					Accept: 'application/json',
					contentType: 'application/json',
					'X-CSRF-Token': state.csrfToken
				}
			}).then(response => response.json())
			.then(responseJson =>
				dispatch(receiveNewMeasurementSuccess(responseJson.measurements, responseJson.patient_id))
			)
		}
	}
}

export const onDeleteFile = (measurementId, blobUrl) => {
  return function (dispatch, getState) {
    const state = getState().patientIndex;
    if (state.clinicId && state.activePatient) {
      dispatch(requestNewMeasurment());

      let url = new URL(
        window.origin +
          '/' +
          state.clinicId +
          '/clients/' +
          state.activePatient +
          '/measurements/' +
          measurementId
      );
      let params = { file: blobUrl };
      Object.keys(params).forEach(key =>
        url.searchParams.append(key, params[key])
      );

      return fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          contentType: 'application/json',
          'X-CSRF-Token': state.csrfToken
        }
      })
        .then(response => response.json())
        .then(responseJson =>
          dispatch(
            receiveNewMeasurementSuccess(
              responseJson.measurements,
              responseJson.patient_id
            )
          )
        );
    }
  };
};
