import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

const DataGridMUI = (props) => {
  const { list, columns, pageSize = 5, handlePageSizeChange = () => {} } = props;
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={list}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={newPageSize =>
          handlePageSizeChange(newPageSize)
        }
        rowsPerPageOptions={[5, 10, 20]}
        pagination
      />
    </div>
  );
};

export default DataGridMUI;
