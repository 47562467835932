import fetch from 'cross-fetch'

export const fetchPatientList = (clinicId, type) =>
{
	return function(dispatch) {

		dispatch(requestPatientList())

		return fetch(window.origin + '/' + clinicId + '/clients.json')
		.then((response) => response.json())
		.then(responseJson =>{
			dispatch(receivePatientListSuccess(responseJson.patients, responseJson.available_clinic_packages))
			if (type === 'unread') {
        dispatch(filterPatientList('', true, false, false));
      }
		})
	}
}

export const toggleActivePatient = (clinicId, patientId) =>
{
	return function(dispatch) {

		dispatch(requestPatient())

		return fetch(window.origin + '/' + clinicId + '/clients/' + patientId )
		.then((response) => response.json())
		.then(responseJson => {
			dispatch(receivePatientSuccess(responseJson.patient))
		})
	}
}

export const requestPatientList = () => {
	return {
		type: "REQUEST_PATIENT_LIST"
	}
}

export const receivePatientListSuccess = (patients, availableClinicPackages) => {
	return {
		type: "RECEIVE_PATIENT_LIST_SUCCESS",
		patients,
		availableClinicPackages
	}
}

export const requestPatient = () => {
	return {
		type: "REQUEST_PATIENT"
	}
}

export const receivePatientSuccess = patient => {
	return {
		type: "RECEIVE_PATIENT_SUCCESS",
		patient
	}
}

export const filterPatientList = (filter, unreadMessages, archived, enrolled) => {
  return {
    type: 'FILTER_PATIENT_LIST',
    filter,
    unreadMessages,
    archived,
    enrolled
  };
};

export const unreadPatientList = (
  filter,
  unreadMessages,
  archived,
  enrolled
) => {
  return {
    type: 'UNREAD_FILTER_LIST',
    filter,
    unreadMessages,
    archived,
    enrolled
  };
};

export const requestNewContactInfoSubmit = () => {
	return {
		type: "REQUEST_NEW_CONTACT_INFO_SUBMIT"
	}
}

export const onContactInfoSubmit = (name, email, address, city, countryState, zipcode, phone_number, archive = false) => {
	return function(dispatch, getState) {
		dispatch(requestNewContactInfoSubmit());

		const state = getState().patientIndex;

		let url = window.origin + '/' + state.clinicId + '/clients/' + state.activePatient;
		let verb = 'PATCH';

		return fetch(url, {
      method: verb,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': state.csrfToken
      },
      body: JSON.stringify({
        patient: {
          name,
          email,
          address,
          city,
          state: countryState,
          zipcode,
          phone_number,
          archive
        }
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        dispatch(receiveNewContactInfoSuccess(responseJson.patient));
        dispatch(
          fetchPatientList(
            responseJson.patient.clinic_id,
          )
        );
      });
	}
}

export const receiveNewContactInfoSuccess = patient => {
	return {
		type: "RECEIVE_NEW_CONTACT_INFO_SUCCESS",
		patient
	}
}
