import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import SignatureInput from '../common/SignatureInput';
import Loader from '../common/Loader';
import { uploadFileToCloudinary } from '../../lib/cloudinary';

const FormView = ({
  csrfToken,
  clinicId,
  dynamicIntakeForm,
  redirectUrl,
  campaignsList,
  formResponse
}) => {
  const [formData, setFormData] = useState(() => {
    return dynamicIntakeForm.form_data.map(jsonStr => {
      const cleanedJsonStr = jsonStr
        .replace(/"=>/g, '":')
        .replace(/\\\"/g, '"');
      return JSON.parse(cleanedJsonStr);
    });
  });

  const [reasonFields, setReasonFields] = useState({ ...formResponse?.response.data?.reason_fields });
  const [selectedOption, setSelectedOption] = useState(
    formResponse ? formResponse.response.data.status : 'new'
  );
  const [signature, setSignature] = useState( () => formResponse ? formResponse.response.data.signature : "");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendSms, setSendSms] = useState( () => formResponse ? formResponse.response.data.send_sms : true);
  const [isSignUpdate, setIsSignUpdate] = useState(false);
  const handleChangeForm = event => {
    const { value, type, name } = event.target;

    if (type == 'checkbox') {
      // Key exists, so remove it
      if (reasonFields[name] && reasonFields[name].hasOwnProperty(value)) {
        const copyObject = { ...reasonFields[name] };
        delete copyObject[value];
        setReasonFields(prevState => ({
          ...prevState,
          [name]: { ...copyObject }
        }));
      } else {
        setReasonFields(prevState => ({
          ...prevState,
          [name]: { ...reasonFields[name], [value]: '' }
        }));
      }
    }
  };
  const handleSignature = (file) => {
    setIsSignUpdate(true);
    setSignature(file)
  }

  const handleReasonChange = (event, choice) => {
    const { name, value } = event.target;
    setReasonFields(prevState => ({
      ...prevState,
      [name]: { ...reasonFields[name], [choice]: value }
    }));
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setReasonFields(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  

  const handleFormSubmit = async values => {
    setIsSubmit(true);
    if (signature == '') return;
    setIsLoading(true);

    try {

      const signatureUrl = !isSignUpdate
        ? signature
        : await uploadFileToCloudinary(signature);
      setIsLoading(false);
      let method = 'post';
      let url = `/${clinicId}/patient_responses`;
      if (formResponse && Object.keys(formResponse).length > 0) {
        method = 'put';
        url = `/${clinicId}/patient_responses/${formResponse.id}`;
      }

      await axios[method](
        url,
        {
          patient_response: {
            response: {
              data: {
                ...values,
                reason_fields: reasonFields,
                signature: signatureUrl,
                send_sms: sendSms,
                status: selectedOption
              }
            },
            dynamic_intake_form_id: dynamicIntakeForm.id,
            campaign_id: dynamicIntakeForm.campaign_id,
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          }
        }
      )
        .then(response => {
         const message =  formResponse
            ? 'Form updated successfully'
            : 'Form created successfully';
          toast.success(message);
          window.location.href = redirectUrl;
        })
        .catch(error => {
          console.error(error.response.data);
          toast.error(error.data.message);
        });
      setIsSubmit(false);
    } catch (error) {}
  };

  const initialValues = formResponse
    ? formResponse.response.data
    : formData.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {});

  const renderInputField = field => {
    if (field.type == 'checkbox')
      return (
        <div
          className={`pt-3 form-builder-grid ${
            field.choices.length > 10 ? 'flex-row flex-wrap' : 'flex-column'
          }`}
        >
          {field.choices.map((choice, index) => (
            <div className='d-flex flex-column'>
              <label
                key={`${index}-${choice}`}
                htmlFor={choice}
                className='d-flex mr-3'
              >
                <Field
                  validate={field.required && isRequired}
                  id={choice}
                  type='checkbox'
                  name={field.name}
                  value={choice}
                  style={{
                    marginRight: '10px',
                    width: '1.2rem',
                    height: '1.2rem',
                    cursor: 'pointer'
                  }}
                />
                {choice}
              </label>

              {reasonFields[field.name] &&
                choice in reasonFields[field.name] && (
                  <Field
                    type='text'
                    placeholder='Reason'
                    name={field.name}
                    value={reasonFields[field.name][choice]}
                    onChange={e => handleReasonChange(e, choice)}
                    className='form-control mb-3 dynamic-form-input'
                  />
                )}
            </div>
          ))}
        </div>
      );
    if (field.type == 'radio')
      return (
        <div className='pt-3 form-builder-grid' style={{ display: 'flex', flexDirection: 'column' }}>
          {field.choices.map((choice, index) => (
            <label
              key={`${index}-${choice}`}
              htmlFor={choice}
              className='d-flex'
            >
              <Field
                validate={field.required}
                id={choice}
                type='radio'
                value={choice}
                name={field.name}
                checked={reasonFields[field.name] === choice}
                style={{ marginRight: '10px', width: '1.2rem' }}
                onChange={e => handleRadioChange(e, choice)}
              />
              {choice}
            </label>
          ))}
        </div>
      );
      
    if (field.type == 'heading')
      return (
        <div className='card-title'>
          <h4
            className={`form-builder-heading ${
              field.text_decoration?.italic ? 'font-italic' : ''
            } ${field.text_decoration?.underline ? 'font-underline' : ''} ${
              field.text_decoration?.bold ? 'font-bold' : ''
            }`}
          >
            {field.title}
          </h4>
        </div>
      );

    if (field.type == 'email')
      return (
        <Field
          validate={field.required && isRequired}
          type='email'
          className='form-control dynamic-form-input'
          disabled={formResponse && Object.keys(formResponse).length > 0}
          name={field.name}
        />
      );

    return (
      <Field
        validate={field.required && isRequired}
        type={field.type == 'number' ? 'number' : 'text'}
        name={field.name}
        className='form-control dynamic-form-input'
      />
    );
  };

  const isRequired = value => {
    let error;
    if (Array.isArray(value) && value.length === 0) {
      return 'Field is requireds';
    } else if (value === '') {
      return 'Field is required';
    }
    return error;
  };

  const handleDropdownChange = async (event) => {
    const newStatus = event.target.value;
    setSelectedOption(newStatus);
  };

  const resendInvite = async () => {
    try {
      await axios.put(
        `/${clinicId}/patient_responses/${window.location.href.match(/\/(\d+)\//)[1]}/update_status`,{},
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
        }
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };


  return (
    <div className='card m-3 p-4' style={{ position: 'relative' }}>
      {isLoading && <Loader />}
      <div className='card-header mb-5'>
        <div className='d-flex align-items-center justify-content-between mb-5'>
          {dynamicIntakeForm.logo_url && (
            <img className='logo-display' src={dynamicIntakeForm.logo_url} />
          )}
          {dynamicIntakeForm.helpline && (
            <div className='dynamic-form-helpine'>
              Need Help? Call: <b>{dynamicIntakeForm.helpline}</b>
            </div>
          )}
        </div>
        <h4
          className='font-weight-bold text-center d-block'
          style={{ flex: 1 }}
        >
          {dynamicIntakeForm.form_name}
        </h4>
      </div>
      <div className='card-body'>
        <div className='row justify-content-end mb-2'>
          <div className='col-md-auto'>
            <div className='form-group'>
              <select
                value={selectedOption}
                onChange={handleDropdownChange}
                className='form-control mt-2'
              >
                <option value='new'>New</option>
                <option value='scheduled'>Scheduled</option>
                <option value='enrolled'>Enrolled</option>
                <option value='contact_later'>Contact Later</option>
                <option value='do_not_contact'>do_not_contact</option>
              </select>
            </div>
          </div>
          {formResponse && (
            <div className='col-md-auto float-right mt-2 pr-1'>
              <button type='button' className='btn btn-outline-warning' onClick={resendInvite}>
                Resend Invitation
              </button>
            </div>
          )}
        </div>

        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
          {({ errors, touched }) => (
            <Form onChange={handleChangeForm}>
              <div className='flex-container'>
                {formData.map(field => {
                  if (['sendSms', 'signature'].includes(field.name)) return;
                  else {
                    return (
                      <label
                        key={field.name}
                        htmlFor='title'
                        className={`w-100 mb-4 flex-col-${field.colspan || 12}`}
                      >
                        {!['heading', 'buttom'].includes(field.type) && (
                          <label
                            className={`mb-2 ${
                              field.text_decoration?.italic ? 'font-italic' : ''
                            } ${
                              field.text_decoration?.underline
                                ? 'font-underline'
                                : ''
                            } ${
                              field.text_decoration?.bold ? 'font-bold' : ''
                            }`}
                          >
                            {field.title}{' '}
                            {field.required ? (
                              <span style={{ color: 'red' }}>*</span>
                            ) : null}
                          </label>
                        )}
                        {renderInputField(field)}
                        {errors[field.name] && touched[field.name] ? (
                          <div style={{ color: 'red', fontSize: '12px' }}>
                            {errors[field.name]}
                          </div>
                        ) : null}
                      </label>
                    );
                  }
                })}
                <div className={`flex-col-${12}`}>
                  <label className='mr-3 d-flex items-center'>
                    <Field
                      type='checkbox'
                      checked={sendSms}
                      style={{
                        marginRight: '10px',
                        width: '1.2rem',
                        height: '1.2rem'
                      }}
                      onChange={() => setSendSms(!sendSms)}
                    />
                    {formData.find(field => field.name == 'sendSms').title}
                  </label>
                </div>
                <div className={`flex-col-${12}`}>
                  <label className='mb-3'>
                    {formData.find(field => field.name == 'signature').title}
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <SignatureInput
                    onChange={handleSignature}
                    isSubmit={isSubmit}
                    initialSignature={signature}
                  />
                </div>
                <div
                  className={`d-flex justify-content-between align-items-center flex-col-${12}`}
                >
                  <button
                    className='btn btn-outline-primary mb-2 mt-2 '
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer />
    </div>
  );
};

export default FormView;
