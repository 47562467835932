import React, { useState } from 'react';
import PhasesModal from './PhasesModal';
import AttachmentsModal from './AttachmentsModal';

const WLPMessage = props => {
  const { message, userWlProgram, showPhasesButton } = props;
  const [showPhasesModal, setShowPhasesModal] = useState(false);
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);

  const handleClosePhasesModal = () => setShowPhasesModal(false);
  const handleShowPhasesModal = () => setShowPhasesModal(true);

  const handleCloseAttachmentsModal = () => setShowAttachmentsModal(false);
  const handleShowAttachmentsModal = () => setShowAttachmentsModal(true);

  return (
    <div className='row pt-3 pr-3 pl-3 mb-3'>
      <div className='col-12'>
        <div className='card'>
          <div className='card-body d-flex flex-column flex-md-row justify-content-between align-items-start'>
            <div
              className='mb-3 mb-md-0'
              style={{ flex: 1 }}
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
            {showPhasesButton && (
              <div className='d-flex flex-column flex-md-row'>
                <button
                  className='btn btn-custom btn-outline-primary mr-md-2 mb-2 mb-md-0'
                  onClick={handleShowPhasesModal}
                >
                  Show Session Instructions
                </button>
                <button
                  className='btn btn-custom btn-outline-primary'
                  onClick={handleShowAttachmentsModal}
                >
                  View Attachments
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <PhasesModal
        handleClose={handleClosePhasesModal}
        userWlProgram={userWlProgram}
        show={showPhasesModal}
      />
      <AttachmentsModal
        show={showAttachmentsModal}
        handleClose={handleCloseAttachmentsModal}
        pdfFiles={userWlProgram?.weight_loss_program?.pdf_files_urls || []}
      />
    </div>
  );
};

export default WLPMessage;
