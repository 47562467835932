/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  info: {
    display: 'flex',
    width: '100%',
    '& > span': {
      width: '50%',
    },
  },
});

const SearchItemOption = ({ name, price, type }) => {
  const classes = useStyles();

  const formatPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
  const categories = { 1: 'Product', 2: 'Service', 3: 'Package', 4: 'Weight Loss' };
  return (
    <div className={classes.root}>
      <div>
        <h5>{name}</h5>
      </div>
      <div className={classes.info}>
        <span>{formatPrice}</span>
        <span>{categories[type]}</span>
      </div>
    </div>
  );
};

SearchItemOption.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
};
export default SearchItemOption;
