/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Cached as CachedIcon,
  Logout as LogoutIcon
} from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Tooltip, Button, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toggleMeasurementForm } from '../../actions/measurements';
import { markMessagesRead } from '../../actions/messages';
import {
  onContactInfoSubmit,
  toggleActivePatient
} from '../../actions/patients';
import SelectClient from '../../components/common/SelectClient';
import { countUnreadMessages } from '../../lib/patient_utils';
import Assessments from '../assesstments/Assesstments';
import ReferralForm from '../intake_form_referrals/ReferralForm';
import ReferralIndex from '../intake_form_referrals/ReferralIndex';
import Measurements from '../measurements/Measurements';
import Conversation from '../messages/Conversation';
import Orders from '../orders/Orders';
import ActivePackageIndex from '../packages/ActivePackageIndex';
import PastPackages from '../packages/PastPackages';
import Video from '../videos/Video';
import DailyLogs from '../weight_loss/components/DailyLogs';
import PatientContactInfo from './PatientContactInfo';
import PatientSummary from './PatientSummary';
import Index from '../scheduler/Index';
import HealthProgressBar from '../shared/HealthProgressBar';

const PatientDetails = props => {
  const [showButton, setShowButton] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  
  useEffect(() => {
    if (selectedTab === 'intake_form_tab') onIntakeFormClicked();
    if (selectedTab === 'data_tab') setShowButton(true);
    else setShowButton(false);
  }, [props.patient, selectedTab]);

  const onMessagesClicked = () => {
    props.markMessagesRead(
      props.patient.conversation.id,
      props.isOwner,
      props.patient.clinic_id
    );
  };

  const updatePatientArchiveInfo = e => {
    e.preventDefault();
    props.updatePatient(
      props.patient.name,
      props.patient.email,
      props.patient.address,
      props.patient.city,
      props.patient.state,
      props.patient.zipcode,
      props.patient.phone_number,
      !props.patient.archive
    );
  };

  const onIntakeFormClicked = () => {
    const url = `${props.patient.clinic_id}/intake_forms/${props.patient.intake_form.id}`;
    $.ajax({
      url,
      success(response) {
        $('#form-container').empty();
        const content = $(response).find('.card-body').get(0);
        $('#form-container').append(content);
      }
    });
  };

  const onLogout = () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': props.csrfToken
    };

    axios
      .delete(`/users/sign_out`, {
        headers
      })
      .then(response => {
        toastr.success('Logged out successfully');
        window.location.reload();
      })
      .catch(e => {
        toastr.error(e.response.data.message);
      });
  };

  const handleSelect = key => {
    setSelectedTab(key);
    if (key === 'message_tab') onMessagesClicked();
  };

  const hasUnreadMessages =
    props.patient &&
    !props.isFetchingMessageReadRequest &&
    countUnreadMessages(props.patient, props.isOwner) > 0;

  return (
    <div className='patient-details-container'>
      {props.patient ? (
        <>
          <Card id='patient-title-card' className='shadow-sm mb-3'>
            <Card.Body className='pb-2 px-1 px-md-3'>
              <div className='card-title justify-content-between d-flex'>
                <div className='d-flex'>
                  <div className='ml-3 mt-1'>
                    <h5>{props.patient.name}</h5>
                  </div>
                  {props.isOwner && (
                    <CachedIcon
                      fontSize='medium'
                      className='float-left ml-2 cursor-pointer'
                      onClick={() => {
                        props.refreshPatient(
                          props.patient.clinic_id,
                          props.patient.id
                        );
                        toastr.success('Data reloaded successfully');
                      }}
                    />
                  )}
                </div>
                <div className='d-flex flex-column flex-md-row align-items-center'>
                  {showButton && props.isOwner && (
                    <Button
                      variant='outline-secondary'
                      size='sm'
                      className='ml-0 ml-md-2 mb-2 mb-md-0 d-md-inline-block'
                      onClick={props.onNewMeasurementClick}
                    >
                      New Measurement
                    </Button>
                  )}
                  <div className='d-flex align-items-center'>
                    {props.isOwner && (
                      <Button
                        variant={
                          props.patient.archive
                            ? 'outline-success'
                            : 'outline-secondary'
                        }
                        size='sm'
                        className='mr-2 ml-2 btn-responsive'
                        onClick={updatePatientArchiveInfo}
                      >
                        {props.patient.archive
                          ? 'Reactivate Record'
                          : 'Archive Account'}
                      </Button>
                    )}
                    {props.patient.score && (
                      <div className='ml-3'>
                        <h5 className='mb-1'>
                          Health Score: {parseInt(props.patient.score, 10)} pts
                        </h5>
                        <HealthProgressBar
                          progress={parseInt(props.patient.score, 10)}
                        />
                      </div>
                    )}
                    {!props.isOwner && (
                      <Button
                        variant='outline-danger'
                        size='sm'
                        className='ml-3 mt-3 btn-responsive'
                        onClick={onLogout}
                      >
                        <LogoutIcon fontSize='small' className='mr-1' /> Logout
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className='card-text'>
                {props.patient.role === 'scheduler_patient' ? (
                  <Tabs
                    defaultActiveKey='scheduler'
                    className='center-nav-tabs tabs-responsive'
                    onSelect={handleSelect}
                  >
                    <Tab
                      eventKey='scheduler'
                      title='Scheduler'
                      className='tab-content'
                      unmountOnExit
                    >
                      {props.patient.patient_clinic.rooms.length > 0 ? (
                        <Index
                          patients={props.patient}
                          isOwner={false}
                          appointmentTypes={
                            props.patient.patient_clinic
                              .allowed_appointment_types
                          }
                          clinicScheduler={
                            props.patient.patient_clinic.scheduler
                          }
                          rooms={props.patient.patient_clinic.rooms}
                          csrfToken={props.csrfToken}
                          clinicId={props.patient.clinic_id}
                        />
                      ) : (
                        <div className='card'>
                          <div className='card-body'>
                            Create Appointment Types and Rooms first!
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                ) : (
                  <Tabs
                    defaultActiveKey='active_tab'
                    className='center-nav-tabs tabs-responsive'
                    onSelect={handleSelect}
                  >
                    <Tab
                      unmountOnExit
                      eventKey='active_tab'
                      title='Active Packages'
                      className='tab-content'
                    >
                      <ActivePackageIndex patient={props.patient} />
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='data_tab'
                      title='Client Data'
                      className='tab-content'
                    >
                      <PatientSummary patient={props.patient} />
                      <Measurements
                        patient={props.patient}
                        handleToggle={setShowButton}
                      />
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='weight_loss_tab'
                      title='Weight Loss'
                      className='tab-content'
                    >
                      <DailyLogs
                        patient={props.patient}
                        csrfToken={props.csrfToken}
                      />
                    </Tab>
                    {props.patient.patient_clinic.scheduler_available && (
                      <Tab
                        unmountOnExit
                        eventKey='scheduler'
                        title='Scheduler'
                        className='tab-content'
                      >
                        {props.patient.patient_clinic.rooms.length > 0 ? (
                          <Index
                            patients={props.patient}
                            isOwner={false}
                            appointmentTypes={
                              props.patient.patient_clinic
                                .allowed_appointment_types
                            }
                            clinicScheduler={
                              props.patient.patient_clinic.scheduler
                            }
                            rooms={props.patient.patient_clinic.rooms}
                            csrfToken={props.csrfToken}
                            clinicId={props.patient.clinic_id}
                          />
                        ) : (
                          <div className='card'>
                            <div className='card-body'>
                              Clinic needs to create Appointment Types and Rooms
                              first!
                            </div>
                          </div>
                        )}
                      </Tab>
                    )}
                    <Tab
                      unmountOnExit
                      eventKey='past_packages_tab'
                      title='Past Packages'
                      className='tab-content'
                    >
                      <PastPackages patient={props.patient} />
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='contact_info_tab'
                      title='Contact Info'
                      className='tab-content'
                    >
                      <PatientContactInfo patient={props.patient} />
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='message_tab'
                      title={<>Messages {hasUnreadMessages && <Dot />} </>}
                      className='tab-content'
                      onClick={onMessagesClicked}
                    >
                      <Conversation patient={props.patient} />
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='videos_tab'
                      title='Videos'
                      className='tab-content'
                    >
                      <Video videos={props.patient.videos} />
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='intake_form_tab'
                      title='Intake Form'
                      className='tab-content'
                    >
                      <div id='form-container' className='card mt-3'></div>
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='orders_tab'
                      title='Orders/Account'
                      className='tab-content'
                    >
                      <Orders />
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='assessment_tab'
                      title='Client Assessments'
                      className='tab-content'
                    >
                      <Assessments />
                    </Tab>
                    <Tab
                      unmountOnExit
                      eventKey='refferals_tab'
                      title={props.isOwner ? 'Referrals' : 'Refer a Friend'}
                      className='tab-content'
                    >
                      {props.isOwner ? <ReferralIndex /> : <ReferralForm />}
                    </Tab>
                  </Tabs>
                )}
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        <SelectClient isOwner={props.isOwner} />
      )}
    </div>
  );
};

const Dot = styled.span`
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
`;

const mapStateToProps = state => ({
  isOwner: state.patientIndex.isOwner,
  isFetchingMessageReadRequest: state.patientIndex.isFetchingMessageReadRequest,
  csrfToken: state.patientIndex.csrfToken
});

const mapDispatchToProps = dispatch => ({
  refreshPatient: (clinic_id, patient_id) => {
    dispatch(toggleActivePatient(clinic_id, patient_id));
  },
  onNewMeasurementClick: () => {
    dispatch(toggleMeasurementForm());
  },
  markMessagesRead: (conversationId, isClinic, clinicId) => {
    dispatch(markMessagesRead(conversationId, isClinic, clinicId));
  },
  updatePatient: (
    name,
    email,
    address,
    city,
    state,
    zipcode,
    phone_number,
    archive
  ) => {
    dispatch(
      onContactInfoSubmit(
        name,
        email,
        address,
        city,
        state,
        zipcode,
        phone_number,
        archive
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
