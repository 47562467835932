import { CircularProgress } from '@mui/material';
import React from 'react';
const Loader = () => {
  return (
    <div class='loader-container'>
      <div class='loader-blur'></div>
      <CircularProgress />
    </div>
  );
};

export default Loader;
