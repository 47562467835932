import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { templateMessage } from '../../../../actions/weight_loss';
import { templateMessagesColumns } from '../../../../helpers/weight_loss';
import DataGridMUI from '../../../common/DataGridMUI';

const TemplateMessages = props => {
  const {
    csrfToken,
    clinicId,
    templateMessagesList,
    setTemplateMessagesList,
    handleMessageSend,
    istrue
  } = props;
  const [show, setShow] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [pageSize, setPageSize] = useState(5);

  const handleClose = () => {
    setShow(false)
    setTitle('');
    setMessage('');
    setSelectedMessage(null)
  }
  const handleShow = () => setShow(true);

  const handleCreate = () => {
    templateMessage(
      { title, message },
      csrfToken,
      setTemplateMessagesList,
      selectedMessage ? axios.patch : axios.post,
      selectedMessage
        ? `/${clinicId}/template_messages/${selectedMessage}`
        : `/${clinicId}/template_messages`
    );
    handleClose();
  }

  return (
    <>
      <div className='card'>
        <div className='card-header d-flex flex-column align-items-start flex-md-row justify-content-md-between'>
          <h4 className='font-weight-bold ml-md-3 d-inline'>Custom Template Messages</h4>
          {istrue && (<Button
            variant='btn btn-primary float-right text-white'
            onClick={handleShow}
          >
            Create
          </Button>)}
        </div>
        <div className='card-body'>
          <DataGridMUI
            handlePageSizeChange={newPageSize => setPageSize(newPageSize)}
            pageSize={pageSize}
            list={templateMessagesList}
            columns={templateMessagesColumns(
              handleMessageSend,
              setTitle,
              setMessage,
              setShow,
              csrfToken,
              setTemplateMessagesList,
              setSelectedMessage,
              istrue
            )}
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>Create Custom Template Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <label text='name'>Title:</label>
            <input
              type='text'
              onChange={e => setTitle(e.target.value)}
              value={title}
              className='form-control'
              placeholder='Enter Title...'
            />
          </div>
          <div className='form-group'>
            <label text='name'>Message:</label>
            <textarea
              onChange={e => setMessage(e.target.value)}
              value={message}
              className='form-control'
              placeholder='Enter Message...'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleCreate}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateMessages;
