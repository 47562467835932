import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from 'react-bootstrap';
import { roomsListColumns } from '../../../helpers/room';
import { offListColumns } from '../../../helpers/room_off';
import AddRoom from './AddRoom';
import DoctorAvailibility from '../DoctorAvailibility';
import {
  getOffAppointment,
  deleteAppointment,
  createOffTimeAppointment,
  updateOffTimeAppointment,
  updateClinicRoom,
  deleteRoom
} from '../../../actions/scheduler';
import { convertReactSelectToOptions } from '../../../helpers/scheduler';
import RoomCard from './RoomCard';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ToastContainer } from 'react-toastify';
import { WEEK_DAYS } from '../../../constants';

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#00ba9b2b' : 'white',
  display: 'flex',
  padding: 8,
  overflow: 'auto'
});

const RoomsTable = props => {
  const { clinicId, csrfToken, rooms, appointmentTypes } = props;
  const [drName, setDrName] = useState(null);
  const [roomNum, setRoomNum] = useState(null);
  const [drDesignation, setDrDesignation] = useState(null);
  const [drImage, setDrImage] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showdrModal, setdrModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [clinicAppointmentTypes, setClinicAppointmentTypes] = useState(
    convertReactSelectToOptions(appointmentTypes)
  );
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [checkk, setCheckk] = useState(false);
  const [roomCapacity, setRoomCapacity] = useState(1);
  const [isMultipleAppointmentAllowed, setIsMultipleAppointmentAllowed] =
    useState(false);
  const [endTo, setEndTo] = useState(
    new Date(Date.parse(`${new Date().toDateString()} 17:00`))
  );
  const [startFrom, setStartFrom] = useState(
    new Date(Date.parse(`${new Date().toDateString()} 09:00`))
  );

  const [stime, setStime] = useState(new Date());
  const [etime, setEtime] = useState(new Date());
  const [dr, setDr] = useState(null);

  const [roomData, setRoomData] = useState(() =>
    rooms.sort((a, b) => a.number - b.number)
  );
  const [doctorId, setDoctorId] = useState();
  const [appointments, setAppointments] = useState([]);
  const [reason, setReason] = useState(null);
  const [isDayOff, setIsDayOff] = useState(false);
  const [isCustomTimeSlot, setIsCustomTimeSlot] = useState(false);
  const [hours, setHours] = useState(WEEK_DAYS);
  const [isWeekendOff, setIsWeekendOff] = useState(true);

  useEffect(() => {
    getOffAppointment(clinicId, setAppointments);
  }, [setAppointments]);

  const addAvailibility = async arrayOfData => {
    let appointments = [];
    let isError = false;
    for await (let data of arrayOfData) {
      try {
        if (!checkk) {
          appointments = await createOffTimeAppointment(
            clinicId,
            data,
            csrfToken,
            props.clinicScheduler?.timezone || 'America/New_York'
          );
        } else {
          data.Id = appointmentId;
          appointments = await updateOffTimeAppointment(
            clinicId,
            data,
            csrfToken,
            props.clinicScheduler?.timezone || 'America/New_York'
          );
        }
        const offAppointments = appointments.filter(
          appointment => appointment.IsBlock === true
        );
        setAppointments(offAppointments);
      } catch (e) {
        console.error(e.message);
        isError = true;
        toastr.error(e.mesage);
      }
    }
    !isError && toastr.success('Off Time Added!');
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragStart = () => {
    // vibrate on mobile devices
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = async result => {
    // dropped outside the list
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items = reorder(
      roomData,
      result.source.index,
      result.destination.index
    );

    const selectedRoom = roomData.find(
      room => room.id === parseInt(result.draggableId)
    );

    let room = {
      rooms: {
        clinic_id: selectedRoom.clinic_id,
        designation: selectedRoom.designation,
        name: selectedRoom.name,
        number: roomData[result.destination.index].number,
        image: selectedRoom.drImage || undefined,
        allowed_types: selectedRoom.allowed_types.map(x => x.value),
        is_multiple_appointment: selectedRoom.is_multiple_appointment,
        capacity: selectedRoom.capacity,
        end_to: new Date(selectedRoom.end_to),
        start_from: new Date(selectedRoom.start_from)
      }
    };
    setRoomData(items);

    try {
      const result = await updateClinicRoom(room, selectedRoom.id, csrfToken);
      toastr.success('Room Updated!');
      setRoomData(result.sort((a, b) => a.number - b.number));
    } catch (e) {
      toastr.error(e.message);
      console.error(e.message);
    }
  };

  const handleUpdate = room => {
    setDoctorId(room.id);
    setDrName(room.name);
    setRoomNum(room.number);
    setDrDesignation(room.designation);
    setDrImage(room.drImage || undefined);
    setShowModal(true);
    setSelectedOptions(
      clinicAppointmentTypes.filter(function (item) {
        return room.allowed_types.indexOf(item.value) != -1;
      })
    );
    setRoomCapacity(room.capacity);
    setIsMultipleAppointmentAllowed(room.is_multiple_appointment);
    setEndTo(new Date(room.end_to));
    setStartFrom(new Date(room.start_from));
    setIsCustomTimeSlot(room.custom_time_slots);
    setIsWeekendOff(room.is_weekend_off)

    const calculateHours = room.hours.map(hour => ({
      id: hour.id,
      endHour: Date.parse(`${new Date().toDateString()} ${hour.endHour}`),
      startHour: Date.parse(`${new Date().toDateString()} ${hour.startHour}`),
      isBreakTimeVisible: hour.isBreakTimeVisible,
      breakStartTime: Date.parse(`${new Date().toDateString()} ${hour.breakStartTime}`),
      breakEndTime: Date.parse(`${new Date().toDateString()} ${hour.breakEndTime}`)
    }));

    setHours(calculateHours);
  };

  const handleDelete = async room => {
    try {
      await deleteRoom(room.clinic_id, room.id, setRoomData);
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <div className='card'>
      <div className='card-header font-weight-bold d-flex justify-content-between align-items-center'>
        <h4 className='font-weight-bold ml-3 d-flex align-items-center mb-0'>
          Rooms / Resources
        </h4>
        <div className='d-flex justify-content-end'>
          <Button
            variant='btn btn-glow-secondary btn-primary float-right text-white'
            onClick={() => setShowModal(true)}
            style={{ height: '35px' }}
          >
            Create
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId='droppable' direction='horizontal'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {roomData.map((room, index) => (
                <Draggable
                  key={room.id}
                  draggableId={room.id.toString()}
                  index={index}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <RoomCard
                        room={room}
                        handleDelete={handleDelete}
                        appointmentTypes={appointmentTypes}
                        handleUpdate={handleUpdate}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className='card-header font-weight-bold '>
        <h4 className='font-weight-bold ml-3 d-inline'>Schedule Off Times and Vacations</h4>
        <Button
          variant='btn btn-glow-secondary btn-primary float-right text-white'
          onClick={() => {
            setCheckk(false);
            setdrModal(true);
          }}
        >
          Create
        </Button>
      </div>
      <div className='card-body'>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            getRowId={row => row.Id}
            rows={appointments}
            disableSelectionOnClick
            columns={offListColumns(
              setdrModal,
              clinicId,
              setAppointments,
              deleteAppointment,
              setStime,
              setEtime,
              setDr,
              setCheckk,
              setAppointmentId,
              setReason,
              setIsDayOff
            )}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </div>
      </div>
      <AddRoom
        drName={drName}
        roomNum={roomNum}
        drDesignation={drDesignation}
        drImage={drImage}
        roomCapacity={roomCapacity}
        appointmentTypes={clinicAppointmentTypes}
        endTo={endTo}
        hours={hours}
        isCustomTimeSlot={isCustomTimeSlot}
        startFrom={startFrom}
        setDrName={setDrName}
        setRoomNum={setRoomNum}
        setDrDesignation={setDrDesignation}
        setDrImage={setDrImage}
        showModal={showModal}
        setShowModal={setShowModal}
        csrfToken={csrfToken}
        clinicId={clinicId}
        doctorId={doctorId}
        setRoomData={setRoomData}
        setDoctorId={setDoctorId}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        setRoomCapacity={setRoomCapacity}
        isMultipleAppointmentAllowed={isMultipleAppointmentAllowed}
        setIsMultipleAppointmentAllowed={setIsMultipleAppointmentAllowed}
        setEndTo={setEndTo}
        setStartFrom={setStartFrom}
        setIsCustomTimeSlot={setIsCustomTimeSlot}
        setHours={setHours}
        rooms={roomData}
        setIsWeekendOff={setIsWeekendOff}
        isWeekendOff={isWeekendOff}
        addAvailibility={addAvailibility}
      />
      {showdrModal && (
        <DoctorAvailibility
          sDate={stime}
          eDate={etime}
          drs={roomData}
          check={checkk}
          showdrModal={showdrModal}
          setdrModal={setdrModal}
          dr={dr}
          addAvailibility={addAvailibility}
          setDr={setDr}
          appointmentReason={reason}
          isDayOff={isDayOff}
          setIsDayOff={setIsDayOff}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default RoomsTable;
