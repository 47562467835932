import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PhasesCard = props => {
  const { phase, phaseIndex } = props;
  const [arrowIcon, setArrowIcon] = useState(faAngleRight);
  const [isOpen, setIsOpen] = useState(false);
  const createSublist = () =>{
    let listitems = [];
    let convertedHash;
    let message;
    for (let i = 0; i < phase.allowed_food.length; i++) {
      convertedHash = phase.allowed_food[i].replaceAll('=>' , ':');
      convertedHash = JSON.parse(convertedHash);
      message = `${convertedHash?.category}: ${convertedHash?.name}`;
      listitems.push(<li>{message}</li>);
    }
    return listitems;
  }

  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h4 className='font-weight-bold d-inline float-left mb-0 mt-2 mr-2'>
          {`Phase ${phaseIndex + 1} - ${phase.name}`}
        </h4>
        <button
          className='btn btn-sm-secondary btn-primary float-right'
          onClick={() => {
            setIsOpen(!isOpen);
            setArrowIcon(!isOpen ? faAngleDown : faAngleRight);
          }}
        >
          <FontAwesomeIcon icon={arrowIcon} />
        </button>
      </div>
      <Collapse in={isOpen}>
        <div className='card-body'>
          <ul>
            <li>Days: {phase.days}</li>
            <li>Meals: {phase.meals}</li>
            <li>Snacks: {phase.snacks}</li>
            <li>
              Instructions:
              <div dangerouslySetInnerHTML={{ __html: phase.instructions }} />
            </li>
            {phase.allowed_food.length > 0 && (
              <li>
                {' '}
                Allowed Food
                <ul>{createSublist()}</ul>
              </li>
            )}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};

export default PhasesCard;
