import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getEmailUsers } from '../../../actions/email';
import { campaignUserEmailColumns } from '../../../helpers/user_email';
import { DataGrid } from '@mui/x-data-grid';

const EmailUsersModal = props => {
  const { show, setShow, clinicId, emailData } = props;

  const [users, setUsers] = useState([]);

  useEffect(() => {
    getEmailUsers(clinicId, emailData.id, setUsers);
    console.log(users)
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Email Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={users}
              disableSelectionOnClick
              columns={campaignUserEmailColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailUsersModal;
