import React from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';

const AttachmentsModal = ({ show, handleClose, pdfFiles }) => (
  <Modal
    show={show}
    onHide={handleClose}
    size="lg"
    style={{ maxWidth: '95%', margin: 'auto' }}
  >
    <Modal.Header closeButton>
      <Modal.Title>Attachments</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {pdfFiles.length > 0 ? (
        <ListGroup>
          {pdfFiles.map((url, index) => {
            const fileName = url
              .split('/')
              .pop()
              .split('?')
              .shift()
              .replace('.pdf', '');
            return (
              <ListGroup.Item
                key={index}
                className="d-flex justify-content-between align-items-center p-3"
              >
                <span>
                  {index + 1}
                  .
                  {fileName}
                </span>
                <Button
                  variant="primary"
                  onClick={() => window.open(url, '_blank')}
                >
                  View
                </Button>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : (
        <p>No PDF files attached.</p>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default AttachmentsModal;
