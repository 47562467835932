import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';
import { editorConfig } from '../../../helpers/template';

const TemplateEditor = props => {
  const { body, setBody, setTitle, title, subject, setSubject } = props;
  const editor = useRef(null);

  return (
    <>
      <div className='form-group'>
        Title:
        <input
          className='form-control'
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <div className='form-group'>
        Subject:
        <input
          className='form-control'
          value={subject}
          onChange={e => setSubject(e.target.value)}
        />
      </div>
      <JoditEditor
        ref={editor}
        value={body}
        config={(editorConfig(false))}
        tabIndex={1}
        onBlur={newContent => setBody(newContent)}
      />
    </>
  );
};

export default TemplateEditor;
