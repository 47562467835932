import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import MITLogo from '../../../assets/images/logo_MIT_Ver_color.png';
import { Modal, Button, Spinner } from 'react-bootstrap';
import PaymentForm from './PaymentForm';
import { startOfDay } from 'date-fns';

const SignupForm = ({
  csrfToken,
  usStates,
  cardConnectSite,
  lasers,
  affiliates
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    cellPhone: '',
    clinicName: '',
    billingAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    bodyContouringDevice: '',
    merchantProcessing: '',
    affiliate: ''
  });

  const [showSignupButton, setShowSignupButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === 'merchantProcessing') {
      setShowSignupButton(true);
    }
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption ? selectedOption.value : ''
    });
  };

  const handleAffiliateChange = selectedOption => {
    setFormData({
      ...formData,
      affiliate:
        selectedOption && selectedOption.value === formData.affiliate
          ? ''
          : selectedOption
          ? selectedOption.value
          : ''
    });
  };

  const handleProceedToPayment = e => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handlePaymentSubmit = cardData => {
    setLoading(true);
    setIsModalOpen(false);
    const submissionDate = startOfDay(new Date());

    let merchantTag = '';
    if (formData.merchantProcessing === 'representative_contact') {
      merchantTag = 'process card connect';
    } else if (formData.merchantProcessing === 'no_processing') {
      merchantTag = 'no card connect';
    }

    const data = {
      authenticity_token: csrfToken,
      clinic: {
        name: formData.clinicName,
        laser_id: formData.bodyContouringDevice,
        preferred_email: formData.email,
        user: {
          email: formData.email,
          name: `${formData.firstName} ${formData.lastName}`,
          address: formData.billingAddress,
          city: formData.city,
          state: formData.state,
          zipcode: formData.postalCode,
          phone_number: formData.cellPhone,
          role: 'owner'
        }
      },
      card_data: cardData,
      tags: ['MIT Client', merchantTag]
    };

    if (formData.affiliate && formData.affiliate !== '') {
      data.clinic.clinic_affiliates_attributes = {
        0: {
          affiliate_id: formData.affiliate,
          start_date: submissionDate.toISOString().split('T')[0],
          end_date: '2099-12-31'
        }
      };
    }

    fetch('/clinics/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.errors) {
          setIsError(true);
          setMessage(data.errors.join(', '));
        } else {
          setIsError(false);
          setSuccess(true);
          setIsModalOpen(false);
        }
      })
      .catch(error => {
        setLoading(false);
        setIsError(true);
        setMessage('An error occurred. Please try again.');
      });
  };

  const stateOptions = usStates.map(state => ({
    value: state[0],
    label: state[0]
  }));

  const laserOptions = lasers.map(laser => ({
    value: laser.id,
    label: laser.model
  }));

  const affiliateOptions = [
    { value: '', label: 'None' },
    ...affiliates.map(affiliate => ({
      value: affiliate.id,
      label: affiliate.name
    }))
  ];

  return (
    <div
      className='py-5 d-flex justify-content-center'
      style={{ padding: '20px' }}
    >
      {loading && (
        <div className='loader-container'>
          <div className='loader-blur'></div>
          <div className='loader'>
            <Spinner animation='border' variant='primary' />
          </div>
        </div>
      )}
      {success ? (
        <div className='text-center'>
          <h4>Congratulations! You've Successfully Signed Up!</h4>
          <p>
            We have sent you an email with instructions to set up your password.
            Please check your inbox (and spam folder) to complete the process.
          </p>
        </div>
      ) : (
        <div
          className={`card ${loading ? 'disabled-form' : ''}`}
          style={{ maxWidth: '700px', width: '100%', padding: '20px' }}
        >
          <div className='card-body'>
            <div className='text-center mb-4'>
              <img
                src={MITLogo}
                className='img-fluid mb-3'
                alt='Logo'
                style={{ maxWidth: '150px' }}
              />
              <h5
                className='card-title text-center text-muted'
                style={{ fontWeight: 'normal' }}
              >
                Sign Up
              </h5>
            </div>
            {message && (
              <div
                className={`alert ${
                  isError ? 'alert-danger' : 'alert-success'
                }`}
                style={{ marginBottom: '20px' }}
              >
                {message}
              </div>
            )}
            <form onSubmit={handleProceedToPayment}>
              <input
                type='hidden'
                name='authenticity_token'
                value={csrfToken}
              />

              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <label htmlFor='firstName'>
                    First name <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='firstName'
                    name='firstName'
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='col-md-6 mb-4'>
                  <label htmlFor='lastName'>
                    Last name <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='lastName'
                    name='lastName'
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <label htmlFor='email'>
                    Email <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='col-md-6 mb-4'>
                  <label htmlFor='cellPhone'>
                    Cell Phone <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='tel'
                    className='form-control'
                    id='cellPhone'
                    name='cellPhone'
                    value={formData.cellPhone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className='mb-4'>
                <label htmlFor='clinicName'>
                  Clinic Name <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='clinicName'
                  name='clinicName'
                  value={formData.clinicName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <label htmlFor='billingAddress'>
                    Billing Address <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='billingAddress'
                    name='billingAddress'
                    value={formData.billingAddress}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='col-md-6 mb-4'>
                  <label htmlFor='state'>
                    State <span className='text-danger'>*</span>
                  </label>
                  <Select
                    options={stateOptions}
                    onChange={handleSelectChange}
                    name='state'
                    className='basic-single'
                    classNamePrefix='select'
                    required
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <label htmlFor='city'>
                    City <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='city'
                    name='city'
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='col-md-6 mb-4'>
                  <label htmlFor='postalCode'>
                    Zip Code <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='postalCode'
                    name='postalCode'
                    value={formData.postalCode}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className='mb-4'>
                <label htmlFor='country'>
                  Country <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='country'
                  name='country'
                  value={formData.country}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-4'>
                <label>
                  Name of Your Body Contouring Device{' '}
                  <span className='text-danger'>*</span>
                </label>
                <Select
                  options={laserOptions}
                  onChange={handleSelectChange}
                  name='bodyContouringDevice'
                  className='basic-single'
                  classNamePrefix='select'
                  required
                />
              </div>

              <div className='mb-4'>
                <label>Who referred you?</label>
                <Select
                  options={affiliateOptions}
                  onChange={handleAffiliateChange}
                  name='affiliate'
                  className='basic-single'
                  classNamePrefix='select'
                  value={affiliateOptions.find(
                    option => option.value === formData.affiliate
                  )}
                />
              </div>

              <div className='mb-4'>
                <label>
                  Merchant Processing/Point of Sale/Financial Reporting
                </label>
                <span className='text-danger'>*</span>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='merchantProcessing'
                    value='representative_contact'
                    id='merchant_processing_contact'
                    onChange={handleChange}
                    required
                  />
                  <label
                    className='form-check-label'
                    htmlFor='merchant_processing_contact'
                  >
                    I would like a representative from Card Connect to contact
                    me so I can accept payments through the software and save
                    thousands in merchant processing fees
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='merchantProcessing'
                    value='no_processing'
                    id='merchant_processing_no'
                    onChange={handleChange}
                    required
                  />
                  <label
                    className='form-check-label'
                    htmlFor='merchant_processing_no'
                  >
                    I do not want to process payments through the app
                  </label>
                </div>
              </div>

              {showSignupButton && (
                <button
                  type='submit'
                  className='btn btn-primary'
                  style={{ width: '100%', padding: '12px', fontSize: '16px' }}
                >
                  Proceed To Payment
                </button>
              )}
            </form>
          </div>
        </div>
      )}

      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        centered
        dialogClassName='modal-dialog-centered'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '500px',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            style={{
              borderRadius: '10px',
              padding: '20px',
              fontSize: '16px',
              color: '#333',
              marginBottom: '20px',
              backgroundColor: 'pink'
            }}
          >
            I authorize My Ideal Tracker to charge my credit card $1.00 for the
            45-day trial period.
          </p>

          <PaymentForm
            cardConnectSite={cardConnectSite}
            usStates={usStates}
            onSubmit={handlePaymentSubmit}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

SignupForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  usStates: PropTypes.arrayOf(PropTypes.array).isRequired,
  cardConnectSite: PropTypes.string.isRequired,
  lasers: PropTypes.arrayOf(PropTypes.object).isRequired,
  affiliates: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SignupForm;
