export const indexHeadCells = [
  {
    id: 'client',
    numeric: false,
    disablePadding: true,
    label: 'Client'
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: true,
    label: 'Created On'
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Unpaid Balance'
  },
  {
    id: 'amount_paid',
    numeric: true,
    disablePadding: false,
    label: 'Amount Paid'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  }
];

export const pendingFundCells = [
  {
    id: 'client',
    numeric: false,
    disablePadding: true,
    label: 'Client'
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: true,
    label: 'Created On'
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Unpaid Balance'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  }
]
