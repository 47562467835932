import axios from 'axios';
import { GET_STORE_ITEMS_SUCCESSFULLY } from './actionTypes';
import { finishFetching, startFetching } from './fetching';

const getStoreItemsSuccessfully = (json) => ({
  type: GET_STORE_ITEMS_SUCCESSFULLY,
  payload: json,
});

const getStoreItems = (clinic) => {
  const url = `/${clinic}/store_items.json`;

  return ((dispatch) => {
    dispatch(startFetching());
    axios
      .get(url, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        dispatch(getStoreItemsSuccessfully(response.data));
      })
      .then(() => {
        dispatch(finishFetching());
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch(finishFetching());
      });
  });
};

export { getStoreItems };
