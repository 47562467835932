import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import placeholder from '../../../../assets/images/placeholder.jpg';
import { Card, CardContent, CardHeader, CardMedia, Chip, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import AreYouSureModal from '../../common/AreYouSureModal';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    maxHeight: 470,
    width: 300
  },
  media: {
    height: 160
  },
  button: {
    margin: theme.spacing(1),
    color: '#fff',
    backgroundColor: '#000'
  },
  elipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerText:{
    fontSize: '9px',
    color: '#757477'
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: '0px',
    color: 'white !important'
  },
    noOutline: {
    outline: 'none',
  },
  title:{
    fontSize: '1.5rem'
  }
}));

const RoomCard = ({
  handleDelete,
  room,
  appointmentTypes,
  handleUpdate
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [types, setTypes] = useState([]);
  const [isSureModalOpen, setIsSureModalOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const data = appointmentTypes.filter(type =>
      room.allowed_types.includes(type.id)
    );
    setTypes(data);
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSureMoal = () => {
    setIsSureModalOpen(!isSureModalOpen);
  };

  return (
    <Card className={classes.root}>
      <div className='d-flex flex-column justify-content-center pb-1 px-3 mb-3'>
        <div className='d-flex justify-content-between pt-2 mb-1'>
          <Tooltip title={room.name} placement='top'>
            <span className={`${classes.elipsis} ${classes.title} mb-0`}>
              {room.name}
            </span>
          </Tooltip>
          <IconButton
            aria-label='actions'
            className='pr-0 pt-0'
            style={{ outline: 'none' }}
            edge={false}
            onClick={handleClick}
            disableRipple={true}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
        <div className='d-flex justify-content-between'>
          <Typography variant='body2' className='d-flex flex-column'>
            <span className={classes.headerText}>Availability</span>
            {room.custom_time_slots
              ? 'multiple'
              : `${room.hours[0].startHour} - ${room.hours[0].endHour}`}
          </Typography>
          <div className='d-flex justify-content-between'>
            <Typography
              variant='body2'
              className='d-flex flex-column mr-2 room-card-attribute'
            >
              <span className={classes.headerText}>Multiple</span>
              {room.is_multiple_appointment.toString()}
            </Typography>
            <Typography
              variant='body2'
              className='d-flex flex-column align-items-center room-card-attribute'
            >
              <span className={classes.headerText}>Capacity</span>
              {room.capacity}
            </Typography>
          </div>
        </div>
      </div>
      <CardMedia
        image={room.profile_image_url ? room.profile_image_url : placeholder}
        className={classes.media}
      />
      <CardContent>
        <Typography
          variant='body2'
          color='text.secondary'
          className={classes.elipsis}
        >
          {room.designation ? room.designation : <span>&zwnj;</span>}
        </Typography>
      </CardContent>
      <Stack
        direction='row'
        spacing={1}
        className='mb-2 overflow-auto px-3 align-items-center'
      >
        {types?.map(tag => (
          <Chip
            key={tag.id}
            label={tag.name}
            className={classes.chip}
            style={{ backgroundColor: tag.color }}
          />
        ))}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleUpdate(room);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleSureMoal();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <AreYouSureModal
        handleClose={handleSureMoal}
        open={isSureModalOpen}
        action={() => handleDelete(room)}
      />
    </Card>
  );
};

export default RoomCard;
