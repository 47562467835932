import React from 'react'

const updateComparison = (comparisons, measurement, area, displayName) => {
	let updated = comparisons[area] ? comparisons[area] : { displayName };

	if(measurement[area]) {
		if (updated.first) {
			updated.last = measurement[area]
		} else {
			updated.first = measurement[area]
		}
	}

	return updated;
}

const MeasurementDifferenceSummary = props => {
	let comparisons = {};
	const measurements = props.measurements;

	for (var i = 0; i < measurements.length; i++)
	{
		comparisons.neck = updateComparison(comparisons, measurements[i], "neck", "Neck");
		comparisons.chest = updateComparison(comparisons, measurements[i], "chest", "Chest");
		comparisons.left_arm = updateComparison(comparisons, measurements[i], "left_arm", "Left Arm");
		comparisons.right_arm = updateComparison(comparisons, measurements[i], "right_arm", "Right Arm");
		comparisons.bellybutton = updateComparison(comparisons, measurements[i], "bellybutton", "Belly Button");
		comparisons.hips = updateComparison(comparisons, measurements[i], "hips", "Hips");
		comparisons.above_bb = updateComparison(comparisons, measurements[i], "above_bb", "Above Umbilicus");
		comparisons.below_bb = updateComparison(comparisons, measurements[i], "below_bb", "Below Umbilicus");
		comparisons.left_thigh = updateComparison(comparisons, measurements[i], "left_thigh", "Left Thigh");
		comparisons.right_thigh = updateComparison(comparisons, measurements[i], "right_thigh", "Right Thigh");
		comparisons.left_calf = updateComparison(comparisons, measurements[i], "left_calf", "Left Calf");
		comparisons.right_calf = updateComparison(comparisons, measurements[i], "right_calf", "Right Calf");
		comparisons.weight = updateComparison(comparisons, measurements[i], "weight");
	}

	let totalInchesLost = 0;
	let totalWeightLost = 0;
	let rows = [];
	let rowContent, rowDiff;

	for(var area in comparisons) {
		if (area == "weight") {
			totalWeightLost += comparisons[area].first - comparisons[area].last;
		} else {
			if (comparisons[area].last && comparisons[area].first) {
				rowDiff = comparisons[area].first - comparisons[area].last;
				totalInchesLost += rowDiff;
				rowContent = rowDiff.toFixed(3);
			} else {
				rowContent = '-';
			}
			rows.push(<div className="col-lg-3 col-md-4 col-sm-6" id={area} key={area}>{comparisons[area].displayName}: {rowContent}</div>)
		}
	}

	return (
		<div className="card mt-3">
			<div className="card-body">
				<div className="card-title row">
					<div className="col-sm-6">
						<h5 className="subheading">Total Inches Lost: <span className="highlighted-text">{totalInchesLost.toFixed(2)}</span></h5>
					</div>
					<div className="col-sm-6">
						<h5 className="subheading">Total Weight Lost: <span className="highlighted-text">{totalWeightLost.toFixed(1)}</span></h5>
					</div>
				</div>
				<div className="row">
					{rows}
				</div>
			</div>
		</div>
	)
}

export default MeasurementDifferenceSummary
