import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  createNewClinicRoom,
  updateClinicRoom
} from '../../../actions/scheduler';
import { validateSize } from '../../../helpers/template';
import Select from 'react-select';
import { Checkbox, TextField } from '@mui/material';
import { PRIMARY_COLOR, WEEK_DAYS, WEEK_NAMES } from '../../../constants';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toDate } from 'date-fns-tz';
import { getDateByDayName, handleDateFormat } from '../../../helpers/room';

const AddRoom = ({
  showModal,
  setShowModal,
  csrfToken,
  clinicId,
  drName,
  roomNum,
  drDesignation,
  drImage,
  endTo,
  startFrom,
  setDrName,
  setRoomNum,
  setDrDesignation,
  setDrImage,
  doctorId,
  setRoomData,
  setDoctorId,
  appointmentTypes,
  setSelectedOptions,
  selectedOptions,
  setRoomCapacity,
  roomCapacity,
  isMultipleAppointmentAllowed,
  setIsMultipleAppointmentAllowed,
  rooms,
  hours,
  setHours,
  isCustomTimeSlot,
  setIsCustomTimeSlot,
  isWeekendOff,
  setIsWeekendOff
}) => {
  const handleAdd = (day, roomId) => {
    if (day.breakStartTime > day.breakEndTime) {
      toastr.error('Invalid Date');
      return {};
    }

    const breakStartTime = getDateByDayName(
      new Date(day.breakStartTime),
      day.id - 1
    );

    const breakEndTime = getDateByDayName(
      new Date(day.breakEndTime),
      day.id - 1
    );

    const weekIntial = new Date(breakStartTime)
      .toLocaleDateString('en-US', { weekday: 'long' })
      .toUpperCase()
      .slice(0, 2);

    if ((!JSON.parse(day.isBreakTimeVisible) && !(weekIntial == 'SU' || weekIntial == 'SA'))) return {};
    if (
      (weekIntial == 'SU' || weekIntial == 'SA') &&
      !JSON.parse(day.isBreakTimeVisible) && !isWeekendOff
    )
      return {};
    const data = {
      Subject: 'Not Available',
      StartTime: new Date(breakStartTime).toISOString(),
      EndTime: new Date(breakEndTime).toISOString(),
      IsAllDay:
        isWeekendOff && (weekIntial == 'SU' || weekIntial == 'SA')
          ? isWeekendOff
          : false,
      IsBlock: true,
      RoomId: roomId,
      RecurrenceRule: `FREQ=WEEKLY;INTERVAL=1;BYDAY=${weekIntial}`
    };
    
    return data;
  };

  const resetData = () => {
    setDrName('');
    setRoomNum('');
    setDrDesignation('');
    setDrImage(undefined);
    setShowModal(false);
    setDoctorId('');
    setRoomCapacity(1);
    setIsMultipleAppointmentAllowed(false);
    setHours(WEEK_DAYS);
    setIsCustomTimeSlot(false);
    setIsWeekendOff(true);
  };

  const handleValidation = isUpdate => {
    if (selectedOptions == null || selectedOptions.length < 1) {
      toastr.error('Please select appointment type');
      return true;
    }

    if (!isUpdate && rooms.find(room => room.number === parseInt(roomNum))) {
      toastr.error('Room Number should be unique');
      return true;
    }

    return false;
  };

  const handleWeekDay = (id, name, value) => {
    const isBooleanValue = typeof value == 'boolean';

    const newWeekDays = hours.map(day => {
      if (day.id == id)
        return { ...day, [name]: isBooleanValue ? value : new Date(value) };
      else return day;
    });
    setHours(newWeekDays);
  };

  const handleSave = async () => {
    if (handleValidation(false)) return;
    const newHours = hours.map(day => ({
      id: day.id,
      startHour: isCustomTimeSlot
        ? getOnlyHours(day.startHour)
        : getOnlyHours(hours[0].startHour),
      endHour: isCustomTimeSlot
        ? getOnlyHours(day.endHour)
        : getOnlyHours(hours[0].endHour),
      isBreakTimeVisible: day.isBreakTimeVisible,
      breakStartTime: getOnlyHours(day.breakStartTime),
      breakEndTime: getOnlyHours(day.breakEndTime)
    }));

    let room = {
      rooms: {
        clinic_id: clinicId,
        designation: drDesignation,
        name: drName,
        number: rooms.length > 0 ? rooms[rooms.length - 1].number + 1 : 0,
        image: drImage,
        allowed_types: selectedOptions.map(x => x.value),
        is_multiple_appointment: isMultipleAppointmentAllowed,
        capacity: roomCapacity,
        start_from: toDate(startFrom).toString(),
        end_to: toDate(endTo).toString(),
        hours: newHours,
        custom_time_slots: isCustomTimeSlot,
        is_weekend_off: isWeekendOff
      }
    };
    try {
      const result = await createNewClinicRoom(room, csrfToken);
      setShowModal(false);

      const breakTimes = {};
      hours.forEach(day => {
        const data = handleAdd(day, result[result.length - 1].id);
        if (Object.keys(data).length > 0) {
          breakTimes[day.id - 1] = data;
        }
      });

      room.rooms['break_time'] = breakTimes;

      await updateClinicRoom(room, result[result.length - 1].id, csrfToken);

      setRoomData(result.sort((a, b) => a.number - b.number));
      toastr.success('Room Created');
      resetData();
    } catch (e) {
      toastr.error(e.message);
      console.error(e.message);
    }
  };

  const getOnlyHours = dateTime => {
    const date = new Date(dateTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  };

  const handleUpdate = async () => {
    if (handleValidation(true)) return;
    const updatedHours = hours.map(day => ({
      id: day.id,
            startHour: isCustomTimeSlot
        ? getOnlyHours(day.startHour)
        : getOnlyHours(hours[0].startHour),
      endHour: isCustomTimeSlot
        ? getOnlyHours(day.endHour)
        : getOnlyHours(hours[0].endHour),
      isBreakTimeVisible: day.isBreakTimeVisible,
      breakStartTime: getOnlyHours(day.breakStartTime),
      breakEndTime: getOnlyHours(day.breakEndTime)
    }));

    console.log(updatedHours, 'updatedHours');

    let breakTimes = {};
    let keysSum = 0;
    hours.forEach(day => {
      const data = handleAdd(day, doctorId);
      const value = Object.keys(data).length;
            keysSum += value;
      if (value > 0) {
        breakTimes[day.id - 1] = data;
      }
    });

    if (keysSum === 0) breakTimes = null;

    let room = {
      rooms: {
        clinic_id: clinicId,
        designation: drDesignation,
        name: drName,
        number: roomNum,
        image: drImage,
        allowed_types: selectedOptions.map(x => x.value),
        is_multiple_appointment: isMultipleAppointmentAllowed,
        capacity: roomCapacity,
        end_to: toDate(endTo).toString(),
        start_from: toDate(startFrom).toString(),
        hours: updatedHours,
        custom_time_slots: isCustomTimeSlot,
        break_time: breakTimes,
        is_weekend_off: isWeekendOff
      }
    };

    console.log(breakTimes, 'breakTimes');
    console.log(room, 'room');

    try {
      const result = await updateClinicRoom(room, doctorId, csrfToken);
      setShowModal(false);
      setRoomData(result.sort((a, b) => a.number - b.number));
      toastr.success('Room Updated!');
      resetData();
    } catch (e) {
      toastr.error(e.message);
      console.error(e.message);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => resetData()}
      backdrop='static'
      keyboard={false}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Resource/Room</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='form-group'>
          Name:
          <input
            className='form-control'
            type='text'
            value={drName}
            required={true}
            onChange={e => setDrName(e.target.value)}
          />
        </div>
        <div className='form-group'>
          Description:
          <input
            className='form-control'
            type='text'
            value={drDesignation}
            required={true}
            onChange={e => setDrDesignation(e.target.value)}
          />
        </div>
        <div className='form-group mt-1'>
          <Checkbox
            checked={isMultipleAppointmentAllowed}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={() =>
              setIsMultipleAppointmentAllowed(!isMultipleAppointmentAllowed)
            }
          />
          Allow Multiple Appointment
          <div className='form-group mt-1'>
            Capacity:
            <input
              className='form-control'
              type='number'
              min={1}
              value={roomCapacity}
              required={true}
              onChange={e => setRoomCapacity(e.target.value)}
              disabled={!isMultipleAppointmentAllowed}
            />
          </div>
        </div>
        <div className='form-group mt-1 mb-4'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className='d-flex'>
              <div className='mr-4'>
                <Checkbox
                  checked={isCustomTimeSlot}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={() => setIsCustomTimeSlot(!isCustomTimeSlot)}
                />
                Custom Time Slots
              </div>
              <div>
                <Checkbox
                  checked={isWeekendOff}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={() => setIsWeekendOff(!isWeekendOff)}
                />
                Mark Weekend Off
              </div>
            </div>
            {isCustomTimeSlot ? (
              hours.map((day, index) => {
                return (
                  <div
                    className='form-group mt-1 d-flex flex-column align-items-start justify-content-center'
                    key={day.id}
                  >
                    <span style={{ width: '15%' }} className='mr-4 mb-3'>
                      {WEEK_NAMES[index]}:
                    </span>
                    <div className='mb-4 d-flex align-items-center'>
                      <span className='mr-3'>
                        <TimePicker
                          ampm={true}
                          renderInput={params => <TextField {...params} />}
                          label='Day Start Time'
                          value={day.startHour}
                          disabled={
                            isWeekendOff && (index == 0 || index == 6)
                              ? true
                              : false
                          }
                          onChange={value =>
                            handleWeekDay(day.id, 'startHour', value)
                          }
                        />
                      </span>
                      <TimePicker
                        ampm={true}
                        renderInput={params => <TextField {...params} />}
                        label='Day End Time'
                        value={day.endHour}
                        disabled={
                          isWeekendOff && (index == 0 || index == 6)
                            ? true
                            : false
                        }
                        onChange={value =>
                          handleWeekDay(day.id, 'endHour', value)
                        }
                      />
                      <button
                        className={`btn btn-sm-secondary btn-primary ml-2 btn-responsive`}
                        style={{ height: '40px' }}
                        onClick={() =>
                          handleWeekDay(
                            day.id,
                            'isBreakTimeVisible',
                            !JSON.parse(hours[index].isBreakTimeVisible)
                          )
                        }
                      >
                        {JSON.parse(day.isBreakTimeVisible)
                          ? 'Remove Break Time'
                          : 'Add Break Time'}
                      </button>
                    </div>
                    <div
                      className={` ${
                        JSON.parse(day.isBreakTimeVisible) ? 'd-flex' : 'd-none'
                      }`}
                    >
                      <span className='ml-4 mr-3'>
                        <TimePicker
                          label='Break Start Time'
                          renderInput={params => <TextField {...params} />}
                          value={day.breakStartTime}
                          // onError={error => toastr.error('Invalid Date')}
                          onChange={value =>
                            handleWeekDay(day.id, 'breakStartTime', value)
                          }
                          disabled={
                            isWeekendOff && (index == 0 || index == 6)
                              ? true
                              : false
                          }
                        />
                      </span>
                      <TimePicker
                        label='Break End Time'
                        renderInput={params => <TextField {...params} />}
                        // onError={error => toastr.error('Invalid Date')}
                        onChange={value =>
                          handleWeekDay(day.id, 'breakEndTime', value)
                        }
                        value={day.breakEndTime}
                        disabled={
                          isWeekendOff && (index == 0 || index == 6)
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='form-group mt-1'>
                <span className='mr-3'>
                  <TimePicker
                    ampm={true}
                    renderInput={params => <TextField {...params} />}
                    label='Day Start Time'
                    value={hours[0].startHour}
                    onChange={value => handleWeekDay(1, 'startHour', value)}
                  />
                </span>
                <TimePicker
                  ampm={true}
                  renderInput={params => <TextField {...params} />}
                  label='Day End Time'
                  value={hours[0].endHour}
                  onChange={value => handleWeekDay(1, 'endHour', value)}
                />
              </div>
            )}
          </LocalizationProvider>
        </div>

        <div className='form-group mt-1'>
          Select Allowed Appointment Types:
          <Select
            isMulti
            defaultValue={selectedOptions}
            onChange={setSelectedOptions}
            placeholder='Start Typing...'
            options={appointmentTypes}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: PRIMARY_COLOR
              }
            })}
          />
        </div>

        <label
          htmlFor={`file-upload-${1}`}
          className='btn btn-glow-secondary btn-primary ml-2'
        >
          {drImage ? drImage.name : ' Add Image'}
        </label>

        <input
          id={`file-upload-${1}`}
          hidden
          type='file'
          name='file'
          onChange={e => {
            if (validateSize(e)) {
              setDrImage(e.target.files[0]);
            }
          }}
        />

        {drImage && (
          <button
            className='btn btn-sm-secondary btn-primary ml-2'
            onClick={() => {
              setDrImage(undefined);
            }}
          >
            Remove Attachment
          </button>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => resetData()}>
          Close
        </Button>
        {doctorId ? (
          <Button variant='primary' type='Submit' onClick={handleUpdate}>
            Update
          </Button>
        ) : (
          <Button variant='primary' type='Submit' onClick={handleSave}>
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddRoom;
