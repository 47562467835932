import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useRef, useState } from 'react';
import { intakeColumns } from '../../helpers/intakeForm';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import FormCard from './FormCard';

const IntakeForm = ({
  intakeForms,
  redLight,
  nonRedLight,
  submitUrl,
  csrfToken,
  inatkeFormNames,
  patientResponses,
  clinicId
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDynamicFormModalOpen, setIsDynamicFormModalOpen] = useState(false);
  const formRef = useRef();
  const [intakeFormsPage, setIntakeFormsPage] = useState(0);
  const [intakeFormsRowsPerPage, setIntakeFormsRowsPerPage] = useState(5);
  const [patientResponsesPage, setPatientResponsesPage] = useState(0);
  const [patientResponsesRowsPerPage, setPatientResponsesRowsPerPage] = useState(5);
  const ROW_HEIGHT = 65;
  const MIN_GRID_HEIGHT = 420;

  const handleIntakeFormsPageSizeChange = (newPageSize) => {
    if (newPageSize > intakeForms.length) {
      setIntakeFormsRowsPerPage(intakeForms.length); // Display all rows
    } else {
      setIntakeFormsRowsPerPage(parseInt(newPageSize, 10));
    }
  };

  const handlePatientResponsesPageSizeChange = (newPageSize) => {
    if (newPageSize > patientResponses.length) {
      setPatientResponsesRowsPerPage(patientResponses.length); // Display all rows
    } else {
      setPatientResponsesRowsPerPage(newPageSize);
    }
  };


  const copyToClipBoard = (e, id) => {
    const url = id == 'redlinkcopy' ? redLight : nonRedLight;
    navigator.clipboard.writeText(window.location.origin + url);
    e.target.innerHTML = '✔';
    setTimeout(() => {
      document.getElementById(id).innerHTML = '📋';
    }, 2000);
  };

  const handleFormCardClick = id => {
    window.location.href = `dynamic_intake_forms/${id}`;
  };

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const headers = {
      'X-CSRF-Token': csrfToken
    };
    try {
      if (e.target[1].value == 'red' || e.target[1].value == 'non-red') {
        let role = 0;
        if (e.target[1].value == 'red') {
          role = 0;
        } else if (e.target[1].value == 'non-red') {
          role = 1;
        }

        const response = await axios.post(
          submitUrl,
          {
            email: e.target[0].value,
            role: role
          },
          { headers }
        );
        formRef.current.reset();
        setIsModalOpen(false);
        // window.location.href = response.request.responseURL;
        toast.success('Invitation Sent!');
      } else {
        await axios
          .post(
            `/${clinicId}/dynamic_intake_forms/${e.target[1].value}/send_invitation`,
            {
              email: e.target[0].value
            },
            { headers }
          )
          .then(response => {
            toast.success(response.data.message);
          })
          .catch(error => {
            console.error(error.response.data);
            toast.error(error.data.message);
          });
        formRef.current.reset();
        setIsModalOpen(false);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const calculateEmptyRows = (rowsCount, page, rowsPerPage) => {
    return rowsPerPage - Math.min(rowsPerPage, rowsCount - page * rowsPerPage);
  };

  const calculateGridHeight = (rowsCount, page, rowsPerPage) => {
    const filledRowsHeight = Math.min(rowsPerPage, rowsCount - page * rowsPerPage) * ROW_HEIGHT;
    const emptyRows = calculateEmptyRows(rowsCount, page, rowsPerPage);
    const emptyRowsHeight = emptyRows * ROW_HEIGHT;
    const totalGridHeight = filledRowsHeight + emptyRowsHeight;
    return Math.max(totalGridHeight, MIN_GRID_HEIGHT);
  };

  return (
    <>
      <div className='card-body'>
        <p className='d-block'>Create New Intake Form For:</p>

        <div className='btn-group mb-3 mb-md-0'>
          <button
            className='btn btn-danger float-left btn-responsive'
            id='redbtn'
            onClick={() => (window.location.href = redLight)}
          >
            Red Light Therapy Client
          </button>
          <button
            className='btn btn-danger float-left copy-link-btn btn-responsive '
            id='redlinkcopy'
            onClick={e => copyToClipBoard(e, 'redlinkcopy')}
          >
            '📋'
          </button>
        </div>
        <div className='btn-group'>
          <button
            className='ml-md-1 btn btn-sm-secondary btn-primary btn-small float-left btn-responsive'
            id='nonredbtn'
            onClick={() => (window.location.href = nonRedLight)}
          >
            Non Red Light Therapy Client
          </button>
          <button
            className='btn btn-sm-secondary btn-primary btn-small float-left btn-responsive copy-link-btn'
            id='nonredlinkcopy'
            onClick={e => copyToClipBoard(e, 'nonredlinkcopy')}
          >
            '📋'
          </button>
          {inatkeFormNames?.length > 0 && (
            <button
              className='btn btn-secondary float-left btn-responsive ml-md-1'
              onClick={() => setIsDynamicFormModalOpen(true)}
            >
              Fill Dynamic Form
            </button>
          )}
        </div>
        <br />
        <p className='d-block mt-3'>OR</p>
        <button
          type='button'
          onClick={handleModal}
          className='btn btn-secondary mb-4'
        >
          + Let Client Fill Form
        </button>
        <p className='d-block'>Intake Forms Table:</p>
        <div
          style={{
            height: `${calculateGridHeight(
              intakeForms.length,
              intakeFormsPage,
              intakeFormsRowsPerPage
            )}px`,
            width: '100%'
          }}
        >
          <DataGrid
            rows={intakeForms}
            disableSelectionOnClick
            columns={intakeColumns(true)}
            pageSize={intakeFormsRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, intakeForms.length]}
            onPageChange={newPage => setIntakeFormsPage(newPage)}
            onPageSizeChange={handleIntakeFormsPageSizeChange}
            pagination
            rowCount={intakeForms.length}
            page={intakeFormsPage}
            sortingMode='client'
            components={{
              Toolbar: GridToolbar
            }}
          />
        </div>
        <p className='d-block'>Dynamic Intake Forms Table:</p>
        <div
          style={{
            height: `${calculateGridHeight(
              patientResponses.length,
              patientResponsesPage,
              patientResponsesRowsPerPage
            )}px`,
            width: '100%'
          }}
        >
          <DataGrid
            rows={patientResponses}
            disableSelectionOnClick
            columns={intakeColumns(false)}
            pageSize={patientResponsesRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, patientResponses.length]}
            onPageChange={newPage => setPatientResponsesPage(newPage)}
            onPageSizeChange={handlePatientResponsesPageSizeChange}
            pagination
            rowCount={patientResponses.length}
            page={patientResponsesPage}
            components={{
              Toolbar: GridToolbar
            }}
          />
        </div>
      </div>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Send Intake Form To Client</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Modal.Body>
            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email' required />
            </Form.Group>
            <Form.Group controlId='role'>
              <Form.Label>Select Form</Form.Label>
              <Form.Control as='select' required>
                <option value='red'>Red Light Therapy Client</option>
                <option value='non-red'>Non Red Light Therapy Client</option>
                {inatkeFormNames?.map(form => (
                  <option value={form[1]} className='mb-2'>
                    {form[0]}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-success' type='submit'>
              Send
            </Button>
            <Button variant='outline-danger' onClick={handleModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={isDynamicFormModalOpen}
        onHide={() => setIsDynamicFormModalOpen(false)}
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-card-wrapper'>
            {inatkeFormNames?.map(form => (
              <FormCard
                name={form[0]}
                id={form[1]}
                handleClick={handleFormCardClick}
              />
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};
export default IntakeForm;
