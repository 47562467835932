import React from 'react';
import PropTypes from 'prop-types';

const PriceItem = ({ price }) => {
  const temPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);

  return (
    <div>
      {price ? temPrice : null}
    </div>
  );
};

PriceItem.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PriceItem.defaultProps = {
  price: null,
};

export default PriceItem;
