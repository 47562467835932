import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getCampaignStats } from '../../../actions/campaign';
import { campaignUsersListColumns } from '../../../helpers/campaign';
import EmailModal from './EmailModal';
import EmailUsersModal from './EmailUsersModal';

const CampaignStatsModal = props => {
  const { show, setShow, clinicId, campaignId } = props;

  const [users, setUsers] = useState([]);
  const [showEmailModal, setEmailModal] = useState(false);
  const [showEmailUsersModal, setEmailUsersModal] = useState(false);
  const [email, setEmail] = useState({id: null, subject: null, body: null});

  useEffect(() => {
    getCampaignStats(clinicId, campaignId, setUsers);
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Campaign Emails Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={users}
              disableSelectionOnClick
              columns={campaignUsersListColumns(
                setEmail,
                setEmailModal,
                setEmailUsersModal
              )}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </Modal.Body>
      </Modal>
      {showEmailModal && (
        <EmailModal
          email={email}
          show={showEmailModal}
          setShow={setEmailModal}
        />
      )}
      {showEmailUsersModal && (
        <EmailUsersModal
          emailData={email}
          clinicId={clinicId}
          show={showEmailUsersModal}
          setShow={setEmailUsersModal}
        />
      )}
    </>
  );
};

export default CampaignStatsModal;
