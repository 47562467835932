import React from 'react';

export const ToastTemplate = ({ closeToast, subject, startTime, endTime }) => (
  <div className='e-toast-template'>
    <div className='e-toast-message'>
      <div className='e-toast-title'>{subject}</div>
      <div className='e-toast-content'>
        {startTime.toLocaleTimeString()} - {endTime.toLocaleTimeString()}
      </div>
    </div>
  </div>
);
