import React, { useRef, useState } from 'react';

function SignatureInput({ onChange, isSubmit, initialSignature }) {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const startDrawing = e => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.beginPath();
    context.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  };

  const draw = e => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    context.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const saveSignature = () => {
    const canvas = canvasRef.current;
    const newSignatureImage = canvas.toDataURL('image/png');
    setIsEdit(false);
    onChange(newSignatureImage);
  };

  const editSignature = () => {
    setIsEdit(!isEdit);
  }

  const signature = typeof(initialSignature) == 'string' ? initialSignature : '';
  return (
    <>
      {signature == '' || isEdit ? (
        <div className='signature-container'>
          <canvas
            ref={canvasRef}
            width={400}
            height={200}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={endDrawing}
            onMouseLeave={endDrawing}
          ></canvas>
          <div className='signature-controls'>
            <button
              type='button'
              className='btn btn-outline-primary'
              onClick={clearCanvas}
            >
              Clear
            </button>
            <button
              type='button'
              className='btn btn-outline-primary'
              onClick={saveSignature}
            >
              Save Signature
            </button>
           { isEdit && <button
              type='button'
              className='btn btn-outline-primary'
              onClick={editSignature}
            >
              Cancel
            </button>}
          </div>
        </div>
      ) : (
        <>
          <div className='signature-container'>
          <img src={signature} alt='Signature' className='d-block' />
          <div className='signature-controls'>
            <button
              type='button'
              className='btn btn-outline-primary'
              onClick={editSignature}
            >
              Redo
            </button>
          </div>
        </div>
        </>
      )}
      {isSubmit && signature === '' && (
        <small
          id='passwordHelpBlock'
          style={{ color: 'red' }}
          className='form-text'
        >
          This is required
        </small>
      )}
    </>
  );
}

export default SignatureInput;
