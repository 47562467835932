import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import patientIndex from '../reducers/patients'
import { createLogger } from 'redux-logger'
import { combineReducers } from 'redux'

const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
	patientIndex
});

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  )
}