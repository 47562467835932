import React from 'react';
import { Provider } from 'react-redux';
import Message from '../../components/common/Message';
import { store } from '../../helpers/store';
import { BrowserRouter as Router } from 'react-router-dom';

const PatientIndex = props => {
  const {
    clinicId,
    csrfToken,
    isOwner,
    subscriptionActive,
    clinicName,
    cardUrl,
    usStates,
    patients,
    userId,
    selectedPatientId
  } = props;

  const query = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });

  let queryActivePatient = null;
  if (query.patientId) queryActivePatient = parseInt(query.patientId);

  return (
    <Router>
      <Provider
        store={store(
          clinicId,
          csrfToken,
          isOwner,
          userId,
          subscriptionActive,
          clinicName,
          cardUrl,
          usStates
        )}
      >
        <Message
          isOwner={isOwner}
          clinicId={clinicId}
          patients={patients}
          userId={userId}
          type={'patientIndex'}
          csrfToken={csrfToken}
          selectedPatientId={
            queryActivePatient ? queryActivePatient : selectedPatientId
          }
        />
      </Provider>
    </Router>
  );
};

export default PatientIndex;
