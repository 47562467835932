/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import SessionsTable from '../sessions/SessionsTable';
import NewSessionForm from '../sessions/NewSessionForm';

const PastPackages = (props) => {
  const {
    newSessionShow,
    sessionEdit,
    patient,
  } = props;

  const tables = patient.past_packages.map(pkg => (
    <div className='row card mt-3' key={pkg.id}>
      <div className='card-body table-responsive px-2 px-md-4'>
        <h5 className='subheading mt-2 mb-3'>{pkg.name}</h5>
        <SessionsTable sessions={pkg.sessions} />
      </div>
    </div>
  ));

  const shouldShowSessionForm = newSessionShow && sessionEdit
                                && patient.active_packages.length
                                && patient.active_packages[0].sessions.some(
                                  (session) => session.id === sessionEdit.id,
                                );
  return (
    <>
      { shouldShowSessionForm && <NewSessionForm patient={patient} />}
      <div className="row pt-4 pr-4 pl-4">
        <div className="col">
          {tables}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  newSessionShow: state.patientIndex.newSessionShow,
  sessionEdit: state.patientIndex.sessionEdit,
  isOwner: state.patientIndex.isOwner,
  subscriptionActive: state.patientIndex.subscriptionActive,
});

export default connect(
  mapStateToProps,
  null,
)(PastPackages);
