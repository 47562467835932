/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import { newUserPackageSubmit } from '../../actions/user_packages';

const NewClinicPackageSelect = (props) => {

  const packageOptions = props.availableClinicPackages?.map((clinicPackage) => (
    <option key={clinicPackage.id} value={clinicPackage.id}>{clinicPackage.name}</option>
  ));

  const form = (
    <Formik
      enableReinitialize
      initialValues={{
        clinicPackageId:
          props.availableClinicPackages?.length > 0 && props.availableClinicPackages[0].id
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          props.onSubmit(
            values.clinicPackageId,
            props.patient.id,
            props.patient.clinic_id
          );
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='clinic-package-id' className='w-100'>
              <h4>New Package</h4>
              <select
                name='clinicPackageId'
                value={values.clinicPackageId}
                className='form-control'
                id='clinic-package-id'
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {packageOptions}
              </select>
            </label>
          </div>
          <div className='row justify-content-end'>
            <div className='col-auto'>
              <button
                type='submit'
                className='btn btn-outline-success btn-responsive'
              >
                Add
              </button>
              <button
                type='button'
                onClick={() => {
                  window.location.href = `/${props.patient.clinic_id}/clients/${props.patient.id}/package_detail?package_id=${values.clinicPackageId}`;
                }}
                className='btn btn-outline-warning ml-2 btn-responsive'
              >
                Purchase
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );

  return (
    <>
      { props.availableClinicPackages?.length > 0
        ? <>{form}</>
        : undefined }
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (clinicPackageId, patientId, clinicId) => {
    dispatch(newUserPackageSubmit(clinicPackageId, patientId, clinicId));
  },
});

const mapStateToProps = (state) => ({
  availableClinicPackages: state.patientIndex.availableClinicPackages,
  isOwner: state.patientIndex.isOwner,
  clinicId: state.patientIndex.clinicId,
  activePatient: state.patientIndex.activePatient,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewClinicPackageSelect);
