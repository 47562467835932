const initState = (
  fetching = 0, orders = [], clientFilter = '', token = '', logo = '', storeItems = [], cardUrl = '', clinicId = '', usStates = [],
) => ({
  fetching,
  orders,
  clientFilter,
  token,
  logo,
  storeItems,
  cardUrl,
  clinicId,
  usStates,
});

export default initState;
