import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CampaignNew from '../CampaignNew.jsx'
import { AUTOMATED_CAMPAIGN, CUSTOM_CAMPAIGN } from '../../../actions/actionTypes';

const CreateCampaignTabs = props => {
  const { clients, csrfToken, redirect_url, clinicId, templatesList } = props;

  const renderNewCampaignPage = type => (
    <CampaignNew
      clients={clients}
      csrfToken={csrfToken}
      redirect_url={redirect_url}
      clinicId={clinicId}
      templatesList={templatesList}
      campaignType={type}
    />
  );
  return (
    <div className='layout-content'>
      <div className='container-fluid flex-grow-1 container-p-y'>
        <div className='card'>
          <div className='card-header'>
            <Tabs
              defaultActiveKey='custom'
              className='center-nav-tabs'
              unmountOnExit
            >
              <Tab
                eventKey='custom'
                title='Mass Messages'
                className='tab-content'
              >
                {renderNewCampaignPage(CUSTOM_CAMPAIGN)}
              </Tab>
              <Tab
                eventKey='automated'
                title='Automated Campaigns'
                unmountOnExit
              >
                {renderNewCampaignPage(AUTOMATED_CAMPAIGN)}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCampaignTabs;
